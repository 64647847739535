export const enum Preset {
  Details = 'Details',
  DetailsWithMatrix = 'DetailsWithMatrix',
}

export const enum ProductMediaType {
  Image = 'ProductImage',
  Video = 'ProductVideo',
}

export const enum ShipmentType {
  Regular = 'Regular',
  Oversize = 'Oversize',
  LTL = 'LTL',
  Crate = 'Crate',
}