import styles from '../Newsletter.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { unsubscribeFromNewsletter } from 'behavior/pages/newsletter';
import { useSuccessMessage } from '../hooks';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { FormName } from 'behavior/pages';
import PropTypes from 'prop-types';
import NewsletterForm from '../NewsletterForm';
import { useCallback } from 'react';

const NewsletterUnsubscription = ({ id, model, isDesignerMode }) => {
  const dispatch = useDispatch();
  const { lastUpdated, updatedById } = useSelector(state => state.newsletter);
  const formName = `${FormName.NewsletterUnsubscribe}_${id}`;
  const unsubscribedByComponent = formName === updatedById;
  const onSubmit = useCallback(formData => {
    dispatch(unsubscribeFromNewsletter(formData, formName));
  }, [formName]);

  useSuccessMessage(lastUpdated, unsubscribedByComponent, 'NewsletterUnsubscribedSuccessfully');

  return (
    <div className={styles.container}>
      <NewsletterForm
        formName={formName}
        onSubmit={onSubmit}
        buttonTextKey={'Unsubscribe'}
        submitButtonDisplayMode={model.unsubscribeBtnDisplayMode}
        shouldReset={unsubscribedByComponent}
        isDesignerMode={isDesignerMode}
      />
    </div>
  );
};

NewsletterUnsubscription.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    unsubscribeBtnDisplayMode: PropTypes.number.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

// eslint-disable-next-line react/no-multi-comp
const NewsletterUnsubscriptionBlock = ({ isDesignerMode, ...props }) => {
  const [canSubscribe] = useHasAbilities(AbilityTo.SubscribeToNewsletter);

  if (!isDesignerMode && !canSubscribe)
    return null;

  return <NewsletterUnsubscription isDesignerMode={isDesignerMode} {...props} />;
};

NewsletterUnsubscriptionBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    unsubscribeBtnDisplayMode: PropTypes.number.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default NewsletterUnsubscriptionBlock;
