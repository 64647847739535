import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const SubscribePage = loadable(() => import(/*webpackChunkName:"user"*/'./subscribe/Page'));
const UnsubscribePage = loadable(() => import(/*webpackChunkName:"user"*/'./unsubscribe/Page'));

/* eslint-disable react/no-multi-comp */
export default {
  [PageComponentNames.Subscribe]: page => <SubscribePage desktop={page.content?.desktop} mobile={page.content?.mobile} />,
  [PageComponentNames.Unsubscribe]: page => <UnsubscribePage desktop={page.content?.desktop} mobile={page.content?.mobile} />,
};
