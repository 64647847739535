import styles from './ImageBlock.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import { Link } from 'components/primitives/links';

const ImageWithLink = ({ link, ...props }) => (
  <Link
    className={styles.link}
    url={link?.url}
    target={link?.target || null}
    to={link?.to}
  >
    <Image {...props} />
  </Link>
);

ImageWithLink.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  target: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
    to: PropTypes.object,
  }),
};

export default memo(ImageWithLink);
