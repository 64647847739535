import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useServices } from 'utils/services';
import { createUrl } from 'utils/url';
import renderUntilPageChanged from 'components/objects/renderUntilPageChanged';

const CanonicalLink = () => {
  const { index, canonicalUrl } = useSelector(selector, (l, r) => l.canonicalUrl === r.canonicalUrl);

  const { context: { origin } } = useServices();

  if (!index || !canonicalUrl)
    return null;

  const href = createUrl(canonicalUrl, origin);

  return (
    <Helmet>
      <link rel="canonical" href={encodeURI(href)} />
    </Helmet>
  );
};

export default renderUntilPageChanged(CanonicalLink);

function selector({ routing: { canonicalUrl }, page }) {
  return {
    canonicalUrl,
    index: page.index,
  };
}
