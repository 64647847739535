import type { CheckoutOffersState } from './types';
import type { CheckoutOffersAction } from './actions';
import { CHECKOUT_OFFERS_RECEIVED, CHECKOUT_OFFERS_EXPIRED } from './actions';
import { VIEWER_CHANGED, LANGUAGE_CHANGED, languageChanged, viewerChanged } from 'behavior/events';

type EventsAction = ReturnType<
  | typeof languageChanged
  | typeof viewerChanged
>;

export const initialState: CheckoutOffersState = {
  expired: false,
  products: null,
};

export default (state: CheckoutOffersState = initialState, action: CheckoutOffersAction | EventsAction): CheckoutOffersState => {
  switch (action.type) {
    case CHECKOUT_OFFERS_RECEIVED:
      return { ...state, products: action.payload };
    case CHECKOUT_OFFERS_EXPIRED:
    case LANGUAGE_CHANGED:
      return { ...state, expired: true };
    case VIEWER_CHANGED:
      return initialState;

    default:
      return state;
  }
};