import type { ComponentType, ButtonHTMLAttributes, ReactText, ReactElement } from 'react';
import type { ButtonProps } from './Button';
import { memo } from 'react';
import Button from './Button';
import { useSanaButton } from './UseSanaButton';

export type SanaButtonProps = Omit<
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'placeholder'
> & {
  textKey: string;
  titleTextKey?: string;
  defaultText?: string;
  className?: string;
  formatWith?: ReactText[];
  component?: ComponentType<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>;
  placeholder?: ReactElement;
};

const SanaButton = ({
  textKey,
  titleTextKey,
  defaultText,
  className,
  formatWith,
  component,
  placeholder,
  ...props
}: SanaButtonProps) => {
  const Component = component || Button;
  const { text, title, loaded } = useSanaButton(textKey, defaultText, formatWith, titleTextKey);

  if (!loaded)
    return placeholder || null;

  return (
    <Component className={className} title={title || undefined} {...props}>
      {text}
    </Component>
  );
};

export default memo(SanaButton);
