import {
  RegistrationAction,
  REGISTRATION_PROCESSED,
  REGISTRATION_TEMPLATEFIELDS_RECEIVED,
} from './actions';
import type { TemplateFields, RegistrationResult } from './types';

type State = {
  registrationResult: RegistrationResult;
  templateFields: TemplateFields | null;
};

const initialState = {
  registrationResult: {
    failureText: null,
    isRegistered: false,
  },
  templateFields: null,
};

export default (state = initialState, action: RegistrationAction): State => {
  switch (action.type) {
    case REGISTRATION_PROCESSED:
      return { ...state, registrationResult: action.payload };
    case REGISTRATION_TEMPLATEFIELDS_RECEIVED:
      return { ...state, templateFields: action.payload };
    default:
      return state;
  }
};