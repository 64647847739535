import styles from './Summary.module.scss';
import PropTypes from 'prop-types';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { Link, LinkButton } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { FooterLinkStyleOption } from './constants';

const FooterLink = ({ style, textKey, ...props }) => {
  switch (style) {
    case FooterLinkStyleOption.ShowAsLink:
      return (
        <Link className={`${linkStyles.arrowed} ${styles.footerLink}`} {...props}>
          <SimpleText textKey={textKey} />
        </Link>
      );
    case FooterLinkStyleOption.ShowAsButton:
      return (
        <LinkButton className={styles.footerBtn} {...props}>
          <SimpleText textKey={textKey} />
        </LinkButton>
      );
    case FooterLinkStyleOption.ShowAsActionButton:
      return (
        <LinkButton className={styles.footerBtn} theme="action" {...props}>
          <SimpleText textKey={textKey} />
        </LinkButton>
      );
    default:
      return null;
  }
};

FooterLink.propTypes = {
  style: PropTypes.number.isRequired,
  textKey: PropTypes.string.isRequired,
};

export default FooterLink;
