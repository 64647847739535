import { useSelector, useDispatch } from 'react-redux';
import { useLoadEffect } from 'utils/hooks';
import { loadNavigation, NavigationGroupCode } from 'behavior/navigation';
import { shouldRenderNavigation } from './helpers';
import { useSimpleTexts } from 'components/sanaText';

const navigationGroupCodeToAriaLabelTextKey = {
  [NavigationGroupCode.Footer]: 'Aria_Footer_Navigation',
  [NavigationGroupCode.Main]: 'Aria_MainNavigation',
  [NavigationGroupCode.MyAccount]: 'Aria_MyAccount_Navigation',
  [NavigationGroupCode.Sitemap]: 'Aria_Sitemap_Navigation',
  [NavigationGroupCode.Header]: 'Aria_Header_Navigation',
};

export const useAriaLabel = groupCode => {
  const ariaLabelTextKey = navigationGroupCodeToAriaLabelTextKey[groupCode];

  return useSimpleTexts([ariaLabelTextKey]).texts[0];
};

export const useLoadNavigation = (componentGroup, groupCode) => {
  const dispatch = useDispatch();

  const items = useSelector(({ navigation }) => navigation[componentGroup]?.[groupCode]);
  const ready = shouldRenderNavigation(items);

  useLoadEffect(() => {
    if (!items)
      dispatch(loadNavigation(componentGroup, groupCode));
  }, [items]);

  return [items, ready];
};
