import { ReactNode, forwardRef } from 'react';
import { encodeSpacesInUrl } from 'utils/url';
import { isIndexed, SystemRouteData } from 'routes';
import RouteLink from './RouteLink';
import NavigationLink from './NavigationLink';
import { createRouteData } from './helpers';
import { HTMLAnchorFactory, SystemLinkTo, LinkTo } from './types';

type BaseProps = Omit<Parameters<typeof NavigationLink>[0], 'createLink' | 'to' | 'url'> & {
  ref?: React.Ref<HTMLAnchorElement>;
  children?: ReactNode;
  disabled?: boolean;
} & Omit<JSX.IntrinsicElements['a'], 'itemRef' | 'onClick'>;

export type SystemLinkProps = BaseProps & {
  to: SystemLinkTo;
  url?: string;
};

export type LinkProps = BaseProps & {
  to?: LinkTo;
  url: string;
};

export type Props = SystemLinkProps | LinkProps;

export default forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const {
    to,
    url,
    omitScroll,
    replaceHistory,
    options,
    onClick,
    children,
    rel,
    disabled,
    ...attributes
  } = props;

  const route = to && createRouteData(to);

  const createLink: HTMLAnchorFactory = (href, onLinkClick) => {
    let relValue = rel;
    if (relValue === undefined && route && !isIndexed(route.routeName))
      relValue = 'nofollow';

    // Link without href should have role="link" attribute for its disabled state to be read by screen readers.
    const conditionalAttributes = disabled
      ? { 'aria-disabled': true, role: 'link' }
      : {
        href: href ? encodeSpacesInUrl(href) : undefined,
        rel: relValue,
        onClick: onLinkClick,
      };

    return (
      <a
        data-broken={href ? null : 'true'}
        ref={ref}
        {...conditionalAttributes}
        {...attributes}
      >
        {children}
      </a>
    );
  };

  if (!url && to)
    return (
      <RouteLink
        to={route as SystemRouteData}
        omitScroll={omitScroll}
        replaceHistory={replaceHistory}
        options={options}
        onClick={onClick}
        createLink={createLink}
      />
    );

  return (
    <NavigationLink
      url={url}
      to={route}
      omitScroll={omitScroll}
      replaceHistory={replaceHistory}
      options={options}
      onClick={onClick}
      createLink={createLink}
    />
  );
});
