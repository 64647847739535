import { ofType } from 'redux-observable';
import { PASSWORD_RESET_REQUESTED, notifyResetResult, ResetPasswordAction } from './actions';
import { exhaustMap, mergeMap, pluck } from 'rxjs/operators';
import { resetPasswordMutation } from './queries';
import { catchApiErrorWithToast } from 'behavior/errorHandling';
import { Epic } from 'behavior/types';
import { toasts } from 'behavior/toasts';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';

type ResetPasswordResponse = {
  profile: {
    resetPassword: {
      changed: boolean;
    };
  };
};

const resetPasswordEpic: Epic<ResetPasswordAction> = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(PASSWORD_RESET_REQUESTED),
    exhaustMap(({ payload }) => api.graphApi<ResetPasswordResponse>(resetPasswordMutation, { input: payload }).pipe(
      pluck('profile', 'resetPassword', 'changed'),
      mergeMap(changed => {
        if (!changed)
          return [navigateTo(routesBuilder.forForgotPassword()), notifyResetResult(changed)];

        toasts.success('', { textKey: 'PasswordSuccessfullyUpdated' });

        return [navigateTo(routesBuilder.forLogin()), notifyResetResult(changed)];
      }),
      catchApiErrorWithToast(),
    )),
  );
};

export default resetPasswordEpic;
