import styles from './Grid.module.scss';
import type { HtmlProps, HtmlTagName } from 'utils/react';
import type { Optional } from 'utils/types';
import { forwardRef, memo, ReactElement, ReactNode, Ref } from 'react';

type Props<T1 extends HtmlTagName | undefined, T2 extends HtmlTagName = T1 extends HtmlTagName ? T1 : 'div'> = {
  as: T1;
  className?: string | null;
  children: ReactNode;
  fluid?: boolean;
} & Omit<HtmlProps<T2>, 'className' | 'ref'>;

declare function Container<T extends HtmlTagName>(props: Props<T> & { ref?: Ref<T> }): ReactElement;
declare function Container<T extends undefined>(props: Optional<Props<T>, 'as'> & { ref?: Ref<HTMLDivElement> }): ReactElement;

const ForwardRefContainer = forwardRef<any, Props<any>>(({ as: Tag = 'div', className, fluid, children, ...attributes }, ref) => (
  <Tag className={getContainerClass(fluid, className)} {...attributes} ref={ref}>
    {children}
  </Tag>
));

ForwardRefContainer.displayName = 'Container';

export default memo(ForwardRefContainer) as typeof Container;

function getContainerClass(fluid: boolean | undefined, className: string | null | undefined) {
  const classList = [];
  !fluid && classList.push(styles.container);
  !!className && classList.push(className);
  return classList.join(' ');
}
