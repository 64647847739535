import type { ClientRoute } from './types';

const systemPageRoute = (path: string, queryVariables?: ReadonlyArray<string>): ClientRoute => {
  return {
    getVirtualPath(params) {
      if (!queryVariables?.length || !params)
        return path;

      let query = '';
      for (const variable of queryVariables) {
        const value = params[variable];
        if (value == null || value === false)
          continue;

        query += `${variable}=${value}&`;
      }

      if (!query)
        return path;

      return `${path}?${query.slice(0, -1)}`;
    },
  };
};

export default systemPageRoute;
