import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Text from './Text';
import { TogglePresentationOption } from '../constants';

const IconWithText = ({ presentation, isAuthenticated, isImpersonating }) => {
  switch (presentation) {
    case TogglePresentationOption.TextOnly:
      return <span><Text isAuthenticated={isAuthenticated} /></span>;
    case TogglePresentationOption.TextRightOfIcon:
      return (
        <>
          <Icon isImpersonating={isImpersonating} />
          <span><Text isAuthenticated={isAuthenticated} /></span>
        </>
      );
    case TogglePresentationOption.TextLeftOfIcon:
      return (
        <>
          <span><Text isAuthenticated={isAuthenticated} /></span>
          <Icon isImpersonating={isImpersonating} />
        </>
      );
    case TogglePresentationOption.TextBelowIcon:
      return (
        <div>
          <Icon isImpersonating={isImpersonating} />
          <div><Text isAuthenticated={isAuthenticated} /></div>
        </div>
      );
    default:
      return <Icon isImpersonating={isImpersonating} />;
  }
};

IconWithText.propTypes = {
  isAuthenticated: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  presentation: PropTypes.number.isRequired,
};

export default memo(IconWithText);
