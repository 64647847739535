import type {
  CheckoutInput,
  CheckoutOptionInput,
  ProductClickInput,
  ProductDetailsViewInput,
  ProductListViewInput,
  ProductsAddToBasketInput,
  ProductsRemoveFromBasketInput,
  Transaction,
} from './payload.types';
import type { AnalyticsSettings } from './types';

export const ANALYTICS_TRACKERS_INITIALIZE = 'ANALYTICS_TRACKERS_INITIALIZE' as const;
export const initializeAnalyticsTrackers = (analyticsSettings: AnalyticsSettings) => ({
  type: ANALYTICS_TRACKERS_INITIALIZE,
  payload: analyticsSettings,
});

export const ANALYTICS_DATALAYER_READY = 'ANALYTICS_DATALAYER_READY' as const;
export const notifyDataLayerReady = () => ({
  type: ANALYTICS_DATALAYER_READY,
});

export const ANALYTICS_DISABLE_TRACKING = 'ANALYTICS_DISABLE_TRACKING' as const;
export const disableTracking = () => ({
  type: ANALYTICS_DISABLE_TRACKING,
});

export const ANALYTICS_PRODUCT_CLICKED = 'ANALYTICS_PRODUCT_CLICKED' as const;
export const trackProductClick = (input: ProductClickInput) => ({
  type: ANALYTICS_PRODUCT_CLICKED,
  payload: input,
});

export const ANALYTICS_PRODUCT_DETAILS_VIEWED = 'ANALYTICS_PRODUCT_DETAILS_VIEWED' as const;
export const trackProductDetailsView = (input: ProductDetailsViewInput) => ({
  type: ANALYTICS_PRODUCT_DETAILS_VIEWED,
  payload: input,
});

export const ANALYTICS_PRODUCT_LIST_VIEWED = 'ANALYTICS_PRODUCT_LIST_VIEWED' as const;
export const trackProductListView = (input: ProductListViewInput) => ({
  type: ANALYTICS_PRODUCT_LIST_VIEWED,
  payload: input,
});

export const ANALYTICS_PRODUCTS_ADDED_TO_BASKET = 'ANALYTICS_PRODUCTS_ADDED_TO_BASKET' as const;
export const trackAddToBasket = (input: ProductsAddToBasketInput) => ({
  type: ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
  payload: input,
});

export const ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET = 'ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET' as const;
export const trackRemoveFromBasket = (input: ProductsRemoveFromBasketInput) => ({
  type: ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
  payload: input,
});

export const ANALYTICS_CHECKOUT = 'ANALYTICS_CHECKOUT' as const;
export const trackCheckout = (input: CheckoutInput) => ({
  type: ANALYTICS_CHECKOUT,
  payload: input,
});

export const ANALYTICS_CHECKOUT_OPTION = 'ANALYTICS_CHECKOUT_OPTION' as const;
export const trackCheckoutOption = (input: CheckoutOptionInput) => ({
  type: ANALYTICS_CHECKOUT_OPTION,
  payload: input,
});

export const ANALYTICS_PURCHASE = 'ANALYTICS_PURCHASE' as const;
export const trackPurchase = (transaction: Transaction) => ({
  type: ANALYTICS_PURCHASE,
  payload: { transaction },
});

export const ANALYTICS_PAGE_TITLE_IS_SET = 'ANALYTICS_PAGE_TITLE_IS_SET';
export const pageTitleIsSet = () => ({
  type: ANALYTICS_PAGE_TITLE_IS_SET,
});

export type InitializeAnalyticsTrackersAction = ReturnType<typeof initializeAnalyticsTrackers>;
export type AnalyticsPageTitleIsSetAction = ReturnType<typeof pageTitleIsSet>;

export type AnalyticsAction = ReturnType<
  | typeof initializeAnalyticsTrackers
  | typeof disableTracking
  | typeof trackAddToBasket
  | typeof trackRemoveFromBasket
  | typeof notifyDataLayerReady
  | typeof trackProductClick
  | typeof trackProductDetailsView
  | typeof trackProductListView
  | typeof trackCheckout
  | typeof trackCheckoutOption
  | typeof trackPurchase
>;
