import type { Observable } from 'rxjs';
import { fromEvent, asyncScheduler, EMPTY, BehaviorSubject } from 'rxjs';
import { throttleTime, share, startWith, map } from 'rxjs/operators';
import { isBrowser } from '../detections';

export const resize$ = getEventObservable<UIEvent>('resize').pipe(
  throttleTime(350, asyncScheduler, { leading: false, trailing: true }),
  share(),
);

export const scroll$ = getEventObservable<UIEvent>('scroll').pipe(
  throttleTime(250, asyncScheduler, { leading: false, trailing: true }),
  share(),
);

export const orientationChange$ = getEventObservable<Event>('orientationchange').pipe(
  throttleTime(250, asyncScheduler, { leading: false, trailing: true }),
  share(),
);

export const windowMessage$ = getEventObservable<MessageEvent>('message').pipe(
  share(),
);

export const visibility$ = isBrowser && 'onvisibilitychange' in window.document
  ? getEventObservable<Event>('visibilitychange', true).pipe(
    startWith(0),
    map(() => !window.document.hidden),
    share(),
  )
  : new BehaviorSubject(true);

export const popstate$ = getEventObservable<PopStateEvent>('popstate').pipe(
  share(),
);

function getEventObservable<T extends Event>(eventName: string, isDocumentEvent?: boolean): Observable<T | never> {
  return isBrowser
    ? fromEvent<T>(isDocumentEvent ? window.document : window, eventName)
    : EMPTY;
}
