import type { LanguageType, AlternateUrl } from './types';

export const LANGUAGES_LOADED = 'LANGUAGES/LOADED' as const;
export const languagesLoaded = (languages: Array<LanguageType>) => ({ type: LANGUAGES_LOADED, payload: languages });

export const ALTERNATE_URLS_REQUESTED = 'LANGUAGES/ALTERNATE_URLS/REQUESTED' as const;
export const loadAlternateUrls = () => ({ type: ALTERNATE_URLS_REQUESTED });

export const ALTERNATE_URLS_LOADED = 'LANGUAGES/ALTERNATE_URLS/LOADED' as const;
export const alternateUrlsLoaded = (alternateUrls: Array<AlternateUrl>) => ({ type: ALTERNATE_URLS_LOADED, payload: alternateUrls });

export const URL_LANGUAGE_CHANGED = 'URL/LANGUAGE/CHANGED' as const;
export const urlLanguageChanged = (newLanguageId: number) => ({ type: URL_LANGUAGE_CHANGED, payload: newLanguageId });

export type LanguageAction = ReturnType<
  | typeof languagesLoaded
  | typeof loadAlternateUrls
  | typeof alternateUrlsLoaded
  | typeof urlLanguageChanged
  >;
