exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HeaderMenu_container{line-height:1.4;margin:0 -.5em;padding:0}.HeaderMenu_container a{color:var(--link-fontColor,inherit);display:inline-block;font-family:var(--link-fontFamily,inherit);font-size:var(--link-fontSize,13px);margin:.3em 0;text-transform:var(--link-allCaps);vertical-align:middle}.HeaderMenu_container a:hover{background-color:var(--link-hoverBackgroundColor,inherit)!important;color:var(--link-hoverFontColor,inherit)!important}.HeaderMenu_container ul{list-style-type:none;margin:0;padding:0}.HeaderMenu_container li{padding:0 .5em;vertical-align:bottom}.HeaderMenu_top-level{background-color:var(--link-backgroundColor,inherit);display:flex;flex-wrap:wrap;margin:0 -.5em}.HeaderMenu_top-level>li{padding:0}.HeaderMenu_top-level>li>a{padding:0 10px;position:relative}.HeaderMenu_top-level>li>a:after{border-left:1px solid var(--link-fontColor,inherit);bottom:0;content:\"\";height:1.4em;margin:auto;position:absolute;right:-1px;top:0}.HeaderMenu_top-level>li:last-child>a:after{content:none}.HeaderMenu_nav-header-list-root{background-color:transparent;display:flex;flex-direction:column;height:100%;list-style:none;margin:0;padding:0;padding-left:16px;padding-right:16px}.HeaderMenu_nav-header-list-root>li>a{color:var(--mobile-link-fontColor,inherit);display:block;font-family:var(--mobile-link-fontFamily,inherit);font-size:var(--mobile-link-fontSize,inherit);overflow:hidden;padding:10px 10px 10px 10px;text-decoration:none;text-transform:var(--mobile-link-allCaps)}.HeaderMenu_nav-header-list-root>li:hover>a{background-color:var(--mobile-link-hoverBackgroundColor,inherit);color:var(--mobile-link-hoverFontColor,inherit)}", ""]);

// exports
exports.locals = {
	"container": "HeaderMenu_container",
	"top-level": "HeaderMenu_top-level",
	"topLevel": "HeaderMenu_top-level",
	"nav-header-list-root": "HeaderMenu_nav-header-list-root",
	"navHeaderListRoot": "HeaderMenu_nav-header-list-root"
};