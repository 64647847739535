import type { Handler } from '../types';
import type { ContentPage } from './types';
import { contentPageQuery } from './queries';
import { of } from 'rxjs';
import { pluck, map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { parseContent, RowContentElementData } from 'behavior/content';
import { RouteName } from 'routes';

type ContentPageData = {
  title: string;
  metaTitle: string;
  metaDescription: string | null;
  content: {
    desktop: RowContentElementData[];
    mobile: RowContentElementData[] | null;
  };
};

type ContentPageResponse = {
  pages: {
    content: ContentPageData | null;
  };
};

type ContentPageRouteData = {
  routeName: RouteName.ContentPage;
  params?: { id: string };
};

const handler: Handler<ContentPageRouteData, ContentPage> = ({ params }, _state$, { api }) => {
  if (!params?.id)
    return of(null);

  return api.graphApi<ContentPageResponse>(contentPageQuery, { id: params.id }).pipe(
    pluck('pages', 'content'),
    map(page => {
      if (!page)
        return null;

      const desktop = parseContent(page.content.desktop);
      const mobile = page.content.mobile && parseContent(page.content.mobile);
      const firstRowHeroSettings = desktop[0]?.heroEffect;

      return {
        page: {
          title: page.title,
          metaTitle: page.metaTitle,
          metaDescription: page.metaDescription,
          content: {
            desktop,
            mobile,
          },
          layoutHeroEffect: firstRowHeroSettings ? {
            headerOnTop: firstRowHeroSettings.headerOnTop,
            headerTextColor: firstRowHeroSettings.headerTextColor,
            altLogo: firstRowHeroSettings.altLogo,
            altSmallLogo: firstRowHeroSettings.altSmallLogo,
          } : undefined,
          component: PageComponentNames.Content as const,
        },
      };
    }),
  );
};

export default handler;
