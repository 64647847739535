import type { AddonField } from 'behavior/pages/product';
import { useProductContext, useProductViewContext } from 'components/objects/product/hooks';
import { useMemo } from 'react';

export default () => {
  const contextData = useProductContext();
  const { isMatrixMode } = useProductViewContext();

  if (!contextData.product)
    return null;

  const { product: { id, addonFields }, variantId, uomId } = contextData;

  return useMemo(() => {
    return {
      id,
      variantId: isMatrixMode ? null : variantId,
      uomId,
      extraData: addonFields?.reduce<Record<string, AddonField['value']>>((acc, field) => {
        acc[field.name] = field.value;

        return acc;
      }, {}) || {},
    };
  }, [id, addonFields, variantId, uomId, isMatrixMode]);
};
