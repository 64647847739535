import { FunctionComponent, useState } from 'react';
import { useOnPageChanged } from 'utils/hooks';

export default function renderUntilPageChanged<P>(WrappedComponent: FunctionComponent<P>) {
  return (props: P) => {
    const [hideOnClient, setHideOnClient] = useState(false);
    useOnPageChanged(() => setHideOnClient(true));

    if (!hideOnClient)
      return <WrappedComponent {...props} />;

    return null;
  };
}
