import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'components/primitives/breadcrumb';
import { Media } from 'components/responsive';
import useNavigationPath from './useNavigationPath';

const PageBreadcrumb = ({ breadcrumbsEnabled, noTopMargin }) =>
  breadcrumbsEnabled
    ? <BreadcrumbBase noTopMargin={noTopMargin} />
    : null;

const BreadcrumbBase = ({ noTopMargin }) => {
  const items = useNavigationPath(false);
  return items && (
    <Media greaterThanOrEqual="md">
      <Breadcrumb items={items} noTopMargin={noTopMargin} />
    </Media>
  );
};

PageBreadcrumb.propTypes = {
  breadcrumbsEnabled: PropTypes.bool,
  noTopMargin: PropTypes.bool,
};

export default connect(state => ({
  breadcrumbsEnabled: state.settings.navigation?.breadcrumb,
}))(PageBreadcrumb);
