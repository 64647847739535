import { createReducer } from 'utils/redux';
import { EDIT_0R_CREATE_MY_GARAGE_VEHICLE_RECEIVED, GET_MY_GARAGE_VEHICLES_RECEIVED, CHANGE_ORDER_GARAGE_VEHICLES_RECEIVED } from './actions';

const initialState = {
  myGarageVehicles: [],
};

export default createReducer(initialState, {
  [EDIT_0R_CREATE_MY_GARAGE_VEHICLE_RECEIVED]: onEditOrCreateMyGarageVehicleReceived,
  [GET_MY_GARAGE_VEHICLES_RECEIVED]: onGetMyGarageVehicleReceived,
  [CHANGE_ORDER_GARAGE_VEHICLES_RECEIVED]: onChangeOrderGarageVehiclesReceived,
});

function onEditOrCreateMyGarageVehicleReceived(state = initialState, action) {
  return {
    ...state,
    myGarageVehicles: action.payload.options?.addNewVehicle,
  };
}

function onGetMyGarageVehicleReceived(state = initialState, action) {
  return {
    ...state,
    myGarageVehicles: action.payload.options?.myGarageVehicles,
  };
}

function onChangeOrderGarageVehiclesReceived(state = initialState, action) {
  return {
    ...state,
    myGarageVehicles: action.payload.options?.changeOrderVehicles,
  };
}

