import { RouteName } from 'routes';

type Node = {
  link: {
    to?: {
      routeName: RouteName;
    };
    url: string | null | undefined;
  } | null;
};

export function isHomePage(node: Node | undefined, homePath: string | undefined) {
  const link = node?.link;
  if (!link)
    return false;

  if (link.to)
    return link.to.routeName === RouteName.Home;

  return !!homePath && link.url === homePath;
}
