exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Placeholder_placeholder-item{background-clip:content-box;background-color:#f7f7f7;box-sizing:content-box;display:block;height:1em}", ""]);

// exports
exports.locals = {
	"placeholder-item": "Placeholder_placeholder-item",
	"placeholderItem": "Placeholder_placeholder-item"
};