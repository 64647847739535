import type { BlogItem } from './types';
import { stripHtmlTagsAndFormatting } from '../../helpers';
import { getToLocaleDate } from 'utils/format';

export default function (blogItem: BlogItem, culture: string) {
  const { comments } = blogItem;
  if (!comments || !comments.items?.length)
    return null;

  const formatDate = getToLocaleDate(culture);

  return {
    comment: comments.items.map(({ title, name, date, details }) => ({
      '@type': 'Comment',
      headline: title,
      name,
      text: stripHtmlTagsAndFormatting(details),
      author: {
        '@type': 'Person',
        name,
      },
      dateCreated: formatDate(date),
    })),
    commentCount: comments.totalCount,
  };
}

