export function normalizeAddonId(id: string): string;
export function normalizeAddonId(id: string | null | undefined): string | null | undefined;

export function normalizeAddonId(id: string | null | undefined): string | null | undefined {
  return id && id.toLowerCase();
}

export function getAddonSrc(addonId: string, addonHash: string | null, forSSR = false) {
  const filename = forSSR ? 'server' : (addonHash ? 'index.' + addonHash : 'index');
  return `/static/a/${normalizeAddonId(addonId)}/js/${filename}.js`;
}
