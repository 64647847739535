import { ADDONS_METADATA_REQUESTED, addonsMetadataLoaded, MetadataActions } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { addonsQuery } from './queries';
import type { Observable } from 'rxjs';
import type { Api } from 'utils/api';

const addonsEpic = (action$: Observable<MetadataActions>, _: unknown, { api }: { api: Api }) => action$.pipe(
  ofType(ADDONS_METADATA_REQUESTED),
  switchMap(_ => api.graphApi(addonsQuery).pipe(
    pluck('addons', 'packages'),
    map(addonsMetadataLoaded),
  )),
);

export default addonsEpic;