exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Spinner_spinner{border-radius:50%;display:block;height:2em;overflow:hidden;position:relative;width:2em}.Spinner_spinner:before{border:solid .3em var(--theme-link_Color,#1f7bc9);opacity:.3}.Spinner_spinner:after,.Spinner_spinner:before{border-radius:50%;box-sizing:border-box;content:\"\";display:block;height:100%;width:100%}.Spinner_spinner:after{-webkit-animation:Spinner_spinner 1.2s ease-in-out infinite;animation:Spinner_spinner 1.2s ease-in-out infinite;border-color:var(--theme-link_Color,#1f7bc9) transparent transparent transparent;border-style:solid;border-width:.3em;left:0;position:absolute;top:0}@-webkit-keyframes Spinner_spinner{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes Spinner_spinner{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"spinner": "Spinner_spinner"
};