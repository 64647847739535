export const enum BasketLinkLayoutOption {
  TextRightOfIcon = 0,
  TextLeftOfIcon = 1,
  TextBelowOfIcon = 2,
  ShowNumberOfProductsAsBadge = 3,
}

export const enum FooterLinkStyleOption {
  ShowAsLink = 0,
  ShowAsButton = 1,
  ShowAsActionButton = 2,
  Hide = 3,
}
