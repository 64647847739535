import type { SubAccount, RemovalResult } from './types';

export const SUB_ACCOUNT_SUBMITTED = 'SUB_ACCOUNT_SUBMITTED' as const;
export const submitSubAccount = (account: SubAccount) => ({
  type: SUB_ACCOUNT_SUBMITTED,
  payload: account,
});

export const SUB_ACCOUNT_REMOVE = 'SUB_ACCOUNT_REMOVE' as const;
export const removeSubAccount = (id: string) => ({
  type: SUB_ACCOUNT_REMOVE,
  payload: id,
});

export const SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED = 'SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED' as const;
export const subAccountRemovalResultReceived = (result: RemovalResult) => ({
  type: SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED,
  payload: result,
});

export type SubAccountAction = ReturnType<
  | typeof submitSubAccount
  | typeof removeSubAccount
  | typeof subAccountRemovalResultReceived
>;
