import { useSanaTexts } from 'components/sanaText';
import { useEffect } from 'react';

export default () => {
  const [title, message] = useSanaTexts(['SelfXssWarningTitle', 'SelfXssWarningMessage']).texts;
  useEffect(() => {
    if (!title || !message)
      return;

    console.log('%c' + title, 'color: red; font-size: 9em; font-weight: bold;');
    console.log('%c' + message, 'font-size: 2em; font-weight: bold;');
  }, [title, message]);

  return null;
};