import styles from './WarehouseSelector.module.scss';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Text } from './iconWithText';
import WarehouseSelectorItems from './warehouseSelectorItems';
import { ModalDrawer } from 'components/primitives/modalDrawer';
import { getThemeFontSizeClassName } from 'components/theme';
import { isBrowser } from 'utils/detections';
import { RouteName } from 'routes';
import { useRoutesChecker } from 'components/primitives/checkers';

const MobileWarehouseSelectorBlock = ({ model, isDesignerMode, id }) => {
  const [expanded, setExpanded] = useState(false);
  const { isLoading, warehouses, isAuthenticated } = useSelector(state => state.user);
  const selectedWarehouseId = warehouses?.find(warehouse => warehouse.isSelected)?.id;
  const warehouseMenuId = `MobileWarehouse_${id}`;

  useEffect(() => {
    if (!isLoading)
      return;

    setExpanded(false);
  }, [isLoading]);

  const handleInfoItemsClick = useCallback(({ target: { tagName } }) => {
    if (tagName !== 'A' && tagName !== 'BUTTON')
      return;

    setExpanded(false);
  }, []);

  const handleClose = useCallback(() => setExpanded(false), []);

  const isIgnoredRoute = useRoutesChecker([RouteName.BasketPage, RouteName.Checkout, RouteName.QuotePromotion]);  

  if(isIgnoredRoute || !isAuthenticated || !warehouses && !isDesignerMode)
    return null;

  const style = {
    '--content-fontColor': model.content_FontColor,
    '--content-linkFontColor': model.content_LinkFontColor,
    '--content-backgroundColor': model.content_BackgroundColor,
    '--content-separatorsColor': model.content_SeparatorsColor,
  };

  return (
    <>
      <button
        className={styles.openerBtn}
        onClick={() => setExpanded(!expanded)}
        aria-controls={isBrowser && !isDesignerMode ? warehouseMenuId : null}
        style={{ '--toggle-iconColor': model.toggle_IconColor }}
        suppressHydrationWarning
      >
        <Text warehouseId={selectedWarehouseId} />
        {model.content_ShowExpandIcon &&
          <span className={styles.caret}>
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        }
      </button>
      {isBrowser && !isDesignerMode && (
        <ModalDrawer
          expanded={expanded}
          onClick={handleInfoItemsClick}
          onClose={handleClose}
          id={warehouseMenuId}
          className={`${styles.drawer} ${getThemeFontSizeClassName(model.content_ThemeFontSize)}`}
          containerClassName={styles.drawerContainer}
          style={style}
        >
          <WarehouseSelectorItems
            warehousesInfo={warehouses}
            onSelected={handleClose}
          />
        </ModalDrawer>
      )}
    </>
  );
};

MobileWarehouseSelectorBlock.propTypes = {
  model: PropTypes.shape({
    toggle_IconColor: PropTypes.string,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_ThemeFontSize: PropTypes.string.isRequired,
    content_FontColor: PropTypes.string,
    content_LinkFontColor: PropTypes.string,
    content_BackgroundColor: PropTypes.string,
    content_SeparatorsColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default MobileWarehouseSelectorBlock;
