import type { Epic } from 'behavior/types';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PREVIEW_SHOW_ACTION_FORBIDDEN_TOAST, PreviewAction } from './actions';
import { showActionCannotBePerformedToast } from './operators';

const epic: Epic<PreviewAction> = (action$, state$, deps) => action$.pipe(
  ofType(PREVIEW_SHOW_ACTION_FORBIDDEN_TOAST),
  mergeMap(() => showActionCannotBePerformedToast(state$, deps)),
);

export default epic;
