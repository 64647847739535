import { of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState } from 'behavior/user/constants';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';

export const createMyAccountMiddleware = ability => function (next, routeData, state$, dependencies) {    
    return requestAbility(ability, state$, dependencies).pipe(
      mergeMap(abilityState => {
        switch (abilityState) {
          case AbilityState.NotAvailable:
            return of({action$: of(navigateTo(routesBuilder.forAccountDashboard()))});
          case AbilityState.Unauthorized:
            return of({ statusCode: 401 });
          case AbilityState.TemporaryNotAvailable:
            return of({ statusCode: 503, page: {component: PageComponentNames.Offline, index: false} });
          default:
            return of({action$: of(navigateTo(routesBuilder.forAnnouncements()))});
        }
      }),
    );
  };