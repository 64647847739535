import type {
  CascadingMainMenuItem,
  MultiColumnMainMenuItem,
  DefaultNavItem,
  LinkNavItem,
} from './types';
import {
  NAVIGATION_LOADED,
  MAIN_NAVIGATION_CHILDREN_LOADED,
  NavigationAction,
  NavigationLoaded,
  MainNavigationChildrenLoaded,
} from './actions';
import { createReducer } from 'utils/redux';
import { updateTreeItem } from './helpers';
import { NavigationGroupCode } from './constants';

const initialState: State = {};

type State = {
  Default?: {
    [code in NavigationGroupCode]?: DefaultNavItem[];
  };
  CascadingMainMenu?: {
    [code in NavigationGroupCode]?: CascadingMainMenuItem[];
  };
  MultiColumnMainMenu?: {
    [code in NavigationGroupCode]?: MultiColumnMainMenuItem[];
  };
  LinkMenu?: {
    [code in NavigationGroupCode]?: LinkNavItem[];
  };
  HeaderMenu?: {
    [code in NavigationGroupCode]?: DefaultNavItem[];
  };
};

export default createReducer<State, NavigationAction>(initialState, {
  [NAVIGATION_LOADED]: onNavigationLoaded,
  [MAIN_NAVIGATION_CHILDREN_LOADED]: onMainNavigationChildrenLoaded,
});

function onNavigationLoaded(state: State, action: NavigationLoaded) {
  const { componentGroup, groupCode, items } = action.payload;

  return {
    ...state,
    [componentGroup]: {
      ...state[componentGroup],
      [groupCode]: items,
    },
  };
}

function onMainNavigationChildrenLoaded(state: State, action: MainNavigationChildrenLoaded) {
  const { componentGroup, parentId, children: newChildren } = action.payload;
  const rootItems = state[componentGroup]?.[NavigationGroupCode.Main];

  if (!rootItems)
    return state;

  const newRootItems = updateTreeItem(
    rootItems,
    item => item.id === parentId,
    item => {
      item.children = newChildren;

      return item;
    },
  );

  if (rootItems === newRootItems)
    return state;

  return {
    ...state,
    [componentGroup]: {
      ...state[componentGroup],
      [NavigationGroupCode.Main]: newRootItems,
    },
  };
}
