import { Container } from 'components/primitives/grid';
import { BackToContainer } from 'components/primitives/links';
import { PageTitle, PageContent } from 'components/objects/pages';

function createSystemRenderer(pageTitleProps, contentRenderer, backTo) {
  return page => (
    <>
      {backTo && <BackToContainer options={backTo} />}
      {pageTitleProps?.textKey &&
        <Container>
          <PageTitle {...pageTitleProps} />
        </Container>
      }
      <PageContent content={page.content?.header} position="header" />
      <Container>
        {contentRenderer(page)}
      </Container>
      <PageContent content={page.content?.footer} position="footer" />
    </>
  );
}

export default createSystemRenderer;
