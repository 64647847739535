import { ofType } from 'redux-observable';
import {
  switchMap,
  mergeMap,
  startWith,
  switchMapTo,
  first,
  filter,
  catchError,
  delay,
  ignoreElements,
  takeUntil,
} from 'rxjs/operators';
import { of, EMPTY, merge } from 'rxjs';
import {
  checkoutInfoUpdated,
  PROMOTION_CODE_ENTER,
  CHECKOUT_INFO_UPDATED,
} from './actions';
import { LOCATION_CHANGED } from 'behavior/events';
import { getEnterPromotionCodeMutation, clearNonOrderablesFromCheckoutMutation } from './queries';
import { adjustPaymentMethodData, navigateOnIncorrect } from './helpers';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isPromotion = () => !!state$.value.page.info?.quote;
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const setLoading = setLoadingIndicator();
    const unsetLoading = unsetLoadingIndicator();

    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

    const selectPromotionCode$ = action$.pipe(
      ofType(PROMOTION_CODE_ENTER),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getEnterPromotionCodeMutation(isPromotion()), {
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
        code: payload.code,
      }).pipe(
        mergeMap(({ checkout }) => {
          if (checkout) {
            const selectResult = checkout.promotion.select;
            adjustPaymentMethodData(selectResult.info);

            if (selectResult.success)
              return of(checkoutInfoUpdated(selectResult.info), unsetLoading);
          }

          return of(navigateOnIncorrect(state$.value.page.info), unsetLoading);
        }),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ))),
    );

    const triggerNonOrderableRemoval$ = action$.pipe(
      ofType(CHECKOUT_INFO_UPDATED),
      switchMapTo(
        state$.pipe(
          first(),
          filter(({ page: { info: { nonOrderableLines } } }) => !!(nonOrderableLines?.length)),
          delay(2000),
          mergeMap(_ => api.graphApi(clearNonOrderablesFromCheckoutMutation).pipe(
            ignoreElements(),
            catchError(e => {
              logger.error(e);
              return EMPTY;
            }),
          )),
          takeUntil(locationChanged$),
        ),
      ),
    );

    return merge(selectPromotionCode$, triggerNonOrderableRemoval$);
  };
}