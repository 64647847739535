import type { Transform } from 'utils/render/types';
import { memo, useEffect } from 'react';
import { renderHTML } from 'utils/render';
import { getNodeText } from './helpers';

const DomElement = (props: { content: string }) => {
  const scripts: { txt: string; attrs: Record<string, string> }[] = [];

  const transformer: Transform = (node): ReturnType<Transform> => {
    if (node.name === 'script') {
      const attrs = node.attribs!;
      scripts.push({
        txt: attrs.src ? '' : getNodeText(node),
        attrs,
      });

      return null;
    }
  };

  useEffect(() => {
    const elements = scripts.map(({ txt, attrs }) => {
      const element = document.createElement('script');
      if (txt)
        element.textContent = txt;

      Object.assign(element, attrs);

      document.body.appendChild(element);
      return element;
    });

    return () => {
      elements.forEach(
        element => document.body.removeChild(element),
      );
    };
  }, [props.content]);

  return renderHTML(props.content, transformer) as JSX.Element;
};

export default memo(DomElement);
