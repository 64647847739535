import btnStyles from 'components/primitives/buttons/Button.module.scss';
import type { ButtonHTMLAttributes } from 'react';
import { SimpleText } from 'components/sanaText';
import { ExpandIcon, CollapseIcon } from 'components/primitives/icons';
import Button from './Button';

type Props = {
  show: boolean;
  className?: string;
};

const ShowHideButton = ({ show, className = '', ...attributes }: Props & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const Icon = show ? CollapseIcon : ExpandIcon;

  return (
    <Button
      type="button"
      size="sm"
      icon={false}
      className={`${btnStyles.showHide} ${className}`}
      noContentWrapper
      {...attributes}
    >
      <Icon className={btnStyles.showHideIcon} aria-hidden />
      <span className="visually-hidden"><SimpleText textKey={show ? 'Aria_Hide' : 'Aria_Show'} /></span>
    </Button>
  );
};

export default ShowHideButton;
