import type { StateObservable } from 'redux-observable';
import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, tap, ignoreElements } from 'rxjs/operators';
import { requestAdminText } from 'behavior/adminTexts/epic';
import { toasts } from 'behavior/toasts';

export function skipIfPreview<T>(state$: StateObservable<AppState>) {
  return filter<T>(() => !isPreview(state$.value));
}

export function skipIfPreviewWithToast<T>(state$: StateObservable<AppState>, deps: StoreDependencies) {
  return mergeMap<T, Observable<T>>(value => isPreview(state$.value) ? showActionCannotBePerformedToast(state$, deps) : of(value));
}

export function showActionCannotBePerformedToast(state$: StateObservable<AppState>, deps: StoreDependencies) {
  return requestAdminText('Preview_ActionCannotBePerformed', state$, deps).pipe(
    tap(message => toasts.info(message, { className: 'Preview_toast' })),
    ignoreElements(),
  );
}

function isPreview(state: AppState) {
  return state.routing.routeData?.params?.previewToken != null;
}