exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSuggestions_suggestions-btn{align-items:center;background-color:var(--theme-button_Color,#fff);border-color:var(--theme-button_BackgroundColor,#1f7bc9);border-style:solid;border-width:1px;box-shadow:none;color:var(--theme-button_BackgroundColor,#1f7bc9);display:inline-flex;height:44px;justify-content:center;line-height:2em;margin:0;min-height:auto;min-width:auto;padding:0;text-shadow:none;text-transform:none;width:44px}.ProductSuggestions_suggestions-btn .ProductSuggestions_icon{max-height:20px;max-width:20px}.ProductSuggestions_suggestions-btn:focus{background-color:#fff;border-color:var(--theme-button_Hover_BackgroundColor,#2289e3);box-shadow:none;color:var(--theme-button_Hover_BackgroundColor,#2289e3);text-shadow:none}html.pointer-mouse .ProductSuggestions_suggestions-btn:hover{background-color:#fff;border-color:var(--theme-button_Hover_BackgroundColor,#2289e3);box-shadow:none;color:var(--theme-button_Hover_BackgroundColor,#2289e3);text-shadow:none}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .ProductSuggestions_suggestions-btn:active{background-color:#fff;border-color:var(--theme-button_Hover_BackgroundColor,#2289e3);box-shadow:none;color:var(--theme-button_Hover_BackgroundColor,#2289e3);text-shadow:none}}}@media not all and (min-resolution:.001dpcm){@media (pointer:coarse){.ProductSuggestions_suggestions-btn:hover{-webkit-tap-highlight-color:var(--theme-button_Hover_BackgroundColor,#2289e3)}}}", ""]);

// exports
exports.locals = {
	"suggestions-btn": "ProductSuggestions_suggestions-btn",
	"suggestionsBtn": "ProductSuggestions_suggestions-btn",
	"icon": "ProductSuggestions_icon"
};