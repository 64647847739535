import React, { useEffect, useState } from 'react';
import ImageBlock from './ImageCatalogueBlock';
import styles from './FlyersBlock.module.scss';
import { isUndefined, setTextPosition } from './ImageBlockUtilities';
import PropTypes from 'prop-types';

const CatalogueBlock = ({ model, isDesignerMode }) => {
    const { background, button, layout } = { ...model };
    const [isAdminElement, setIsAdminElement] = useState(false);
    const textPositionClassNamesString = layout ? setTextPosition(Number(layout.buttonTextPosition)) : setTextPosition(4);

    let buttonDisplayStyle = 'none';
  const tileBackgroundStyles = background.useBackgroundImage ?
    { backgroundImage: 'unset' } :
    {
      backgroundColor: !isUndefined(background.backgroundColor)
        && background.backgroundColor !== null &&
        background.backgroundColor,
    };

  if (!isUndefined(button) && !isUndefined(button.buttonText) && button.buttonText.length)
    buttonDisplayStyle = 'inline-flex';

    useEffect(() => {
        if (isDesignerMode) {
          setIsAdminElement(true);
        }
    }, [isAdminElement]);  

  return (
    <>
    <ImageBlock 
      tileData={model}
      adminClasses={isDesignerMode && isAdminElement ? styles.adminMinHeight : null}
      textPositionClassNamesString={textPositionClassNamesString}
      buttonDisplayStyle={buttonDisplayStyle}
      tileBackgroundStyles={tileBackgroundStyles}
    />
  </>
  );
};

CatalogueBlock.propTypes = {
  model: PropTypes.object,
  isDesignerMode: PropTypes.bool,
};

export default React.memo(CatalogueBlock);
