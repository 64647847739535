import type { Logger } from 'utils/logs';
import { Action } from 'redux';
import { concat, of, Observable, OperatorFunction } from 'rxjs';
import { catchError, first, mergeMapTo, takeUntil, filter } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { RETRY_FAILED_REQUESTS, CLOSE_ERROR_TOAST, showErrorToast } from './actions';

export type RetryWithToast = (action$: Observable<Action<unknown>>, logger: Logger, getContinueWith?: (error: any) => Observable<Action<any>>) => OperatorFunction<unknown, Action>;

const retryWithToast: RetryWithToast = (action$, logger, getContinueWith) =>
(catchError((error: any, observable) => {
  logger.error('The following error occurred', error);

  const reset$ = action$.pipe(
    ofType(CLOSE_ERROR_TOAST),
    filter(({ payload: { type } }) => type === 'retry'),
  );

  const retry$ = action$.pipe(
    ofType(RETRY_FAILED_REQUESTS),
    first(),
    takeUntil(reset$),
    mergeMapTo(observable),
  );

  const toastAction$ = of(showErrorToast({ type: 'retry', data: error.response?.errors?.[0].extensions?.data }));

  if (!getContinueWith)
    return concat(toastAction$, retry$);

  return concat(toastAction$, getContinueWith(error), retry$);
}) as OperatorFunction<unknown, Action<unknown>>);

export default retryWithToast;
