import { ViewMode } from './constants';
import type {
  Facets,
  ProductListOptions,
  ProductListParams,
  ProductListSettings,
  SortField,
  ProductListLoadOptions,
} from './types';
import { CatalogSearchMode } from 'behavior/products/product/constants';

export function getViewModeProductAmount(viewMode: ViewMode | undefined, settings: ProductListSettings): number | undefined {
  const { gridProductAmount, listProductAmount } = settings;

  switch (viewMode) {
    case ViewMode.Grid:
      return gridProductAmount;
    case ViewMode.List:
      return listProductAmount;
    default:
      return undefined;
  }
}

const _desc = '_desc';

export function getSortOptionKey(field: string, ascending: boolean): string {
  const normalized = field.toLowerCase();
  return ascending ? normalized : normalized + _desc;
}

export function parseSortOption(value: string): SortField {
  return value.endsWith(_desc)
    ? { field: value.slice(0, -_desc.length), ascending: false }
    : { field: value, ascending: true };
}

export function sortOptionsAreEqual(option1: SortField | null | undefined, option2: SortField | null | undefined): boolean {
  return !!option1
    && !!option2
    && option1.field.toLowerCase() === option2.field.toLowerCase()
    && option1.ascending === option2.ascending;
}

export function createLoadOptions(params: ProductListParams, options: ProductListOptions, settings: ProductListSettings): ProductListLoadOptions {
  const { sort, facets, q, fitment } = params;

  const paramsPage = params.page;
  const pageIndex = options.page || (paramsPage ? paramsPage - 1 : 0);
  const count = options.size || params.count;
  const viewMode = options.viewMode;

  const loadOptions = {
    page: {
      index: pageIndex,
      size: count || getViewModeProductAmount(viewMode, settings),
    },
    sorting: sort ? [sort] : undefined,
    facets: getFacets(facets),
    keywords: q,
    loadProductsBihaviour: CatalogSearchMode.GroupsAndRegular,
    fitment,
  };

  return loadOptions;
}

function getFacets(facets: Record<string, string[]> | undefined): Facets | undefined {
  if (facets) {
    const facetsArr = Object.entries(facets).map(([name, values]) => ({ name, values }));
    if (facetsArr.length)
      return { filter: facetsArr };
  }

  return undefined;
}