import type { Document } from './types';
import { DocumentType, QuoteStatus } from 'behavior/documents';
import { AttachmentType } from 'behavior/attachments';
import { OrderAuthorizationStatus } from './constants';

export const getDocumentData = (documentType: DocumentType): Document => ({
  id: '',
  status: 'OPEN',
  documentId: '',
  currency: {
    id: '',
    cultureName: '',
    decimalDigits: 0,
    symbol: '',
  },
  shipping: {
    methodName: null,
    status: null,
    tracking: {
      number: null,
      link: null,
    },
  },
  payment: {
    status: null,
    isPaidOnAccount: false,
    transaction: null,
    discountDate: null,
    methodName: null,
  },
  addresses: {
    billing: null,
    payer: null,
    shipping: null,
  },
  lines: {
    totalCount: 3,
    itemLines: Array.from(Array(3)).map(() => ({
      title: null,
      product: { id: '' },
      extendedTexts: [],
      sublines: [],
    })),
  },
  canReorder: null,
  orderDate: '',
  requestedDeliveryDate: null,
  referenceNo: null,
  comments: null,
  pickupLocation: null,
  attachments: Array.from(Array(5)).map(() => ({
    title: null,
    type: AttachmentType.Note,
    value: null,
  })),
  reportUrl: 'link',
  ...getExtraFields(documentType),
});

function getExtraFields(documentType: DocumentType) {
  switch (documentType) {
    case DocumentType.Order:
      return {
        documentType,
        totals: null,
        editable: true,
        authorizationInfo: {
          canAuthorize: true,
          authorizers: null,
          status: OrderAuthorizationStatus.Pending,
          feedbackMessage: null,
          authorizationDate: null,
        },
      };
    case DocumentType.Quote:
      return {
        documentType,
        status: QuoteStatus.Orderable,
        totals: null,
        editable: true,
        canConvert: true,
      };
    case DocumentType.Invoice:
      return {
        documentType,
        totals: null,
        orderId: null,
        canBeReturned: true,
      };
    case DocumentType.CreditNote:
      return {
        documentType,
        totals: null,
        orderId: null,
      };
    case DocumentType.ReturnOrder:
      return {
        documentType,
        orderId: null,
        returnReason: null,
      };
    case DocumentType.ReturnReceipt:
      return {
        documentType,
        orderId: null,
      };
    case DocumentType.Shipment:
      return {
        documentType,
        orderId: null,
      };
    default:
      throw new Error(`Document type "${documentType}" is not supported.`);
  }
}
