import type { ReactNode } from 'react';
import type { AddToast, Options, Appearance } from './types';
import { noop } from 'rxjs';
import { removeInsiteEditorEncoding } from 'components/visualDesigner/insiteEditor';

let addToast: AddToast = noop;

export const onAdd = (callback: AddToast) => {
  addToast = callback;
};

type ShowToast = (content: ReactNode, option: Options, callback?: (id: string) => void) => void;

const success: ShowToast = (content, option, callback) => {
  addToast(getToastContent(content), getToastOption(option, 'success'), callback);
};
const error: ShowToast = (content, option, callback) => {
  addToast(getToastContent(content), getToastOption(option, 'error'), callback);
};
const errorDialog: ShowToast = (content, option, callback) => {
  addToast(getToastContent(content), getToastOption(option, 'errorDialog'), callback);
};
const warning: ShowToast = (content, option, callback) => {
  addToast(getToastContent(content), getToastOption(option, 'warning'), callback);
};
const info: ShowToast = (content, option, callback) => {
  addToast(getToastContent(content), getToastOption(option, 'info'), callback);
};

export const toasts = {
  success,
  error,
  errorDialog,
  warning,
  info,
} as const;

function getToastContent(content: ReactNode) {
  return typeof content === 'string'
    ? removeInsiteEditorEncoding(content)
    : content;
}

function getToastOption(option: Options, appearance: Appearance) {
  return { ...option, appearance };
}
