import type { AppLocation } from './types';
import type { RouteData as RouteDataCore } from 'routes';
import {
  InitAction,
  NavigationRequestedAction,
  NavigatedAction,
  NavigatingAction,
  NAVIGATED,
  NAVIGATING,
  NAVIGATION_REQUESTED,
  INIT,
} from './actions';
import { areLocationsEqual } from './helpers';
import { createReducer } from 'utils/redux';

const initialState = {
  location: undefined,
  previous: undefined,
  routeData: undefined,
  navigatingTo: null,
};

type RouteData = Omit<RouteDataCore, 'canonicalUrl'>;
type InitialState = {
  location?: AppLocation;
  previous: undefined;
  routeData: undefined;
  navigatingTo: {
    location: AppLocation;
    routeData: RouteData;
  } | null;
};

type State = InitialState | {
  location: AppLocation;
  previous?: {
    location: AppLocation;
    routeData: RouteData;
  };
  routeData: RouteData;
  navigatingTo: {
    location: AppLocation;
    routeData: RouteData;
  } | null;
  canonicalUrl?: string;
};

type Action = InitAction | NavigationRequestedAction | NavigatedAction | NavigatingAction;

export default createReducer<State, Action>(initialState, {
  [INIT]: onInitialize,
  [NAVIGATED]: onNavigated,
  [NAVIGATION_REQUESTED]: onNavigationRequested,
  [NAVIGATING]: onNavigating,
});

function onInitialize(state: State, action: InitAction): State {
  const { location } = action.payload;
  return { ...state, location };
}

function onNavigated(state: State, action: NavigatedAction): State {
  const { location: newLocation, routeData: newRouteData } = action.payload;

  const { canonicalUrl, options, ...routeData } = newRouteData || {};

  return {
    ...state,
    navigatingTo: null,
    canonicalUrl,
    routeData,
    location: newLocation,
    previous: getPrevious(state, newLocation, options?.replaceHistory),
  };
}

function onNavigationRequested(state: State, action: NavigationRequestedAction): State {
  if (!action.payload.routeData)
    return state.navigatingTo ? { ...state, navigatingTo: null } : state;

  return {
    ...state,
    navigatingTo: {
      location: action.payload.location,
      routeData: action.payload.routeData,
    },
  };
}

function onNavigating(state: State, action: NavigatingAction): State {
  return {
    ...state,
    navigatingTo: action.payload,
  };
}

function getPrevious(state: State, newLocation: AppLocation, replaceHistory?: boolean) {
  const { location: currentLocation, routeData: currentRouteData } = state;
  if (!currentLocation || !currentRouteData)
    return;

  if (replaceHistory)
    return state.previous;

  const previous = areLocationsEqual(currentLocation, newLocation)
    ? state.previous
    : {
      location: currentLocation,
      routeData: currentRouteData,
    };
  return previous;
}
