export const CAPTCHA_RESET = 'CAPTCHA/RESET' as const;
export const resetCaptcha = (captchaId: string) => ({
  type: CAPTCHA_RESET,
  payload: { captchaId },
});

export const CAPTCHA_REMOVED = 'CAPTCHA/REMOVED' as const;
export const removeCaptcha = (captchaId: string) => ({
  type: CAPTCHA_REMOVED,
  payload: { captchaId },
});

export type CaptchaAction = ReturnType<
  | typeof resetCaptcha
  | typeof removeCaptcha
>;
