import styles from './LinkMenu.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { BaseNavigationItemPropTypes } from '../PropTypes';
import { renderHTML } from 'utils/render';

const NavItem = ({ item: { link, title, cssClass, children }, childrenClassName = '' }) => (
  <li className={cssClass}>
    <Link {...link}>{title && renderHTML(title)}</Link>
    {children && (
      <ul className={`${styles.subLevel} ${childrenClassName}`}>
        {children.map((child, index) => <NavItem key={index} item={child} />)}
      </ul>
    )}
  </li>
);

NavItem.propTypes = {
  item: BaseNavigationItemPropTypes.isRequired,
  childrenClassName: PropTypes.string,
};

export default NavItem;
