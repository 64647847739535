import type { ContentSystemPageData } from './types';
import { parseContent, RowContentElement } from 'behavior/content';
import { tap } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';

type ContentSystemPage<TData extends ContentSystemPageData> = Omit<TData, 'content'> & {
  content: {
    desktop: RowContentElement[] | null;
    mobile: RowContentElement[] | null;
  } | null;
};

export function initContentSystemPageContent<TData extends { page: ContentSystemPageData }>(): OperatorFunction<TData | null, Omit<TData, 'page'> & { page: ContentSystemPage<TData['page']> }> {
  return tap<TData | null>(data => {
    if (!data || !data.page)
      return;

    data.page = initContentSystemPage(data.page) as any;
  }) as any;
}

export function initContentSystemPage<TData extends ContentSystemPageData>(page: TData): ContentSystemPage<TData> {
  const result = page as ContentSystemPage<TData>;
  if (!result.content)
    return result;

  const { desktop, mobile } = page.content!;

  if (desktop)
    result.content.desktop = parseContent(desktop);

  if (mobile)
    result.content.mobile = parseContent(mobile);

  return result;
}
