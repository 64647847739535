import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const ListPage = loadable(() => import(/*webpackChunkName:"sales-agr"*/'./ListPage'));
const DetailsPage = loadable(() => import(/*webpackChunkName:"sales-agr"*/'./DetailsPage'));

/* eslint-disable react/no-multi-comp */
export default {
  [PageComponentNames.SalesAgreements]: page => (
    <ListPage desktop={page.content?.desktop} mobile={page.content?.mobile} />
  ),
  [PageComponentNames.SalesAgreement]: page => (
    <DetailsPage
      desktop={page.content?.desktop}
      mobile={page.content?.mobile}
      agreement={page.agreement}
    />
  ),
};
