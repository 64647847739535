import type { Product as GeneralProduct, CalculatedProduct } from './types';
import {
  LastViewedTrackingAction,
  LastViewedProductsRequestedAction,
  LastViewedProductsReceivedAction,
  ProductViewedAction,
  LASTVIEWED_PRODUCTS_CLEARED,
  LASTVIEWED_PRODUCTS_RECEIVED,
  LASTVIEWED_PRODUCTS_REQUESTED,
  PRODUCT_VIEWED,
} from './actions';
import { LanguageChangedAction, ViewerChangedAction, VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';
import { arrayToObject } from 'utils/helpers';

type Product = GeneralProduct | GeneralProduct & CalculatedProduct;

type State = {
  products?: Product[] | null;
  expired: boolean;
  lastViewed?: string | null;
};

type Action = LastViewedTrackingAction | LanguageChangedAction | ViewerChangedAction;

const initialState = {
  expired: false,
};

export default createReducer<State, Action>(initialState, {
  [LASTVIEWED_PRODUCTS_REQUESTED]: onRequested,
  [LASTVIEWED_PRODUCTS_RECEIVED]: onProductsReceived,
  [LASTVIEWED_PRODUCTS_CLEARED]: onCleared,
  [PRODUCT_VIEWED]: onProductViewed,
  [LANGUAGE_CHANGED]: onExpired,
  [VIEWER_CHANGED]: onCleared,
});

function onRequested(state: State, action: LastViewedProductsRequestedAction): State {
  if (action.payload.skipCurrent)
    return state;

  // Moved from PDP to PLP -> clear lastViewed.
  return { ...state, lastViewed: null };
}

function onProductsReceived(state: State, action: LastViewedProductsReceivedAction): State {
  const { products: payloadProducts } = action.payload;

  if (isGeneralProducts(payloadProducts)) {
    return {
      ...state,
      products: payloadProducts,
      expired: false,
    };
  }

  const stateProducts = state.products;
  if (stateProducts && stateProducts.length && !state.expired) {
    const productsMap = arrayToObject(payloadProducts, product => product.id);
    return {
      ...state,
      products: stateProducts.map(product => {
        const calculated = productsMap[product.id];

        return calculated
          ? { ...product, ...calculated }
          : product;
      }),
    };
  }

  // Just in case somebody cleared products between general product and calculated info calls.
  return state;
}

function onExpired(state: State): State {
  return { ...state, expired: true };
}

function onCleared(state: State): State {
  return {
    ...state,
    products: null,
  };
}

function onProductViewed(state: State, action: ProductViewedAction): State {
  const { payload: id } = action;
  const { lastViewed } = state;

  if (lastViewed && lastViewed === id)
    return state;

  return {
    ...state,
    products: null,
    lastViewed: id,
  };
}

function isGeneralProducts(products: GeneralProduct[] | CalculatedProduct[]): products is GeneralProduct[] {
  return !products.length || 'title' in products[0];
}
