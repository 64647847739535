import { DocumentType } from 'behavior/documents';
import { AbilityTo } from 'behavior/user';

export const enum OrderAuthorizationStatus {
  NotNeeded = 'NOT_NEEDED',
  Pending = 'PENDING',
  PendingNotSynced = 'PENDING_NOT_SYNCED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export const enum OrderAuthorizationAction {
  Approve = 'approve',
  Decline = 'decline',
  Cancel = 'cancel',
}

export const enum OrderAuthorizationActionResultStatus {
  Applied = 'APPLIED',
  AlreadyApplied = 'ALREADY_APPLIED',
}

export const enum OrderAuthorizationActionResultDetailedStatus {
  Approved = 'APPROVED',
  AlreadyApproved = 'ALREADY_APPROVED',
  Declined = 'DECLINED',
  AlreadyDeclined = 'ALREADY_DECLINED',
  Cancelled = 'CANCELLED',
}

export const DocumentAbilitiesMap = {
  [DocumentType.Order]: AbilityTo.ViewOrders,
  [DocumentType.Quote]: AbilityTo.ViewQuotes,
  [DocumentType.Invoice]: AbilityTo.ViewInvoices,
  [DocumentType.ReturnOrder]: AbilityTo.ViewReturnOrders,
  [DocumentType.ReturnReceipt]: AbilityTo.ViewReturnReceipts,
  [DocumentType.Shipment]: AbilityTo.ViewShipments,
  [DocumentType.CreditNote]: AbilityTo.ViewCreditNotes,
} as const;

export const enum ReorderResult {
  Success = 'SUCCESS',
  BasketNotEmpty = 'BASKET_NOT_EMPTY',
  NoProducts = 'NO_PRODUCTS',
  OrderNotFound = 'ORDER_NOT_FOUND',
}

export const enum EditResult {
  Success = 'SUCCESS',
  BasketNotEmpty = 'BASKET_NOT_EMPTY',
  NoDocument = 'NO_DOCUMENT',
}

export const enum DownloadReportResult {
  Success = 'SUCCESS',
  Waiting = 'WAITING',
  Failure = 'FAILURE',
}
