import styles from './DisabledSearchBox.module.scss';
import searchStyles from './Search.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { getThemeFontSizeClassName } from 'components/theme';
import SearchButton from './SearchButton';

const DisabledSearchBox = ({ className = '', searchText, searchTitle, options, style }) => {
  const loginLink = (
    <Link to={routesBuilder.forLogin}>
      <RichText textKey="DisabledSearchBar_Login" />
    </Link>
  );

  return (
    <div
      style={style}
      className={`${searchStyles.searchBox} ${className} ${getThemeFontSizeClassName(options.boxThemeFontSize)}`}
    >
      <form>
        <div className={`${searchStyles.container} ${searchStyles.disabled}`}>
          <div className={styles.fakeInput}>
            <span><RichText textKey="SearchBarBlockDisabledDescription" formatWith={[loginLink]} /></span>
          </div>
        </div>
        <SearchButton
          text={searchText}
          title={searchTitle}
          options={options}
          type="button"
          disabled
        />
      </form>
    </div>
  );
};

DisabledSearchBox.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  searchText: PropTypes.string,
  searchTitle: PropTypes.string,
  options: PropTypes.shape({
    boxThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(DisabledSearchBox);
