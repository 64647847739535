import type { OrderAuthorizationsAction } from './actions';
import { ORDER_AUTHORIZATIONS_RECEIVED, AUTHORIZATIONS_PENDING_COUNT_RECEIVED } from './actions';
import type { OrderAuthorizationsPage } from './types';

export default (state: OrderAuthorizationsPage, action: OrderAuthorizationsAction): OrderAuthorizationsPage => {
  switch (action.type) {
    case ORDER_AUTHORIZATIONS_RECEIVED:
      const { orderAuthorizations, pageIndex } = action.payload;

      if (!orderAuthorizations)
        return state;

      const { items, totalCount } = orderAuthorizations;
      return {
        ...state,
        orderAuthorizations: {
          items: pageIndex === 0 ? items : state.orderAuthorizations.items.concat(items),
          totalCount,
        },
      };
    case AUTHORIZATIONS_PENDING_COUNT_RECEIVED:
      return {
        ...state,
        pendingAuthorizationsCount: action.payload.count,
      };
    default:
      return state;
  }
};
