import styles from './Spinner.module.scss';
import { forwardRef, memo } from 'react';

type Props = {
  className?: string;
};

const Spinner = forwardRef<HTMLElement, Props>(({ className = '' }, ref) => (
  <span ref={ref} className={`${styles.spinner} ${className}`} />
));

export default memo(Spinner);
