import type { MutableRefObject } from 'react';
import { useRef, useMemo } from 'react';
import { useOnChange } from 'utils/hooks';

export type SliderControl = {
  currentIndex: MutableRefObject<number>;
  setIndex?: (index: number) => void;
  prev: () => void;
  next: () => void;
};

export const useSliderControl = (index: number) => {
  const currentIndex = useRef(index);

  const _setIndex = (index: number) => {
    if (control && control.setIndex)
      control.setIndex(index);
    else
      currentIndex.current = index;
  };

  const control: SliderControl = useMemo(() => ({
    get currentIndex() {
      return currentIndex;
    },
    setIndex: undefined,
    prev() { _setIndex(currentIndex.current - 1); },
    next() { _setIndex(currentIndex.current + 1); },
  }), []);

  useOnChange(() => void (_setIndex(index)), [index]);

  return control;
};
