import Item from 'react-autosuggest/dist/Item';

export default class ExtendedItem extends Item {
  render() {
    const {
      isHighlighted,
      item,
      renderItem,
      renderItemData,
      itemIndex,
      listSize,
      ...restProps
    } = this.props;

    delete restProps.sectionIndex;

    if (typeof restProps.onMouseEnter === 'function') {
      restProps.onMouseEnter = this.onMouseEnter;
    }

    if (typeof restProps.onMouseLeave === 'function') {
      restProps.onMouseLeave = this.onMouseLeave;
    }

    if (typeof restProps.onMouseDown === 'function') {
      restProps.onMouseDown = this.onMouseDown;
    }

    if (typeof restProps.onClick === 'function') {
      restProps.onClick = this.onClick;
    }

    return (
      <li
        role="option"
        aria-selected={isHighlighted}
        aria-posinset={itemIndex + 1}
        aria-setsize={listSize}
        {...restProps}
        ref={this.storeItemReference}

      >
        {renderItem(item, { isHighlighted, ...renderItemData })}
      </li>
    );
  }
}