import { memo } from 'react';
import { useResponsiveBreakpoints } from 'utils/layout';
import { ImageProps, ResponsiveLazyImage } from 'components/primitives/responsiveImages';

type Props = {
  desktopImage?: string | null;
  mobileImage: string;
  alt?: string | null;
  className?: string | null;
  afterLoad: ImageProps['afterLoad'];
  visibleByDefault?: boolean;
};

const DesktopMobileImage = ({
  desktopImage,
  mobileImage,
  alt,
  className,
  afterLoad,
  visibleByDefault,
}: Props) => {
  const { xs } = useResponsiveBreakpoints();

  if (!xs && !desktopImage)
    return null;

  return (
    <ResponsiveLazyImage
      key={xs ? 'm' : 'd'}
      src={xs ? mobileImage : desktopImage}
      className={className}
      afterLoad={afterLoad}
      visibleByDefault={visibleByDefault}
      alt={alt}
    />
  );
};

export default memo(DesktopMobileImage);
