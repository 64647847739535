import styles from '../../Details.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { memo, useEffect, useRef, useState } from 'react';
import { SimpleText } from 'components/sanaText';
import { VerticalSliding } from 'components/primitives/transitions';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import WarehouseStockItem from './WarehouseStockItem';

const WarehousesStockBlock = ({ warehousesStock, productId, uomId, stockLevels, onLoadWarehousesStock }) => {
  const [expanded, setExpanded] = useState(false);
  const opening = useRef(false);
  const controlId = 'Warehouses' + productId;

  useEffect(() => {
    if (warehousesStock && opening.current) {
      opening.current = false;
      setExpanded(true);
    }
    else
      setExpanded(false);
  }, [warehousesStock]);

  const onLoad = () => {
    if (warehousesStock)
      setExpanded(!expanded);
    else {
      opening.current = true;
      onLoadWarehousesStock(productId, uomId);
    }
  };

  const canViewStock = useHasAbilities(AbilityTo.ViewStock)[0];
  
  if (!canViewStock)
    return null;

  return (
    <div className={styles.facet}>
      <VerticalSliding expanded={expanded} containerClass={styles.slider}>
        <ul aria-expanded={expanded} className={styles.listUnstyled}>
          {warehousesStock && warehousesStock.map((stock, index) => {
            return (
              <li key={index}>
                <WarehouseStockItem warehouseStock={stock} stockLevels={stockLevels} />
              </li>
            );
          })}
        </ul>
      </VerticalSliding>
      <div role="button" className={styles.header} onClick={onLoad} tabIndex="0" aria-expanded={expanded} aria-controls={controlId}>
        <span className={`${linkStyles.link} ${linkStyles.underlined}`}>
          <SimpleText textKey={expanded ? 'Warehouses_HideLocations' : 'Warehouses_DisplayLocations'} />
        </span>
      </div>
    </div>
  );
};

WarehousesStockBlock.propTypes = {
  warehousesStock: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      isPrimary: PropTypes.boolean,
      isSelected: PropTypes.boolean,
    }),
  ),
  productId: PropTypes.string,
  uomId: PropTypes.string,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
  onLoadWarehousesStock: PropTypes.func,
};

export default memo(WarehousesStockBlock);