import type { Product } from './types';
import { createReducer } from 'utils/redux';
import {
  PRODUCT_COMPARISON_INITIALIZE,
  PRODUCT_COMPARISON_ADD_PRODUCT,
  PRODUCT_COMPARISON_REMOVE_PRODUCT,
  PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS,
  ProductComparisonAction,
  InitializeProductComparisonAction,
  AddProductToComparisonAction,
  RemoveProductFromComparisonAction,
} from './actions';

type State = {
  initialized: boolean;
  products: Product[];
};

const initialState: State = {
  initialized: false,
  products: [],
};

export default createReducer<State, ProductComparisonAction>(initialState, {
  [PRODUCT_COMPARISON_INITIALIZE]: onInitialize,
  [PRODUCT_COMPARISON_ADD_PRODUCT]: onProductAdded,
  [PRODUCT_COMPARISON_REMOVE_PRODUCT]: onProductRemoved,
  [PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS]: onAllProductsRemoved,
});

function onInitialize(state: State, action: InitializeProductComparisonAction) {
  const { productsToCompare } = action.payload;

  return {
    ...state,
    initialized: true,
    products: productsToCompare,
  };
}

function onProductAdded(state: State, action: AddProductToComparisonAction) {
  const product = action.payload;

  const index = state.products.findIndex(item => item.id === product.id);
  if (index === -1) {
    return {
      ...state,
      products: [...state.products, product],
    };
  }

  const products = [...state.products];
  products[index] = action.payload;
  return {
    ...state,
    products,
  };
}

function onProductRemoved(state: State, action: RemoveProductFromComparisonAction) {
  const { products } = state;
  const { id } = action.payload;

  return {
    ...state,
    products: products.filter(item => item.id !== id),
  };
}

function onAllProductsRemoved(state: State) {
  return {
    ...state,
    products: [],
  };
}
