import type { Handler } from '../types';
import type {
  CreateReturnOrderPage,
  ReturnOrderSettings,
  Product,
  LineBase,
  InvoiceLines,
  Invoice,
  LineUom,
} from './types';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { ContentSystemPageData, initContentSystemPageContent } from '../system';
import { loadCreateReturnOrderPageQuery, loadCreateReturnOrderPreviewPageQuery } from './queries';
import { getBackTo } from '../helpers';
import { invoice, returnReasons } from './stubData';

const handler: Handler<ReturnOrderRouteData, CreateReturnOrderPage> = (routeData, state$, { api }) => {
  const { params: { id, originalOrderId: orderId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<CreateReturnOrderPreviewPageResponse>(loadCreateReturnOrderPreviewPageQuery).pipe(
      map(({ pages: { createDocBasedReturnOrder: page }, settings: { documents: { returnOrder } } }) => ({
        page: {
          ...page,
          component: PageComponentNames.CreateDocBasedReturnOrder as const,
          returnReasons,
          fileUploadSettings: returnOrder.attachments,
          splitLinesSupported: returnOrder.splitLinesSupported,
          invoice,
        },
      })),
      initContentSystemPageContent(),
    );
  }

  if (!id)
    return of(null);

  return api.graphApi<CreateReturnOrderPageResponse>(loadCreateReturnOrderPageQuery, { id, orderId }).pipe(
    map(({ pages, documents: { invoices: { invoice } }, settings: { documents: { returnOrder } } }) => {
      const page = pages.createDocBasedReturnOrder;
      if (!invoice)
        return null;

      const lines = invoice.lines.itemLines.reduce<InvoiceLines[]>((resultLines, { product, ...line }) => {
        if ('sublines' in line) {
          if (line.sublines?.length)
            for (const subline of line.sublines)
              resultLines.push({
                ...subline,
                productId: product.id,
                title: line.title,
                variantTitle: subline.title,
                uom: getLineUom(subline, product),
                availableReturnQuantity: subline.availableReturnQuantity,
              });
        } else {
          resultLines.push({
            ...line,
            productId: product.id,
            uom: getLineUom(line, product),
            availableReturnQuantity: line.availableReturnQuantity,
          });
        }

        return resultLines;
      }, []);

      return {
        page: {
          ...page,
          component: PageComponentNames.CreateDocBasedReturnOrder as const,
          returnReasons: returnOrder.reasons,
          fileUploadSettings: returnOrder.attachments,
          splitLinesSupported: returnOrder.splitLinesSupported,
          invoice: { ...invoice, lines },
          backTo: getBackTo(state$, [
            RouteName.CreateDocBasedReturnOrder,
          ], routeData.params && routeData.params.language),
        },
      };
    }),
    initContentSystemPageContent(),
  );
};

export default handler;

function getLineUom(line: LineBase, product: Product): LineUom {
  if (!line.uom)
    return { minimumQuantity: 1, maximumQuantity: line.availableReturnQuantity };

  if (!product.uoms)
    return { ...line.uom, minimumQuantity: 1, maximumQuantity: line.availableReturnQuantity };

  const productUom = product.uoms.find(uom => uom.id === line.uom.id);
  const uom = { ...line.uom, ...productUom, maximumQuantity: line.availableReturnQuantity };

  if (uom.minimumQuantity == null)
    return { ...uom, minimumQuantity: uom.quantityStep ?? 1 };

  return uom as LineUom;
}

type ReturnOrderRouteData = {
  routeName: RouteName.CreateDocBasedReturnOrder;
  params: {
    id?: string;
    originalOrderId?: string;
    orderId?: string;
    previewToken?: string;
    language?: number | null;
  };
};

type CreateReturnOrderPageResponseBase = {
  pages: {
    createDocBasedReturnOrder: ContentSystemPageData;
  };
};

type CreateReturnOrderPreviewPageResponse = CreateReturnOrderPageResponseBase & {
  settings: {
    documents: {
      returnOrder: Omit<ReturnOrderSettings, 'reasons'>;
    };
  };
};

type CreateReturnOrderPageResponse = CreateReturnOrderPageResponseBase & {
  settings: {
    documents: {
      returnOrder: ReturnOrderSettings;
    };
  };
  documents: {
    invoices: {
      invoice: Invoice;
    };
  };
};
