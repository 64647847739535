export const defaultSearchParams = '--0--';

export const enum ButtonLayoutOption {
  IconOnly = 0,
  TextOnly = 1,
  TextRightOfIcon = 2,
  TextLeftOfIcon = 3,
  TextBelowIcon = 4,
  TextOverIcon = 5,
}
