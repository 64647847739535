import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import type { InvoiceOrderResult, PaymentData } from './types';

type Invoice = {
  id: string;
  orderId: string | null;
};

export const SUBMIT_INVOICE_ORDER = 'INVOICE_PAYMENT/SUBMIT_INVOICE_ORDER' as const;
export const submitInvoiceOrder = (
  orderId: string,
  paymentData: PaymentData,
  additionalCustomerData?: AdditionalCustomerData,
  extraPaymentData?: ExtraPaymentData,
) => ({
  type: SUBMIT_INVOICE_ORDER,
  payload: { orderId, paymentData, additionalCustomerData, extraPaymentData },
});

export const SUBMIT_INVOICE_ORDER_RESULT_RECEIVED = 'INVOICE_PAYMENT/SUBMIT_INVOICE_ORDER_RESULT_RECEIVED' as const;
export const submitInvoiceOrderResultReceived = (result: InvoiceOrderResult) => ({
  type: SUBMIT_INVOICE_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const CREATE_INVOICE_ORDER = 'INVOICE_PAYMENT/CREATE_INVOICE_ORDER' as const;
export const createInvoiceOrder = (invoices: Invoice[]) => ({
  type: CREATE_INVOICE_ORDER,
  payload: { invoices },
});

export const CREATE_INVOICE_ORDER_RESULT_RECEIVED = 'INVOICE_PAYMENT/CREATE_INVOICE_ORDER_RESULT_RECEIVED' as const;
export const createInvoiceOrderResultReceived = (result: InvoiceOrderResult) => ({
  type: CREATE_INVOICE_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const PAYMENT_METHOD_EXTRA_DATA_REQUESTED = 'INVOICE_PAYMENT/PAYMENT_METHOD_EXTRA_DATA_REQUESTED' as const;
export const requestPaymentMethodExtraData = (orderId: string, paymentMethodId: string) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_REQUESTED,
  payload: { orderId, paymentMethodId },
});

export const PAYMENT_METHOD_EXTRA_DATA_RECEIVED = 'INVOICE_PAYMENT/PAYMENT_METHOD_EXTRA_DATA_RECEIVED' as const;
export const paymentMethodExtraDataReceived = (
  paymentMethodId: string,
  additionalCustomerData: AdditionalCustomerData | null,
  extraPaymentData: ExtraPaymentData | null,
) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
  payload: { paymentMethodId, additionalCustomerData, extraPaymentData },
});

export type InvoicePaymentAction = ReturnType<
  | typeof submitInvoiceOrder
  | typeof submitInvoiceOrderResultReceived
  | typeof createInvoiceOrder
  | typeof createInvoiceOrderResultReceived
  | typeof requestPaymentMethodExtraData
  | typeof paymentMethodExtraDataReceived
>;
