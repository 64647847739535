import type scrollIntoView from 'scroll-into-view';
import { createContext } from 'react';

type Options = {
  timeout?: number;
  extraTopOffset?: number;
} & Parameters<typeof scrollIntoView>[1];

type NavigateToFn = (hash: string, options?: Options, callback?: () => void) => void;

export type HashRoutingContext = {
  hash: string;
  options: Options;
  navigateTo: NavigateToFn;
  callback?: () => void;
};

export default createContext({} as HashRoutingContext);
