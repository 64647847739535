import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Breadcrumb from './Breadcrumb';
import { Container } from 'components/primitives/grid';
import { PageContent } from 'components/objects/pages';
import { PageTitle } from 'components/objects/pages';

const ProductComparison = loadable(() => import(/*webpackChunkName:"product-comp"*/'./ProductComparison'));

const ProductComparisonPage = ({ header, footer, products, specifications, backTo }) => (
  <>
    <Container>
      <Breadcrumb />
      <PageTitle textKey="ProductCompare_Header" />
    </Container>
    <PageContent content={header} />
    <Container>
      <ProductComparison products={products} specifications={specifications} backTo={backTo} />
    </Container>
    <PageContent content={footer} />
  </>
);

ProductComparisonPage.propTypes = {
  header: PropTypes.array,
  footer: PropTypes.array,
  products: PropTypes.array,
  specifications: PropTypes.array,
  backTo: PropTypes.object,
};

export default ProductComparisonPage;
