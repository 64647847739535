import { memo } from 'react';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import inputStyles from 'components/primitives/form/Input.module.scss';
import { Modal, useModal } from 'components/objects/modals';
import { RichText, SimpleText } from 'components/sanaText';
import { useEffect } from 'react';
import { createBrowserCookies } from 'utils/cookies';
import { useSelector } from 'react-redux';
import dateOnly from 'date-only';
import { makeRichText } from 'utils/render';

const SubscribePopup = () => {

  const popupShowDateCookieName = 'newsletterShowDate';
  const isSubscribedCookieName = 'isNewsletterSubscribed';
  const { opened, show, hide } = useModal();
  const isClientSide = typeof window !== 'undefined' && window.document;
  const cookies = createBrowserCookies();
  const newsletterSettings = useSelector(({ settings: { newsletter } }) => newsletter);
  let isSubscribed, previousShowDate;
  if (isClientSide) {
    isSubscribed = cookies.get(isSubscribedCookieName);
    previousShowDate = dateOnly.parse(cookies.get(popupShowDateCookieName));
  }

  useEffect(() => {
    if (!isClientSide)
      return null;

    if (isSubscribed)
      return;

    if (!newsletterSettings || !newsletterSettings.showNewsletterPopup || !newsletterSettings.newsletterPopupHtml || newsletterSettings.showNewsletterPopupXDays <= 0)
      return;

    if (previousShowDate) {
      previousShowDate.setDate(previousShowDate.getDate() + newsletterSettings.showNewsletterPopupXDays);

      if (previousShowDate > dateOnly.today())
        return;
    }

    const expirationDate = new Date(new Date().setDate(new Date().getDate() + 999999));

    const options = {
      expires: expirationDate,
    };

    cookies.set(popupShowDateCookieName, dateOnly.toISOString(dateOnly.today()), options);

    show();

    setTimeout(() => {
      const form = document.querySelector('.subscribe-form-popup form');
      form && form.addEventListener('submit', () => {
        cookies.set(
          isSubscribedCookieName,
          true,
          {
            expires: expirationDate,
          },
        );
      });
    }, 100);

  }, [newsletterSettings, isSubscribed, previousShowDate]);

  return (newsletterSettings &&
    <Modal opened={opened} hide={hide} show={show}>
      <h2><SimpleText textKey="NewsletterSubscribePopup_Title" /></h2>
      <div className={'subscribe-form-popup'}>
        {makeRichText(newsletterSettings.newsletterPopupHtml)}
      </div>
    </Modal>
  );
};

export default memo(SubscribePopup);