import styles from './Tooltip.module.scss';
import { useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { PromotionalText } from 'components/objects/promotionalTexts';
import { useEventObservable, orientationChange$, resize$ } from 'utils/rxjs';
import { useExtras, useLayoutShifter } from 'utils/layout';
import { noop, merge, EMPTY } from 'rxjs';
import ReactResizeDetector from 'react-resize-detector';
import { useIsMouse } from 'components/detection';

const resizeRefreshOptions = { leading: false, trailing: true };

const Tooltip = ({
  children,
  title,
  body,
  className,
  bodyClassName,
  id,
  anchorRef,
  showOnTop,
  showOnRight,
  options,
  sign = true,
  ...attr
}) => {
  const isMouse = useIsMouse();
  const extras = useExtras();
  const { topFixedElementsHeight, bottomFixedElementsHeight } = useLayoutShifter();

  const tooltipRef = useRef();
  const tooltipPopupRef = useRef();

  const [opened, forceSetOpened, setOpened] = useOpenedState(options, setTooltipPopupPosition);

  function setTooltipPopupPosition() {
    if (
      !tooltipRef.current
      || !tooltipPopupRef.current
      || tooltipPopupRef.current.offsetHeight === 0
    )
      return;

    resetPopupStyles(tooltipPopupRef.current.style);
    setPopupStyles(
      anchorRef || tooltipRef,
      tooltipPopupRef,
      topFixedElementsHeight,
      bottomFixedElementsHeight,
      showOnTop,
      showOnRight,
    );
  }

  const handleResize = useCallback(
    setTooltipPopupPosition,
    [
      topFixedElementsHeight,
      bottomFixedElementsHeight,
      showOnTop,
      showOnRight,
    ],
  );

  const alwaysOpened = options && options.opened, listenResize = alwaysOpened && opened;
  const scroll$ = (options && options.scroll$) || EMPTY;

  useEventObservable(
    () => listenResize ? merge(orientationChange$, resize$, scroll$) : merge(orientationChange$, scroll$),
    () => {
      setOpened(false);
    },
    opened,
    [listenResize],
  );

  useEffect(
    setTooltipPopupPosition,
    [
      title,
      anchorRef ? !!anchorRef.current : !!tooltipRef.current,
      !!tooltipPopupRef.current,
      topFixedElementsHeight,
      bottomFixedElementsHeight,
    ],
  );

  const showTooltip = e => {
    const alwaysOpened = options && options.opened;
    if (opened) {
      if (isMouse && e && e.type === 'click')
        forceSetOpened(false);
      return;
    }

    if (alwaysOpened && e && e.type === 'mouseover')
      return;

    if (e && e.type === 'focus') {
      setTimeout(() => void (setTooltipPopupPosition(), forceSetOpened(true)), 100);
    } else {
      setTooltipPopupPosition();
      forceSetOpened(true);
    }
  };

  const hideTooltip = () => setOpened(false);

  const tooltipClassNames = joinClasses(
    styles.tooltip,
    className,
    opened && styles.opened,
  );

  const toolTipPopupClassNames = extras && joinClasses(
    styles.popup,
    bodyClassName,
    opened && styles.opened,
  );

  const onKeyDown = e => {
    if (e.keyCode === 27)
      forceSetOpened(false); // on Escape
    else if (e.keyCode === 13)
      opened ? forceSetOpened(false) : showTooltip(); // on Enter
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      {...attr}
      ref={tooltipRef}
      onMouseOver={showTooltip}
      onFocus={showTooltip}
      onClick={showTooltip}
      onKeyDown={onKeyDown}
      onMouseLeave={hideTooltip}
      onBlur={hideTooltip}
      className={tooltipClassNames}
      aria-describedby={opened ? id : null}
      aria-controls={id}
      tabIndex="0"
    >
      {children}
      {sign &&
        <span className={styles.sign} aria-hidden>
          <PromotionalText textKey="TooltipSign" disableInsiteEditor />
        </span>
      }
      {
        extras
          ? createPortal((
            <div
              ref={tooltipPopupRef}
              className={toolTipPopupClassNames}
              role="tooltip"
              aria-hidden
              id={id}
            >
              {title && <h2 className="h4">{title}</h2>}
              {body && <div>{body}</div>}
            </div>
          ), extras)
          : <ins id={id} /> // To pass w3c validation in SSR.
      }
      <ReactResizeDetector
        handleWidth
        handleHeight
        refreshMode="debounce"
        refreshRate={100}
        refreshOptions={resizeRefreshOptions}
        onResize={handleResize}
        targetDomEl={typeof window !== 'undefined' ? document.body : null}
        nodeType="span"
      />
    </span>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  id: PropTypes.string,
  anchorRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  showOnTop: PropTypes.bool,
  showOnRight: PropTypes.bool,
  options: PropTypes.object,
  sign: PropTypes.bool,
};

export default Tooltip;

function resetPopupStyles(styleObj) {
  styleObj.top = '';
  styleObj.left = '';
  styleObj.maxHeight = '';
}

function setPopupStyles(tooltipRef, tooltipPopupRef, topShift, bottomShift, showOnTop, showOnRight) {
  if (!tooltipRef.current)
    return;

  const { offsetWidth, offsetHeight, style } = tooltipPopupRef.current;
  const { pageXOffset, pageYOffset } = window;
  const { offsetWidth: bodyWidth, offsetHeight: bodyHeight } = document.body;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const { top, left, bottom, right, height } = tooltipRef.current.getBoundingClientRect();
  const tooltipPopupOverflowHeight = offsetHeight / 2 - height / 2;

  const viewportTopOffsetHeight = top - offsetHeight;
  const viewportBottomOffsetHeight = viewportHeight - (bottom + offsetHeight);
  const topOffsetHeight = pageYOffset + viewportTopOffsetHeight;
  const bottomOffsetHeight = bodyHeight - (pageYOffset + bottom + offsetHeight);
  const popupTopPosition = pageYOffset + top - offsetHeight - 1;
  const popupGeneralLeftPosition = showOnRight
    ? right + offsetWidth >= bodyWidth ? left - offsetWidth - 1 : right
    : right >= offsetWidth ? right - offsetWidth - 1 : 0;
  const popupBottomPosition = pageYOffset + bottom + 1;

  if (showOnTop && viewportTopOffsetHeight > topShift) {
    setPopupPosition(popupTopPosition, popupGeneralLeftPosition);
    return;
  }

  if (viewportBottomOffsetHeight > bottomShift) {
    setPopupPosition(popupBottomPosition, popupGeneralLeftPosition);
    return;
  }

  if (!showOnTop && viewportTopOffsetHeight > topShift) {
    setPopupPosition(popupTopPosition, popupGeneralLeftPosition);
    return;
  }

  const sideTopOffsetHeight = top - tooltipPopupOverflowHeight;
  const sideBottomOffsetHeight = viewportHeight - (bottom + tooltipPopupOverflowHeight);
  const canBeShownBySide = (sideTopOffsetHeight > 0 && sideBottomOffsetHeight > 0);
  const leftOffsetWidth = pageXOffset + left - offsetWidth;
  const rightOffsetWidth = bodyWidth - (pageXOffset + right + offsetWidth);
  const isLeftOffsetLargerThanRight = leftOffsetWidth > rightOffsetWidth;
  const popupSideTopPosition = pageYOffset + sideTopOffsetHeight;

  if (canBeShownBySide && leftOffsetWidth > 0 && isLeftOffsetLargerThanRight) {
    setPopupPosition(popupSideTopPosition, left - offsetWidth - 1);
    return;
  }

  if (canBeShownBySide && rightOffsetWidth > 0 && !isLeftOffsetLargerThanRight) {
    setPopupPosition(popupSideTopPosition, right + 1);
    return;
  }

  if (bottomOffsetHeight > 0 && viewportBottomOffsetHeight > viewportTopOffsetHeight) {
    setPopupPosition(popupBottomPosition, popupGeneralLeftPosition);
    return;
  }

  if (topOffsetHeight > 0) {
    setPopupPosition(popupTopPosition, popupGeneralLeftPosition);
    return;
  }

  if (topOffsetHeight > bottomOffsetHeight) {
    style.maxHeight = `${Math.floor(pageYOffset + top - 1)}px`;
    setPopupPosition(0, popupGeneralLeftPosition);
    return;
  }

  style.maxHeight = `${Math.floor(bodyHeight - pageYOffset - bottom - 1)}px`;
  setPopupPosition(popupBottomPosition, popupGeneralLeftPosition);

  function setPopupPosition(top, left) {
    style.top = top + 'px';
    style.left = left + 'px';
  }
}

function useOpenedState(options, setStyle) {
  const forcedToOpen = !!options && options.opened;
  const state = useState(forcedToOpen);

  useEffect(() => {
    if (forcedToOpen && !state[0])
      setStyle();
    state[1](forcedToOpen);
  }, [options]);

  return state.concat(forcedToOpen ? noop : state[1]);
}
