import { PageComponentNames } from 'behavior/pages/componentNames';
import { RouteName, routesBuilder } from 'routes';
import { Handler } from 'behavior/pages/types';
import { map } from 'rxjs/operators';
import { blogItemPageQuery } from './queries';
import { RowContentElementData, parseContent } from 'behavior/content';
import type { BlogItemPage } from './types';
import { getBackTo } from '../helpers';

const handler: Handler<BlogItemRouteData, BlogItemPage> = ({ params: { id, language, previewToken } }, state$, { api }) =>
  api.graphApi<BlogItemPageResponse>(blogItemPageQuery, { blogItemId: id, commentsOptions: {} }).pipe(
    map(({ pages: { blogItem } }) => {
      if (!blogItem)
        return null;

      const backTo = previewToken
        ? undefined
        : getBackTo(state$, [RouteName.BlogItemPage], language);

      return {
        page: {
          backTo: backTo ?? { routeData: routesBuilder.forBlog() },
          metaTitle: blogItem.metaTitle,
          metaDescription: blogItem.metaDescription,
          content: {
            desktop: parseContent(blogItem.content.desktop),
            mobile: blogItem.content.mobile && parseContent(blogItem.content.mobile),
          },
          blogItem: blogItem.blogItem,
          title: blogItem.blogItem.title,
          component: PageComponentNames.BlogItemPage as const,
        },
      };
    }),
  );

export default handler;

type BlogItemRouteData = {
  routeName: RouteName.BlogItemPage;
  params: {
    id: string;
    language?: number;
    previewToken?: string;
  };
};

type BlogItemPageResponse = {
  pages: {
    blogItem: {
      metaTitle: string | null;
      metaDescription: string | null;
      content: {
        desktop: RowContentElementData[];
        mobile: RowContentElementData[] | null;
      };
      blogItem: {
        id: string;
        title: string;
        author: string;
        publishDate: string;
        tags: {
          id: string;
          title: string;
        }[];
        categories: {
          id: string;
          title: string;
        }[];
        showComments: boolean;
        comments: {
          totalCount: number;
          items: {
            title: string;
            name: string;
            details: string;
            date: string;
          }[];
        };
        url: string;
        shortDescription: string | null;
        image: string | null;
      };
    } | null;
  };
};
