import React from 'react';
import { map } from 'rxjs/operators';
import { ProductPrice } from 'components/primitives/product';
import { useEffect, useState } from 'react';
import { useServices } from 'utils/services';
import PropTypes from 'prop-types';
import { isUndefined } from './ImageBlockUtilities';
import AddProductButton from '../productList/addons/AddProductButton';
import styles from './FlyersBlock.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import ViewProductButton from '../productList/ViewProductButton';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';

const AddToBasket = ({ buttonAddToCart, linkStyle }) => {
  const [product, setProduct] = useState({});
  const {
    isOrderable,
    hasVariants,
    isProductGroup,
    productConfiguratorInfo,
  } = product;    
  const { api } = useServices();
  useEffect(() => {
    requestOptions.subscribe(product => setProduct(product));
  }, [buttonAddToCart.catalogueProduct]);
  const QUERY = `
        query ($productId: String!) {
            pages {
                product(productId: $productId) {
                    product {
                        id
                        isOrderable
                        hasVariants
                        isProductGroup
                        productConfiguratorInfo {
                            configuratorId
                            isProductConfigurable
                            modelId
                        }
                        url
                        price
                        listPrice
                    }
                }
            }
        }`;

  const requestOptions = api
    .graphApi(QUERY, { productId: buttonAddToCart.catalogueProduct })
    .pipe(
      map(data => {
        return {
          ...data.pages.product.product,
        };
      }),
    );

    let content = null;
    const placeholder = <Placeholder className="placeholder" />;
    if(isProductGroup === true){
        content = (
          <ViewProductButton
            size='md'
            textKey="ViewProductGroup"
            className={'btn-action'}
            product={product}
            placeholder={placeholder}
          />
        );
      } else if (isOrderable == null) {
        content = placeholder;
      } 
      else if (isOrderable === false) {
        content = (
          <span className="msg-not-available">
            <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
          </span>
        );
      } else if (productConfiguratorInfo.isProductConfigurable) {
        content = (
          <ViewProductButton
            size='md'
            textKey="ConfigureProduct"
            className={`${btnStyles.btnMedium} btn-configure`}
            titleTextKey="ConfigureProduct_ListPage"
            product={product}
            placeholder={placeholder}
          />
        );
      } else if (hasVariants) {
        content = (
          <ViewProductButton
            size='md'
            textKey="SelectVariants"
            className={'btn-action'}
            product={product}
            placeholder={placeholder}
          />
        );
      } else {
        content = (
            <AddProductButton
              size='md'
              productId={buttonAddToCart.catalogueProduct}
              style={linkStyle}
              className={`${btnStyles.btnMedium} flayers-button-addtocart-click`}
            /> 
        );
      }

  return (
    <>
      {!isUndefined(buttonAddToCart) &&
        !isUndefined(buttonAddToCart.catalogueProduct) &&
        buttonAddToCart.useAddToCartButton && (
          <>
          {buttonAddToCart.showCataloguePrice && !product.isProductGroup && (
              <div className={styles.priceWrp}>
                <ProductPrice
                  salesPrice={product.price}
                  basePrice={product.listPrice}
                  priceColor={buttonAddToCart.cataloguePriceColor}
                />
              </div>
            )}
            {content}
          </>
        )}
    </>
  );
};

AddToBasket.propTypes = {
    buttonAddToCart: PropTypes.object,
    linkStyle: PropTypes.object,
};

export default React.memo(AddToBasket);
