import styles from '../WarehouseSelector.module.scss';
import PropTypes from 'prop-types';
import { WarehouseSelector } from 'components/primitives/icons';

const Icon = ({ className = '' }) => {
  return <WarehouseSelector className={`${className} ${styles.warehouseIcon}`} />;
};

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;