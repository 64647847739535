import { PageComponentNames } from 'behavior/pages';
import ProductComparisonPage from './Page';

export default {
  [PageComponentNames.ProductComparison]: page => (
    <ProductComparisonPage
      header={page.content?.header}
      footer={page.content?.footer}
      products={page.products}
      specifications={page.specifications}
      backTo={page.backTo}
    />
  ),
};
