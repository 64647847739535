import type { Epic } from 'behavior/types';
import type { AuthorizationInfo } from '../types';
import { ofType } from 'redux-observable';
import { exhaustMap, startWith, mergeMap } from 'rxjs/operators';
import { ORDER_AUTHORIZATION_ACTION_REQUESTED, orderAuthorizationActionResultReceived, DocumentAction } from '../actions';
import { orderAuthorizationMutations } from '../queries';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { of } from 'rxjs';
import {
  OrderAuthorizationActionResultStatus,
  OrderAuthorizationActionResultDetailedStatus,
  OrderAuthorizationAction,
} from '../constants';
import { requestPendingAuthorizationsCount } from 'behavior/pages/orderAuthorizations';

type OrderAuthorizationResponse = {
  documents: {
    orderAuthorization: {
      [action in OrderAuthorizationAction]: {
        status: OrderAuthorizationActionResultStatus;
        authorizationInfo: AuthorizationInfo;
      }
    };
  };
};

const epic: Epic<DocumentAction> = (action$, _state$, dependencies) => action$.pipe(
  ofType(ORDER_AUTHORIZATION_ACTION_REQUESTED),
  exhaustMap(({ payload: { actionType, input } }) =>
    dependencies.api.graphApi<OrderAuthorizationResponse>(
      orderAuthorizationMutations[actionType],
      { input },
      { retries: 0 },
    ).pipe(
      mergeMap(result => {
        const { status, authorizationInfo } = result.documents.orderAuthorization[actionType];

        return of(
          orderAuthorizationActionResultReceived(getDetailedAuthorizationResultStatus(actionType, status), authorizationInfo),
          requestPendingAuthorizationsCount(),
          unsetLoadingIndicator(),
        );
      }),
      retryWithToast(action$, dependencies.logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator())),
  ),
);

function getDetailedAuthorizationResultStatus(actionType: OrderAuthorizationAction, status: OrderAuthorizationActionResultStatus) {
  const justApplied = status === OrderAuthorizationActionResultStatus.Applied;

  switch (actionType) {
    case OrderAuthorizationAction.Approve:
      return justApplied
        ? OrderAuthorizationActionResultDetailedStatus.Approved
        : OrderAuthorizationActionResultDetailedStatus.AlreadyApproved;
    case OrderAuthorizationAction.Decline:
      return justApplied
        ? OrderAuthorizationActionResultDetailedStatus.Declined
        : OrderAuthorizationActionResultDetailedStatus.AlreadyDeclined;
    default:
      return OrderAuthorizationActionResultDetailedStatus.Cancelled;
  }
}

export default epic;
