exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Logo_logo a{display:block;line-height:0}.Logo_logo img{display:inline-block;max-width:100%;vertical-align:bottom}@media not all and (min-width:env(--theme-breakpoints_Medium,960)px){html:not(.print) .Logo_logo img{max-height:32px}}", ""]);

// exports
exports.locals = {
	"logo": "Logo_logo"
};