import type { CalculatedProduct } from './types';

export const PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED = 'PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED' as const;
export const requestCalculatedProductList = (groupedProductIds: string[]) => ({
  type: PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED,
  payload: { groupedProductIds },
});

export const PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED = 'PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED' as const;
export const requestCalculatedProduct = (groupedProductId: string, uomId: string) => ({
  type: PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED,
  payload: { groupedProductId, uomId },
});

export const PRODUCTGROUP_PRODUCTS_UPDATED = 'PRODUCTGROUP_PRODUCTS_UPDATED' as const;
export const productsUpdated = (products: CalculatedProduct[] | null) => ({
  type: PRODUCTGROUP_PRODUCTS_UPDATED,
  payload: products,
});

export type ProductGroupAction = ReturnType<
  | typeof requestCalculatedProductList
  | typeof requestCalculatedProduct
  | typeof productsUpdated
>;
