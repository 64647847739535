import type { Epic } from 'behavior/types';
import {
    productFilterMakeBlockQuery,
    productFilterModelBlockQuery,
    productFilterYearBlockQuery,
} from './queries';

import {
    PRODUCT_FILTER_YEAR_LOAD_COLLECTION_REQUESTED,
    productFieldYearCollectionReceived,
    PRODUCT_FILTER_MAKE_LOAD_COLLECTION_REQUESTED,
    productFieldMakeCollectionReceived,
    PRODUCT_FILTER_MODEL_LOAD_COLLECTION_REQUESTED,
    productFieldModelCollectionReceived,
    ProductFilterAction,
} from './actions';
import { merge } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import retryWithToast from 'behavior/errorHandling/mocks/retryWithToast';
import { LOCATION_CHANGED } from 'behavior/events';
import { YearFilterData } from './types';

const productFilterBlockEpic: Epic<ProductFilterAction> = (action$, _, { api, logger }) => {

    const locationChanged$ = action$.pipe(
        ofType(
            LOCATION_CHANGED,
        ),
    );
    
    const onProductFilterYearCollectionLoad$ = action$.pipe(
        ofType(PRODUCT_FILTER_YEAR_LOAD_COLLECTION_REQUESTED),
        mergeMap(({ payload: { options } }) => {
            return api.graphApi<YearFilterData>(productFilterYearBlockQuery, { options })
                .pipe(
                    map(data => {
                        return productFieldYearCollectionReceived(data);
                    }),
                    retryWithToast(action$, logger),
                    takeUntil(locationChanged$),
                );
        }),
    );

    const onProductFilterMakeCollectionLoad$ = action$.pipe(
        ofType(PRODUCT_FILTER_MAKE_LOAD_COLLECTION_REQUESTED),
        mergeMap(({ payload: { options } }) => {
            return api.graphApi(productFilterMakeBlockQuery, { options })
                .pipe(
                    map(data => {
                        return productFieldMakeCollectionReceived(data);
                    }),
                    retryWithToast(action$, logger),
                    takeUntil(locationChanged$),
                );
        }),
    );

    const onProductFilterModelCollectionLoad$ = action$.pipe(
        ofType(PRODUCT_FILTER_MODEL_LOAD_COLLECTION_REQUESTED),
        mergeMap(({ payload: { options } }) => {
            return api.graphApi(productFilterModelBlockQuery, { options })
                .pipe(
                    map(data => {
                        return productFieldModelCollectionReceived(data);
                    }),
                    retryWithToast(action$, logger),
                    takeUntil(locationChanged$),
                );
        }),
    );

    return merge(
        onProductFilterYearCollectionLoad$,
        onProductFilterMakeCollectionLoad$,
        onProductFilterModelCollectionLoad$,
    );
}; 

export default productFilterBlockEpic;