import styles from './StyleWrapper.module.scss';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ContentHorizontalAlignment, VerticalSelfAlignment } from './constants';
import { joinClasses } from 'utils/helpers';

const StyleWrapper = forwardRef(({
  children,
  spacing: { margin, padding },
  minHeight,
  minWidth,
  horizontalAlignment,
  verticalSelfAlignment,
  contentOrientation,
  stretchHeight,
  stretchWidth,
}, ref) => {
  const isSplitContent = contentOrientation === 'HORIZONTAL_SPLIT';
  const isHorizontalOrientation = contentOrientation === 'HORIZONTAL' || isSplitContent;

  const style = {
    padding: padding || null,
    margin: margin || null,
    textAlign: ContentHorizontalAlignment[horizontalAlignment] || null,
    alignSelf: stretchHeight && isSplitContent
      ? VerticalSelfAlignment.JUSTIFY
      : isHorizontalOrientation && VerticalSelfAlignment[verticalSelfAlignment] || null,
    '--minWidth_Desktop': minWidth.desktop,
    '--minWidth_Tablet': minWidth.tablet,
    '--minWidth_Mobile': minWidth.mobile,
    '--minHeight_Desktop': minHeight.desktop,
    '--minHeight_Tablet': minHeight.tablet,
    '--minHeight_Mobile': minHeight.mobile,
  };

  if (margin) {
    if (isHorizontalOrientation)
      style.height = stretchHeight && !isSplitContent ? getDimensionString(margin, true) : null;
    else
      style.width = stretchWidth ? getDimensionString(margin) : null;
  }

  return (
    <div
      ref={ref}
      className={joinClasses(
        styles.wrapper,
        stretchHeight && !isSplitContent && (isHorizontalOrientation ? styles.stretchHeight : styles.flexStretchHeight),
        stretchWidth && (isHorizontalOrientation ? styles.flexStretchWidth : styles.stretchWidth),
      )}
      style={style}
    >{children}</div>
  );
});

const PropTypesMinValue = PropTypes.shape({
  mobile: PropTypes.string,
  tablet: PropTypes.string,
  desktop: PropTypes.string,
}).isRequired;

StyleWrapper.propTypes = {
  children: PropTypes.any,
  spacing: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  minHeight: PropTypesMinValue,
  minWidth: PropTypesMinValue,
  horizontalAlignment: PropTypes.oneOf(Object.keys(ContentHorizontalAlignment)),
  verticalSelfAlignment: PropTypes.oneOf(Object.keys(VerticalSelfAlignment)),
  contentOrientation: PropTypes.oneOf([
    'VERTICAL',
    'HORIZONTAL',
    'HORIZONTAL_SPLIT',
  ]),
  stretchHeight: PropTypes.bool,
  stretchWidth: PropTypes.bool,
};

export default StyleWrapper;

function getDimensionString(marginString, sideIndents = false) {
  const filterFn = (_, index) => sideIndents ? index % 2 === 0 : index % 2 !== 0;
  const [value1, value2] = marginString.split(/\s/).filter(filterFn).map(m => parseInt(m, 10));
  return value1 || value2 ? `calc(100% - ${value1 + value2}px)` : null;
}
