exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Heading_container{display:inline-block;margin:0}", ""]);

// exports
exports.locals = {
	"container": "Heading_container"
};