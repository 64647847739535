import styles from './ProductTiles.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProductTile from './ProductTile';
import { Slider } from 'components/primitives/slider';

const ProductTilesSlider = ({ products, noImage, tilesPerView = 4 }) => {
  const enableSlider = products.length > tilesPerView;

  const tiles = useMemo(() => products.map(product => (
    <ProductTile
      key={product.id}
      product={product}
      noImage={noImage}
      tilesPerView={enableSlider ? null : tilesPerView}
    />
  )), [products, tilesPerView]);

  if (!tiles || !tiles.length)
    return null;

  return (
    <div className={styles.productTiles} role="presentation" onDragStart={preventDefault}>
      {enableSlider
        ? <Slider items={tiles} tilesPerView={tilesPerView} showArrows={false} />
        : <div className={styles.withoutSlider}>{tiles}</div>
      }
    </div>
  );
};

ProductTilesSlider.propTypes = {
  tilesPerView: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  noImage: PropTypes.string,
};

export default connect(({
  settings: { product: productSettings },
}) => ({
  noImage: productSettings && productSettings.noImage.small,
}))(ProductTilesSlider);

function preventDefault(e) {
  e.preventDefault();
}