import { of } from 'rxjs';
import { RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { paymentErrorQuery } from './queries';
import { initContentSystemPage, loadContentSystemPageQuery } from 'behavior/pages/system';
import { CheckoutProcessNames } from './constants';
import type { Handler } from 'behavior/pages/types';
import type { ContentSystemPage, ContentSystemPageData } from '../system/content';
import type { PaymentErrorTransaction } from './types';

const handler: Handler<PaymentErrorRouteData, PaymentErrorPage> = (routeData, _state$, { api }) => {

  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<PaymentErrorPreviewResponse>(loadContentSystemPageQuery('paymentError')).pipe(
      map(({ pages: { paymentError } }) => ({
        page: {
          ...initContentSystemPage(paymentError),
          component: PageComponentNames.PaymentError as const,
          transaction: {
            id: '-',
            isPaymentError: true as const,
            failed: false as const,
            cancelled: false as const,
            document: { id: '-', documentId: '-' },
            checkoutProcessName: CheckoutProcessNames.Order,
          },
        },
      })),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi<PaymentErrorPageResponse>(paymentErrorQuery, { id: transactionId }).pipe(
    mergeMap(({ pages: { paymentError }, paymentTransaction: transaction }) => {
      if (transaction == null)
        return of(null);

      return of({
        page: {
          ...initContentSystemPage(paymentError),
          component: PageComponentNames.PaymentError as const,
          transaction: {
            id: transactionId,
            isPaymentError: true as const,
            failed: false as const,
            cancelled: false as const,
            ...transaction,
          },
        },
        });
    }),
  );
};

export default handler;

type PaymentErrorRouteData = {
  routeName: RouteName.PaymentError;
  params: {
    previewToken?: string;
    transactionId?: string;
  };
};

type PaymentErrorPage = ContentSystemPage & {
  component: PageComponentNames.PaymentError;
  transaction: PaymentErrorTransaction;
};

type PaymentErrorPreviewResponse = {
  pages: {
    paymentError: ContentSystemPageData;
  };
};

type PaymentErrorPageResponse = {
  paymentTransaction: {
    checkoutProcessName: CheckoutProcessNames;
    document: {
      id: string;
      documentId: string;
    };
  };
} & PaymentErrorPreviewResponse;
