import type { CalculatedProductData, ProductData } from './types';
import type { WarehouseStock } from 'behavior/pages/product';

type SortingType = {
  field: string;
  ascending: boolean;
};

export const PRODUCT_SET_REQUESTED = 'PRODUCT_SET_REQUESTED' as const;
export const requestProductSet = (contentBlockId: string, productSetId: string, size: number, sorting: SortingType | undefined, calculate: boolean) => ({
  type: PRODUCT_SET_REQUESTED,
  payload: { contentBlockId, productSetId, size, sorting, calculate },
});

export const PRODUCT_SET_RECEIVED = 'PRODUCT_SET_RECEIVED' as const;
export const productSetReceived = (contentBlockId: string, products: Array<ProductData | CalculatedProductData>, calculated: boolean) => ({
  type: PRODUCT_SET_RECEIVED,
  payload: { contentBlockId, products, calculated },
});

export const PRODUCT_SET_WAREHOUSES_STOCK_REQUESTED = 'PRODUCT_SET_WAREHOUSES_STOCK_REQUESTED' as const;
export const requestProductSetItemWarehousesStock = (contentBlockId: string, id: string, uomId: string) => ({
  type: PRODUCT_SET_WAREHOUSES_STOCK_REQUESTED,
  payload: { contentBlockId, id, uomId },
});

export const PRODUCT_SET_WAREHOUSES_STOCK_RECEIVED = 'PRODUCT_SET_WAREHOUSES_STOCK_RECEIVED' as const;
export const productSetWarehousesStockReceived = (contentBlockId: string, id: string, warehousesStock: WarehouseStock[] | null) => ({
  type: PRODUCT_SET_WAREHOUSES_STOCK_RECEIVED,
  payload: { contentBlockId, id, warehousesStock },
});

export type ProductSetAction = ReturnType<
  | typeof requestProductSet
  | typeof productSetReceived
  | typeof requestProductSetItemWarehousesStock
  | typeof productSetWarehousesStockReceived
>;