import type { CreateReturnOrderPage } from './types';
import {
  DOC_FREE_RETURN_ORDER_SUBMITTED,
  DOC_FREE_RETURN_ORDER_FAILED,
  DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED,
  CreateDocFreeReturnOrderAction,
} from './actions';
import { arrayToObject } from 'utils/helpers';

export default (state: CreateReturnOrderPage, action: CreateDocFreeReturnOrderAction): CreateReturnOrderPage => {
  switch (action.type) {
    case DOC_FREE_RETURN_ORDER_SUBMITTED:
      return { ...state, isFailed: undefined, updatedById: undefined };
    case DOC_FREE_RETURN_ORDER_FAILED:
      return { ...state, isFailed: true, updatedById: action.payload.updatedById };
    case DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED:
      return {
        ...state,
        products: { ...state.products, ...arrayToObject(action.payload.products, product => product.id) },
      };
    default:
      return state;
  }
};
