import styles from './MainNav.module.scss';
import gridStyles from 'components/primitives/grid/Grid.module.scss';
import { createContext, useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { useLayoutShifter } from 'utils/layout';
import { NavigationGroupCode } from 'behavior/navigation/constants';
import { useIsMobileSafari } from 'utils/detections';
import { useNavMenuContext } from './hooks';

const iOSBottomIndent = +styles.iOSBottomIndent;
const resizeRefreshOptions = { leading: false, trailing: true };

export const NavDimensionsContext = createContext(null);

const NavDimensionsProvider = ({ navRef, children }) => {
  const [navDimensions, setNavDimensions] = useState(null);
  const { topFixedElementsHeight, bottomFixedElementsHeight } = useLayoutShifter();
  const containerRef = useRef();

  if (!containerRef.current && navRef.current) {
    containerRef.current = navRef.current.closest('.' + styles.navContainer) // Modal navigation.
      || navRef.current.closest('.' + gridStyles.container) // Desktop navigation and CascadingMenu content block in a row with fullWidth="false".
      || navRef.current.closest('.' + gridStyles.row).parentElement; // CascadingMenu content block in a row with fullWidth="true".
  } else if (!navRef.current) {
    containerRef.current = undefined;
  }

  const shouldSetDimensions = !!containerRef.current;
  const { componentGroup } = useNavMenuContext();
  const mainNavItems = useSelector(({ navigation }) => navigation[componentGroup]?.[NavigationGroupCode.Main], shallowEqual);
  const isMobileSafari = useIsMobileSafari();

  const handleResize = useCallback(() => {
    const navDimensions = getCurrentNavDimensions(
      containerRef.current,
      navRef.current,
      topFixedElementsHeight,
      bottomFixedElementsHeight,
      isMobileSafari,
    );
    setNavDimensions(navDimensions);
  }, [topFixedElementsHeight, bottomFixedElementsHeight, isMobileSafari]);

  useEffect(() => {
    if (!shouldSetDimensions)
      return;

    const navDimensions = getCurrentNavDimensions(
      containerRef.current,
      navRef.current,
      topFixedElementsHeight,
      bottomFixedElementsHeight,
      isMobileSafari,
    );

    setNavDimensions(prev => {
      if (!prev)
        return navDimensions;

      for (const key in navDimensions) {
        if (prev[key] !== navDimensions[key])
          return navDimensions;
      }

      return prev;
    });
  }, [shouldSetDimensions, mainNavItems, topFixedElementsHeight, bottomFixedElementsHeight, isMobileSafari]);

  return (
    <NavDimensionsContext.Provider value={navDimensions}>
      {shouldSetDimensions &&
        <ReactResizeDetector
          handleWidth
          handleHeight
          refreshMode="debounce"
          refreshRate={500}
          refreshOptions={resizeRefreshOptions}
          onResize={handleResize}
          targetDomEl={containerRef.current.parentElement}
          skipOnMount
        />
      }
      {children}
    </NavDimensionsContext.Provider>
  );
};

export default NavDimensionsProvider;

NavDimensionsProvider.propTypes = {
  navRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  children: PropTypes.node,
};

function getCurrentNavDimensions(containerElement, navElement, topShift, bottomShift, isMobileSafari) {
  const [listRootElement] = navElement.children;
  const { clientHeight } = containerElement;
  const { offsetWidth, clientWidth } = listRootElement;
  const listRootWidth = offsetWidth - (offsetWidth - clientWidth) / 2;
  let menuMaxHeight = window.innerHeight - bottomShift - topShift;

  if (isMobileSafari)
    menuMaxHeight = menuMaxHeight + bottomShift - iOSBottomIndent;

  return {
    navContainerWidth: containerElement.parentElement.offsetWidth,
    navWidth: navElement.clientWidth,
    listRootWidth,
    menuMaxHeight: clientHeight > menuMaxHeight ? menuMaxHeight : clientHeight,
  };
}
