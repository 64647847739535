import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { processMedia, parseRelatedProducts, Preset } from 'behavior/pages/product';
import { VisualDesignerMode } from 'behavior/visualDesigner';
import { visualDesignerLoaded } from 'behavior/visualDesigner/actions/general';
import { parseQuery } from 'utils/url';
import { createProductPageQuery } from './queries';
import productData from './stubData';
import type { AppState } from 'behavior';
import type { Api } from 'utils/api';
import type {
  CalculatedProduct,
  ProductData,
} from 'behavior/pages/product';
import type { VisualDesignerPageHandler } from '../types';
import type { Product } from './types';

const handler: VisualDesignerPageHandler<ProductPage> = (api: Api, state: AppState) => {
  const { routing: { navigatingTo }, settings } = state;
  const { itemId, languageId } = parseQuery(navigatingTo!.location.search);
  const decodedItemId = itemId && decodeURIComponent(itemId);

  if (decodedItemId) {
    const loadProductGroup = settings.loaded && settings.product.productGrouping.isEnabled;
    languageId && api.setLanguage(languageId, false);
    const variables = {
      productId: decodedItemId,
      specificationFilter: ProductSpecificationFilter.ForDetails,
    };

    return api.graphApi<ProductPageResponse>(createProductPageQuery({ loadProductGroup }), variables).pipe(
      map(({ pages: { product: productPageData } }) => {
        const preset = productPageData.preset || productPageData.templateContent?.preset || Preset.Details;

        return {
          page: {
            product: processRelatedProductsAndMedia(productPageData.product),
            preset,
            header: productPageData.header,
            title: productPageData.header ?? productPageData.product.title,
            component: PageComponentNames.VisualDesigner,
          },
          action$: of(visualDesignerLoaded(VisualDesignerMode.ProductDetails)),
        };
      }),
    );
  }
  else {
    return of({
      action$: of(visualDesignerLoaded(VisualDesignerMode.ProductDetails)),
      page: {
        product: productData,
        title: productData.title,
        component: PageComponentNames.VisualDesigner,
      },
    });
  }
};

function processRelatedProductsAndMedia(product: ProductData): Product {
  const relatedProductGroups = parseRelatedProducts(product.relatedProductGroups);
  const media = processMedia(product.media);

  return { ...product, media, relatedProductGroups };
}

export default handler;

type ProductPageResponse = {
  pages: {
    product: {
      header: string | null;
      preset: Preset | null;
      product: ProductData;
      templateContent: {
        preset: Preset;
      } | null;
    };
  };
};

type ProductPage = {
  component: PageComponentNames.VisualDesigner;
  title: string | null;
  product: Product & CalculatedProduct | Product;
  preset?: Preset;
  header?: string | null;
};
