import type { InvoicePaymentPage } from 'behavior/pages/invoicePayment';
import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const PaymentPage = loadable(() => import(/*webpackChunkName:"invoice-payment"*/'./PaymentPage'));
const UpdateFailedPage = loadable(() => import(/*webpackChunkName:"invoice-payment"*/'./UpdateFailedPage'));

/* eslint-disable react/no-multi-comp */
export default {
  [PageComponentNames.InvoicePayment]: (page: InvoicePaymentPage) => <PaymentPage desktop={page.content?.desktop} mobile={page.content?.mobile} />,
  [PageComponentNames.InvoiceUpdateFailed]: () => <UpdateFailedPage />,
};
