import type { Handler } from '../types';
import type { CreateReturnOrderPage, ReturnOrderSettings } from './types';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { ContentSystemPageData, initContentSystemPageContent } from '../system';
import { pageQuery, previewPageQuery } from './queries';
import { RouteName } from 'routes';

const handler: Handler<ReturnOrderRouteData, CreateReturnOrderPage> = (routeData, _state$, { api }) =>
  api.graphApi<PageQueryResponse>(
    routeData.params.previewToken ? previewPageQuery : pageQuery,
  ).pipe(
    map(({ pages: { createDocFreeReturnOrder: page }, settings: { documents: { returnOrder } } }) => ({
      page: {
        ...page,
        component: PageComponentNames.CreateDocFreeReturnOrder as const,
        returnReasons: returnOrder.reasons ?? { forOrder: [], forOrderLines: [] },
        fileUploadSettings: returnOrder.attachments,
        products: {},
      },
    })),
    initContentSystemPageContent(),
  );

export default handler;

type ReturnOrderRouteData = {
  routeName: RouteName.CreateDocFreeReturnOrder;
  params: {
    previewToken?: string;
  };
};

type PageQueryResponse = {
  pages: {
    createDocFreeReturnOrder: ContentSystemPageData;
  };
  settings: {
    documents: {
      returnOrder: ReturnOrderSettings;
    };
  };
};
