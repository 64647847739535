import type { CheckoutOffer } from './types';

export const CHECKOUT_OFFERS_REQUESTED = 'CHECKOUT_OFFERS_REQUESTED' as const;
export const requestOffers = () => ({
  type: CHECKOUT_OFFERS_REQUESTED,
});

export const CHECKOUT_OFFERS_EXPIRED = 'CHECKOUT_OFFERS_EXPIRED' as const;
export const offersExpired = () => ({
  type: CHECKOUT_OFFERS_EXPIRED,
});

export const CHECKOUT_OFFERS_RECEIVED = 'CHECKOUT_OFFERS_RECEIVED' as const;
export const offersReceived = (offers: Array<CheckoutOffer>) => ({
  type: CHECKOUT_OFFERS_RECEIVED,
  payload: offers,
});

export type CheckoutOffersAction = ReturnType<
  | typeof offersReceived
  | typeof requestOffers
  | typeof offersExpired
>;