import type { Product, CalculatedProduct } from './types';

export const LASTVIEWED_PRODUCTS_CLEARED = 'LASTVIEWED_PRODUCTS_CLEARED' as const;
export const clearLastViewedProducts = () => ({
  type: LASTVIEWED_PRODUCTS_CLEARED,
});

export const LASTVIEWED_PRODUCTS_REQUESTED = 'LASTVIEWED_PRODUCTS_REQUESTED' as const;
export const requestLastViewedProducts = (skipCurrent: boolean, count: number) => ({
  type: LASTVIEWED_PRODUCTS_REQUESTED,
  payload: { skipCurrent, count },
});
export type LastViewedProductsRequestedAction = ReturnType<typeof requestLastViewedProducts>;

export const LASTVIEWED_PRODUCTS_RECEIVED = 'LASTVIEWED_PRODUCTS_RECEIVED' as const;
export function lastViewedProductsReceived(products: Product[] | CalculatedProduct[]) {
  return {
    type: LASTVIEWED_PRODUCTS_RECEIVED,
    payload: { products },
  };
}
export type LastViewedProductsReceivedAction = ReturnType<typeof lastViewedProductsReceived>;

export const PRODUCT_VIEWED = 'PRODUCT_VIEWED' as const;
export const trackViewedProduct = (id: string) => ({
  type: PRODUCT_VIEWED,
  payload: id,
});
export type ProductViewedAction = ReturnType<typeof trackViewedProduct>;

export type LastViewedTrackingAction = ReturnType<
  | typeof clearLastViewedProducts
  | typeof requestLastViewedProducts
  | typeof lastViewedProductsReceived
  | typeof trackViewedProduct
>;
