import styles from './MediaBackground.module.scss';
import gridStyles from 'components/primitives/grid/Grid.module.scss';
import { videoFrameAspectRatio } from 'components/primitives/video/Video.module.scss';
import { memo, useRef, useCallback, useMemo, useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';
import DesktopMobileImage from './DesktopMobileImage';
import { BackgroundVideo } from 'components/primitives/video';
import { scroll$, resize$, orientationChange$, useEventObservable } from 'utils/rxjs';
import { merge, of } from 'rxjs';
import { usePrintMode } from 'utils/hooks';
import { joinClasses } from 'utils/helpers';

const PARENT_TYPES = {
  row: 'row',
  col: 'column',
} as const;

const effectsMap = {
  NONE: '',
  KEN_BURN: styles.kenBurn,
  BLACK_AND_WHITE: styles.blackAndWhite,
} as const;

type ParentType = typeof PARENT_TYPES[keyof typeof PARENT_TYPES];

type Props = {
  parentType: ParentType;
  fullWidth?: boolean;
  color?: string | null;
  desktopImage?: string | null;
  mobileImage?: string | null;
  hideImageOnMobile?: boolean;
  isMobile?: boolean;
  imageAltText?: string | null;
  video?: string | null;
  borderWidth?: string;
  borderStyle?: string | null;
  borderColor?: string | null;
  cornerRadius?: string | null;
  imageLoadVisibleByDefault?: boolean;
  mutedSound?: boolean;
  imageEffect: keyof typeof effectsMap | null;
};

const MediaBackground = ({
  parentType = PARENT_TYPES.row,
  fullWidth,
  color,
  desktopImage,
  mobileImage,
  hideImageOnMobile,
  isMobile,
  imageAltText,
  video,
  borderWidth,
  borderStyle,
  borderColor,
  cornerRadius,
  imageLoadVisibleByDefault,
  mutedSound,
  imageEffect,
}: Props) => {
  const isPrintMode = usePrintMode();
  const containerClass = getContainerClass(parentType);
  const containerStyles = {
    backgroundColor: color,
    borderWidth: borderStyle !== 'NONE' ? (borderWidth || 0) : null,
    borderStyle,
    borderColor,
    borderRadius: cornerRadius,
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [playAnimation, setPlayAnimation] = useState<boolean>();

  const imageEffectClassName = !isPrintMode && imageEffect ? effectsMap[imageEffect] : '';

  const wrapperClass = joinClasses(
    getWrapperClass(parentType, fullWidth),
    imageEffectClassName,
    playAnimation && styles.playAnimation,
  );

  const handleResize = useCallback(() => {
    if (desktopImage || mobileImage)
      adjustImage(wrapperRef.current?.querySelector('.' + styles.image));

    if (video)
      adjustVideo(wrapperRef.current?.querySelector('.' + styles.video));
  }, [desktopImage, mobileImage, video]);

  useEffect(handleResize, [handleResize, isPrintMode]);
  useEventObservable(resize$, handleResize, true, [handleResize]);
  useEventObservable(orientationChange$, handleResize, true, [handleResize]);

  useEffect(() => {
    if (!imageEffectClassName)
      return;

    const eventSubscription = merge(of(1), scroll$).subscribe(() => setPlayAnimation(isInView(wrapperRef.current)));

    return () => eventSubscription.unsubscribe();
  }, [imageEffectClassName]);

  const backgroundImage = useMemo(() => {
    if ((!desktopImage && !mobileImage) || (isMobile && hideImageOnMobile))
      return null;

    if (mobileImage)
      return (
        <DesktopMobileImage
          desktopImage={desktopImage}
          mobileImage={mobileImage}
          className={styles.image}
          afterLoad={handleResize}
          visibleByDefault={imageLoadVisibleByDefault}
          alt={imageAltText || ''}
        />
      );

    return (
      <ResponsiveLazyImage
        src={desktopImage || mobileImage}
        className={styles.image}
        afterLoad={handleResize}
        visibleByDefault={imageLoadVisibleByDefault}
        alt={imageAltText || ''}
      />
    );
  }, [desktopImage, mobileImage, isMobile, hideImageOnMobile]);

  return (
    <div className={containerClass} style={containerStyles}>
      {(desktopImage || mobileImage || video) && <ReactResizeDetector handleHeight onResize={handleResize} />}
      <div className={wrapperClass} ref={wrapperRef}>
        <div className={styles.mediaBox}>
          {backgroundImage}
          {video && <BackgroundVideo src={video} className={styles.video} mutedSound={mutedSound} />}
        </div>
      </div>
    </div>
  );
};

export default memo(MediaBackground);

function getContainerClass(parentType: ParentType) {
  return parentType === PARENT_TYPES.row ? styles.container : `${styles.container} ${styles.column}`;
}

function getWrapperClass(parentType: ParentType, fullWidth: boolean | undefined) {
  if (parentType === PARENT_TYPES.col)
    return styles.mediaWrapper;

  return fullWidth ? styles.mediaWrapper : `${styles.mediaWrapper} ${gridStyles.container}`;
}

function adjustImage(element: HTMLImageElement | null | undefined) {
  if (!element)
    return;

  const originRatio = element.naturalHeight / element.naturalWidth;
  const parentRatio = element.parentElement!.offsetHeight / element.parentElement!.offsetWidth;
  const isFullHeight = element.classList.contains(styles.fullHeight);
  if (originRatio < parentRatio) {
    !isFullHeight && element.classList.add(styles.fullHeight);
  } else {
    isFullHeight && element.classList.remove(styles.fullHeight);
  }
}

function adjustVideo(element: HTMLVideoElement | null | undefined) {
  if (!element)
    return;

  element.style.width = Math.ceil(element.parentElement!.offsetHeight / +videoFrameAspectRatio) + 'px';
}

function isInView(block: HTMLDivElement | null | undefined) {
  if (!block)
    return false;

  const { top, bottom } = block.getBoundingClientRect();

  return top > 0 && top < window.innerHeight || bottom > 0 && bottom < window.innerHeight;
}
