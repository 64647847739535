import styles from './WishList.module.scss';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import Breadcrumb from './Breadcrumb';
import { PageContent, PageTitle } from 'components/objects/pages';

const WishList = loadable(() => import(/*webpackChunkName:"wishlist"*/'./WishList'));

const WishListPage = ({ header, footer, productLines, nonOrderableLines }) => (
  <>
    <Container>
      <Breadcrumb />
      <PageTitle className={styles.title} textKey="WishList_Title" />
    </Container>
    <PageContent content={header} position="header" />
    <Container>
      <WishList productLines={productLines} nonOrderableLines={nonOrderableLines} />
    </Container>
    <PageContent content={footer} position="footer" />
  </>
);

WishListPage.propTypes = {
  header: PropTypes.array,
  footer: PropTypes.array,
  productLines: PropTypes.array,
  nonOrderableLines: PropTypes.array,
};

export default WishListPage;
