import loadable from '@loadable/component';

const OrderPayment = loadable(() => import(/*webpackChunkName:"order-paymnt"*/'./OrderPayment'));
const PaymentSuccessful = loadable(() => import(/*webpackChunkName:"order-paymnt"*/'./successful'));
const PaymentCancelled = loadable(() => import(/*webpackChunkName:"order-paymnt"*/'./cancelled'));
const PaymentFailed = loadable(() => import(/*webpackChunkName:"order-paymnt"*/'./failed'));

/* eslint-disable react/no-multi-comp */
export function orderPaymentRenderer(page) {
  return (
    <OrderPayment
      transactionId={page.transactionId}
      order={page.order}
      paymentMethods={page.paymentMethods}
      failureFetching={page.failureFetching}
      payOrderResult={page.payOrderResult}
      additionalCustomerData={page.additionalCustomerData}
      extraPaymentData={page.extraPaymentData}
    />
  );
}

export function paymentSuccessfulRenderer(page) {
  return <PaymentSuccessful transactionId={page.transaction.id} document={page.transaction.document} />;
}

export function paymentCancelledRenderer(page) {
  return <PaymentCancelled transactionId={page.transaction.id} />;
}

export function paymentFailedRenderer(_page) {
  return <PaymentFailed />;
}
