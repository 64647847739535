import type { Handler } from 'behavior/pages/types';
import type { RouteName } from 'routes';
import type { SystemPage, SystemPageData } from 'behavior/pages/system';
import type { Product as GeneralProduct, CalculatedProduct } from '../types';
import type { LoadedSettings } from 'behavior/settings';
import type { AppState } from 'behavior';
import { createLoadPageQuery } from './queries';
import { map, first, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { requestPage } from './actions';
import { createApiVariables, sortProducts } from 'behavior/products/lastViewedTracking/helpers';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { initPageContent, loadSystemPageQuery } from 'behavior/pages/system';

const handler: Handler<PageRouteData, Page> = ({ params }, state$, { api, localStorage }) => {
  if (params?.previewToken) {
    return api.graphApi<PageResponse>(loadSystemPageQuery('lastViewedProducts')).pipe(
      map(({ pages: { lastViewedProducts: page } }) => {
        if (!page)
          return null;

        const lastViewed = Array.from(Array(3)).map((_, index) => ({
          id: (index + 1).toString(),
          url: '',
          title: '',
          isOrderable: true,
          productConfiguratorInfo: {},
        }) as Product);

        return {
          page: {
            ...initPageContent(page),
            component: PageComponentNames.LastViewedProducts as const,
            lastViewed,
          },
        };
      }),
    );
  }

  const handle = ({ settings, analytics, insiteEditor }: LoadedAppState) => {
    const variables = settings.lastViewedEnabled ? createApiVariables(localStorage) : undefined;
    if (variables) {
      variables.loadCategories = analytics.isTrackingEnabled;
      variables.options.ignoreGrouping = true;
    }

    const query = createLoadPageQuery({
      loadProducts: !!variables,
      isInsiteEditor: insiteEditor.initialized,
    });

    return api.graphApi<PageResponse>(query, variables).pipe(
      map(data => {
        const page = data.pages.lastViewedProducts;
        if (!page)
          return null;

        const products = data.catalog?.products?.products;
        const lastViewed = products ? sortProducts(products, variables!) : null;

        const result: HandlerResult = {
          page: {
            ...initPageContent(page),
            component: PageComponentNames.LastViewedProducts as const,
            lastViewed,
          },
        };

        if (variables)
          result.action$ = of(requestPage(true));

        return result;
      }),
    );
  };

  return state$.pipe(
    first(isStateLoaded),
    switchMap(handle),
  );
};

export default handler;

function isStateLoaded(state: AppState): state is LoadedAppState {
  return state.settings.loaded && !!state.analytics;
}

type PageRouteData = {
  routeName: RouteName.LastViewedProducts;
  params?: {
    previewToken?: string;
  };
};

type Product = GeneralProduct | GeneralProduct & CalculatedProduct;

type Page = SystemPage & {
  component: PageComponentNames.LastViewedProducts;
  lastViewed: Product[] | null;
};

type HandlerResult = {
  page: Page;
  action$?: Observable<unknown>;
};

type LoadedAppState = AppState & {
  settings: LoadedSettings;
  analytics: NonNullable<AppState['analytics']>;
};

type PageResponse = {
  pages: {
    lastViewedProducts: SystemPageData | null;
  };
  catalog?: {
    products: {
      products: Product[];
    } | null;
  };
};
