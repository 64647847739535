import { useMemo } from 'react';
import { formatAsPrice } from 'utils/format';
import { useCurrencyInfo } from 'utils/hooks';

export default (price: number | null | undefined, format: (formattedPrice: string) => string) => {
  const currency = useCurrencyInfo();

  return useMemo(() => {
    if (price == null || !currency)
      return null;

    const formattedPrice = formatAsPrice(price, currency);
    return format ? format(formattedPrice) : formattedPrice;
  }, [price, currency]);
};
