import styles from './Grid.module.scss';
import type { Optional } from 'utils/types';
import type { HtmlProps, HtmlTagName } from 'utils/react';
import { forwardRef, memo, Ref, ReactElement } from 'react';
import { isObject } from 'lodash';

const colWidths = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

type ColSize =
  | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'
  | true | '' | 'auto';

type Order =
  | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'
  | 'first' | 'last';

type Offset =
  | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11
  | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11';

const getColumnSizeClass = (colWidth: Exclude<typeof colWidths[number], 'xs'> | null, colSize: ColSize): string => {
  if (colSize === true || colSize === '') {
    return colWidth ? styles[`col-${colWidth}`] : styles.col;
  } else if (colSize === 'auto') {
    return colWidth ? styles[`col-${colWidth}-auto`] : styles.colAuto;
  }

  return colWidth ? styles[`col-${colWidth}-${colSize}`] : styles[`col-${colSize}`];
};

const alignmentClassNamesMap = {
  auto: styles.alignSelfAuto,
  start: styles.alignSelfStart,
  center: styles.alignSelfCenter,
  end: styles.alignSelfEnd,
  baseline: styles.alignSelfBaseline,
  stretch: styles.alignSelfStretch,
} as const;

type ColumnProps =
  | ColSize
  | {
    size?: ColSize | null;
    order?: Order | null;
    offset?: Offset | null;
  }
  | null;

type Props<T1 extends HtmlTagName | undefined, T2 extends HtmlTagName = T1 extends HtmlTagName ? T1 : 'div'> = {
  as: T1;
  crossAxisAlign?: keyof typeof alignmentClassNamesMap | null;
  className?: string | null;
  xs?: ColumnProps;
  sm?: ColumnProps;
  md?: ColumnProps;
  lg?: ColumnProps;
  xl?: ColumnProps;
} & Omit<HtmlProps<T2>, 'className' | 'ref'>;

declare function Col<T extends HtmlTagName>(props: Props<T> & { ref?: Ref<T> }): ReactElement;
declare function Col<T extends undefined>(props: Optional<Props<T>, 'as'> & { ref?: Ref<HTMLDivElement> }): ReactElement;

const ForwardRefCol = forwardRef<any, Props<any>>((props, ref) => {
  const {
    as: Tag = 'div',
    className,
    crossAxisAlign,
    ...attributes
  } = props;
  const colClasses: string[] = [];

  colWidths.forEach(colWidth => {
    const columnProp = attributes[colWidth];

    delete attributes[colWidth];

    if (!columnProp && columnProp !== '')
      return;

    const isXs = colWidth === 'xs';

    if (!isXs && columnProp && !colClasses.length)
      colClasses.push(styles.col);

    if (isObject(columnProp)) {
      const colSizeInterfix = isXs ? '-' : `-${colWidth}-` as const;

      if (columnProp.size || columnProp.size === '')
        colClasses.push(getColumnSizeClass(isXs ? null : colWidth, columnProp.size));
      if (columnProp.order || columnProp.order === 0)
        colClasses.push(styles[`order${colSizeInterfix}${columnProp.order}`]);
      if (columnProp.offset || columnProp.offset === 0)
        colClasses.push(styles[`offset${colSizeInterfix}${columnProp.offset}`]);
    } else {
      colClasses.push(getColumnSizeClass(isXs ? null : colWidth, columnProp));
    }
  });

  if (!colClasses.length)
    colClasses.push(styles.col);

  colClasses.push(crossAxisAlign ? alignmentClassNamesMap[crossAxisAlign] : alignmentClassNamesMap.auto);

  if (className)
    colClasses.push(className);

  return <Tag {...attributes} className={colClasses.join(' ')} ref={ref} />;
});

ForwardRefCol.displayName = 'Col';

export default memo(ForwardRefCol) as typeof Col;
