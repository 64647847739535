import type { VisualDesignerState } from '../types';
import type { GeneralAction } from '../actions/general';
import {
  VISUAL_DESIGNER_LOADED,
  VISUAL_DESIGNER_SET_ERRORS,
  VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED,
  VISUAL_DESIGNER_SET_SETTINGS,
  VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES,
  VISUAL_DESIGNER_SET_VIEWDATA,
  visualDesignerLoaded,
  setErrors,
  receiveVisualDesignerResourceTexts,
  setVisualDesignerSettings,
  setCustomerSegmentTitles,
  setVisualDesignerViewData,
} from '../actions/general';
import { initialState } from './defaultData';

export default (state: VisualDesignerState = initialState, action: GeneralAction) => {
  if (action.type === VISUAL_DESIGNER_LOADED)
    return onLoaded(state, action);
  if (action.type === VISUAL_DESIGNER_SET_ERRORS)
    return onSetErrors(state, action);
  if (action.type === VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED)
    return onReceiveResourceTexts(state, action);
  if (action.type === VISUAL_DESIGNER_SET_SETTINGS)
    return onSetSettings(state, action);
  if (action.type === VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES)
    return onSetCustomerSegmentTitles(state, action);
  if (action.type === VISUAL_DESIGNER_SET_VIEWDATA)
    return onSetViewData(state, action);

  return state;
};

function onLoaded(state: VisualDesignerState, action: ReturnType<typeof visualDesignerLoaded>): VisualDesignerState {
  return {
    ...state,
    initialized: true,
    mode: action.payload.mode,
  };
}

function onSetErrors(state: VisualDesignerState, action: ReturnType<typeof setErrors>): VisualDesignerState {
  const { errors } = action.payload;
  return {
    ...state,
    errors,
  };
}

function onReceiveResourceTexts(state: VisualDesignerState, action: ReturnType<typeof receiveVisualDesignerResourceTexts>): VisualDesignerState {
  const { texts } = action.payload;
  return {
    ...state,
    resourceTexts: {
      ...state.resourceTexts,
      ...texts,
    },
  };
}

function onSetSettings(state: VisualDesignerState, action: ReturnType<typeof setVisualDesignerSettings>): VisualDesignerState {
  const { settings } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      ...settings,
    },
  };
}

function onSetCustomerSegmentTitles(state: VisualDesignerState, action: ReturnType<typeof setCustomerSegmentTitles>): VisualDesignerState {
  const { titles } = action.payload;
  return {
    ...state,
    customerSegmentTitles: { ...titles },
  };
}

function onSetViewData(state: VisualDesignerState, action: ReturnType<typeof setVisualDesignerViewData>): VisualDesignerState {
  const { viewData } = action.payload;
  return {
    ...state,
    viewData,
  };
}
