import { PageComponentNames } from 'behavior/pages';
import WishListPage from './Page';

export default {
  [PageComponentNames.WishList]: page => (
    <WishListPage
      header={page.content?.header}
      footer={page.content?.footer}
      productLines={page.productLines}
      nonOrderableLines={page.nonOrderableLines}
    />
  ),
};
