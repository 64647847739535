import styles from '../WarehouseSelector.module.scss';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { RichText } from 'components/sanaText';
import { selectWarehouse } from 'behavior/user';

const WarehouseSelectorItems = ({ warehousesInfo, onSelected }) => {
  const dispatch = useDispatch();
  const handleClick = warehouse => { 
    if(warehouse.isSelected)
      return;
    dispatch(selectWarehouse(warehouse.id, true));
    onSelected && onSelected();
  };

  warehousesInfo.sort((a, b) => {
    return (a.isPrimary === b.isPrimary) ? 0 : a.isPrimary ? -1 : 1;
  });

  return (
    <>
      {warehousesInfo.map(warehouse => (
        <button key={warehouse.id} className={`${styles.item} ${warehouse.isSelected ? styles.warehouseBtnSelected : styles.warehouseBtnNotSelected}`} onClick={() => handleClick(warehouse)}>
          <span className={styles.warehouseName}>
            <RichText textKey={`Warehouse_Name_${warehouse.id}`} />
          </span>
          <span className={styles.warehouseText}>
            <RichText textKey={warehouse.isPrimary ? `Warehouse_${warehouse.id}_SubHeading_Primary` : `Warehouse_${warehouse.id}_SubHeading_Secondary`} />
          </span>
        </button>
      ))}
  </>
  );
};

WarehouseSelectorItems.propTypes = {
  warehousesInfo: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      isPrimary: PropTypes.boolean,
      isSelected: PropTypes.boolean,
    }).isRequired,
  ).isRequired,
};

export default WarehouseSelectorItems;
