import type { Action } from 'redux';
import type { Location } from '../types';
import type { StoreDependencies } from '../../types';
import type { RouteData } from 'routes';
import { redirectTo } from '../actions';
import { createLocation } from '../helpers';
import { destroyApp } from 'behavior/app';
import { isAbsoluteUrl } from 'utils/url';

export function redirect(url: string, location: Location, route: RouteData | undefined, dependencies: StoreDependencies): Action | undefined {
  const urlLocation = createLocation(url);
  if (urlLocation.pathname === location.pathname)
    return;

  if (dependencies.scope === 'CLIENT' && isAbsoluteUrl(url)) {
    (window as any).location = url;
    return destroyApp();
  }

  return redirectTo(url, 301, route);
}
