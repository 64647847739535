import type { Epic } from 'behavior/types';
import type { ProductGroupAction } from './actions';
import type { LoadedSettings, Settings } from 'behavior/settings';
import { ofType } from 'redux-observable';
import { mergeMap, map, pluck, concatMap, catchError, startWith, filter, switchMap, takeUntil } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import {
  PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED,
  PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED,
  productsUpdated,
} from './actions';
import { createCalculatedProductsQuery, specificationsSettingsQuery } from './queries';
import { merge, of, throwError } from 'rxjs';
import { settingsLoaded, setUpdating } from 'behavior/settings';
import { NAVIGATING } from 'behavior/routing';

const productGroupEpic: Epic<ProductGroupAction> = (action$, state$, { api, logger }) => {
  const specificationsSettings$ = action$.pipe(
    ofType(PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED),
    filter(_ => {
      const settings = state$.value.settings as LoadedSettings;
      return settings.product.productGrouping.isEnabled && !settings.product.productGrouping.specifications;
    }),
    mergeMap(_ => api.graphApi<SettingsResponse>(specificationsSettingsQuery).pipe(
      pluck('settings'),
      map(settingsLoaded),
      catchError(e => merge(of(settingsLoaded()), throwError(e))),
      startWith(setUpdating())),
    ),
  );

  const calculatedSingleProduct$ = action$.pipe(
    ofType(PRODUCTGROUP_CALCULATED_PRODUCT_REQUESTED),
    pluck('payload'),
    switchMap(({ groupedProductId, uomId }) => {
      const options = {
        ids: [groupedProductId],
        uomId,
        ignoreGrouping: true,
      };
      const query = createCalculatedProductsQuery(state$.value.insiteEditor.initialized);

      return api.graphApi<CalculatedProductsResponse>(query, { options }).pipe(
        pluck('catalog', 'products', 'products'),
        map(productsUpdated),
        retryWithToast(action$, logger),
      );
    }),
  );

  const navigating$ = action$.pipe(ofType(NAVIGATING));

  const calculatedProductList$ = action$.pipe(
    ofType(PRODUCTGROUP_CALCULATED_PRODUCTLIST_REQUESTED),
    pluck('payload'),
    mergeMap(data => of(data).pipe(
      mergeMap(({ groupedProductIds }) => {
        const settings = state$.value.settings as LoadedSettings;
        const productsPerBatch = settings.productList.listProductAmount;
        const query = createCalculatedProductsQuery(state$.value.insiteEditor.initialized);

        const optionBatches: {
          ids: string[];
          page: {
            size: number;
          };
          ignoreGrouping: true;
          query: string;
        }[] = [];

        const idsCopy = [...groupedProductIds];

        for (let index = 0; index < idsCopy.length; index + productsPerBatch) {
          const batchIds = idsCopy.splice(index, productsPerBatch);
          optionBatches.push({
            ids: batchIds,
            page: {
              size: productsPerBatch,
            },
            ignoreGrouping: true,
            query,
          });
        }

        return optionBatches;
      }),
      concatMap(({ query, ...options }) => api.graphApi<CalculatedProductsResponse>(query, { options }).pipe(
        pluck('catalog', 'products', 'products'),
        map(productsUpdated),
        retryWithToast(action$, logger),
      )),
      takeUntil(navigating$),
    )),
  );

  return merge(specificationsSettings$, calculatedProductList$, calculatedSingleProduct$);
};

export default productGroupEpic;

type SettingsResponse = {
  settings: Partial<Settings>;
};

type CalculatedProductsResponse = {
  catalog: {
    products: {
      products: Array<CalculatedProductResponse>;
    };
  };
};

type CalculatedProductResponse = {
  id: string;
  price: number | null;
  listPrice: number | null;
  inventory: number | null;
  isOrderable: boolean | null;
  uom: {
    id: string;
  } | null;
  variantComponentGroups: Array<{
    id: string;
  }>;
  specifications: Array<{
    key: string;
    name: string;
    value: string | null;
    titleTextKey?: string;
    valueTextKey?: string;
  }>;
};
