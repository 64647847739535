exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LinkButton_element{vertical-align:bottom!important}", ""]);

// exports
exports.locals = {
	"element": "LinkButton_element"
};