import type { Epic } from 'behavior/types';
import { of, merge, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { filter, ignoreElements, tap, delayWhen, map, shareReplay, takeUntil, take } from 'rxjs/operators';
import { ANALYTICS_DATALAYER_READY, ANALYTICS_PAGE_TITLE_IS_SET, AnalyticsAction, AnalyticsPageTitleIsSetAction } from './actions';
import { APP_STATE_UPDATED, OfflineModeSupport, LoadedAppState } from 'behavior/app';
import { NAVIGATED, NavigatedAction } from 'behavior/routing';
import { skipIfPreview } from 'behavior/preview';
import { pushToDataLayer } from './dataLayer';
import { createPageViewPayload } from './payload';

const epic: Epic<AnalyticsAction | NavigatedAction | AnalyticsPageTitleIsSetAction> = (action$, state$) => {
  const pageTitleIsSet$ = action$.pipe(
    ofType(ANALYTICS_PAGE_TITLE_IS_SET),
    map(_ => {
      const appState = state$.value.app as LoadedAppState;

      return {
        loadedTime: state$.value.page.loadedTime,
        limitedAccessMode: appState.limitedAccessMode,
        offline: isAppOffline(appState),
      };
    }),
    shareReplay(1),
  );

  const trackPageView$ = action$.pipe(
    ofType(ANALYTICS_DATALAYER_READY, NAVIGATED),
    filter(_ => !!state$.value.analytics?.isDataLayerReady),
    skipIfPreview(state$),
    filter(action => {
      if (action.type === NAVIGATED)
        return !action.payload.routeData.options?.skipTracking;

      return !state$.value.routing.navigatingTo;
    }),
    delayWhen(action => {
      /* After navigation completes there should be waiting for app state update and then wait for page title to be set. If title was set before app switched to limited access/offline
      modes it will be skipped and appropriate mode page title will be passed - this is done via filtering using passed in pageTitleIsSet$ limitedAccessMode and offline properties.
      When navigating from the page to the same page ANALYTICS_PAGE_TITLE_IS_SET action is received in epic before NAVIGATED action (please see Action order discrepancy issue -
      https://github.com/redux-observable/redux-observable/issues/252) no matter what RxJS Scheduler function is been used (queueScheduler/asapScheduler/asyncScheduler).
      For this reason shareReplay operator is been used in pageTitleIsSet$ and page 'loadedTime' filter is present.
      For this to work properly in case navigation from page to the same page occurs right after app init additional subscription to pageTitleIsSet$ is been made
      as BehaviorSubject used under the hood in shareReplay operator starts remembering values only after first subscription. This additional subscription will be
      unsubscribed on first NAVIGATED action. */
      if (action.type === NAVIGATED)
        return concat(
          action$.pipe(
            ofType(APP_STATE_UPDATED),
            take(1),
            ignoreElements(),
          ),
          pageTitleIsSet$.pipe(
            filter(({ loadedTime, limitedAccessMode, offline }) => {
              const appState = state$.value.app as LoadedAppState;
              const limitedAccessModeEnabled = appState.limitedAccessMode && !limitedAccessMode;
              const offlineModeEnabled = isAppOffline(appState) && !offline;

              return !limitedAccessModeEnabled && !offlineModeEnabled && loadedTime === state$.value.page.loadedTime;
            }),
          ),
        );

      return of();
    }),
    tap(_ => {
      const inputData = {
        origin: window.location.origin,
        pageTitle: window.document.title,
      };
      pushToDataLayer(state$.value, inputData, createPageViewPayload);
    }),
    ignoreElements(),
  );

  return merge(
    pageTitleIsSet$.pipe(
      takeUntil(action$.pipe(ofType(NAVIGATED))),
      ignoreElements(),
    ),
    trackPageView$,
  );
};

export default epic;

function isAppOffline(appState: LoadedAppState) {
  return appState.offlineMode && appState.offlineModeSupport === OfflineModeSupport.Disabled;
}
