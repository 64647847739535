import type { Handler } from 'behavior/pages/types';
import type { SalesAgreementStatus } from 'behavior/salesAgreements';
import { of } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages';
import {
  ContentSystemPage,
  ContentSystemPageData,
  initContentSystemPageContent,
  loadContentSystemPageQuery,
} from '../system';
import { requestAgreement, requestAgreements } from './actions';
import { PAGE_SIZE } from './constants';
import { agreements } from './stubData';

const handler: Handler<SalesAgreementsRouteData, SalesAgreementsPage> = ({ params, options }, state$, { api }) => {
  const search = params.search ?? null;
  const activeOnly = params.activeOnly ?? true;
  const index = params.index ?? 0;

  if (options?.onlyItems) {
    return of({
      action$: of(search ? requestAgreement(search) : requestAgreements(activeOnly, index, PAGE_SIZE)),
      page: {
        ...state$.value.page as SalesAgreementsPage,
        search,
        activeOnly,
      },
    });
  }

  return api.graphApi<SalesAgreementsPageResponse>(loadContentSystemPageQuery('salesAgreements')).pipe(
    pluck('pages', 'salesAgreements'),
    map(page => {
      const pageData = {
        ...page,
        component: PageComponentNames.SalesAgreements,
        search,
        activeOnly,
      };

      if (params.previewToken)
        return { page: { ...pageData, agreements } };

      return {
        page: pageData,
        action$: of(search ? requestAgreement(search) : requestAgreements(activeOnly, 0, PAGE_SIZE + PAGE_SIZE * index)),
      };
    }),
    initContentSystemPageContent(),
  );
};

export default handler;

type SalesAgreementsRouteData = {
  routeName: RouteName.SalesAgreements;
  params: {
    search?: string | null;
    activeOnly?: boolean;
    previewToken?: string;
    index?: number;
  };
  options?: {
    onlyItems?: boolean;
  };
};

type SalesAgreementsPage = ContentSystemPage & {
  component: PageComponentNames;
  search: string | null;
  activeOnly: boolean;
  agreements?: {
    items: {
      id: string;
      status: SalesAgreementStatus;
      url: string;
      effectiveDate: string | null;
      expirationDate: string | null;
    }[];
    loadedLength: number;
  };
};

type SalesAgreementsPageResponse = {
  pages: {
    salesAgreements: ContentSystemPageData;
  };
};
