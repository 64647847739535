import type { DomElement, Transform } from './types';
import parse, { HTMLReactParserOptions, DOMNode, domToReact } from 'html-react-parser';
import imageTransformer from './transformImage';
import linkTransformer from './transformLink';

const EMPTY = <></>;

const renderHTML = (html: string | null | undefined, ...transformers: Transform[]) => {
  if (!html)
    return '';

  transformers = [...transformers, linkTransformer, imageTransformer];
  let textsOnly = true;
  const options: HTMLReactParserOptions = {
    replace: (domNode): ReturnType<Transform> => {
      if (textsOnly && domNode.type !== 'text')
        textsOnly = false;

      for (const transformer of transformers) {
        const transformResult = transformer(domNode as DomElement, renderChildren);

        if (transformResult) {
          textsOnly &&= typeof transformResult !== 'object';
          return transformResult;
        }

        if (transformResult === null)
          return EMPTY;
      }
    },
  };

  const renderChildren = (nodes: DomElement[]) => domToReact(nodes as DOMNode[], options);

  const result = parse(html, options);
  if (textsOnly && Array.isArray(result) && result.length > 1)
    return result.join('');
    
  return result;
};

export default renderHTML;
