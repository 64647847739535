import { useMemo, useEffect } from 'react';
import { generateKey } from '../helpers';
import { useIsIOSChrome } from '../detections';

type IFrameData = {
  id: string;
  intervalId?: number;
};

type DownloadFn = (src: string, onDownloaded: () => void, onFailed: () => void) => void;
type DownloadApi = [DownloadFn, () => void];

export default function useDownload() {
  const isIOSChrome = useIsIOSChrome();
  const [download, cleanup] = useMemo(() => {
    // iOS Chrome completely ignores Content Disposition header sent by server (also with "attachment" value which used for telling browser
    // to download file instead of previewing it). For bug details please see https://bugs.chromium.org/p/chromium/issues/detail?id=574033
    // For this browser alternate solution using window.open() method is implemented to allow saving of downloaded file.
    // Apple's iOS WebKit rendering engine also blocks and runs in sandbox mode non-html received content as cross-origin
    // even for files downloaded from the same origin resulting in error: "SecurityError: Sandbox access violation: Blocked a frame
    // from accessing cross-origin frame" both for iframes and newly opened tabs.
    return isIOSChrome ? createAlternateDownload() : createDownload();
  }, [isIOSChrome]);

  useEffect(() => cleanup, [isIOSChrome]);

  return download;
}

function createDownload(): DownloadApi {
  const downloadingIframes: Map<string, IFrameData> = new Map();

  const download: DownloadFn = (src, onDownloaded, onFailed) => {
    let iframeData = downloadingIframes.get(src) as IFrameData;
    const key = generateKey();
    if (iframeData) {
      clearInterval(iframeData.intervalId);

      const oldIframe = document.getElementById(iframeData.id);
      oldIframe && document.body.removeChild(oldIframe);
      iframeData.id = key;
    } else {
      iframeData = { id: key };
      downloadingIframes.set(src, iframeData);
    }

    const iframe = document.createElement('iframe');
    iframe.id = iframeData.id;
    iframe.style.cssText = 'position: fixed; bottom: 0; left: 0; right: 0; visibility: hidden; pointer-events: none;';
    iframe.src = getTargetSrc(src, iframeData.id);
    document.body.appendChild(iframe);

    const cookieName = 'download' + iframeData.id;

    iframeData.intervalId = window.setInterval(() => {
      if (document.cookie.toLowerCase().indexOf(cookieName) > -1) {
        window.clearInterval(iframeData.intervalId);

        const isFailed = withHtmlOrFailed(iframe);

        if (isFailed)
          onFailed();
        else
          onDownloaded();
      }

      if (withHtmlOrFailed(iframe)) {
        window.clearInterval(iframeData.intervalId);
        onFailed();
      }
    }, 150);
  };

  const cleanup = () => {
    for (const iframeData of downloadingIframes.values()) {
      clearInterval(iframeData.intervalId);

      const oldIframe = document.getElementById(iframeData.id);
      oldIframe && document.body.removeChild(oldIframe);
    }
  };

  return [download, cleanup];
}

function createAlternateDownload(): DownloadApi {
  let timeoutId: number | undefined;
  let downloadWindow: Window | null = null;

  const alternateDownload: DownloadFn = (src, onDownloaded, onFailed) => {
    if (downloadWindow) {
      clearTimeout(timeoutId);
      if (!downloadWindow.closed)
        downloadWindow.close();
    }

    downloadWindow = window.open(getTargetSrc(src, generateKey()), '_blank');

    const handleUnload = () => {
      downloadWindow!.removeEventListener('unload', handleUnload);
      if (downloadWindow!.closed)
        return;

      timeoutId = window.setTimeout(onDownloaded, 250);
    };

    const handleLoad = () => {
      clearTimeout(timeoutId);
      downloadWindow!.removeEventListener('load', handleLoad);
      downloadWindow!.close();
      onFailed();
    };

    // Unload event fires right before browser will display received from server data (downloaded resource or text message/HTML in case
    // when resource can not be loaded) or when tab is closed by user before result has been received/fully downloaded.
    downloadWindow!.addEventListener('unload', handleUnload);
    // Load event will fire right after unload event only in case resource download failed - in this case opened window
    // will initialize document with text or html content received from server.
    downloadWindow!.addEventListener('load', handleLoad);
  };

  const cleanup = () => clearTimeout(timeoutId);

  return [alternateDownload, cleanup];
}

function getTargetSrc(src: string, key: string) {
  return src + (src.indexOf('?') === -1 ? '?' : '&') + 'download=' + key;
}

interface DOMExceptionWithIESpecificFields extends DOMException {
  number: number;
}

function withHtmlOrFailed(iframe: HTMLIFrameElement) {
  try {
    const iframeDoc = iframe.contentWindow?.document || iframe.contentDocument;
    return !!(iframeDoc?.body?.innerHTML);
  }
  catch (e) {
    // Typescript does not support annotations on the catch variable, applied solution from https://github.com/Microsoft/TypeScript/issues/20024.
    const err = e as DOMExceptionWithIESpecificFields;
    // IE & Chromium throws a permission denied error in case of 404/503 statuses.
    if (err && (err.number === -2146828218 || err.code === 18)) {
      return true;
    } else {
      throw err;
    }
  }
}
