import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link, { SystemLinkProps } from './base/Link';

const LoginLink = (attributes: Omit<SystemLinkProps, 'to'>) => (
  <Link to={routesBuilder.forLogin} {...attributes}>
    <SimpleText textKey="ButtonText_Login" />
  </Link>
);

export default LoginLink;
