import { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import HashRoutingContext from './HashRoutingContext';
import { useLayoutShifter, useResponsiveBreakpoints } from 'utils/layout';
import { useOnLocationChanged } from 'utils/hooks';
import scrollIntoView from 'scroll-into-view';

let scrollTimeout;

const scrollDefaultOptions = {
  time: 0,
  ease: t => ((--t) * t * t + 1),
};

const HashRouter = ({ children }) => {
  const lastPageScrollingDataRef = useRef(null);
  const { topFixedElementsHeight } = useLayoutShifter();
  const { xs, sm, md, lg, xl } = useResponsiveBreakpoints();

  const [context, updateContext] = useState(() => ({
    navigateTo: (hash, options = {}, callback) => {
      updateContext(prev => ({ ...prev, hash, options, callback }));
      setNavigating(!!hash);
    },
  }));
  const [navigating, setNavigating] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const scrollToElement = useCallback((element, options, callback) => {
    setNavigating(false);
    if (lastPageScrollingDataRef.current && lastPageScrollingDataRef.current.pageYOffset !== window.pageYOffset) {
      lastPageScrollingDataRef.current = null;
      return;
    }

    setScrolling(true);
    let { extraTopOffset, ...scrollOptions } = options;
    if (!Number.isInteger(extraTopOffset))
      extraTopOffset = 0;
    scrollIntoView(
      element,
      { ...scrollDefaultOptions, align: { top: 0, topOffset: topFixedElementsHeight + extraTopOffset }, ...scrollOptions },
      () => {
        lastPageScrollingDataRef.current = { topFixedElementsHeight, pageYOffset: window.pageYOffset };
        setScrolling(false);
        callback && callback();
      });
  }, [topFixedElementsHeight]);

  useOnLocationChanged(() => lastPageScrollingDataRef.current = null);

  useEffect(() => void (lastPageScrollingDataRef.current = null), [context, xs, sm, md, lg, xl]);

  useEffect(() => {
    if (scrolling)
      return;

    if (lastPageScrollingDataRef.current == null)
      return;

    if (lastPageScrollingDataRef.current.topFixedElementsHeight === topFixedElementsHeight)
      return;

    if (lastPageScrollingDataRef.current.pageYOffset !== window.pageYOffset) {
      lastPageScrollingDataRef.current = null;
      return;
    }

    setNavigating(true);
  }, [scrolling, topFixedElementsHeight]);

  useEffect(() => {
    if (!navigating)
      return;

    const { hash, options, callback } = context;
    if (!hash)
      return;

    const { timeout } = options;
    let hashElement = document.getElementById(hash.substring(1));
    if (hashElement)
      scrollTimeout = setTimeout(() => void (scrollToElement(hashElement, options, callback)), timeout === undefined ? 100 : timeout);

    const rootObserver = new MutationObserver(_mutation => {
      hashElement = document.getElementById(hash.substring(1));
      if (!hashElement)
        return;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => void (scrollToElement(hashElement, options, callback)), timeout === undefined ? 350 : timeout);
    });

    const root = document.getElementById('root');
    const mutationOptions = { childList: true, subtree: true };
    rootObserver.observe(root, mutationOptions);

    return () => {
      rootObserver.disconnect();
      clearTimeout(scrollTimeout);
    };
  }, [context, navigating, scrollToElement]);

  return (
    <HashRoutingContext.Provider value={context}>
      {children}
    </HashRoutingContext.Provider>
  );
};

HashRouter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HashRouter;