import type { ProductLine, Totals } from './payload.types';
import type { AppState } from 'behavior';
import type { Epic } from 'behavior/types';
import type { LoadedSettings } from 'behavior/settings';
import { merge } from 'rxjs';
import {
  ignoreElements,
  pluck,
  switchMap,
  filter,
  delayWhen,
  first,
  share,
  tap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  AnalyticsAction,
  ANALYTICS_PRODUCT_CLICKED,
  ANALYTICS_PRODUCT_DETAILS_VIEWED,
  ANALYTICS_PRODUCT_LIST_VIEWED,
  ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
  ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
  ANALYTICS_CHECKOUT,
  ANALYTICS_CHECKOUT_OPTION,
  ANALYTICS_PURCHASE,
} from './actions';
import { pushToDataLayer } from './dataLayer';
import {
  createProductClickPayload,
  createProductDetailsViewPayload,
  createProductListViewPayload,
  createProductsAddToBasketPayload,
  createProductsRemoveFromBasketPayload,
  createCheckoutPayload,
  createCheckoutOptionPayload,
  createPurchasePayload,
} from './payload';
import { documentDetailsQuery } from './queries';
import { skipIfPreview } from 'behavior/preview';

type Document = {
  lines: {
    itemLines: Array<ProductLine>;
  };
  totals: Totals | null;
  exists: boolean;
  id: string;
};

type DocumentsResponse = {
  paymentTransaction: {
    document: Document;
  };
};

const analyticsEpic: Epic<AnalyticsAction> = (action$, state$, { api }) => {
  const dataLayerReady$ = state$.pipe(
    first(({ analytics }) => analytics!.isDataLayerReady!),
  );

  const delayedAction$ = action$.pipe(
    skipIfPreview(state$),
    filter(_ => !!state$.value.analytics?.isTrackingEnabled),
    ofType(
      ANALYTICS_PRODUCT_CLICKED,
      ANALYTICS_PRODUCT_DETAILS_VIEWED,
      ANALYTICS_PRODUCT_LIST_VIEWED,
      ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
      ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
      ANALYTICS_CHECKOUT,
      ANALYTICS_CHECKOUT_OPTION,
      ANALYTICS_PURCHASE,
    ),
    delayWhen(_ => dataLayerReady$),
    share(),
  );

  return merge(
    delayedAction$.pipe(
      tap(action => {
        switch (action.type) {
          case ANALYTICS_PRODUCT_CLICKED:
            pushToDataLayer(state$.value, action.payload, createProductClickPayload);
            break;
          case ANALYTICS_PRODUCT_DETAILS_VIEWED:
            pushToDataLayer(state$.value, action.payload, createProductDetailsViewPayload);
            break;
          case ANALYTICS_PRODUCT_LIST_VIEWED:
            pushToDataLayer(state$.value, action.payload, createProductListViewPayload);
            break;
          case ANALYTICS_PRODUCTS_ADDED_TO_BASKET:
            pushToDataLayer(state$.value, action.payload, createProductsAddToBasketPayload);
            break;
          case ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET:
            pushToDataLayer(state$.value, action.payload, createProductsRemoveFromBasketPayload);
            break;
          case ANALYTICS_CHECKOUT:
            pushToDataLayer(state$.value, action.payload, createCheckoutPayload);
            break;
          case ANALYTICS_CHECKOUT_OPTION:
            pushToDataLayer(state$.value, action.payload, createCheckoutOptionPayload);
            break;

          default:
            break;
        }
      }),
      ignoreElements(),
    ),
    delayedAction$.pipe(
      ofType(ANALYTICS_PURCHASE),
      pluck('payload', 'transaction'),
      switchMap(({ id }) => api.graphApi<DocumentsResponse>(documentDetailsQuery, { id }).pipe(
        tap(({ paymentTransaction }) => {
          const document = paymentTransaction?.document;
          if (document?.exists) {
            const data = toPurchaseInput(document, state$.value, id);
            pushToDataLayer(state$.value, data, createPurchasePayload);
          }
        }),
        ignoreElements(),
      )),
    ),
  );
};

const toPurchaseInput = (
  {
    lines: { itemLines },
    totals,
    id,
  }: Document,
  {
    user,
    settings,
  }: AppState,
  transactionId: string,
) => ({
  itemLines,
  totals,
  pricesInclTax: user.pricesInclTax!,
  shopName: (settings as LoadedSettings).shopName || '',
  documentId: id,
  transactionId,
});

export default analyticsEpic;
