import styles from '../OrderBox.module.scss';
import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import ViewProductButton from '../ViewProductButton';
import { SanaButton } from 'components/primitives/buttons';
import { generateKey } from 'utils/helpers';
import { addProducts } from 'behavior/basket';
import { connect } from 'react-redux';
import { abilitiesPropType } from 'behavior/user';
import { createQuantityModel } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsVisualDesigner } from 'components/visualDesigner';
import btnStyles from 'components/primitives/buttons/Button.module.scss';

const OrderBox = ({
  product,
  basketModifiedDate,
  basketUpdatedById,
  addProducts,
  className = '',
  withoutBackTo,
  preselectedAgreementLineId,
}) => {
  const {
    id,
    isOrderable,
    isProductGroup,
    hasVariants,
    productConfiguratorInfo,
  } = product;
  
  const uomId = product.uom && product.uom.id;
  const uom = product.uoms
    ? product.uoms.find(u => u.id === uomId)
    : product.uom;
  const quantity = createQuantityModel(uom).initial;
  const [componentId] = useState(generateKey);
  const [disabled, setDisabled] = useState(false);
  const isDesignerMode = useIsVisualDesigner();

  const addToBasket = useCallback(() => {
    if (disabled)
      return;

    setDisabled(true);
    addProducts([{
      productId: id,
      uomId,
      quantity,
      salesAgreementLineId: preselectedAgreementLineId,
    }], componentId);
  }, []);

  useEffect(() => {
    if (basketUpdatedById === componentId)
      setDisabled(false);
  }, [basketModifiedDate, basketUpdatedById]);

  let content = null;
  const placeholder = <Placeholder className="placeholder" />;

 if(isProductGroup == null)
  content = placeholder;
else if(isProductGroup === true){
  content = null;
} else if (isOrderable == null) {
    content = placeholder;
  } 
  else if (isOrderable === false) {
    content = (
      <span className="msg-not-available">
        <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
      </span>
    );
  } else if (productConfiguratorInfo.isProductConfigurable) {
    content = (
      <ViewProductButton
        textKey="ConfigureProduct"
        className="btn-configure"
        titleTextKey="ConfigureProduct_ListPage"
        product={product}
        placeholder={placeholder}
        withoutBackTo={withoutBackTo}
        disabled={isDesignerMode}
      />
    );
  } else if (hasVariants) {
    content = (
      <ViewProductButton
        textKey="SelectVariants"
        className={'btn-action'}
        product={product}
        placeholder={placeholder}
        withoutBackTo={withoutBackTo}
        disabled={isDesignerMode}
      />
    );
  } else {
    content = (
      <SanaButton className="btn-action"
        size="lg"
        theme="action"
        textKey="AddToBasket"
        onClick={isDesignerMode ? null : addToBasket}
        placeholder={placeholder}
      />
    );
  }

  return (
    <div className={`${className} ${styles.orderBox}`}>
      {content}
    </div>
  );
};

OrderBox.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool,
    hasVariants: PropTypes.bool,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
    uoms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    productConfiguratorInfo: PropTypes.object,
  }),
  abilities: abilitiesPropType,
  basketModifiedDate: PropTypes.number,
  basketUpdatedById: PropTypes.string,
  addProducts: PropTypes.func.isRequired,
  className: PropTypes.string,
  withoutBackTo: PropTypes.bool,
  preselectedAgreementLineId: PropTypes.string,
};

export default connect(({ basket, page: { salesAgreement } }) => ({
  basketModifiedDate: basket.modifiedDate,
  basketUpdatedById: basket.updated.updaterId,
  preselectedAgreementLineId: salesAgreement?.preSelectedLine?.id,
}),
  { addProducts },
)(OrderBox);
