import type { Epic } from 'behavior/types';
import type { CustomerCollectionData } from './types';
import { CUSTOMERS_REQUESTED, customersLoaded, RepresentationAction } from './actions';
import { Subject } from 'rxjs';
import { filter, map, throttle, finalize, mergeMap } from 'rxjs/operators';
import { getCustomersToRepresent } from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { ofType } from 'redux-observable';
import { skipIfPreview } from 'behavior/preview';

type CustomersToRepresentResponse = {
  profile: {
    impersonation: {
      customers: CustomerCollectionData;
    };
  };
};

const representationEpic: Epic<RepresentationAction> = (action$, state$, { api, logger }) => {
  const subj = new Subject();

  return action$.pipe(
    ofType(CUSTOMERS_REQUESTED),
    skipIfPreview(state$),
    throttle(() => subj, { leading: true, trailing: true }),
    mergeMap(action => api.graphApi<CustomersToRepresentResponse>(getCustomersToRepresent, action.payload).pipe(
      filter(r => r.profile.impersonation != null),
      map(({ profile }) => customersLoaded(profile.impersonation.customers, action.payload.keywords)),
      finalize(() => subj.next(1)),
      retryWithToast(action$, logger),
    )),
  );
};

export default representationEpic;
