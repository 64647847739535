import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { LoginInfoIcon, LoginInfoImpersonatingIcon } from 'components/primitives/icons';

const Icon = ({ isImpersonating, className = '' }) => {
  if (isImpersonating)
    return <LoginInfoImpersonatingIcon className={`${className} ${styles.userIcon}`} />;

  return <LoginInfoIcon className={`${className} ${styles.userIcon}`} />;
};

Icon.propTypes = {
  isImpersonating: PropTypes.bool,
  className: PropTypes.string,
};

export default Icon;
