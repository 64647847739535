import type { SaveOrderTemplateResult } from './types';

export const BASKET_ORDER_TEMPLATE_SAVE = 'BASKET_ORDER_TEMPLATE_SAVE' as const;
export const saveOrderTemplate = (input: { name: string }) => ({
  type: BASKET_ORDER_TEMPLATE_SAVE,
  payload: { input },
});

export const BASKET_ORDER_TEMPLATE_SAVED = 'BASKET_ORDER_TEMPLATE_SAVED' as const;
export const orderTemplateSavingResultReceived = (savingResult: SaveOrderTemplateResult) => ({
  type: BASKET_ORDER_TEMPLATE_SAVED,
  payload: { savingResult },
});

export type BasketPageAction = ReturnType<
  | typeof saveOrderTemplate
  | typeof orderTemplateSavingResultReceived
>;

