import type { Product, ReturnOrderInput } from './types';

export const DOC_FREE_RETURN_ORDER_SUBMITTED = 'DOC_FREE_RETURN_ORDER_SUBMITTED' as const;
export const createReturnOrder = (input: ReturnOrderInput, files: FileList, updatedById: string) => ({
  type: DOC_FREE_RETURN_ORDER_SUBMITTED,
  payload: { input, files, updatedById },
});

export const DOC_FREE_RETURN_ORDER_FAILED = 'DOC_FREE_RETURN_ORDER_FAILED' as const;
export const setReturnOrderFailed = (updatedById: string) => ({
  type: DOC_FREE_RETURN_ORDER_FAILED,
  payload: { updatedById },
});

export const DOC_FREE_RETURN_ORDER_PRODUCTS_REQUESTED = 'DOC_FREE_RETURN_ORDER_PRODUCTS_REQUESTED' as const;
export const requestProducts = (ids: string[]) => ({
  type: DOC_FREE_RETURN_ORDER_PRODUCTS_REQUESTED,
  payload: { ids },
});

export const DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED = 'DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED' as const;
export const receiveProducts = (products: Product[]) => ({
  type: DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED,
  payload: { products },
});

export type CreateDocFreeReturnOrderAction = ReturnType<
  | typeof createReturnOrder
  | typeof setReturnOrderFailed
  | typeof requestProducts
  | typeof receiveProducts
>;
