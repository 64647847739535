import styles from './Alert.module.scss';
import { ComponentType, HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import scrollIntoView from 'scroll-into-view';

export type Props = {
  icon?: ComponentType<{ className?: string }>;
  scrollOnAppear?: boolean;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Alert = ({
  icon: Icon,
  children,
  scrollOnAppear,
  className = styles.info,
  ...props
}: Props) => {
  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollOnAppear)
      return;

    scrollIntoView(alertRef.current!);
  }, []);

  return (
    <div className={`${styles.block} ${className}`} ref={alertRef} {...props}>
      {Icon &&
        <div className={styles.iconWrapper} role="presentation">
          <Icon className={styles.icon} />
        </div>
      }
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default Alert;
