import type { RouteData } from 'routes';
import type { Middleware } from 'redux';
import type { HistoryLocation } from './types';
import { NavigationRequestedAction, NAVIGATION_REQUESTED } from './actions';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { areLocationsEqual } from 'behavior/routing/helpers';
import { popstate$, useEventObservable } from 'utils/rxjs';
import { isBrowser } from 'utils/detections';

type PostponeNavigationDelegate = (route: RouteData | undefined) => boolean;
type ContinuePendingAction = ReturnType<typeof continuePending>;

const subscribers: PostponeNavigationDelegate[] = [];
let pendingAction: NavigationRequestedAction | null = null;
let historyDelta: -1 | 1 | null = null;

const NAVIGATING_CONTINUE_PENDING = 'NAVIGATING_CONTINUE_PENDING' as const;
const continuePending = (action: NavigationRequestedAction) => ({
  type: NAVIGATING_CONTINUE_PENDING,
  payload: action,
});

export const omitNavigationMiddleware: Middleware = _store => next => (action: NavigationRequestedAction | ContinuePendingAction) => {
  switch (action.type) {
    case NAVIGATION_REQUESTED:
      if (!subscribers.length)
        return next(action);

      const routeData = action.payload.routeData;
      for (const shouldPostponeNavigation of subscribers) {
        if (shouldPostponeNavigation(routeData)) {
          pendingAction = action;
          return;
        }
      }

      return next(action);
    case NAVIGATING_CONTINUE_PENDING:
      pendingAction = null;
      return next(action.payload);
    default:
      return next(action);
  }
};

export const useOmitNavigation = (shouldPostponeNavigation: PostponeNavigationDelegate) => {
  const currentHistoryEntryTimeStamp = useRef<number>();
  if (isBrowser && !currentHistoryEntryTimeStamp.current)
    currentHistoryEntryTimeStamp.current = (window.history.state as HistoryLocation)?.state.timeStamp;

  useEffect(() => {
    subscribers.push(shouldPostponeNavigation);

    return () => {
      const index = subscribers.indexOf(shouldPostponeNavigation);
      subscribers.splice(index, 1);
    };
  }, [shouldPostponeNavigation]);

  useEventObservable(popstate$, e => {
    if (!pendingAction || !areLocationsEqual(window.location, pendingAction.payload.location))
      return;

    // Clear history delta value after continue pending browser history navigation.
    if (historyDelta) {
      historyDelta = null;
      return;
    }

    /* In case navigation was triggered via history by browser native backward/forward functionalities and navigation action was pended,
    history entry should be reverted to the one before, so page will have proper URL. Reverted history delta value should be saved for using
    in resume method for continue pending history navigation. */
    pendingAction = null;
    historyDelta = (e.state as HistoryLocation).state.timeStamp < currentHistoryEntryTimeStamp.current! ? 1 : -1;
    window.history.go(historyDelta);
  });

  const dispatch = useDispatch();
  const resume = () => {
    if (pendingAction) {
      dispatch(continuePending(pendingAction));
      pendingAction = null;
    } else if (historyDelta) {
      // Continue pending browser history navigation.
      window.history.go(-historyDelta);
    }
  };
  const discard = () => {
    pendingAction = null;
    historyDelta = null;
    dispatch(unsetLoadingIndicator());
  };

  return { resume, discard };
};
