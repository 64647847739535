import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link, { SystemLinkProps } from './base/Link';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { withAbilities } from 'components/objects/user';

type Props = {
  abilities: [AbilityState];
} & Omit<SystemLinkProps, 'to'>;

const MyAccountLink = ({ abilities: [viewMyAccountAbility], ...attributes }: Props) => {
  if (viewMyAccountAbility !== AbilityState.Available)
    return null;

  return (
    <Link to={routesBuilder.forMyAccount} {...attributes}>
      <SimpleText textKey="Login_MyAccount" />
    </Link>
  );
};

export default withAbilities(MyAccountLink, [AbilityTo.ViewMyAccountPage]);
