import styles from '../Newsletter.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { subscribeToNewsletter } from 'behavior/pages/newsletter';
import { useSuccessMessage } from '../hooks';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { FormName } from 'behavior/pages';
import PropTypes from 'prop-types';
import NewsletterForm from '../NewsletterForm';
import { SimpleText, RichText } from 'components/sanaText';
import { useCaptchaOnSubmit } from 'components/objects/forms';

const NewsletterSubscription = ({ id, model, isDesignerMode }) => {
  const dispatch = useDispatch();
  const { lastUpdated, updatedById } = useSelector(state => state.newsletter);
  const formName = `${FormName.NewsletterSubscribe}_${id}`;
  const subscribedByComponent = formName === updatedById;
  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    dispatch(subscribeToNewsletter(formData, formName));
  }, FormName.NewsletterSubscribe, [formName]);

  useSuccessMessage(lastUpdated, subscribedByComponent, 'NewsletterSubscribedSuccessfully');

  return (
    <div className={styles.container}>
      {
        model.showTitle &&
        <h2 className={`${styles.title} h4`}>
          <SimpleText textKey="NewsletterContainer_Title" />
        </h2>
      }
      {
        model.showDescription &&
        <div className={styles.description}>
          <RichText textKey="NewsletterContainer_Description" />
        </div>
      }
      <NewsletterForm
        formName={formName}
        onSubmit={onSubmit}
        buttonTextKey={'Subscribe'}
        submitButtonDisplayMode={model.subscribeBtnDisplayMode}
        shouldReset={subscribedByComponent}
        isDesignerMode={isDesignerMode}
        ref={captchaRef}
      />
    </div>
  );
};

NewsletterSubscription.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showTitle: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
    subscribeBtnDisplayMode: PropTypes.number.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

// eslint-disable-next-line react/no-multi-comp
const NewsletterSubscriptionBlock = ({ isDesignerMode, ...props }) => {
  const [canSubscribe] = useHasAbilities(AbilityTo.SubscribeToNewsletter);

  if (!isDesignerMode && !canSubscribe)
    return null;

  return <NewsletterSubscription isDesignerMode={isDesignerMode} {...props} />;
};

NewsletterSubscriptionBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showTitle: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
    subscribeBtnDisplayMode: PropTypes.number.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default NewsletterSubscriptionBlock;
