import type { ContentSystemPage } from '../system';

export const LIMITED_ACCESS_REQUESTED = 'LIMITED_ACCESS/REQUESTED' as const;
export const requestLimitedAccess = () => ({
  type: LIMITED_ACCESS_REQUESTED,
});

export const LIMITED_ACCESS_RECEIVED = 'LIMITED_ACCESS/RECEIVED' as const;
export const limitedAccessReceived = (page: ContentSystemPage) => ({
  type: LIMITED_ACCESS_RECEIVED,
  payload: { page },
});

export type LimitedAccessAction = ReturnType<
  | typeof requestLimitedAccess
  | typeof limitedAccessReceived
>;
