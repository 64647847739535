import { useSelector } from 'react-redux';

let initialNavigation = true;
export default function useIsInitialNavigation() {
  return useSelector(s => {
    if (initialNavigation && (!!s.routing.previous || s.page.loadedTime && !!s.routing.navigatingTo))
      initialNavigation = false;

    return initialNavigation;
  });
}
