import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

const requireOrderProductsAbility = Component => {
  return props => {
    const canOrderProducts = useHasAbilities([AbilityTo.OrderProducts])[0];

    if (!canOrderProducts)
      return null;

    return <Component {...props} />;
  };
};

export default requireOrderProductsAbility;
