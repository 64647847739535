import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { HashRoutingContext } from 'components/hash';
import { connectToContext } from 'utils/react';
import { PageRenderer } from 'components/pages';
import { orientationChange$, useEventObservable } from 'utils/rxjs';
import { delay } from 'rxjs/operators';

const Page = ({ page, location, isVisualDesigner, navigateTo }) => {
  useEventObservable(() => orientationChange$.pipe(delay(100)), setDocumentScrollPosition);

  useEffect(() => {
    if (isVisualDesigner || !location?.hash)
      return;

    navigateTo(location.hash);
  }, [location, isVisualDesigner]);

  return <PageRenderer {...page} />;
};

Page.propTypes = {
  page: PropTypes.object,
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
  isVisualDesigner: PropTypes.bool,
  navigateTo: PropTypes.func.isRequired,
};

export default connectToContext([HashRoutingContext], ({ navigateTo }) => ({ navigateTo }))(
  connect(
    ({ page, visualDesigner, routing }) => ({
      page,
      isVisualDesigner: visualDesigner.initialized,
      location: routing.location,
    }),
  )(Page),
);

function setDocumentScrollPosition() {
  // fixes incorrect scroll position on iOS Safari
  const { documentElement } = document;
  const maxScrollTop = documentElement.scrollHeight - window.innerHeight;

  if (documentElement.scrollTop <= maxScrollTop)
    return;

  documentElement.scrollTop = maxScrollTop;
}
