exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#header .dropdown{color:var(--theme-header_Color,#333)}#header .dropdown.dropdown-opened{background-color:var(--theme-header_Dropdown_BackgroundColor,#fff);border-color:var(--theme-header_BorderColor,#e6e6e6) var(--theme-header_BorderColor,#e6e6e6) transparent}#header .dropdown.dropdown-opened.dropdown-offset-bottom{border-color:transparent var(--theme-header_BorderColor,#e6e6e6) var(--theme-header_BorderColor,#e6e6e6)}#header .dropdown .dropdown-body{border-color:var(--theme-header_BorderColor,#e6e6e6)}.Header_logo img{max-height:32px}.Header_static{-webkit-transform:translateZ(0);transform:translateZ(0)}.Header_sticky{background-color:var(--theme-header_BackgroundColor,#fff);margin-top:var(--sticky-header-margin-top);position:fixed;top:0;-webkit-transform:translateY(-100%);transform:translateY(-100%);transition:-webkit-transform .2s ease-out .2s;transition:transform .2s ease-out .2s;transition:transform .2s ease-out .2s,-webkit-transform .2s ease-out .2s;width:100%}.Header_sticky.Header_show{-webkit-transform:translateY(0);transform:translateY(0)}.Header_sticky .logo-image{max-height:32px}.Header_bottom{background-color:var(--theme-header_BackgroundColor,#fff);bottom:0;position:fixed;width:100%}@media print,screen{html.print .Header_logo{max-width:40%}html.print .Header_logo img{max-height:none}}.Header_desktop,.Header_mobile{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"logo": "Header_logo",
	"static": "Header_static",
	"sticky": "Header_sticky",
	"show": "Header_show",
	"bottom": "Header_bottom",
	"desktop": "Header_desktop",
	"mobile": "Header_mobile"
};