import loadable from '@loadable/component';

export default {
  ProfileSalesAgreementAddress: loadable(() => import(/*webpackChunkName: "sales-agr"*/ './address')),
  ProfileSalesAgreementApplyOrCancelButton: loadable(() => import(/*webpackChunkName: "sales-agr"*/ './applyOrCancelButton')),
  ProfileSalesAgreementHeader: loadable(() => import(/*webpackChunkName: "sales-agr"*/ './header')),
  ProfileSalesAgreementLines: loadable(() => import(/*webpackChunkName: "sales-agr"*/ './lines')),
  ProfileSalesAgreementMessages: loadable(() => import(/*webpackChunkName: "sales-agr"*/ './messages')),
  ProfileSalesAgreementPageTitle: loadable(() => import(/*webpackChunkName: "sales-agr"*/ './pageTitle')),
};
