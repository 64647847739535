import type { ModifiedLine, QuickOrderBasket } from './types';
import { filterExistingImages } from 'utils/helpers';

type QuickOrderAddedProduct = {
  productId: string;
  variantId: string | null;
  uomId: string | null;
  quantity: number;
};
export const QUICK_ORDER_ADD_PRODUCTS = 'QUICK_ORDER_ADD_PRODUCTS' as const;
export const quickOrderAddProducts = (added: QuickOrderAddedProduct[], modified: ModifiedLine[], size: number) => ({
  type: QUICK_ORDER_ADD_PRODUCTS,
  payload: {
    added,
    modified,
    size,
  },
});

export const QUICK_ORDER_LINES_RECEIVED = 'QUICK_ORDER_LINES_RECEIVED' as const;
export const quickOrderLinesReceived = (lines: QuickOrderProductLines) => ({
  type: QUICK_ORDER_LINES_RECEIVED,
  payload: { lines: filterImages(lines) },
});

export const QUICK_ORDER_CLEARED = 'QUICK_ORDER_CLEARED' as const;
export const clearQuickOrder = () => ({ type: QUICK_ORDER_CLEARED });

function filterImages(lines: QuickOrderProductLines) {
  for (const line of lines.list)
    if ('product' in line && line.product?.images)
      line.product.images = filterExistingImages(line.product.images);

  return lines;
}

type QuickOrderProductLines = QuickOrderBasket['productLines'];

export type QuickOrderLinesReceivedAction = ReturnType<typeof quickOrderLinesReceived>;
export type QuickOrderAction =
  | QuickOrderLinesReceivedAction
  | ReturnType<
    | typeof quickOrderAddProducts
    | typeof clearQuickOrder
  >;
