import { generateKey } from 'utils/helpers';

export const subAccount = {
  options: {
    orderProducts: true,
    showPrices: true,
    showStock: true,
  },
  subAccount: {
    id: 'someId',
    name: '',
    email: '',
    active: true,
    canAuthorizeOrders: false,
    canOrder: true,
    canSeePrices: true,
    canSeeStock: true,
    limits: [],
    dependentAccounts: [],
  },
  authorizers: { 'someId': { id: 'someId', name: 'Some account' } },
};

export const subAccounts = {
  list: Array.from(Array(5)).map(() => ({
    id: generateKey(),
    name: '',
    email: '',
    active: true,
    canAuthorizeOrders: false,
    canOrder: true,
    canSeePrices: true,
    canSeeStock: true,
    limits: [],
    dependentAccounts: [],
  })),
};
