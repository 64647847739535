import { createContext } from 'react';

type Payload = unknown;
export type Registry = Record<string, Record<string, Payload>>;
export type ReadonlyRegistry = Readonly<Record<string, Readonly<Record<string, Readonly<Payload>>>>>;

type Unregister = () => void;
export type Register = (area: string, id: string, payload?: Payload) => Unregister;

type RegistryContextType = {
  readonly registry: ReadonlyRegistry;
  readonly register: Register;
  readonly getFirst: <TPayload>(area: string) => Readonly<TPayload> | undefined;
  readonly getAll: <TPayload>(area: string) => Readonly<TPayload>[] | undefined;
};

export default createContext(null as unknown as RegistryContextType);
