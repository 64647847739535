import type { State } from '../types';
import type { BasketErrorModeAction } from '../actions.errorMode';
import { BASKET_ERROR_MODE_ENABLED } from '../actions.errorMode';

const reducer = (state = null as unknown as State, action: BasketErrorModeAction) => {
  if (action.type === BASKET_ERROR_MODE_ENABLED)
    return {
      ...state,
      isErrorMode: action.payload.enabled,
    };

  return state;
};

export default reducer;
