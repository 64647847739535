exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Newsletter_container form:only-child{margin-top:-.5rem}.Newsletter_title{margin:0 0 .5rem}.Newsletter_title+.Newsletter_description{margin-top:.5rem}.Newsletter_description{margin-bottom:.5em}.Newsletter_main-form{display:flex;flex-wrap:wrap;margin:0 -.15rem;position:relative;width:100%}.Newsletter_field{flex:100 0 auto;margin:.5rem .15rem 0}.Newsletter_no-label .form-row-label{display:none}.Newsletter_validation{color:var(--theme-validation_Error_Color,#cb2245);font-size:var(--theme-text_SmallerSize,12px);left:0;line-height:1.2em;padding:.4em 0 0 .15rem;position:absolute;top:100%}.Newsletter_validation svg{margin-right:.5em}.Newsletter_btn-submit{flex:1 1 auto;margin:.5rem .15rem 0;word-break:break-word}@supports (-ms-ime-align:auto){.Newsletter_btn-submit{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Newsletter_btn-submit{word-wrap:break-word}}.Newsletter_btn-below{margin-top:1.75rem}.Newsletter_no-label+.Newsletter_btn-below{margin-top:0}@media not all and (min-width:env(--theme-breakpoints_Small,600)px){html:not(.print) .Newsletter_btn-below,html:not(.print) .Newsletter_field{margin-left:0;margin-right:0;width:100%}}.Newsletter_recaptcha{margin-top:1.75rem}.Newsletter_toast{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"container": "Newsletter_container",
	"title": "Newsletter_title",
	"description": "Newsletter_description",
	"main-form": "Newsletter_main-form",
	"mainForm": "Newsletter_main-form",
	"field": "Newsletter_field",
	"no-label": "Newsletter_no-label",
	"noLabel": "Newsletter_no-label",
	"validation": "Newsletter_validation",
	"btn-submit": "Newsletter_btn-submit",
	"btnSubmit": "Newsletter_btn-submit",
	"btn-below": "Newsletter_btn-below",
	"btnBelow": "Newsletter_btn-below",
	"recaptcha": "Newsletter_recaptcha",
	"toast": "Newsletter_toast"
};