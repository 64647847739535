import type { Player as VimeoPlayer } from 'vimeo__player';
import type { YoutubePlayer } from './types';

const PROVIDER_REGEX = /(?:(?:https?:)?\/\/)?(?:[^.]+\.)?(\w+)\./i;
const YOUTUBE_VIDEOID_REGEX = /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i;
const VIMEO_VIDEOID_REGEX = /(vimeo(?:cdn|pro)?)\.com\/(?:(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+|staff\/frame)\/)?videos?)\/)?(\d+)(?:_(\d+)(?:x(\d+))?)?(\.\w+)?/i;

export const enum VideoProviderType {
  Youtube = 'youtube',
  Vimeo = 'vimeo',
}

export const parseVideoData = (url: string) => {
  if (!url)
    return null;

  const provider = getProvider(url);
  switch (provider) {
    case VideoProviderType.Youtube:
      const ytVideoId = getYoutubeVideoId(url);
      return ytVideoId ? { provider, videoId: ytVideoId } : null;
    case VideoProviderType.Vimeo:
      const vimeoVideoId = getVimeoVideoId(url);
      return vimeoVideoId ? { provider, videoId: vimeoVideoId } : null;
    default:
      return null;
  }
};

export function pauseVideo(player: YoutubePlayer | VimeoPlayer) {
  if ('pauseVideo' in player)
    player.pauseVideo();
  else
    player.pause();
}

function getProvider(url: string) {
  const match = url.match(PROVIDER_REGEX);
  if (!match)
    return null;

  switch (match[1].toLowerCase()) {
    case 'youtube':
    case 'youtu':
      return VideoProviderType.Youtube;
    case 'vimeo':
    case 'vimeopro':
    case 'vimeocdn':
      return VideoProviderType.Vimeo;
    default:
      return null;
  }
}

function getYoutubeVideoId(url: string) {
  const match = url.match(YOUTUBE_VIDEOID_REGEX);
  return match ? match[1] : null;
}

function getVimeoVideoId(url: string) {
  const match = url.match(VIMEO_VIDEOID_REGEX);
  return match ? match[2] : null;
}
