import styles from './Error.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSimpleTexts } from 'components/sanaText';
import { retryFailedRequests } from 'behavior/errorHandling/actions';
import { useToasts } from 'react-toast-notifications';
import { Placeholder } from 'components/primitives/placeholders';
import ErrorId from './ErrorId';

const ErrorToastWithRetry = forwardRef(({ id, timeId, labelId, descriptionId }, ref) => {
  const dispatch = useDispatch();
  const connectionStatus = useSelector(s => s.connection.onLine, _ => true);

  const { removeToast } = useToasts();

  const onClick = () => {
    dispatch(retryFailedRequests());
    removeToast(id);
  };

  const options = [
    'ButtonText_Retry',
    {
      key: 'RetryErrorMessageText',
      fallback: 'Unfortunately something went wrong during the execution of your action.',
    },
    {
      key: 'OfflineErrorMessageText',
      fallback: `Unfortunately there was an issue with retrieving the information for this page.
      Please check your internet connection.`,
    },
  ];
  options.disableInsiteEditor = true;

  const { texts: [btnText, retryErrMsg, offlineErrMsg], loaded } = useSimpleTexts(options);

  return (
    <div className={styles.body}>
      <p id={labelId}>
        {loaded
          ? connectionStatus ? retryErrMsg : offlineErrMsg
          : <Placeholder className={styles.loading} />
        }
      </p>
      {loaded
        ? (
          <button ref={ref} className={`${linkStyles.link} ${styles.actionBtn}`} onClick={onClick}>
            {btnText || 'Retry'}
          </button>
        )
        : <Placeholder className={styles.loading} />
      }
      {/* Element with id equal to descriptionId should be present in layout to avoid W3C validation error "The aria-describedby attribute
          must point to an element in the same document" on toast wrapper. */}
      {timeId ? <ErrorId id={timeId} containerId={descriptionId} /> : <i id={descriptionId} />}
    </div>
  );
});

ErrorToastWithRetry.propTypes = {
  id: PropTypes.string.isRequired,
  timeId: PropTypes.string,
  labelId: PropTypes.string,
  descriptionId: PropTypes.string,
};

export default ErrorToastWithRetry;

export const preload = {
  texts: [
    'RetryErrorMessageText',
    'OfflineErrorMessageText',
    'ButtonText_Retry',
    'ErrorId',
    'Aria_Copy',
    'Copied',
  ],
};
