export const enum Updaters {
  Basket = 'BASKET',
  Checkout = 'CHECKOUT',
  Sync = 'SYNC',
  QuickOrder = 'QUICK_ORDER',
  SalesAgreement = 'SALES_AGREEMENT',
}

export const enum CreditLimitValidationResult {
  Valid = 'VALID',
  Error = 'ERROR',
  Warning = 'WARNING',
  ForceOnlinePayment = 'FORCE_ONLINE_PAYMENT',
}

export const enum NonOrderableReason {
  NotAvailable = 'NOT_AVAILABLE',
  NotInStock = 'NOT_IN_STOCK',
  QuantityNotInStock = 'QUANTITY_NOT_IN_STOCK',
  Custom = 'CUSTOM',
  CustomerSpecificAssortments = 'CUSTOMER_SPECIFIC_ASSORTMENTS',
}
