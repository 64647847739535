import type { VisualDesignerRow } from 'behavior/content';

export const visualDesignerHeader = 'X-VisualDesigner';
export const insiteEditorHeader = 'X-InsiteEditor';

export const rowTemplate: Omit<VisualDesignerRow, 'id'> = {
  fullWidth: false,
  heroEffect: null,
  rowAnimation: 'NONE',
  verticalAlignment: 'TOP',
  columns: [],
  background: {
    color: null,
    desktopImage: null,
    fullWidth: null,
    hideImageOnMobile: null,
    imageAltText: null,
    mobileImage: null,
    video: null,
  },
  border: {
    style: 'none',
    color: null,
    radius: null,
    width: null,
  },
  spacing: {
    margin: '0 0 30px 0',
    hideSpaces: null,
    padding: '12px 0',
  },
  attributes: {
    className: null,
    id: null,
  },
  minHeight: {
    desktop: null,
    mobile: null,
    tablet: null,
  },
  displayConfiguration: {
    customerSegmentIds: [],
    segmentLabel: null,
  },
};
