import type { History } from 'history';
import type { Router, HistoryLocation } from '../types';
import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';

export default (history: History<HistoryLocation['state']>) => {
  const locationObservable = Observable.create((observer: Observer<HistoryLocation>) => {
    return history.listen(location => observer.next(location));
  }).pipe(share());

  const router: Router = {
    get locationObservable() {
      return locationObservable;
    },
    get location() {
      return history.location;
    },
    push(location) {
      history.push(location);
    },
    replace(location) {
      history.replace(location);
    },
    goBack() {
      history.goBack();
    },
    onStatusCodeResolved(_statusCode) {
    },
    onNavigating(_location, _statusCode) {
      return true;
    },
  };

  return router;
};
