type InputLine = {
  lineNo: string;
  quantity: number;
  reasonId?: string;
  comment?: string;
};

type ReturnOrderInput = {
  reasonId?: string;
  comment?: string;
  id: string;
  orderId?: string;
  lines: InputLine[];
  fileNames?: string[];
};

export const DOC_BASED_RETURN_ORDER_SUBMITTED = 'DOC_BASED_RETURN_ORDER_SUBMITTED' as const;
export const createReturnOrder = (input: ReturnOrderInput, files: FileList, updatedById: string) => ({
  type: DOC_BASED_RETURN_ORDER_SUBMITTED,
  payload: { input, files, updatedById },
});

export const DOC_BASED_RETURN_ORDER_FAILED = 'DOC_BASED_RETURN_ORDER_FAILED' as const;
export const setReturnOrderFailed = (updatedById: string) => ({
  type: DOC_BASED_RETURN_ORDER_FAILED,
  payload: { updatedById },
});

export type CreateDocBasedReturnOrderAction = ReturnType<
  | typeof createReturnOrder
  | typeof setReturnOrderFailed
>;
