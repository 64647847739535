import { ReactElement, useEffect, useState } from 'react';
import { SimpleText } from 'components/sanaText';

const STATUS_TIMEOUT_MS = 800;

type Props = {
  inFocus: boolean;
  suggestions: Record<string, unknown>[];
};

const AvailabilityStatus = ({ suggestions, inFocus }: Props): ReactElement => {
  const [shoudAnnounce, setShouldAnnounce] = useState<boolean>();

  useEffect(() => {
    setShouldAnnounce(false);

    if (!inFocus)
      return;

    const id = setTimeout(() => {
      setShouldAnnounce(true);
    }, STATUS_TIMEOUT_MS);

    return () => clearTimeout(id);
  }, [suggestions, inFocus]);

  const message = suggestions.length
    ? <SimpleText textKey="Search_AvailableSuggestion" formatWith={[suggestions.length]} />
    : <SimpleText textKey="Search_NoSuggestion" />;

  return (
    <div className="visually-hidden" aria-live="polite" aria-atomic>
      {inFocus && shoudAnnounce && message}
    </div>
  );
};

export default AvailabilityStatus;
