import type { Settings } from './types';

export const SETTINGS_LOADED = 'SETTINGS/LOADED' as const;
export const settingsLoaded = (settings?: Partial<Settings>) => ({ type: SETTINGS_LOADED, payload: settings });

export const SETTINGS_UPDATING = 'SETTINGS/UPDATING' as const;
export const setUpdating = () => ({ type: SETTINGS_UPDATING });

export type SettingsAction = ReturnType<
  | typeof settingsLoaded
  | typeof setUpdating
>;
