import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { useIsMobileViewport } from 'utils/hooks';
import { Breadcrumb as BreadcrumbPrimitive } from 'components/primitives/breadcrumb';
import { BackLinkWrapper } from 'components/objects/pageBreadcrumb';
import { BackTo } from 'components/primitives/links';

const Breadcrumb = ({ routeData, showBreadcrumb, backTo }) => {
  const isMobile = useIsMobileViewport();
  const { texts: [titleText], loaded } = useSanaTexts(['WishList_Title']);

  const backToLink = useMemo(() => {
    if (!backTo)
      return null;

    return <BackTo to={backTo.routeData} url={backTo.url} />;
  }, [backTo]);

  if (!showBreadcrumb || isMobile)
    return <BackLinkWrapper>{backToLink}</BackLinkWrapper>;

  if (!loaded)
    return null;

  const pageNode = {
    title: titleText,
    link: {
      to: routeData,
      options: {
        backTo,
      },
    },
  };

  return <BreadcrumbPrimitive items={[pageNode]} backLink={backToLink} />;
};

Breadcrumb.propTypes = {
  routeData: PropTypes.object,
  showBreadcrumb: PropTypes.bool,
  backTo: PropTypes.shape({
    url: PropTypes.string,
    routeData: PropTypes.shape({
      routeName: PropTypes.string.isRequired,
      params: PropTypes.object,
    }).isRequired,
  }),
};

export default connect(state => ({
  routeData: state.routing.routeData,
  showBreadcrumb: state.settings.navigation && state.settings.navigation.breadcrumb,
  backTo: state.page.backTo,
}))(Breadcrumb);
