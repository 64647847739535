import PropTypes from 'prop-types';
import { Col, Container, Row } from 'components/primitives/grid';
import { PageTitle, PageContent } from 'components/objects/pages';
import Sidebar from './Sidebar';
import { useResponsiveBreakpoints } from 'utils/layout';
import { BackToContainer } from 'components/primitives/links';
import { usePrintMode } from 'utils/hooks';

const ProfileTemplate = ({ header, footer, titleTextKey, sidebarLinkKey, backTo, pageBackTo, children }) => {
  const hasHeaderBlocks = header && header.length > 0;
  const { lg, xl } = useResponsiveBreakpoints();
  const marginTop = lg || xl ? '5.6em' : '1em';
  const isPrintMode = usePrintMode();
  const sidebarTopMargin = titleTextKey ? 0 : marginTop;

  return (
    <>
      {!isPrintMode && <BackToContainer options={backTo} pageBackTo={pageBackTo} />}
      {titleTextKey &&
        <Container>
          <Row>
            <Col lg={!hasHeaderBlocks ? { offset: 3 } : null} xl={!hasHeaderBlocks ? { offset: 2 } : null}>
              <PageTitle textKey={titleTextKey} />
            </Col>
          </Row>
        </Container>
      }
      <PageContent content={header} position="header" />
      <Container>
        <Row>
          {!isPrintMode &&
            <Col xs={12} lg={3} xl={2}>
              <Sidebar linkKey={sidebarLinkKey} styles={sidebarTopMargin ? { marginTop: sidebarTopMargin } : null} />
            </Col>
          }
          <Col xs={12} lg={isPrintMode ? null : 9} xl={isPrintMode ? null : 10}>
            {children}
          </Col>
        </Row>
      </Container>
      <PageContent content={footer} position="footer" />
    </>
  );
};

ProfileTemplate.propTypes = {
  header: PropTypes.array,
  footer: PropTypes.array,
  pageTitleProps: PropTypes.object,
  sidebarLinkKey: PropTypes.string.isRequired,
  backTo: PropTypes.shape({
    route: PropTypes.object,
    texts: PropTypes.arrayOf(PropTypes.string.isRequired),
    supportedRoutes: PropTypes.instanceOf(Set),
  }),
  pageBackTo: PropTypes.shape({
    routeData: PropTypes.object.isRequired,
    url: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default ProfileTemplate;
