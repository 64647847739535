import PropTypes from 'prop-types';
import SummaryBase from './SummaryBase';
import { BasketLinkLayoutOption } from './constants';

const MobileBasketSummaryBlock = ({ id, model, isDesignerMode }) => {
  const options = {
    compact: true,
    showBasketLinkLabel: false,
    showSubtotal: false,
    showMiniatureOnBasket: model.showMiniatureOnBasket,
    basketLinkLayout: model.basketLink_ShowNumberOfProductsAsBadge
      ? BasketLinkLayoutOption.ShowNumberOfProductsAsBadge
      : BasketLinkLayoutOption.TextRightOfIcon,
    basketLinkThemeFontSize: model.basketLink_ThemeFontSize,
  };
  const summaryId = `Summary_${id}`;

  const style = {
    '--basketLink-fontFamily': model.basketLink_FontFamily,
    '--basketLink-iconColor': model.basketLink_IconColor,
    '--basketLink-backgroundColor': model.basketLink_BackgroundColor,
    '--basketLink-fontColor': model.basketLink_FontColor,
    '--basketLink-badgeBackgroundColor': model.basketLink_BadgeBackgroundColor,
  };

  return (
    <SummaryBase
      headerStyle={style}
      id={summaryId}
      isDesignerMode={isDesignerMode}
      options={options}
    />
  );
};

MobileBasketSummaryBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showMiniatureOnBasket: PropTypes.bool.isRequired,
    basketLink_ShowNumberOfProductsAsBadge: PropTypes.bool.isRequired,
    basketLink_FontFamily: PropTypes.string,
    basketLink_ThemeFontSize: PropTypes.string.isRequired,
    basketLink_IconColor: PropTypes.string,
    basketLink_BackgroundColor: PropTypes.string,
    basketLink_FontColor: PropTypes.string,
    basketLink_BadgeBackgroundColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default MobileBasketSummaryBlock;
