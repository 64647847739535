import type { Epic } from 'behavior/types';
import { ProductSortFieldsAction, PRODUCT_SORTFIELDS_REQUESTED, sortableFieldsReceived } from './actions';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { sortableFieldsQuery } from './queries';
import { VIEWER_CHANGED } from 'behavior/events';
import { SortableField } from './types';

const epic: Epic<ProductSortFieldsAction> = (action$, _state$, { api }) => {
  const viewerChanged$ = action$.pipe(ofType(VIEWER_CHANGED));

  return action$.pipe(
    ofType(PRODUCT_SORTFIELDS_REQUESTED),
    switchMap(_action => api.graphApi<SortableFieldsResponse>(sortableFieldsQuery).pipe(
      map(({ catalog }) => sortableFieldsReceived(catalog.sortableFields)),
      takeUntil(viewerChanged$),
    )),
  );
};

export default epic;

type SortableFieldsResponse = {
  catalog: {
    sortableFields: SortableField[];
  };
};
