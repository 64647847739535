import type { Transform } from 'utils/render/types';
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { renderHTML, makeSimpleText } from 'utils/render';
import { getNodeText } from './helpers';

const DomHead = (props: { content: string }) => {
  let title = '';
  const scripts: { txt: string; attrs: Record<string, string> }[] = [];
  const meta: Record<string, string>[] = [];
  const links: Record<string, string>[] = [];
  const styles: { txt: string; attrs: Record<string, string> }[] = [];

  const transformer: Transform = node => {
    const attrs = node.attribs!;
    // eslint-disable-next-line default-case
    switch (node.name) {
      case 'script':
        scripts.push({
          txt: attrs.src ? '' : getNodeText(node),
          attrs,
        });
        break;

      case 'meta':
        meta.push(attrs);
        break;

      case 'title':
        title = getNodeText(node);
        break;

      case 'link':
        links.push(attrs);
        break;

      case 'style':
        styles.push({
          txt: getNodeText(node),
          attrs,
        });
        break;
    }

    return null;
  };

  renderHTML(props.content, transformer);

  return (
    <Helmet>
      {title && <title>{makeSimpleText(title)}</title>}
      {meta.map((attributes, index) => <meta key={index} {...attributes} />)}
      {links.map((attributes, index) => <link key={index} {...attributes} />)}
      {styles.map(({ txt, attrs }, index) => <style key={index} {...attrs}>{txt}</style>)}
      {scripts.map(({ txt, attrs }, index) => (txt
        ? <script key={index} {...attrs}>{txt}</script>
        : <script key={index} {...attrs} />
      ))}
    </Helmet>
  );
};

export default memo(DomHead);
