import type { NewsItem } from '../types';
import { loadNewsDetailPageQuery } from './queries';
import { of } from 'rxjs';
import { initComponent } from 'behavior/pages/helpers';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { pluck } from 'rxjs/operators';
import { RouteName } from 'routes';
import { Handler } from 'behavior/pages/types';

type NewsDetailPageResponse = {
  pages: {
    newsDetail: {
      metaTitle: string | null;
      metaDescription: string | null;
      newsItem: NewsItem;
    };
  };
};

type NewsDetailRouteData = {
  routeName: RouteName.NewsOverview;
  params: { id: string };
};

type NewsDetailPage = {
  metaTitle: string | null;
  metaDescription: string | null;
  newsItem: NewsItem | null;
  component: PageComponentNames.NewsDetails;
};

const handler: Handler<NewsDetailRouteData, NewsDetailPage> = ({ params: { id } }, _state$, { api }) => id
  ? api.graphApi<NewsDetailPageResponse>(loadNewsDetailPageQuery, { id }).pipe(
    pluck('pages', 'newsDetail'),
    initComponent(PageComponentNames.NewsDetails),
  )
  : of(null);

export default handler;
