import type {
  ProductCategories,
  CustomEventSource,
  EventSource,
} from './types';
import type { AppState } from 'behavior';
import { createUrl } from 'behavior/routing/helpers';

import type {
  PurchaseActionField,
  ProductLine,
  CheckoutInput,
  CheckoutOutput,
  CheckoutOptionInput,
  CheckoutOptionOutput,
  ProductClickInput,
  ProductClickOutput,
  ProductDetailsViewInput,
  ProductDetailsViewOutput,
  ProductListViewInput,
  ProductListViewOutput,
  ProductsAddToBasketInput,
  ProductsAddToBasketOutput,
  ProductsRemoveFromBasketInput,
  ProductsRemoveFromBasketOutput,
  PurchaseInput,
  PurchaseOutput,
  ProductOutputData,
  ProductInputData,
  VariantProductLine,
} from './payload.types';

export const createProductClickPayload = ({ product, source }: ProductClickInput): ProductClickOutput => ({
  event: 'productClick',
  ecommerce: {
    click: {
      actionField: {
        list: eventSourceToList(source)!,
      },
      products: [toProductOutput(product)],
    },
  },
});

export const createProductDetailsViewPayload = ({ product }: ProductDetailsViewInput): ProductDetailsViewOutput => ({
  event: 'detail',
  ecommerce: {
    detail: {
      products: [toProductOutput(product)],
    },
  },
});

export const createProductListViewPayload = ({ products, source }: ProductListViewInput, state: AppState): ProductListViewOutput => ({
  event: 'impression',
  ecommerce: {
    currencyCode: getCurrencyCode(state),
    impressions: {
      products: products.map(product => toProductOutput(product, source)),
    },
  },
});

export const createProductsAddToBasketPayload = ({ products }: ProductsAddToBasketInput, state: AppState): ProductsAddToBasketOutput => ({
  event: 'addToCart',
  ecommerce: {
    currencyCode: getCurrencyCode(state),
    add: {
      products: products.map(product => toProductOutput(product)),
    },
  },
});

export const createProductsRemoveFromBasketPayload = ({ products }: ProductsRemoveFromBasketInput): ProductsRemoveFromBasketOutput => ({
  event: 'removeFromCart',
  ecommerce: {
    remove: {
      products: products.map(product => toProductOutput(product)),
    },
  },
});

export const createCheckoutPayload = ({ step, products }: CheckoutInput, state: AppState): CheckoutOutput => ({
  event: 'checkout',
  ecommerce: {
    checkout: {
      actionField: {
        step,
      },
      products: products.map(product => toProductOutput(product)),
    },
  },
  customerType: getCustomerType(state),
});

export const createCheckoutOptionPayload = (actionField: CheckoutOptionInput): CheckoutOptionOutput => ({
  event: 'checkoutOption',
  ecommerce: {
    checkout_option: {
      actionField,
    },
  },
});

export const createPurchasePayload = (input: PurchaseInput, state: AppState): PurchaseOutput => {
  const {
    itemLines,
    totals,
    pricesInclTax,
    documentId,
  } = input;

  const products = extractProducts(itemLines);
  const revenue = pricesInclTax ? totals?.totalPrice : totals?.totalExcludingTax;

  const actionField: PurchaseActionField = {
    id: input.transactionId,
    affiliation: input.shopName,
    revenue: revenue ?? 0,
    shipping: totals?.shippingCost ?? 0,
    tax: totals?.taxAmount ?? 0,
  };

  if (totals?.promotion)
    actionField.coupon = totals.promotion.title;

  return {
    event: 'purchase',
    ecommerce: {
      currencyCode: getCurrencyCode(state),
      purchase: {
        actionField,
        products,
      },
    },
    customerType: getCustomerType(state),
    documentId,
  };
};

const isVariantLine = (line: ProductLine): line is VariantProductLine =>
  'sublines' in line;

const extractProducts = (itemLines: Array<ProductLine>) => {
  const products: Array<ProductOutputData> = [];

  itemLines.forEach(itemLine => {
    if (isVariantLine(itemLine)) {
      itemLine.sublines.forEach(subline => {
        const product = toProductOutput({
          id: itemLine.product.id,
          title: itemLine.title,
          variant: subline.title,
          uom: subline.uom,
          price: subline.price,
          quantity: subline.quantity,
          categoriesPaths: itemLine.product.categoriesPaths,
        });
        products.push(product);
      });
    } else {
      const product = toProductOutput({
        id: itemLine.product.id,
        title: itemLine.title,
        uom: itemLine.uom,
        price: itemLine.price,
        quantity: itemLine.quantity,
        categoriesPaths: itemLine.product.categoriesPaths,
      });
      products.push(product);
    }
  });

  return products;
};

export const createPageViewPayload = ({ origin, pageTitle }: { origin: string; pageTitle: string }, state: AppState) => ({
  event: 'pageView',
  pageUrl: origin + createUrl(state.routing.location!),
  pageUrlFragment: state.routing.location!.hash,
  oldPageUrl: state.routing.previous ? origin + createUrl(state.routing.previous.location) : '',
  oldPageUrlFragment: state.routing.previous?.location.hash || '',
  pageTitle,
});

export const createUserIdPayload = (userId: string | null) => ({ userId });

const isCustomEventSource = (source: undefined | EventSource | CustomEventSource): source is CustomEventSource =>
  !!source && (source as CustomEventSource).value !== undefined;

const eventSourceToList = (eventSource: undefined | EventSource | CustomEventSource) =>
  isCustomEventSource(eventSource) ? eventSource.value : eventSource;

export const toProductOutput = ({
    id,
    title: name,
    uom,
    categoriesPaths,
    variant,
    price,
    quantity,
    productGroup,
  }: ProductInputData,
  source?: EventSource | CustomEventSource,
): ProductOutputData => {
  const uomId = uom && uom.id;
  const outputId = productGroup
    ? productGroup.id
    : (uomId && `${id}_${uomId}`) || id;
  const data: ProductOutputData = {
    id: outputId,
    name: productGroup ? productGroup.title ?? outputId : name,
  };

  addPropIfNotNull(data, 'list', eventSourceToList(source));
  addPropIfNotNull(data, 'category', reduceCategories(getProductCategories(categoriesPaths)));

  if (productGroup)
    return data;

  addPropIfNotNull(data, 'variant', variant);
  addPropIfNotNull(data, 'price', price);
  addPropIfNotNull(data, 'quantity', roundDecimal(quantity));

  return data;
};

export const getProductCategories = (categoriesPaths: Array<{ categories: ProductCategories }>) => {
  if (categoriesPaths && categoriesPaths.length > 0)
    return categoriesPaths[0].categories;

  return null;
};

// A fix for GTM rounding issue. When qty is less than 1, GTM rounds it to 0
// and does not take into account such product anymore.
// We decided to round it manually to 1 in such scenario.
const roundDecimal = (quantity: number | undefined) => quantity && quantity < 1 ? 1 : quantity;

const addPropIfNotNull = <T, K extends keyof T>(obj: T, prop: K, value: T[K]) => {
  if (value !== undefined && value !== null)
    obj[prop] = value;
};

const reduceCategories = (categories: null | string | Array<{ name: string }> | Array<string>) => {
  let category = null;
  if (!categories || typeof categories === 'string')
    category = categories;
  else if (categories.length) {
    if (typeof categories[0] === 'object')
      category = (categories.slice(0, 5) as any[]).map(c => c.name.replace('/', '|'));
    category = category ? category.join('/') : categories.join('/');
  }
  return category;
};

const getCurrencyCode = ({ user }: AppState) => user!.currencyId || '';

const getCustomerType = ({ user }: AppState) => user!.customerType!;
