import type { RouteData } from 'routes';
import type { Handler } from '../types';
import type { VisualDesignerCommonPage } from './types';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

let handlerPromise: Promise<{ default: Handler<RouteData, VisualDesignerCommonPage> }>;
export default (...args: Parameters<Handler<RouteData, VisualDesignerCommonPage>>) => {
  if (!handlerPromise)
    handlerPromise = import(/*webpackChunkName:"vd"*/ './handler');

  return from(handlerPromise).pipe(
    switchMap(module => {
      const handler = module.default;
      return handler(...args);
    }));
};
