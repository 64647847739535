import styles from './Alert.module.scss';
import { WarningIcon } from 'components/primitives/icons';
import Alert, { Props as AlertProps } from './Alert';

type Props = Omit<AlertProps, 'icon'>;

const WarningAlert = ({ children, className = '', ...props }: Props) => (
  <Alert className={`${styles.warning} ${className}`} icon={WarningIcon} {...props}>
    {children}
  </Alert>
);

export default WarningAlert;
