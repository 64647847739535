import styles from './SanaTextBlock.module.scss';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';

type Props = {
  model: {
    key: string;
    styleMode: StyleMode;
  };
  createInlineSanaTextEditor: (key: string, text: string) => JSX.Element;
};

type StyleMode = 'Heading1' | 'Heading2' | 'Heading3' | 'Heading4' | 'Heading5' | 'None';

const SanaTextBlock = ({ model: { key, styleMode }, createInlineSanaTextEditor }: Props) => {
  const text = useSanaTexts([key]).texts[0];

  if (!text)
    return null;

  const className = `fr-view ${styles.noMargin}`;
  const children = createInlineSanaTextEditor
      ? createInlineSanaTextEditor(key, text)
      : makeRichText(text);

  switch (styleMode) {
    case 'Heading1':
      return <h1 className={className}>{children}</h1>;
    case 'Heading2':
      return <h2 className={className}>{children}</h2>;
    case 'Heading3':
      return <h3 className={className}>{children}</h3>;
    case 'Heading4':
      return <h4 className={className}>{children}</h4>;
    case 'Heading5':
      return <h5 className={className}>{children}</h5>;
    case 'None':
      return <div className="fr-view">{children}</div>;
    default:
      return null;
  }
};

export default SanaTextBlock;
