import type { Epic } from 'behavior/types';
import { LIMITED_ACCESS_REQUESTED, limitedAccessReceived, LimitedAccessAction } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import {
  ContentSystemPageData,
  initContentSystemPage,
  loadContentSystemPageQuery,
} from '../system';

const epic: Epic<LimitedAccessAction> = (action$, _, { api }) => action$.pipe(
  ofType(LIMITED_ACCESS_REQUESTED),
  switchMap(() => api.graphApi<LimitedAccessPageResponse>(loadContentSystemPageQuery('limitedAccess')).pipe(
    pluck('pages', 'limitedAccess'),
    map(limitedAccess => limitedAccessReceived(initContentSystemPage(limitedAccess))),
  ),
));

export default epic;

type LimitedAccessPageResponse = {
  pages: {
    limitedAccess: ContentSystemPageData;
  };
};
