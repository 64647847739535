export const invoice = {
  id: '',
  orderId: '',
  canBeReturned: true,
  currency: { id: '', symbol: '', cultureName: '', decimalDigits: 1 },
  salesAgreementId: null,
  lines: Array.from(Array(3)).map(() => ({
    lineNo: '',
    availableReturnQuantity: 1,
    quantity: 1,
    uom: { minimumQuantity: 1, maximumQuantity: 1 },
  })),
};

export const returnReasons = { forOrder: [], forOrderLines: [] };
