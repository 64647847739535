import { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Text from './Text';
import { TogglePresentationOption } from '../constants';

const IconWithText = ({ presentation, warehouseId }) => {
  switch (presentation) {
    case TogglePresentationOption.TextOnly:
      return <span><Text warehouseId={warehouseId} useFullName /></span>;
    case TogglePresentationOption.TextRightOfIcon:
      return (
        <>
          <Icon />
          <span><Text warehouseId={warehouseId} useFullName /></span>
        </>
      );
    case TogglePresentationOption.TextLeftOfIcon:
      return (
        <>
          <span><Text warehouseId={warehouseId} useFullName /></span>
          <Icon />
        </>
      );
    case TogglePresentationOption.TextBelowIcon:
      return (
        <div>
          <Icon />
          <div><Text warehouseId={warehouseId} useFullName /></div>
        </div>
      );
    default:
      return <Icon />;
  }
};

IconWithText.propTypes = {
  warehouseId: PropTypes.string,
  presentation: PropTypes.number.isRequired,
};

export default memo(IconWithText);
