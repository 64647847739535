import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';
import type { DocumentPage } from 'behavior/pages/document';

const OrderDetails = loadable(() => import(/*webpackChunkName:"document"*/'./orderDetails'));
const QuoteDetails = loadable(() => import(/*webpackChunkName:"document"*/'./quoteDetails'));
const InvoiceDetails = loadable(() => import(/*webpackChunkName:"document"*/'./invoiceDetails'));
const ReturnOrderDetails = loadable(() => import(/*webpackChunkName:"document"*/'./returnOrderDetails'));
const CreditNoteDetails = loadable(() => import(/*webpackChunkName:"document"*/'./creditNoteDetails'));
const ReturnReceiptDetails = loadable(() => import(/*webpackChunkName:"document"*/'./returnReceiptDetails'));
const ShipmentDetails = loadable(() => import(/*webpackChunkName:"document"*/'./shipmentDetails'));

/* eslint-disable react/no-multi-comp */
export default {
  [PageComponentNames.Order]: (page: DocumentPage) => <OrderDetails desktop={page.content?.desktop} mobile={page.content?.mobile} document={page.document} />,
  [PageComponentNames.Quote]: (page: DocumentPage) => <QuoteDetails desktop={page.content?.desktop} mobile={page.content?.mobile} document={page.document} />,
  [PageComponentNames.Invoice]: (page: DocumentPage) => <InvoiceDetails desktop={page.content?.desktop} mobile={page.content?.mobile} document={page.document} />,
  [PageComponentNames.ReturnOrder]: (page: DocumentPage) => <ReturnOrderDetails desktop={page.content?.desktop} mobile={page.content?.mobile} document={page.document} />,
  [PageComponentNames.CreditNote]: (page: DocumentPage) => <CreditNoteDetails desktop={page.content?.desktop} mobile={page.content?.mobile} document={page.document} />,
  [PageComponentNames.ReturnReceipt]: (page: DocumentPage) => <ReturnReceiptDetails desktop={page.content?.desktop} mobile={page.content?.mobile} document={page.document} />,
  [PageComponentNames.Shipment]: (page: DocumentPage) => <ShipmentDetails desktop={page.content?.desktop} mobile={page.content?.mobile} document={page.document} />,
};
