import styles from './Containers.module.scss';
import 'css/Animations.module.scss';
import { memo, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import createColumn from './createColumn';
import MediaBackground from 'components/primitives/grid/MediaBackground';
import { useCssAnimation } from './hooks';
import { AnimationsMap } from './constants';
import { NoBorderStyleValue } from './constants';
import { Container, Row } from 'components/primitives/grid';
import { useResponsiveBreakpoints, useLayoutShifter } from 'utils/layout';
import { ScrollDownIcon } from 'components/primitives/buttons';
import { joinClasses } from 'utils/helpers';

const ContentBlockRow = ({
  index,
  columns,
  fullWidth,
  background,
  border,
  spacing,
  attributes,
  rowAnimation,
  verticalAlignment,
  minHeight,
  heroEffect,
}) => {
  const { id, className } = attributes;
  const showBorder = border.style !== NoBorderStyleValue && !!border.width;
  const wrapperRef = useRef(null);
  const rowRef = useRef(null);

  const wrapperStyles = {
    padding: showBorder && border.width || null,
    margin: spacing.margin || null,
  };
  const animationClassName = useCssAnimation(wrapperRef, rowAnimation);
  const wrapperClassNames = joinClasses(
    styles.wrapper,
    animationClassName,
    index === 0 && heroEffect && styles.heroRow,
    className,
  );
  const rowClassNames = joinClasses(
    styles.row,
    heroEffect && styles.heroHeight,
  );

  const rowStyles = {
    padding: spacing.padding || null,
    '--minHeight_Desktop': minHeight.desktop,
    '--minHeight_Tablet': minHeight.tablet,
    '--minHeight_Mobile': minHeight.mobile,
  };

  const showBackground = showBorder
    || background.color
    || background.mobileImage
    || background.desktopImage
    || background.video;

  const { xs, sm } = useResponsiveBreakpoints();
  const { topFixedElementsHeight, bottomFixedElementsHeight } = useLayoutShifter();
  const shiftOffset = xs || sm ? topFixedElementsHeight + bottomFixedElementsHeight : 0;

  useEffect(() => {
    rowRef.current.style.minHeight = heroEffect ? `calc(100vh - ${shiftOffset}px)` : '';
  }, [shiftOffset, heroEffect]);
  const memoizedColumns = useMemo(() => renderColumns(columns, fullWidth, verticalAlignment),
    [columns, fullWidth, verticalAlignment]);

  return (
    <div
      ref={wrapperRef}
      id={id || null}
      className={wrapperClassNames}
      style={wrapperStyles}
    >
      {showBackground &&
        <MediaBackground
          {...background}
          fullWidth={!!heroEffect || background.fullWidth}
          borderWidth={border.width}
          borderStyle={border.style}
          borderColor={border.color}
          cornerRadius={border.radius}
          imageLoadVisibleByDefault={!!AnimationsMap[rowAnimation]}
          mutedSound={heroEffect ? heroEffect.mutedSound : true}
          isMobile={xs}
          imageEffect={heroEffect?.imageEffect}
        />
      }
      <Container className={styles.contentBox} fluid={fullWidth}>
        <Row
          ref={rowRef}
          className={rowClassNames}
          style={rowStyles}
          noGutters={spacing.hideSpaces}
        >
          {memoizedColumns}
        </Row>
        {heroEffect?.showScrolldownIcon && <ScrollDownIcon aimRef={wrapperRef} />}
      </Container>
    </div>
  );
};

ContentBlockRow.propTypes = {
  columns: PropTypes.array,
  fullWidth: PropTypes.bool,
  background: PropTypes.shape({
    fullWidth: PropTypes.bool,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
    imageAltText: PropTypes.string,
    video: PropTypes.string,
    color: PropTypes.string,
    hideImageOnMobile: PropTypes.bool,
  }).isRequired,
  border: PropTypes.shape({
    width: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.string,
  }).isRequired,
  spacing: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
    noGutters: PropTypes.bool,
  }),
  attributes: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  rowAnimation: PropTypes.string,
  verticalAlignment: PropTypes.string,
  minHeight: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  heroEffect: PropTypes.shape({
    mutedSound: PropTypes.bool,
    showScrolldownIcon: PropTypes.bool,
    imageEffect: PropTypes.string,
    headerOnTop: PropTypes.bool,
  }),
};

export default memo(ContentBlockRow);

function renderColumns(columns, fullWidthRow, rowVerticalAlignment) {
  if (!columns || !columns.length)
    return null;

  return columns.map((column, index) => createColumn(column, index, fullWidthRow, rowVerticalAlignment));
}
