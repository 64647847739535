import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { createUrl } from 'behavior/routing';

export default function useCurrentRouteAsBackTo() {
  const { location, routeData } = useSelector(({ routing }) => ({
    location: routing.location,
    routeData: routing.routeData,
  }), shallowEqual);

  return useMemo(() => ({ url: location && createUrl(location), routeData }), [location, routeData]);
}
