import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { useShopName } from 'utils/hooks';

const CopyrightTextBlock = () => {
  const shopName = useShopName();
  const { texts: [footerCopyright, sanaCommerce], loaded } = useSanaTexts(['Footer_Copyright', 'SanaCommerce']);

  if (!loaded)
    return null;

  return (
    <span className="copyright">
      {/* Class 'copyright' is used in web tests. */}
      {makeRichText(footerCopyright, [new Date().getFullYear(), shopName, makeRichText(sanaCommerce)])}
    </span>
  );
};

export default CopyrightTextBlock;
