import type { SortableField } from './types';

export const PRODUCT_SORTFIELDS_REQUESTED = 'PRODUCT/SORTFIELDS/REQUESTED' as const;
export const requestSortableFields = () => ({
  type: PRODUCT_SORTFIELDS_REQUESTED,
});

export const PRODUCT_SORTFIELDS_RECEIVED = 'PRODUCT/SORTFIELDS/RECEIVED' as const;
export const sortableFieldsReceived = (sortableFields: SortableField[]) => ({
  type: PRODUCT_SORTFIELDS_RECEIVED,
  payload: sortableFields,
});

export type SortableFieldsReceived = ReturnType<typeof sortableFieldsReceived>;
export type ProductSortFieldsAction = ReturnType<typeof requestSortableFields> | SortableFieldsReceived;
