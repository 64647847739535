export const enum DocumentType {
  Order = 'Order',
  Quote = 'Quote',
  Invoice = 'Invoice',
  ReturnOrder = 'ReturnOrder',
  ReturnReceipt = 'ReturnReceipt',
  Shipment = 'Shipment',
  CreditNote = 'CreditNote',
}

export const enum QuoteStatus {
  Expired = 'Expired',
  InReview = 'InReview',
  Orderable = 'Orderable',
  Cancelled = 'Cancelled',
}

export const enum PromoteQuoteStatus {
  Succeed = 'SUCCEED',
  CreditLimitForceOnlinePayment = 'CREDIT_LIMIT_FORCE_ONLINE_PAYMENT',
  CreditLimitWarning = 'CREDIT_LIMIT_WARNING',
  CreditLimitError = 'CREDIT_LIMIT_ERROR',
  OutOfStock = 'OUT_OF_STOCK',
  ContainsNonOrderableProducts = 'CONTAINS_NON_ORDERABLE_PRODUCTS',
  QuoteNotFound = 'QUOTE_NOT_FOUND',
}

export const ChildDocumentTypes = [
  DocumentType.Invoice,
  DocumentType.ReturnReceipt,
  DocumentType.CreditNote,
  DocumentType.Shipment,
];

export const enum PromotionType {
  Disabled = 'DISABLED',
  WithCheckout = 'WITH_CHECKOUT',
  Unattended = 'UNATTENDED',
}
