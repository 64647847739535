import { RouteName } from 'routes';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import {
  ContentSystemPage,
  ContentSystemPageData,
  initContentSystemPage,
  loadContentSystemPageQuery,
} from '../system';
import type { Handler } from '../types';

const handler: Handler<ChangePasswordRouteData, ChangePasswordPage> = (_routeData, _state$, { api }) =>
  api.graphApi<ChangePasswordPageResponse>(loadContentSystemPageQuery('changePassword')).pipe(
    map(({ pages: { changePassword } }) => ({
      page: {
        ...initContentSystemPage(changePassword),
        component: PageComponentNames.ChangePassword,
      },
    }),
  ),
);

export default handler;

type ChangePasswordRouteData = {
  routeName: RouteName.ChangePassword;
};

type ChangePasswordPage = ContentSystemPage & {
  component: PageComponentNames.ChangePassword;
};

type ChangePasswordPageResponse = {
  pages: {
    changePassword: ContentSystemPageData;
  };
};
