import type { BlogItem, BlogFacet } from './types';
import type { Epic } from 'behavior/types';
import {
  BlogItemsAction,
  BLOG_ITEMS_REQUESTED,
  BLOG_FACETS_REQUESTED,
  itemsReceived,
  facetsReceived,
} from './actions';
import { ofType } from 'redux-observable';
import { switchMap, mergeMap, map, pluck } from 'rxjs/operators';
import { merge } from 'rxjs';
import { itemsQuery, facetsQuery } from './queries';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';

const epic: Epic<BlogItemsAction> = (action$, _, { api, logger }) => {
  const itemsRequested$ = action$.pipe(
    ofType(BLOG_ITEMS_REQUESTED),
    pluck('payload'),
    mergeMap(({ options: { appendItems, ...options } }) => api.graphApi<BlogItemsResponse>(itemsQuery, { options }).pipe(
      map(response => mapBlogItems(response, appendItems)),
      retryWithToast(action$, logger),
    )),
  );

  const facetsRequested$ = action$.pipe(
    ofType(BLOG_FACETS_REQUESTED),
    pluck('payload'),
    switchMap(({ options }) => api.graphApi<BlogFacetsResponse>(facetsQuery, { options }).pipe(
      pluck('blog', 'list', 'facets'),
      map(facetsReceived),
      retryWithToast(action$, logger),
    )),
  );

  return merge(itemsRequested$, facetsRequested$);
};

export default epic;

type BlogItemsResponse = {
  blog: {
    list: {
      items: {
        id: string;
        title: string;
        url: string;
        author: string;
        tags: {
          id: string;
          title: string;
        }[];
        publishDate: string;
        image: string | null;
        shortDescription: string | null;
      }[];
      totalCount: number;
    };
  };
};

type BlogFacetsResponse = {
  blog: {
    list: {
      facets: BlogFacet[];
    };
  };
};

function mapBlogItems(response: BlogItemsResponse, appendItems: boolean) {
  const blogItems: BlogItem[] = [];
  const { items, totalCount } = response.blog.list;

  for (const item of items) {
    const blogItem: BlogItem = {
      ...item,
      routeData: routesBuilder.forBlogItem(item.id),
    };

    blogItems.push(blogItem);
  }

  return itemsReceived(blogItems, totalCount, appendItems);
}
