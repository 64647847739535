import { Breadcrumb as BreadcrumbPrimitive } from 'components/primitives/breadcrumb';
import { routesBuilder } from 'routes';
import { useSimpleTexts } from 'components/sanaText';
import { useIsMobileViewport } from 'utils/hooks';

const BasketBreadcrumb = () => {
  const isMobile = useIsMobileViewport();
  const [shoppingBasketHeaderText] = useSimpleTexts([!isMobile && 'ShoppingBasket_Header']).texts;

  if (isMobile)
    return null;

  const basketNode = {
    title: shoppingBasketHeaderText || '',
    link: { to: routesBuilder.forBasket() },
  };

  return <BreadcrumbPrimitive items={[basketNode]} />;
};

export default BasketBreadcrumb;
