import { RouteName } from 'routes';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import {
  ContentSystemPage,
  ContentSystemPageData,
  initContentSystemPage,
  loadContentSystemPageQuery,
} from '../system';
import type { Handler } from '../types';

const handler: Handler<AnnouncementsRouteData, AnnouncementsPage> = (_routeData, _state$, { api }) =>
  api.graphApi<AnnouncementsPageResponse>(loadContentSystemPageQuery('announcements')).pipe(
    map(({ pages: { announcements } }) => ({
      page: {
        ...initContentSystemPage(announcements),
        component: PageComponentNames.Announcements,
      },
    }),
  ),
);

export default handler;

type AnnouncementsRouteData = {
  routeName: RouteName.Announcements;
};

type AnnouncementsPage = ContentSystemPage & {
  component: PageComponentNames.Announcements;
};

type AnnouncementsPageResponse = {
  pages: {
    announcements: ContentSystemPageData;
  };
};
