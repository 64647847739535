import { of } from 'rxjs';
import { mergeMap, catchError, tap, defaultIfEmpty } from 'rxjs/operators';
import { handlers, errorHandler } from './routeHandlers';
import { isIndexed, RouteName } from 'routes';
import { fallbackHandler } from './system';

export function handle(state$, dependencies, routeData) {
  const state = state$.value;
  if (state.error.initial || state.settings.error || routeData.routeName === RouteName.Error)
    return errorHandler(state.error.initial === true ? undefined : { timeId: state.error.initial }, dependencies.logger);

  const handler = handlers[routeData.routeName] || fallbackHandler;

  return handler(routeData, state$, dependencies).pipe(
    defaultIfEmpty(null),
    mergeMap(result => {
      if (!result)
        return fallbackHandler(routeData, state$, dependencies);

      if (result.forwardTo)
        return handle(state$, dependencies, result.forwardTo);

      return of({ ...result, routeData });
    }),
    tap(({ page }) => {
      if (page) {
        if (!('index' in page))
          page.index = isIndexed(routeData.routeName);

        const { options } = routeData;
        const backTo = options?.backTo;
        if (backTo)
          page.backTo = backTo;

        if (page.backTo) {
          const backToScrollPosition = options?.backToScrollPosition;
          if (backToScrollPosition && !page.backTo.routeData.options?.initialScrollPosition)
            page.backTo = {
              ...page.backTo,
              routeData: {
                ...page.backTo.routeData,
                options: {
                  ...page.backTo.routeData.options,
                  initialScrollPosition: backToScrollPosition,
                },
              },
            };
        }
      }
    }),
    catchError(e => errorHandler(e, dependencies.logger)),
  );
}
