import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { DocumentTitle, useDocumentTitle } from '../documentTitle';

const PageTitle = ({ textKey, className, asDocumentTitle, placeholder }) => {
  const { texts: [title], loaded } = useSimpleTexts([textKey]);
  const documentTitle = useDocumentTitle(asDocumentTitle && loaded ? title : null);

  return (
    <>
      {documentTitle && <DocumentTitle title={documentTitle} />}
      <h1 className={className}>{!loaded && placeholder ? placeholder : title}</h1>
    </>
  );
};

PageTitle.propTypes = {
  textKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      fallback: PropTypes.string,
    }),
  ]),
  className: PropTypes.string,
  asDocumentTitle: PropTypes.bool,
  placeholder: PropTypes.node,
};

export default memo(PageTitle);
