import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { map, tap, pluck } from 'rxjs/operators';
import { languageChanged, VISUAL_DESIGNER_LANGUAGE_CHANGED } from './actions/general';
import { urlLanguageChanged } from 'behavior/localization/actions';

const changeLanguage: Epic<ReturnType<typeof languageChanged>> = (action$, _, { api }) => action$.pipe(
  ofType(VISUAL_DESIGNER_LANGUAGE_CHANGED),
  pluck('payload'),
  tap(languageId => api.setLanguage(languageId.toString(), false)),
  map(urlLanguageChanged),
);

export default changeLanguage;