import styles from './Newsletter.module.scss';
import { forwardRef, memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SanaForm, FieldError, CaptchaFormGroup } from 'components/objects/forms';
import { EmailAddressField } from 'components/objects/forms';
import { SanaButton } from 'components/primitives/buttons';
import { useSimpleTexts } from 'components/sanaText';
import { DesignerModeCaptcha } from 'components/primitives/captcha';
import { SubmitButtonDisplayMode } from './constants';
import { navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { useDispatch } from 'react-redux';

const NewsletterForm = forwardRef(({ formName, onSubmit, buttonTextKey, submitButtonDisplayMode, shouldReset, isDesignerMode }, captchaRef) => {
  const [emailTitle] = useSimpleTexts(['EmailAddress']).texts;
  const onBlur = (e, formik) => !e.currentTarget.contains(e.relatedTarget || document.activeElement) && formik.setFieldError('email', undefined);
  const buttonBelow = submitButtonDisplayMode === SubmitButtonDisplayMode.Below;

  const [shouldFormReset, setShouldReset] = useState(false);
  const dispatch = useDispatch();

  const onFormSubmit = useCallback(formData => {
    const url = "/newsletter/subscribe?email=" + formData.email;
    setShouldReset(true);
    setShouldReset(false);
    dispatch(navigateTo(null, url));
  }, []);

  const submitButton = (
    <SanaButton
      textKey={buttonTextKey}
      type="submit"
      size="sm"
      className={`${styles.btnSubmit} ${buttonBelow ? styles.btnBelow : ''}`}
      onClick={isDesignerMode ? e => e.preventDefault() : undefined}
    />
  );

  return (
    <SanaForm
      name={formName}
      onSubmit={onFormSubmit}
      shouldReset={shouldFormReset}
      validateOnBlur={false}
      onBlur={onBlur}
    >
      <div className={styles.mainForm}>
        <div className={styles.field}>
          <EmailAddressField
            fieldName="email"
            fieldTitle={emailTitle}
            placeholder={emailTitle}
            maxLength={80}
            size="12"
            required
            validation={{ required: true }}
            disabled={isDesignerMode}
          />
        </div>
        {buttonBelow === false && submitButton}
        <div className={styles.validation}>
          <FieldError fieldName="email" />
        </div>
      </div>
      {captchaRef && (
        isDesignerMode
          ? <DesignerModeCaptcha />
          : <CaptchaFormGroup ref={captchaRef} captchaId={formName} className={styles.recaptcha} formGroupClassName={styles.noLabel} />
      )}
      {buttonBelow && submitButton}
    </SanaForm>
  );
});

NewsletterForm.propTypes = {
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonTextKey: PropTypes.string.isRequired,
  submitButtonDisplayMode: PropTypes.number.isRequired,
  shouldReset: PropTypes.bool,
  isDesignerMode: PropTypes.bool,
};

export default memo(NewsletterForm);
