import type { DomElement, Transform } from './types';
import type { ReactNode } from 'react';
import { Fragment } from 'react';
import { splitToFragments } from 'utils/formatting';

const transformWithFormat = (args: ReactNode[], node: DomElement): ReturnType<Transform> => {
  if (!args || node.type !== 'text')
    return;

  const text = node.data;
  if (!text)
    return;

  const fragments = splitToFragments(text, args);
  if (fragments.length === 1)
    return <>{fragments[0]}</>;

  return (
    <>
      {fragments.map((fragment, fragmentIndex) =>
        <Fragment key={fragmentIndex}>{fragment}</Fragment>)}
    </>
  );
};

export default (args: ReactNode[]) => (node: DomElement) => transformWithFormat(args, node);
