import {
  LANGUAGES_LOADED,
  ALTERNATE_URLS_LOADED,
  URL_LANGUAGE_CHANGED,
  LanguageAction,
} from './actions';
import { NAVIGATED, RoutingAction } from 'behavior/routing';
import { LOCATION_CHANGED, LocationChangedAction } from 'behavior/events';
import { createReducer } from 'utils/redux';
import type { LanguageType, CurrentLanguageType, AlternateUrl } from './types';

export type LanguageState = {
  languages: Array<LanguageType>;
  currentLanguage: CurrentLanguageType;
  alternateUrls: Array<AlternateUrl>;
  alternateUrlsExpired: boolean;
};

const initialState: LanguageState = {
  languages: [],
  currentLanguage: {
    id: null,
    name: null,
    cultureName: null,
    firstDayOfWeek: null,
  },
  alternateUrls: [],
  alternateUrlsExpired: false,
};

export default createReducer<LanguageState, LanguageAction | LocationChangedAction | RoutingAction>(initialState, {
  [LANGUAGES_LOADED]: onLanguagesLoaded,
  [ALTERNATE_URLS_LOADED]: onAlternateUrlsLoaded,
  [LOCATION_CHANGED]: onLocationChanged,
  [URL_LANGUAGE_CHANGED]: onUrlLanguageChanged,
  [NAVIGATED]: onNavigated,
});

function onLanguagesLoaded(state: LanguageState, action: { payload: Array<LanguageType> }): LanguageState {
  const languages = action.payload;

  return {
    ...state,
    languages,
    currentLanguage: createCurrentLanguage(state.currentLanguage.id, languages),
  };
}

function onAlternateUrlsLoaded(state: LanguageState, action: { payload: Array<AlternateUrl> }): LanguageState {
  return { ...state, alternateUrls: action.payload };
}

function onLocationChanged(state: LanguageState, _action: any): LanguageState {
  return { ...state, alternateUrlsExpired: true };
}

function onNavigated(state: LanguageState, _action: any): LanguageState {
  if (state.alternateUrlsExpired)
    return { ...state, alternateUrlsExpired: false, alternateUrls: [] };

  return state;
}

function onUrlLanguageChanged(state: LanguageState, action: { payload: number }): LanguageState {
  const id = action.payload;

  return {
    ...state,
    currentLanguage: createCurrentLanguage(id, state.languages),
  };
}

/**
 * Finds a language in an array by the specified language ID.
 * @param {Number} id - ID of the language.
 * @param {Array} languages - languages array.
 * @returns {Object} - found language, or null in case language wasn't found.
 */
function findLanguageById(id: number, languages: Array<LanguageType>): LanguageType | undefined {
  if (!languages)
    return;
  return languages.find(l => l.id === id);
}

function createCurrentLanguage(id: number | null, languages: Array<LanguageType>): CurrentLanguageType {
  const language = id ? findLanguageById(id, languages) : undefined;

  return {
    id,
    name: language ? language.twoLetterISOName : null,
    cultureName: language ? language.name : null,
    firstDayOfWeek: language ? language.firstDayOfWeek : null,
  };
}