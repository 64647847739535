import loadable from '@loadable/component';

export default {
  ProfileDocumentPageTitle: loadable(() => import(/*webpackChunkName:"document"*/'./pageTitle')),
  ProfileQuoteStatusMessages: loadable(() => import(/*webpackChunkName:"document"*/'./quoteStatusMessages')),
  ProfileDocumentHeader: loadable(() => import(/*webpackChunkName:"document"*/'./header')),
  ProfileDocumentAddresses: loadable(() => import(/*webpackChunkName:"document"*/'./addresses')),
  ProfileDocumentAttachments: loadable(() => import(/*webpackChunkName:"document"*/'./attachments')),
  ProfileDocumentLines: loadable(() => import(/*webpackChunkName:"document"*/'./lines')),
  ProfileOrderAuthorizationInformation: loadable(() => import(/*webpackChunkName:"document"*/'./orderAuthorizationInformation')),
  ProfileDocumentEditButton: loadable(() => import(/*webpackChunkName:"document"*/'./editButton')),
  ProfileQuotePromoteButton: loadable(() => import(/*webpackChunkName:"document"*/'./quotePromoteButton')),
  ProfileDocumentReorderButton: loadable(() => import(/*webpackChunkName:"document"*/'./reorderButton')),
  ProfileInvoicePayButton: loadable(() => import(/*webpackChunkName:"document"*/'./invoicePayButton')),
  ProfileDocumentMessages: loadable(() => import(/*webpackChunkName:"document"*/'./messages')),
  ProfileDocumentDownloadReportButton: loadable(() => import(/*webpackChunkName:"document"*/'./downloadReportButton')),
  ProfileDocumentDownloadReportMessages: loadable(() => import(/*webpackChunkName:"document"*/'./downloadReportMessages')),
  ProfileInvoiceReturnOrderButton: loadable(() => import(/*webpackChunkName:"document"*/'./invoiceReturnOrderButton')),
};
