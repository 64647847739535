import type { SortableField } from './types';
import type { RoutingAction } from 'behavior/routing';
import { ProductSortFieldsAction, SortableFieldsReceived, PRODUCT_SORTFIELDS_RECEIVED } from './actions';
import { VIEWER_CHANGED, viewerChanged } from 'behavior/events';
import { NAVIGATED } from 'behavior/routing';
import { createReducer } from 'utils/redux';

type State = {
  fields: SortableField[] | null;
  expired?: true;
};

const initialState: State = {
  fields: null,
};

export default createReducer<State, ProductSortFieldsAction | RoutingAction | ViewerChangedEvent>(initialState, {
  [PRODUCT_SORTFIELDS_RECEIVED]: onSortableFieldsReceived,
  [VIEWER_CHANGED]: onViewerChanged,
  [NAVIGATED]: onNavigated,
});

function onSortableFieldsReceived(_state: State, action: SortableFieldsReceived) {
  return { fields: action.payload };
}

function onViewerChanged(state: State) {
  return { ...state, expired: true as const };
}

function onNavigated(state: State) {
  if (state.expired)
    return { fields: null };

  return state;
}

type ViewerChangedEvent = ReturnType<typeof viewerChanged>;
