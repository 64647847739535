import type { SanaTextAction } from '../actions/sanaText';
import type { VisualDesignerState } from '../types';
import { VISUAL_DESIGNER_UPDATE_SANA_TEXTS } from '../actions/sanaText';

export default (state: VisualDesignerState, action: SanaTextAction) => {
  if (action.type === VISUAL_DESIGNER_UPDATE_SANA_TEXTS)
    return onUpdateSanaTexts(state, action);

  return state;
};

function onUpdateSanaTexts(state: VisualDesignerState, action: SanaTextAction): VisualDesignerState {
  return {
    ...state,
    editedSanaTexts: action.payload,
  };
}
