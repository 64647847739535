import type { MainMenuItem } from './types';

type Predicate = (item: MainMenuItem) => boolean;
type UpdateItem = (item: MainMenuItem) => MainMenuItem;

/**
 * Finds an item in the navigation tree by predicate and then calls an update function on it.
 * All ascendants of updated item are updated to new objects.
 * @param {Array} items - array of navigation items.
 * @param {Function} predicate - a predicate to find an item.
 * @param {Function} updateItem - an action to run on a found item.
 * @returns {Array} - initial / updated array of items.
 */
export function updateTreeItem(items: MainMenuItem[] | undefined | null, predicate: Predicate, updateItem: UpdateItem) {
  const newItems = traverseTree(items, predicate, updateItem);
  return newItems || items;
}

function traverseTree(items: MainMenuItem[] | undefined | null, predicate: Predicate, updateItem: UpdateItem): MainMenuItem[] | null | undefined {
  if (!items || !items.length)
    return items;

  let modified;
  const result = items.map(item => {
    if (predicate(item)) {
      modified = true;

      return updateItem({ ...item });
    }

    const updatedChildren = traverseTree(item.children, predicate, updateItem);

    if (updatedChildren?.length) {
      modified = true;
      return { ...item, children: updatedChildren };
    }
    return item;
  });

  return modified ? result : null;
}
