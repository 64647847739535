import type { Location } from 'behavior/routing';
import { parseQuery } from 'utils/url';
import { VisualDesignerMode } from './constants';

export function isVisualDesignerLocation(location: Location) {
  return location.pathname === '/sana-visual-designer';
}

export function isVisualDesigner(location: Location) {
  return isVisualDesignerLocation(location) && getVisualDesignerMode(location) !== VisualDesignerMode.InsiteEditor;
}

export function isInsiteEditorLocation(location: Location) {
  return isVisualDesignerLocation(location) && getVisualDesignerMode(location) === VisualDesignerMode.InsiteEditor;
}

export function getVisualDesignerMode(location: Location) {
  return parseQuery(location.search).mode as VisualDesignerMode | undefined;
}
