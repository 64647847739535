import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';
import { selectPropsFromPage } from '../helpers';

const ProductListPage = loadable(() => import(/*webpackChunkName:"plp"*/'./Page'));

export default {
  [PageComponentNames.ProductList]: page => (
    <ProductListPage
      preset={page.preset}
      facets={page.facets}
      headerContent={page.headerContent}
      footerContent={page.footerContent}
      {...selectPropsFromPage(page)}
    />
  ),
};
