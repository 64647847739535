import type { InvoicePaymentPage } from './types';
import {
  CREATE_INVOICE_ORDER,
  CREATE_INVOICE_ORDER_RESULT_RECEIVED,
  SUBMIT_INVOICE_ORDER_RESULT_RECEIVED,
  SUBMIT_INVOICE_ORDER,
  PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
  InvoicePaymentAction,
} from './actions';

export default (state: InvoicePaymentPage, action: InvoicePaymentAction): InvoicePaymentPage => {
  switch (action.type) {
    case CREATE_INVOICE_ORDER:
      return { ...state, createInvoiceOrderResult: null };
    case CREATE_INVOICE_ORDER_RESULT_RECEIVED:
      return { ...state, createInvoiceOrderResult: action.payload.result };
    case SUBMIT_INVOICE_ORDER_RESULT_RECEIVED:
      return { ...state, submitInvoiceOrderResult: action.payload.result };
    case SUBMIT_INVOICE_ORDER:
      return { ...state, submitInvoiceOrderResult: null };
    case PAYMENT_METHOD_EXTRA_DATA_RECEIVED:
      const { paymentMethodId, additionalCustomerData, extraPaymentData } = action.payload;

      return {
        ...state,
        additionalCustomerData,
        extraPaymentData: extraPaymentData && { paymentMethodId, ...extraPaymentData },
      };
    default:
      return state;
  }
};
