import type { Api } from 'utils/api';
import type { Handler } from '../types';
import type { CustomerCollectionData, CustomerCollection } from './types';
import { representationPage } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initPageContent, SystemPage, SystemPageData } from '../system';
import { loadSystemPageQuery } from '../system/queries';
import { RouteName } from 'routes';

type RepresentRouteData = {
  routeName: RouteName.Represent;
  params?: {
    previewToken?: string;
  };
};

type RepresentSystemPageResponse = {
  pages: {
    represent: SystemPageData;
  };
};

type RepresentPageResponse = RepresentSystemPageResponse & {
  profile: {
    impersonation: {
      customers: CustomerCollectionData;
    };
  };
};

type RepresentPage = SystemPage & {
  component: PageComponentNames.Represent;
  customers: CustomerCollection;
};

const handler: Handler<RepresentRouteData, RepresentPage> = ({ params }, _state$, { api }) => {
  if (params?.previewToken)
    return handlePreview(api);

  return api.graphApi<RepresentPageResponse>(representationPage).pipe(
    map(({ pages, profile: { impersonation } }) => {
      const page = pages.represent;

      if (!impersonation || !page)
        return null;

      const result = {
        page: initPageContent(page) as RepresentPage,
      };
      result.page.component = PageComponentNames.Represent;
      result.page.customers = impersonation.customers;
      result.page.customers.keywords = '';

      return result;
    }),
  );
};

export default handler;

function handlePreview(api: Api) {
  return api.graphApi<RepresentSystemPageResponse>(loadSystemPageQuery('represent')).pipe(
    map(({ pages: { represent: page } }) => {
      if (!page)
        return null;

      const result = {
        page: initPageContent(page) as RepresentPage,
      };

      result.page.component = PageComponentNames.Represent;
      result.page.customers = {
        keywords: '',
        total: 5,
        list: Array.from(Array(5)).map((_, index) => ({
          id: index.toString(),
          name: '',
          address: { postalAddress: '' },
        })),
      };

      return result;
    }),
  );
}
