import styles from './Slider.module.scss';
import type { SliderControl } from './useSliderControl';
import type { ReactElement } from 'react';
import type { SwipePosition } from 'react-easy-swipe';
import { cloneElement, useMemo, useRef, useState, useEffect, useCallback } from 'react';
import Swipe from 'react-easy-swipe';
import { useOnChange } from 'utils/hooks';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  BigArrowLeftIcon,
  BigArrowLeftPassiveIcon,
  BigArrowRightIcon,
  BigArrowRightPassiveIcon,
  ListItemIcon,
  ListSelectedItemIcon,
} from 'components/primitives/icons';

type Props = {
  items?: Array<ReactElement>;
  index?: number;
  control?: SliderControl;
  tilesPerView?: number;
  showArrows?: boolean;
  onSlideChange?: (index: number) => void;
  noMouseSliding?: boolean;
};

const SWIPE_THRESHOLD = 0.1;

const Slider = ({ index = 0, items = [], tilesPerView = 1, control, onSlideChange, showArrows = true, noMouseSliding }: Props) => {
  const [activeIndex, setActiveIndex] = useState(control ? control.currentIndex.current : index);
  const subject = useRef<Subject<number>>(new Subject());
  const slidesAmount = useMemo(() => Math.ceil(items.length / tilesPerView), [items, tilesPerView]);
  const sliderRef = useRef<HTMLDivElement>(null);
  const slidesWrapperRef = useRef<HTMLDivElement>(null);
  const deltaX = useRef(0);
  const isVerticalSwipe = useRef(false);
  const activeIndexRef = useRef<number>();
  activeIndexRef.current = activeIndex;
  const isFirstRenderRef = useRef(true);

  const activeDelta = useMemo(() => {
    if (activeIndex !== slidesAmount - 1)
      return 0;

    const leftAmount = slidesAmount * tilesPerView - items.length;
    return leftAmount / tilesPerView * 100;
  }, [activeIndex, slidesAmount, tilesPerView]);

  const setIndex = (index: number) => {
    if (index >= 0 && index < slidesAmount) {
      setActiveIndex(index);
      subject.current.next(index);
      if (control)
        control.currentIndex.current = index;
    }
  };

  useOnChange(() => {
    if (index === activeIndex) {
      if (!slidesWrapperRef.current)
        return;

      shiftSlides(slidesWrapperRef.current, -100 * activeIndex + activeDelta);
      return;
    }

    if (activeIndex >= slidesAmount) {
      setActiveIndex(slidesAmount - 1);
      return;
    }

    setActiveIndex(0);
  }, [slidesAmount]);

  useOnChange(() => {
    if (!control)
      return;

    const { currentIndex } = control;
    control.setIndex = setIndex;

    if (currentIndex.current)
      setIndex(currentIndex.current);
  }, [control]);

  useEffect(() => {
    let animationFrameId: number;
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      slidesWrapperRef.current!.classList.add(styles.sliding);

      animationFrameId = requestAnimationFrame(() => {
        slidesWrapperRef.current!.classList.remove(styles.sliding);
      });
    }

    shiftSlides(slidesWrapperRef.current!, -100 * activeIndex + activeDelta);

    return () => cancelAnimationFrame(animationFrameId);
  }, [activeIndex, tilesPerView]);

  useEffect(() => () => void (subject.current.complete()), []);

  const prev = useCallback(() => {
    if (activeIndex > 0) {
      onSlideChange && onSlideChange(activeIndex - 1);
      setIndex(activeIndex - 1);
    } else {
      shiftSlides(slidesWrapperRef.current!, -100 * activeIndex + activeDelta);
    }
  }, [activeIndex]);

  const next = useCallback(() => {
    if (activeIndex < slidesAmount - 1) {
      onSlideChange && onSlideChange(activeIndex + 1);
      setIndex(activeIndex + 1);
    } else {
      shiftSlides(slidesWrapperRef.current!, -100 * activeIndex + activeDelta);
    }
  }, [activeIndex]);

  const onNavigate = (index: number) => {
    onSlideChange && onSlideChange(index);
    setIndex(index);
  };

  const onSwipeStart = useCallback(() => {
    deltaX.current = 0;
    slidesWrapperRef.current!.classList.add(styles.sliding);
  }, []);

  const onSwipeMove = useCallback(({ x, y }: SwipePosition) => {
    if (isVerticalSwipe.current)
      return false;

    if (activeIndex === 0 && x > 0)
      return false;

    if (activeIndex === slidesAmount - 1 && x < 0)
      return false;

    const absX = Math.abs(x);

    if (deltaX.current === 0 && absX < Math.abs(y)) {
      isVerticalSwipe.current = true;
      return false;
    }

    const firstElementChild = slidesWrapperRef.current!.firstElementChild as HTMLDivElement | null;
    if (firstElementChild && absX > firstElementChild.offsetWidth)
      return false;

    deltaX.current = x;
    const deltaInPercent = getPercentage(sliderRef.current!.offsetWidth, x) * 100;
    shiftSlides(slidesWrapperRef.current!, deltaInPercent - 100 * activeIndex + activeDelta);

    const nextIndex = deltaInPercent > 0
      ? activeIndex - 1
      : activeIndex + 1;

    subject.current.next(nextIndex);
    // return value is used in react-easy-swipe library for scrolling cancelling on touchmove event
    return true;
  }, [activeIndex, activeDelta]);

  const onSwipeEnd = useCallback(() => {
    isVerticalSwipe.current = false;
    slidesWrapperRef.current!.classList.remove(styles.sliding);
    const delta = getPercentage(sliderRef.current!.offsetWidth, deltaX.current);

    if (delta > SWIPE_THRESHOLD) {
      prev();
      return;
    }

    if (delta < -SWIPE_THRESHOLD) {
      next();
      return;
    }

    shiftSlides(slidesWrapperRef.current!, -100 * activeIndex + activeDelta);
  }, [activeIndex, activeDelta]);

  // Resets left scrolling on slider element when focus is set on elements inside slider item.
  const handleScroll = useCallback(() => sliderRef.current!.scrollLeft = 0, []);

  const slidersList = useMemo(() => {
    const emitter = subject.current
      .pipe(distinctUntilChanged());

    return items.map((item, index) => {
      const segmentIndex = Math.floor(index / tilesPerView);
      return (
        <div key={index}
          onFocus={() => {
            if (
              activeIndexRef.current === slidesAmount - 1
              && segmentIndex === activeIndexRef.current - 1
              && index >= items.length - tilesPerView
            ) {
              return;
            }

            setIndex(segmentIndex);
          }}
          role="presentation"
          className={styles.slide}
          style={{ width: `${100 / tilesPerView}%` }}
        >
          {cloneElement(item, { emitter })}
        </div>
      );
    });
  }, [items, tilesPerView]);

  const dotsList = useMemo(() => {
    const list = Array<ReactElement>(slidesAmount);
    for (let index = 0; index < slidesAmount; index++) {
      const Icon = index === activeIndex ? ListSelectedItemIcon : ListItemIcon;

      list.push((
        // dots are additional nav possibility under aria-hidden. Keyboard events should be listened on the wrapper
        /*eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions*/
        <li
          className={index === activeIndex ? styles.active : null}
          key={index}
          onClick={() => onNavigate(index)}
        >
          <Icon className={styles.icon} />
        </li>
      ));
    }

    return list;
  }, [activeIndex, slidesAmount]);

  const isFirstItemActive = activeIndex === 0;
  const isLastItemActive = activeIndex === slidesAmount - 1;

  const PrevBtnIcon = isFirstItemActive ? BigArrowLeftPassiveIcon : BigArrowLeftIcon;
  const NextBtnIcon = isLastItemActive ? BigArrowRightPassiveIcon : BigArrowRightIcon;

  return (
    <>
      <Swipe
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
        allowMouseEvents
        className={`${styles.swipeWrapper} swipe-wrapper`}
      >
        <div className={styles.slider} draggable="false" ref={sliderRef} onScroll={handleScroll}>
          <div
            className={`${styles.slides} ${noMouseSliding ? styles.noMouseSliding : ''}`}
            ref={slidesWrapperRef}
          >
            {slidersList}
          </div>
        </div>
      </Swipe>
      <div className={styles.navControls}>
        {showArrows && (
          <div className={styles.prev}>
            <button
              className={`${styles.navBtn} ${isFirstItemActive ? styles.disabled : ''}`}
              onClick={activeIndex > 0 ? prev : null}
              aria-disabled={isFirstItemActive}
              tabIndex={isFirstItemActive ? -1 : null}
            >
              <span className={styles.iconWrapper}>
                <PrevBtnIcon className={styles.icon} />
              </span>
            </button>
          </div>
        )}
        {showArrows && (
          <div className={styles.next}>
            <button
              className={`${styles.navBtn} ${isLastItemActive ? styles.disabled : ''}`}
              onClick={activeIndex < slidesAmount - 1 ? next : null}
              aria-disabled={isLastItemActive}
              tabIndex={isLastItemActive ? -1 : null}
            >
              <span className={styles.iconWrapper}>
                <NextBtnIcon className={styles.icon} />
              </span>
            </button>
          </div>
        )}
        <ul className={`${styles.dots} dots`} aria-hidden>
          {dotsList}
        </ul>
      </div>
    </>
  );
};

export default Slider;

function getPercentage(fullWidth: number, partWidth: number) {
  return partWidth / fullWidth;
}

function shiftSlides(wrapper: HTMLDivElement, slideWidth: number) {
  Object.assign(wrapper.style, styleTranslate(slideWidth, 0, '%'));
}

function styleTranslate(x: number, y: number, uom: string) {
  const translateX = x + uom;
  const translateY = y + uom;
  return {
    WebkitTransform: `translate(${translateX}, ${translateY})`,
    MozTransform: `translate(${translateX}, ${translateY})`,
    MsTransform: `translate(${translateX}, ${translateY})`,
    transform: `translate(${translateX}, ${translateY})`,
  };
}