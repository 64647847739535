import type { TeardownLogic } from 'rxjs';
import { Observable, concat } from 'rxjs';
import { tap } from 'rxjs/operators';

export default function concatToIfEmpty<S, T>(to$: Observable<T>) {
  return (source$: Observable<S>) => {
    let emited: true | undefined;
    return concat(
      tap<S>(() => emited = true)(source$),
      new Observable<T>((observer): TeardownLogic => {
        if (!emited)
          return to$.subscribe(observer);
        observer.complete();
      }),
    );
  };
}
