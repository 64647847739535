import { ForgotPasswordAction, FORGOT_PASSWORD_RECEIVED } from './actions';

type State = {
  forgotSucceededAt?: Date;
};

export default function forgotPasswordReducer(state: State, action: ForgotPasswordAction) {
  if (action.type === FORGOT_PASSWORD_RECEIVED) {
    return {
      ...state,
      forgotSucceededAt: Date.now(),
    };
  }
  return state;
}
