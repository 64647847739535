import type { WishListAction, UpdateWishListAction } from './actions';
import { createReducer } from 'utils/redux';
import { WISH_LIST_UPDATED } from './actions';

type State = {
  modifiedDate?: number;
};

export default createReducer<State, WishListAction>({}, {
  [WISH_LIST_UPDATED]: onWishListUpdated,
});

function onWishListUpdated(state: State, action: UpdateWishListAction) {
  const { modifiedDate } = action.payload;
  return {
    ...state,
    modifiedDate,
  };
}
