import PropTypes from 'prop-types';
import Search from './Search';
import { getBorderStyleCssValue } from 'components/theme';

const SearchBarBlock = ({ id, model, isDesignerMode }) => {
  const options = {
    placeholder: model.box_Placeholder,
    boxThemeFontSize: model.box_ThemeFontSize,
    buttonLayout: model.button_Layout,
    buttonThemeFontSize: model.button_ThemeFontSize,
    showThumbnails: model.suggestions_ShowThumbnails,
    suggestionsThemeFontSize: model.suggestions_ThemeFontSize,
  };

  const searchId = `Search_${id}`;

  const style = {
    '--box-placeholderFontColor': model.box_PlaceholderFontColor,
    '--box-borderStyle': getBorderStyleCssValue(model.box_BorderStyle),
    '--box-borderColor': model.box_BorderColor,
    '--box-borderWidth': model.box_BorderWidth,
    '--box-cornerRadius': model.box_CornerRadius,
    '--box-focusBorderColor': model.box_FocusBorderColor,
    '--box-fontFamily': model.box_FontFamily && model.box_FontFamily + ', sans-serif',
    '--box-fontColor': model.box_FontColor,
    '--box-backgroundColor': model.box_BackgroundColor,
    '--box-focusBackgroundColor': model.box_FocusBackgroundColor,
    '--button-fontFamily': model.button_FontFamily && model.button_FontFamily + ', sans-serif',
    '--button-fontColor': model.button_FontColor,
    '--button-backgroundColor': model.button_BackgroundColor,
    '--button-cornerRadius': model.button_CornerRadius,
    '--button-disabledBackgroundColor': model.button_DisabledBackgroundColor,
    '--button-hoverFontColor': model.button_HoverFontColor,
    '--button-hoverBackgroundColor': model.button_HoverBackgroundColor,
    '--suggestions-fontColor': model.suggestions_FontColor,
    '--suggestions-backgroundColor': model.suggestions_BackgroundColor,
    '--suggestions-borderColor': model.suggestions_BorderColor,
    '--suggestions-highlightedTextFontColor': model.suggestions_HighlightedTextFontColor,
    '--suggestions-itemHoverFontColor': model.suggestions_ItemHoverFontColor,
    '--suggestions-itemHoverBackgroundColor': model.suggestions_ItemHoverBackgroundColor,
  };

  return <Search id={searchId} isDesignerMode={isDesignerMode} options={options} style={style} />;
};

SearchBarBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    box_Placeholder: PropTypes.string,
    box_PlaceholderFontColor: PropTypes.string,
    box_BackgroundColor: PropTypes.string,
    box_FocusBackgroundColor: PropTypes.string,
    box_BorderColor: PropTypes.string,
    box_FocusBorderColor: PropTypes.string,
    box_BorderStyle: PropTypes.number.isRequired,
    box_BorderWidth: PropTypes.string.isRequired,
    box_CornerRadius: PropTypes.string.isRequired,
    box_FontFamily: PropTypes.string,
    box_ThemeFontSize: PropTypes.string.isRequired,
    box_FontColor: PropTypes.string,
    button_Layout: PropTypes.number.isRequired,
    button_FontFamily: PropTypes.string,
    button_ThemeFontSize: PropTypes.string.isRequired,
    button_FontColor: PropTypes.string,
    button_HoverFontColor: PropTypes.string,
    button_BackgroundColor: PropTypes.string,
    button_HoverBackgroundColor: PropTypes.string,
    button_DisabledBackgroundColor: PropTypes.string,
    button_CornerRadius: PropTypes.string.isRequired,
    suggestions_ShowThumbnails: PropTypes.bool.isRequired,
    suggestions_ThemeFontSize: PropTypes.string.isRequired,
    suggestions_FontColor: PropTypes.string,
    suggestions_BackgroundColor: PropTypes.string,
    suggestions_BorderColor: PropTypes.string,
    suggestions_ItemHoverFontColor: PropTypes.string,
    suggestions_ItemHoverBackgroundColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default SearchBarBlock;
