import type { DocumentType } from 'behavior/documents';
import { DocumentCollection, Filter, HeaderSorting } from './types';

type Options = Filter & {
  page: {
    index: number;
    size: number;
  };
};

export const DOCUMENTS_REQUESTED = 'DOCUMENTS_REQUESTED' as const;
export const requestDocuments = (options: Options, documentType: DocumentType) => ({
  type: DOCUMENTS_REQUESTED,
  payload: { options, documentType },
});

export const DOCUMENTS_RECEIVED = 'DOCUMENTS_RECEIVED' as const;
export const documentsReceived = (documents: DocumentCollection | null, page: number) => ({
  type: DOCUMENTS_RECEIVED,
  payload: { documents, page },
});

export const SORTING_CHANGED = 'SORTING_CHANGED' as const;
export const sortingChanged = (sorting: HeaderSorting) => ({
  type: SORTING_CHANGED,
  payload: { sorting },
});

export type SortingChangedAction = ReturnType<typeof sortingChanged>;
export type DocumentsReceivedAction = ReturnType<typeof documentsReceived>;
export type DocumentsAction = ReturnType<typeof requestDocuments> | DocumentsReceivedAction | SortingChangedAction;
