import { AbilityTo as AbilityToCore } from '../../webstore/behavior/user/constants';

export { CatalogSearchMode } from '../../webstore/behavior/products/product';

export const AbilityTo = Object.freeze({
  ViewCatalog: AbilityToCore.ViewCatalog,
  ViewPrices: AbilityToCore.ViewPrices,
  ViewStock: AbilityToCore.ViewStock,
  ViewUnitOfMeasure: AbilityToCore.ViewUnitOfMeasure,
  OrderProducts: AbilityToCore.OrderProducts,
});
