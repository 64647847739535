export const enum CaptchaTypes {
  Default = 'DEFAULT',
  Invisible = 'INVISIBLE',
}

export const enum CookieBarModes {
  Top = 'TOP',
  Bottom = 'BOTTOM',
  None = 'NONE',
}

export const enum TaxAmountPosition {
  Above = 'ABOVE',
  Below = 'BELOW',
}

export const enum TaxDisplayMode {
  Hide = 'DISABLED',
  Total = 'TOTAL',
  Detailed = 'DETAILED',
}

export const enum TaxFocusMode {
  Excl = 'TOTAL_EXCL_TAX',
  Incl = 'TOTAL_INCL_TAX',
}

export const enum CheckoutPresets {
  MultiStep = 'MULTI_STEP',
  OneStep = 'ONE_STEP',
}

export const enum StoreType {
  Closed = 'CLOSED_STORE',
  Private = 'PRIVATE_STORE',
  Public = 'PUBLIC_STORE',
}
