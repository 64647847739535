import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UserType } from 'behavior/user';

const AccountImpersonationMessage = loadable(() => import(/*webpackChunkName:"impersonation"*/'./AccountImpersonationMessage'));

const AccountImpersonationBar = ({ isPrintMode, userType }) => {
  if (isPrintMode || userType !== UserType.Admin)
    return null;

  return <AccountImpersonationMessage />;
};

AccountImpersonationBar.propTypes = {
  isPrintMode: PropTypes.bool,
  userType: PropTypes.string,
};

export default connect(s => ({ userType: s.user.type, isPrintMode: s.page.isPrintMode }))(AccountImpersonationBar);
