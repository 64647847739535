import { LimitedAccessAction, LIMITED_ACCESS_RECEIVED } from './actions';
import type { ContentSystemPage } from '../system';

export default (state: ContentSystemPage, action: LimitedAccessAction) => {
  switch (action.type) {
    case LIMITED_ACCESS_RECEIVED:
      const { page } = action.payload;

      return {
        ...state,
        limitedAccess: page,
      };
    default:
      return state;
  }
};
