import type { Epic } from 'behavior/types';
import { REGISTRATION_PROCESSED, templateFieldsReceived, RegistrationAction } from './actions';
import { exhaustMap, filter, map } from 'rxjs/operators';
import { loadEntityTemplateFieldsQuery } from './queries';
import { ofType } from 'redux-observable';

const registrationEpic: Epic<RegistrationAction> = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(REGISTRATION_PROCESSED),
    filter(action => ('invalidInput' in  action.payload) && action.payload.invalidInput),
    exhaustMap(_ => api.graphApi(loadEntityTemplateFieldsQuery).pipe(
      map(({ profile }) => templateFieldsReceived(profile.registrationFields)),
    )),
  );
};

export default registrationEpic;