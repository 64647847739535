import type { ToastType, Toast } from './types';

export const RETRY_FAILED_REQUESTS = 'APP/RETRY_FAILED_REQUESTS' as const;
export const retryFailedRequests = () => ({ type: RETRY_FAILED_REQUESTS });

export const SHOW_ERROR_TOAST = 'APP/SHOW_ERROR_TOAST' as const;
export const showErrorToast = (toast: Toast) => ({
  type: SHOW_ERROR_TOAST,
  payload: toast,
});

export const CLOSE_ERROR_TOAST = 'APP/CLOSE_ERROR_TOAST' as const;
export const closeErrorToast = (type: ToastType) => ({
  type: CLOSE_ERROR_TOAST,
  payload: { type },
});

export type ErrorAction = ReturnType<
  | typeof retryFailedRequests
  | typeof showErrorToast
  | typeof closeErrorToast
>;
