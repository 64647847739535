import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BodyTag as Gtm } from './googleTagManager';
import { BodyTag as Pwk } from './piwikPro';

const tagRenderers = [{
  type: 'GTM',
  Tag: Gtm,
}, {
  type: 'PWK',
  Tag: Pwk,
}];

const BodyTags = ({ trackers }) => {
  if (!trackers)
    return null;

  const tags = [];
  trackers.forEach(tracker => {
    const renderer = tagRenderers.find(r => r.type === tracker.type);
    if (renderer)
      tags.push({ tracker, Tag: renderer.Tag });
  });

  return tags.map(({ tracker, Tag }, key) => <Tag key={key} tracker={tracker} />);
};

BodyTags.propTypes = {
  trackers: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
  })),
};

export default connect(
  ({ analytics }) => ({ trackers: analytics?.trackers }),
)(BodyTags);