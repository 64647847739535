import type { Epic } from 'behavior/types';
import type { DocumentLines } from '../types';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { takeUntil, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { LOCATION_CHANGED } from 'behavior/events';
import { DOCUMENT_LINES_REQUESTED, DocumentAction, documentLinesReceived } from '../actions';
import { documentLinesQuery } from '../queries';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

const epic: Epic<DocumentAction> = (action$, _state$, dependencies) => {
  const { api, logger } = dependencies;
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const locationChanged$ = action$.pipe(
    ofType(LOCATION_CHANGED),
  );

  const loadLines$ = action$.pipe(
    ofType(DOCUMENT_LINES_REQUESTED),
    switchMap(({ payload: { id, documentType, orderId } }) =>
      api.graphApi<DocumentLinesResponse>(documentLinesQuery(documentType), { id, orderId }).pipe(
        mergeMap(({ documents }) =>
          of(
            documentLinesReceived(documents.doc.byId),
            unsetLoading),
        ),
        takeUntil(locationChanged$),
        retryWithToast(action$, logger),
        startWith(setLoading),
      ),
    ),
  );

  return loadLines$;
};

export default epic;

type DocumentLinesResponse = {
  documents: {
    doc: {
      byId: { canReorder: boolean } & DocumentLines;
    };
  };
};
