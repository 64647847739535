import styles from './ProductSuggestions.module.scss';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { LinkButton } from 'components/primitives/links';
import { withAbilitiesAndRouteChecker } from 'components/primitives/checkers';
import { AbilityTo } from 'behavior/user/constants';
import { RouteName } from 'routes';
import { CartSuggestionIcon } from 'components/primitives/icons';
import { useSimpleTexts } from 'components/sanaText';

const ProductSuggestionsBlock = ({ isDesignerMode }) => {
  const [getProductSuggestionsText] = useSimpleTexts(['ButtonAltText_GetProductSuggestions']).texts;
  const shoppingCartSuggestionPage = useSelector(({ settings }) => settings.shoppingCartSuggestionPage);

  if (shoppingCartSuggestionPage == null && !isDesignerMode)
    return null;

  return (
    <LinkButton
      title={getProductSuggestionsText}
      to={shoppingCartSuggestionPage?.to}
      url={shoppingCartSuggestionPage?.url}
      size="sm"
      theme="basic"
      className={styles.suggestionsBtn}
      noContentWrapper
      icon={false}
    >
      <CartSuggestionIcon className={styles.icon} aria-hidden />
    </LinkButton>
  );
};

ProductSuggestionsBlock.propTypes = {
  isDesignerMode: PropTypes.bool,
};

export default withAbilitiesAndRouteChecker(
  memo(ProductSuggestionsBlock),
  [RouteName.BasketPage, RouteName.Checkout],
  [AbilityTo.ViewProductSuggestions],
);
