import type { ClientRoute } from './types';

type Params = {
  id?: string;
  agreementLine?: string;
};

const salesAgreementRoute = (): ClientRoute => {
  const path = '/profile/sales-agreements/';

  return {
    getVirtualPath(params) {
      if (!params)
        paramsAreNotFound();

      const { id, agreementLine } = params as Params;

      if (!id)
        paramsAreNotFound();

      if(!agreementLine)
        return path + id;

      return `${path}${id}?agreement-line=${agreementLine}`;
    },
  };
};

function paramsAreNotFound(): never {
  throw new Error('Can\'t build URL for the page. Sales agreement ID was not specified.');
}

export default salesAgreementRoute;
