import type { Epic } from 'behavior/types';
import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import { mergeMap, startWith, pluck } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { PAYMENT_METHOD_EXTRA_DATA_REQUESTED, paymentMethodExtraDataReceived, InvoicePaymentAction } from '../actions';
import { paymentMethodExtraDataQuery } from '../queries';
import { skipIfPreview } from 'behavior/preview';

type PaymentMethodExtraDataResponse = {
  invoicePayment: {
    paymentMethods: {
      byId: {
        extraPaymentCheckoutStep: ExtraPaymentData | null;
        additionalCustomerDataStep: AdditionalCustomerData | null;
      };
    };
  };
};

const epic: Epic<InvoicePaymentAction> = (action$, state$, { api }) => action$.pipe(
  ofType(PAYMENT_METHOD_EXTRA_DATA_REQUESTED),
  skipIfPreview(state$),
  pluck('payload'),
  mergeMap(({ orderId, paymentMethodId }) => api.graphApi<PaymentMethodExtraDataResponse>(paymentMethodExtraDataQuery, { orderId, paymentMethodId }).pipe(
    pluck('invoicePayment', 'paymentMethods', 'byId'),
    mergeMap(({ additionalCustomerDataStep, extraPaymentCheckoutStep }) => of(
      paymentMethodExtraDataReceived(paymentMethodId, additionalCustomerDataStep, extraPaymentCheckoutStep),
      unsetLoadingIndicator(),
    )),
    startWith(setLoadingIndicator()),
  )),
);

export default epic;
