import { normalizeAddonId } from '../helpers';
import { MetadataActions, ADDONS_METADATA_LOADED } from './actions';
import type { AddonsMetadataState } from './types';

export default (state: AddonsMetadataState = null, action: MetadataActions): AddonsMetadataState => {
  if (action.type === ADDONS_METADATA_LOADED) {
    return action.payload.reduce((result, addon) => {
      result[normalizeAddonId(addon.id)] = addon.metadata;
      return result;
    }, { ...state });
  }

  return state;
};
