import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';
import { selectPropsFromPage } from '../helpers';

const ProductListWithCategoryPage = loadable(() => import(/*webpackChunkName:"plp"*/'./Page'));

export default {
  [PageComponentNames.ProductsWithCategory]: page => (
    <ProductListWithCategoryPage
      facets={page.facets}
      categoryName={page.categoryName}
      backgroundColor={page.backgroundColor}
      backgroundImage={page.backgroundImage}
      {...selectPropsFromPage(page)}
    />
  ),
};
