import { memo } from 'react';
import { renderHTML } from 'utils/render';
import { HeadingStyle } from './constants';
import Heading from './Heading';

type Model = {
  content?: string;
  headingStyle: HeadingStyle;
  color?: string;
  backgroundColor?: string;
};

type Props = {
  model: Model;
  createInlineHtmlEditor: (model: Model, editablePropName: string, params: { textOnlyMode: boolean }) => JSX.Element;
};

const HeadingBlock = ({ model, createInlineHtmlEditor }: Props) => {
  if (!model.content)
    return null;

  return (
    <Heading headingStyle={model.headingStyle} color={model.color} backgroundColor={model.backgroundColor}>
      {createInlineHtmlEditor
        ? createInlineHtmlEditor(model, 'content', { textOnlyMode: true })
        : renderHTML(model.content)}
    </Heading>
  );
};

export default memo(HeadingBlock);