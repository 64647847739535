import type { DocumentType } from 'behavior/documents';
import type {
  DocumentLines,
  QuoteStock,
  PromotionResult,
  AuthorizationActionInput,
  AuthorizationInfo,
  PromotionErrorResult,
} from './types';
import {
  EditResult,
  OrderAuthorizationAction,
  OrderAuthorizationActionResultDetailedStatus,
  ReorderResult,
  DownloadReportResult,
} from './constants';

export const DOCUMENT_LINES_REQUESTED = 'DOCUMENT/DOCUMENT_LINES_REQUESTED' as const;
export const requestDocumentLines = (id: string, documentType: DocumentType, orderId: string | null) => ({
  type: DOCUMENT_LINES_REQUESTED,
  payload: { id, documentType, orderId },
});

export const DOCUMENT_LINES_RECEIVED = 'DOCUMENT/DOCUMENT_LINES_RECEIVED' as const;
export const documentLinesReceived = (documentLines: DocumentLines) => ({
  type: DOCUMENT_LINES_RECEIVED,
  payload: { documentLines },
});

export const DOCUMENT_REORDER_REQUESTED = 'DOCUMENT/DOCUMENT_REORDER_REQUESTED' as const;
export const requestDocumentReorder = (id: string, orderId: string | null, forceBasketClean: boolean) => ({
  type: DOCUMENT_REORDER_REQUESTED,
  payload: { id, orderId, forceBasketClean },
});

export const DOCUMENT_REORDER_RESULT_RECEIVED = 'DOCUMENT/DOCUMENT_REORDER_RESULT_RECEIVED' as const;
export const documentReorderResultReceived = (result: ReorderResult | null) => ({
  type: DOCUMENT_REORDER_RESULT_RECEIVED,
  payload: { result },
});

export const QUOTE_STOCK_REQUESTED = 'DOCUMENT/QUOTE_STOCK_REQUESTED' as const;
export const requestQuoteStock = (id: string) => ({
  type: QUOTE_STOCK_REQUESTED,
  payload: { id },
});

export const QUOTE_STOCK_RECEIVED = 'DOCUMENT/QUOTE_STOCK_RECEIVED' as const;
export const quoteStockReceived = (quote: QuoteStock | undefined) => ({
  type: QUOTE_STOCK_RECEIVED,
  payload: { quote },
});

export const QUOTE_PROMOTION_REQUESTED = 'DOCUMENT/QUOTE_PROMOTION_REQUESTED' as const;
export const requestQuotePromotion = (id: string, ignoreCreditLimitWarnings: boolean) => ({
  type: QUOTE_PROMOTION_REQUESTED,
  payload: { id, ignoreCreditLimitWarnings },
});

export const QUOTE_PROMOTION_RESULT_RECEIVED = 'DOCUMENT/QUOTE_PROMOTION_RESULT_RECEIVED' as const;
export const quotePromotionResultReceived = (result: PromotionResult) => ({
  type: QUOTE_PROMOTION_RESULT_RECEIVED,
  payload: result,
});

export const SET_QUOTE_PROMOTION_ERROR = 'DOCUMENT/SET_QUOTE_PROMOTION_ERROR' as const;
export const setQuotePromotionError = (result: PromotionErrorResult) => ({
  type: SET_QUOTE_PROMOTION_ERROR,
  payload: result,
});

export const DOCUMENT_EDIT_REQUESTED = 'DOCUMENT/DOCUMENT_EDIT_REQUESTED' as const;
export const requestDocumentEdit = (id: string, forceBasketClean = false) => ({
  type: DOCUMENT_EDIT_REQUESTED,
  payload: { id, forceBasketClean },
});

export const DOCUMENT_EDIT_RESULT_RECEIVED = 'DOCUMENT/DOCUMENT_EDIT_RESULT_RECEIVED' as const;
export const documentEditResultReceived = (result: EditResult | null) => ({
  type: DOCUMENT_EDIT_RESULT_RECEIVED,
  payload: { result },
});

export const ORDER_AUTHORIZATION_ACTION_REQUESTED = 'DOCUMENT/ORDER_AUTHORIZATION_ACTION_REQUESTED' as const;
const requestOrderAuthorizationAction = (actionType: OrderAuthorizationAction, input: AuthorizationActionInput) => ({
  type: ORDER_AUTHORIZATION_ACTION_REQUESTED,
  payload: { actionType, input },
});

export const approveOrderAuthorization = (orderId: string) => requestOrderAuthorizationAction(OrderAuthorizationAction.Approve, { orderId });
export const declineOrderAuthorization = (orderId: string, feedbackMessage: string) => requestOrderAuthorizationAction(OrderAuthorizationAction.Decline, {
  orderId,
  feedbackMessage,
});
export const cancelOrderAuthorization = (orderId: string) => requestOrderAuthorizationAction(OrderAuthorizationAction.Cancel, { orderId });

export const ORDER_AUTHORIZATION_ACTION_RESULT_RECEIVED = 'DOCUMENT/ORDER_AUTHORIZATION_ACTION_RESULT_RECEIVED' as const;
export const orderAuthorizationActionResultReceived = (authorizationActionResultStatus: OrderAuthorizationActionResultDetailedStatus, authorizationInfo: AuthorizationInfo) => ({
  type: ORDER_AUTHORIZATION_ACTION_RESULT_RECEIVED,
  payload: { authorizationActionResultStatus, authorizationInfo },
});

export const DOCUMENT_DOWNLOAD_REPORT_RESULT_RECEIVED = 'DOCUMENT/DOCUMENT_DOWNLOAD_REPORT_RESULT_RECEIVED' as const;
export const documentDownloadReportResultReceived = (result?: DownloadReportResult) => ({
  type: DOCUMENT_DOWNLOAD_REPORT_RESULT_RECEIVED,
  payload: { result },
});

export type DocumentAction = ReturnType<
  | typeof requestDocumentLines
  | typeof documentLinesReceived
  | typeof requestDocumentReorder
  | typeof documentReorderResultReceived
  | typeof requestQuoteStock
  | typeof quoteStockReceived
  | typeof requestQuotePromotion
  | typeof quotePromotionResultReceived
  | typeof setQuotePromotionError
  | typeof requestDocumentEdit
  | typeof documentEditResultReceived
  | typeof requestOrderAuthorizationAction
  | typeof orderAuthorizationActionResultReceived
  | typeof documentDownloadReportResultReceived
>;
