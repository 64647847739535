exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Breadcrumb_container{display:flex}.Breadcrumb_container.Breadcrumb_no-back{display:none}.Breadcrumb_top-margin{margin-top:1em}.Breadcrumb_nav{display:none}.Breadcrumb_nav ul{align-items:flex-end;display:flex;font-size:var(--theme-text_SmallerSize,12px);line-height:1;list-style:none;margin:0;padding:0}.Breadcrumb_nav li .Breadcrumb_icon{color:inherit;margin:0 .06em;max-height:.2em;max-width:.2em}.Breadcrumb_nav li svg.Breadcrumb_icon{margin:0}.Breadcrumb_nav li a{color:var(--theme-breadcrumb_Color,grey)}html.pointer-mouse .Breadcrumb_nav li a:hover{color:var(--theme-breadcrumb_Color,grey)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Breadcrumb_nav li a:active{color:var(--theme-breadcrumb_Color,grey)}}}.Breadcrumb_nav li:first-of-type .Breadcrumb_icon{display:none}.Breadcrumb_nav li:last-of-type a{color:var(--theme-breadcrumb_LastItem_Color,#333)}html.pointer-mouse .Breadcrumb_nav li:last-of-type a:hover{color:var(--theme-breadcrumb_LastItem_Color,#333)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Breadcrumb_nav li:last-of-type a:active{color:var(--theme-breadcrumb_LastItem_Color,#333)}}}.Breadcrumb_back{align-items:center;display:flex}.Breadcrumb_back ins{display:none}.Breadcrumb_back a+ins{border-left:1px solid var(--theme-borderColor,#e6e6e6);margin-left:42px;padding-left:41px}@media (min-width:env(--theme-breakpoints_Medium,960)px),print{.Breadcrumb_container.Breadcrumb_no-back{display:flex}.Breadcrumb_top-margin{margin-top:1.8em}.Breadcrumb_nav{align-items:center;display:flex}.Breadcrumb_back ins{display:block;font-size:var(--theme-text_SmallerSize,12px);height:1.5em}}@media print{.Breadcrumb_cnt{display:none}}", ""]);

// exports
exports.locals = {
	"container": "Breadcrumb_container",
	"no-back": "Breadcrumb_no-back",
	"noBack": "Breadcrumb_no-back",
	"top-margin": "Breadcrumb_top-margin",
	"topMargin": "Breadcrumb_top-margin",
	"nav": "Breadcrumb_nav",
	"icon": "Breadcrumb_icon",
	"back": "Breadcrumb_back",
	"cnt": "Breadcrumb_cnt"
};