import type { Handler } from '../types';
import { contactUsPageQuery } from './queries';
import { PageComponentNames } from '../componentNames';
import { map } from 'rxjs/operators';
import { RouteName } from 'routes';
import type { EntityTemplateField } from 'behavior/entityTemplates';
import { ContentSystemPage, ContentSystemPageData, initContentSystemPage } from '../system';

const handler: Handler<ContactUsRouteData, ContactUsPage> = (_routeData, _state$, { api }) => api.graphApi<ContactUsPageResponse>(contactUsPageQuery).pipe(
  map(({ pages: { contactUs }, profile }) => ({
    page: {
      ...initContentSystemPage(contactUs),
      component: PageComponentNames.ContactUs,
      templateFields: profile.contactUsFields,
    },
  })),
);

export default handler;

type ContactUsRouteData = {
  routeName: RouteName.ContactUs;
};

type ContactUsPage = ContentSystemPage & {
  component: PageComponentNames.ContactUs;
  templateFields: EntityTemplateField[];
};

type ContactUsPageResponse = {
  pages: {
    contactUs: ContentSystemPageData;
  };
  profile: {
    contactUsFields: EntityTemplateField[];
  };
};
