import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

let VdPageWrapper;
let VdInsiteEditorWrapper;

const VdPageSwitcher = ({ children, isVisualDesigner, isInsiteEditor }) => {
  if (isVisualDesigner) {
    if (!VdPageWrapper)
      VdPageWrapper = loadable(() => import(/*webpackChunkName:"vd"*/'./page/VdPageWrapper'));

    return <VdPageWrapper>{children}</VdPageWrapper>;
  }
  else if (isInsiteEditor) {
    if (!VdInsiteEditorWrapper)
      VdInsiteEditorWrapper = loadable(() => import(/*webpackChunkName:"vd"*/'./insiteEditor/VdInsiteEditorWrapper'));

    return <VdInsiteEditorWrapper>{children}</VdInsiteEditorWrapper>;
  }

  return children;
};

VdPageSwitcher.propTypes = {
  children: PropTypes.node.isRequired,
  isVisualDesigner: PropTypes.bool.isRequired,
  isInsiteEditor: PropTypes.bool.isRequired,
};

export default connect(
  ({ visualDesigner, insiteEditor }) => ({
    isVisualDesigner: visualDesigner.initialized,
    isInsiteEditor: insiteEditor.initialized,
  }),
)(VdPageSwitcher);
