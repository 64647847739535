export const enum ViewMode {
  List = 'List',
  Grid = 'Grid',
}

export const enum DateFormat {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
}
