import styles from './Button.module.scss';
import { memo } from 'react';

export const ArrowRightIcon = memo(() => <span aria-hidden className={`${styles.icon} btn-icon`} />);
export const ArrowDownIcon = memo(() => <span aria-hidden className={`${styles.icon} ${styles.arrowDownIcon}`} />);
export const PlusIcon = memo(() => <span aria-hidden className={`${styles.icon} ${styles.plusIcon}`} />);
export const LoadingIcon = memo(() => (
  <span aria-hidden className={`${styles.icon} ${styles.loadingIcon}`}>
    <svg width="16" height="16" version="1.1" viewBox="0 0 4.233 4.233" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
      <g><circle cx="2.117" cy=".463" r=".463" /><circle cx="2.117" cy="3.77" r=".463" opacity=".52" /><circle cx=".463" cy="2.117" r=".463" opacity=".76" /><circle cx="3.77" cy="2.117" r=".463" opacity=".28" /><circle transform="rotate(45)" cx="1.34" cy="7.376e-8" r=".463" opacity=".88" /><circle transform="rotate(45)" cx="4.647" cy="7.376e-8" r=".463" opacity=".4" /><circle transform="rotate(45)" cx="2.993" cy="-1.654" r=".463" opacity=".16" /><circle transform="rotate(45)" cx="2.993" cy="1.654" r=".463" opacity=".64" /> </g>
    </svg>
  </span>
));
