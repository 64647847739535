import type { ImpersonationAction } from './actions';
import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { tap, mapTo, filter, switchMap } from 'rxjs/operators';
import { ADMIN_IMPERSONATION_STOP, notifyStopped } from './actions';
import { toolDisableMutation } from '../queries';

const ToolName = 'Impersonate';

const epic: Epic<ImpersonationAction> = (action$, _, { api, toolsStorage }) => {
  const close$ = action$.pipe(
    ofType(ADMIN_IMPERSONATION_STOP),
    filter(_ => toolsStorage.toolEnabled(ToolName)),
    switchMap(action => api.graphApi(toolDisableMutation, { name: ToolName }, { useCookies: true }).pipe(
      tap(_ => toolsStorage.disableTool(ToolName)),
      mapTo(notifyStopped(action.payload.isLogout)),
    )),
  );

  return close$;
};

export default epic;
