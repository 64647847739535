import type { FooterState } from './types';
import type { FooterAction } from './actions';
import { FOOTER_LOADED } from './actions';
import { parseContent } from 'behavior/content';

export const initialState: FooterState = {
  desktop: null,
  mobile: null,
  loaded: false,
};

export default (state: FooterState = initialState, action: FooterAction): FooterState => {
  switch (action.type) {
    case FOOTER_LOADED:
      return {
        desktop: action.payload?.desktop ? parseContent(action.payload.desktop) : null,
        mobile: action.payload?.mobile ? parseContent(action.payload.mobile) : null,
        loaded: true,
      };
    default:
      return state;
  }
};
