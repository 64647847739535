import { useProductViewContext } from '../hooks';

const whenOrderBoxVisible = Component => {
  return props => {
    const { showOrderBox } = useProductViewContext();

    return showOrderBox ? <Component {...props} /> : null;
  };
};

export default whenOrderBoxVisible;
