import type { ProductPageTemplate, PageTemplate, ProductGroupPageTemplate } from './types';

export const PRODUCT_PAGE_TEMPLATE_LOADED = 'PAGE_TEMPLATE/PRODUCT_LOADED' as const;
export const productPageTemplateLoaded = (page: ProductPageTemplate, languageId: number | null) => ({
  type: PRODUCT_PAGE_TEMPLATE_LOADED,
  payload: { page, languageId },
});

export const PRODUCT_GROUP_PAGE_TEMPLATE_LOADED = 'PAGE_TEMPLATE/PRODUCT_GROUP_LOADED' as const;
export const productGroupPageTemplateLoaded = (page: ProductGroupPageTemplate, languageId: number | null) => ({
    type: PRODUCT_GROUP_PAGE_TEMPLATE_LOADED,
    payload: { page, languageId },
});
export const BLOG_ITEM_PAGE_TEMPLATE_LOADED = 'PAGE_TEMPLATE/BLOG_ITEM_LOADED' as const;
export const blogItemPageTemplateLoaded = (page: PageTemplate, languageId: number | null) => ({
  type: BLOG_ITEM_PAGE_TEMPLATE_LOADED,
  payload: { page, languageId },
});

export type PageTemplateAction = ReturnType<
  | typeof productPageTemplateLoaded
  | typeof productGroupPageTemplateLoaded
  | typeof blogItemPageTemplateLoaded
>;
