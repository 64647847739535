import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { renderRows, extendDefaultSettings } from 'components/containers';
import UseStickyHeader from './UseStickyHeader';
import { desktop as defaultStatic } from './defaultContent';
import { tablet as defaultSticky } from './defaultContent';

const DesktopHeaderTemplate = ({ isSticky }) => {
  const { desktop, showDesktopSticky, desktopSticky } = useSelector(state => state.header);
  const showSticky = showDesktopSticky && isSticky;

  if (!showDesktopSticky)
    return renderRows(desktop ?? extendDefaultSettings(defaultStatic));

  return (
    <UseStickyHeader showSticky={showSticky}>
      {showSticky
        ? renderRows(desktopSticky ?? extendDefaultSettings(defaultSticky))
        : renderRows(desktop ?? extendDefaultSettings(defaultStatic))
      }
    </UseStickyHeader>
  );
};

DesktopHeaderTemplate.propTypes = {
  isSticky: PropTypes.bool,
};

export default memo(DesktopHeaderTemplate);
