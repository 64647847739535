import type { BlogSuggestion } from './types';

export const REQUEST_BLOG_SUGGESTIONS = 'BLOG/SUGGESTIONS/REQUESTED' as const;
export const requestBlogSuggestions = (keywords: string) => ({
  type: REQUEST_BLOG_SUGGESTIONS,
  payload: { keywords },
});

export const BLOG_SUGGESTIONS_REQUEST_COMPLETED = 'BLOG/SUGGESTIONS/REQUEST/COMPLETED' as const;
export const blogSuggestionsRequestCompleted = (suggestions: BlogSuggestion[]) => ({
  type: BLOG_SUGGESTIONS_REQUEST_COMPLETED,
  payload: suggestions,
});

export const BLOG_SUGGESTIONS_CLEARED = 'BLOG/SUGGESTIONS/CLEARED' as const;
export const blogSuggestionsCleared = () => ({
  type: BLOG_SUGGESTIONS_CLEARED,
});

export type BlogSuggestionsAction = ReturnType<
  | typeof requestBlogSuggestions
  | typeof blogSuggestionsRequestCompleted
  | typeof blogSuggestionsCleared
>;
