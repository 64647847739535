import proxies from './proxies';
import type { AddonFactory, AddonFactoryArg, AddonExports } from './types';

export default function addonCreatorFactory(
  libraries: Record<string, unknown>,
  sharedModules: Record<string, unknown | ((addonId: string) => unknown)>) {
  return (id: string, factory: AddonFactory): AddonExports | null => {
    try {
      const sana: AddonFactoryArg = {
        lib: getLibraries(libraries, id),
      };
      Object.entries(sharedModules).forEach(([key, value]) => {
        sana[key] = typeof (value) === 'function' ? value(id) : value;
      });

      return factory(sana);
    }
    catch (e) {
      console.debug(e);
      return null;
    }
  };
}

function getLibraries(libraries: Record<string, unknown>, addonId: string) {
  return Object.keys(libraries).reduce((acc, name) => {
    const proxy = proxies[name];

    acc[name] = proxy ? proxy(addonId) : libraries[name];
    return acc;
  }, {} as Record<string, unknown>);
}