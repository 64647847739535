import type { ProductConfiguratorState } from './types';
import { parse } from 'url';

export const isValidConfiguratorWindowMessage = (message: MessageEvent, configuratorState: ProductConfiguratorState) => {
  if (!configuratorState.productConfigurator)
    return false;
  const { configuratorUrl } = configuratorState.productConfigurator;
  const configuratorOrigin = configuratorUrl && getUrlOrigin(configuratorUrl);
  return message.origin === configuratorOrigin;
};

const getUrlOrigin = (configuratorUrl: string) => {
  if (!configuratorUrl)
    return '';

  const parsedAbsoluteUrl = parse(configuratorUrl);
  return parsedAbsoluteUrl.protocol + '//' + parsedAbsoluteUrl.host;
};

export const convertToString = (obj: any) => {
  if (typeof obj === 'string' )
    return obj;

  if (obj instanceof String)
    return obj.valueOf();

  return JSON.stringify(obj);
};