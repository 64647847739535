import type { Handler } from 'behavior/pages/types';
import type { Document, DocumentPage } from './types';
import type { AppState } from 'behavior';
import type { LoadedSettings } from 'behavior/settings';
import { PageComponentNames } from '../componentNames';
import { of } from 'rxjs';
import { map, mergeMap, first } from 'rxjs/operators';
import { DocumentType, getDocumentDetailsField } from 'behavior/documents';
import { createAbilityMiddleware } from '../middleware';
import { CustomerType } from 'behavior/user/constants';
import { getDocumentPageQuery } from './queries';
import { isQuoteInStock } from './helpers';
import { initContentSystemPage, loadContentSystemPageQuery, ContentSystemPage, ContentSystemPageData } from '../system';
import { RouteName } from 'routes';
import { getDocumentData } from './stubData';
import { DocumentAbilitiesMap } from './constants';

const pageComponentNamesPerDocumentType = {
  [DocumentType.Order]: PageComponentNames.Order as const,
  [DocumentType.Quote]: PageComponentNames.Quote as const,
  [DocumentType.Invoice]: PageComponentNames.Invoice as const,
  [DocumentType.ReturnOrder]: PageComponentNames.ReturnOrder as const,
  [DocumentType.CreditNote]: PageComponentNames.CreditNote as const,
  [DocumentType.ReturnReceipt]: PageComponentNames.ReturnReceipt as const,
  [DocumentType.Shipment]: PageComponentNames.Shipment as const,
};

const handler: Handler<DocumentRouteData, DocumentPage> = (routeData, state$, dependencies) => {
  const { params: { id, documentType, originalOrderId: orderId, previewToken } } = routeData;
  const { api } = dependencies;
  const createPageResult: PageResultCreator = (page, document) => ({
    page: {
      ...page,
      component: pageComponentNamesPerDocumentType[documentType],
      document,
    },
  });

  if (previewToken)
    return api.graphApi<DocumentPreviewPageResponse>(loadContentSystemPageQuery(`page:${getDocumentDetailsField(documentType)}`)).pipe(
      map(({ pages: { page } }) => createPageResult(initContentSystemPage(page), getDocumentData(documentType))),
    );

  const ability = DocumentAbilitiesMap[documentType];
  const abilityMiddleware = createAbilityMiddleware<DocumentRouteData, DocumentPage>(ability);

  const loadDocumentPage = (state: AppState) => {
    const {
      tax: { mode: taxMode },
      checkout: { maxOverviewLines },
    } = state.settings as LoadedSettings;

    const query = getDocumentPageQuery(documentType, state.user.customerType !== CustomerType.B2C, taxMode);

    return api.graphApi<DocumentPageResponse>(query, {
      id,
      linesLimit: maxOverviewLines,
      orderId,
    }).pipe(
      mergeMap(({ pages: { page }, documents: { doc: { byId } } }) => {
        if (!byId)
          return of(null);

        const initializedPage = initContentSystemPage(page);
        const document = {
          ...byId,
          documentType,
        } as Document;

        if (document.documentType === DocumentType.Quote)
          return of(createPageResult(initializedPage, { ...document, hasStock: isQuoteInStock(document) }));

        return of(createPageResult(initializedPage, document));
      }),
    );
  };

  const next = () => {
    if (state$.value.settings.loaded)
      return loadDocumentPage(state$.value);

    return state$.pipe(
      first(s => s.settings.loaded),
      mergeMap(loadDocumentPage),
    );
  };

  return abilityMiddleware(next, routeData, state$, dependencies);
};

export default handler;

type DocumentPreviewPageResponse = {
  pages: {
    page: ContentSystemPageData;
  };
};

type DocumentRouteData = {
  routeName: RouteName.DocumentDetails;
  params: {
    id: string;
    documentType: DocumentType;
    originalOrderId: string;
    orderId: string;
    previewToken?: string;
  };
};

type PageResultCreator = (page: ContentSystemPage, document: Document) => { page: DocumentPage };

type DocumentPageResponse = {
  pages: {
    page: ContentSystemPageData;
  };
  documents: {
    doc: { byId: Omit<Document, 'documentType'> | null };
  };
};
