import { createLocation as createHistoryLocation } from 'history';
import type { Location } from './types';

export function areLocationsEqual(l1: Readonly<Location> | null | undefined, l2: Readonly<Location> | null | undefined, ignoreHash = false): boolean {
  return l1 === l2
    || !!l1 && !!l2 && l1.pathname === l2.pathname && l1.search === l2.search && (ignoreHash || l1.hash === l2.hash);
}

export function createLocation<T extends Location>(url: string): T {
  const { pathname, search, hash } = createHistoryLocation(url);
  return { pathname, search, hash } as T;
}

export function createUrl(location: Readonly<Location>): string {
  const { pathname, search, hash } = location;
  return pathname + search + hash;
}
