import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { ContentSystemPage, initContentSystemPage, loadContentSystemPageQuery } from './content';
import { Handler } from '../types';
import { RouteName } from 'routes';
import { ContentSystemPageData } from '../system';
import { BackTo } from 'routes/types';

type FallbackPageResponse = {
  pages: {
    notFound: ContentSystemPageData;
  };
};

type FallbackRouteData = {
  routeName: RouteName.NotFound;
  options?: {
    backTo: BackTo;
  };
};

export type FallbackPage = ContentSystemPage & {
  component: PageComponentNames.NotFound;
  index: boolean;
  backTo: BackTo | undefined;
};

const handler: Handler<FallbackRouteData, FallbackPage> = (routeData, _state$, { api }) =>
  api.graphApi<FallbackPageResponse>(loadContentSystemPageQuery('notFound')).pipe(
    map(({ pages }) => {
      const result = {
        page: initContentSystemPage(pages.notFound) as FallbackPage,
        statusCode: 404,
        routeData,
      };

      result.page.component = PageComponentNames.NotFound;
      result.page.index = false;
      result.page.backTo = routeData.options ? routeData.options.backTo : undefined;

      return result;
    }),
  );

export default handler;
