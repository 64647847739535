import { PageComponentNames } from '../componentNames';
import { ChangePasswordAction, PASSWORD_CHANGED } from './actions';

type State = {
  passwordChanged: number;
  component: PageComponentNames.ChangePassword;
};

export default function (state: State, action: ChangePasswordAction): State {
  if (action.type !== PASSWORD_CHANGED)
    return state;

  return {
    ...state,
    passwordChanged: Date.now(),
  };
}
