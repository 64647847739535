import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { map, mergeMap, pluck, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import {
  changeOrderGarageVehiclesReceived,
  CHANGE_ORDER_GARAGE_VEHICLES,
  editOrCreateMyGarageVehicleReceived,
  EDIT_0R_CREATE_MY_GARAGE_VEHICLE,
  getMyGarageVehiclesReceived,
  GET_MY_GARAGE_VEHICLES,
} from './actions';
import {
  changeOrderVehiclesMutation,
  editOrCreateMyGarageVehicleMutation,
  getMyGarageVehiclesQuery,
} from './queries';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';

export default (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.ofType(LOCATION_CHANGED);
  
  const editOrCreateMyGarageVehicle$ = action$.pipe(
    ofType(EDIT_0R_CREATE_MY_GARAGE_VEHICLE),
    pluck('payload'),
    switchMap(({ input, files }) => 
      api
        .graphApi(
          editOrCreateMyGarageVehicleMutation,
          { input },
          { files, retries: 0 },
        )
        .pipe(
          mergeMap(data => [
            editOrCreateMyGarageVehicleReceived(data),
            unsetLoadingIndicator(),
          ]),
          catchApiErrorWithToast(['OUT_OF_LENGTH'], of(unsetLoadingIndicator())),
          retryWithToast(action$, logger),
          takeUntil(locationChanged$),
          startWith(setLoadingIndicator()),
        ),
    ),
  );

  const getMyGarageVehicles$ = action$.pipe(
    ofType(GET_MY_GARAGE_VEHICLES),
    switchMap(() =>
      api.graphApi(getMyGarageVehiclesQuery).pipe(
        map(data => {
          return getMyGarageVehiclesReceived(data);
        }),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      ),
    ),
  );

  const changeOrderMyGarageVehiclesMutation$ = action$.pipe(
    ofType(CHANGE_ORDER_GARAGE_VEHICLES),
    pluck('payload'),
    switchMap(({ input }) =>
      api.graphApi(changeOrderVehiclesMutation, { input }).pipe(
        mergeMap(data => [
          changeOrderGarageVehiclesReceived(data),
          unsetLoadingIndicator(),
        ]),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  return merge(editOrCreateMyGarageVehicle$, getMyGarageVehicles$, changeOrderMyGarageVehiclesMutation$);
};
