import type { SanaTextsInput } from '../types';

export const VISUAL_DESIGNER_SANA_TEXT_CHANGED = 'VD/SANA_TEXT_CHANGED' as const;
export const changeSanaText = (key: string, value: string) => ({
  type: VISUAL_DESIGNER_SANA_TEXT_CHANGED,
  payload: { key, value },
});

export const VISUAL_DESIGNER_UPDATE_SANA_TEXTS = 'VD/UPDATE_SANA_TEXTS' as const;
export const updateSanaTexts = (sanaTextsInput: SanaTextsInput) => ({
  type: VISUAL_DESIGNER_UPDATE_SANA_TEXTS,
  payload: sanaTextsInput,
});

export type SanaTextAction = ReturnType<typeof updateSanaTexts>;
