import { useSimpleTexts } from 'components/sanaText';
import { useMemo } from 'react';
import { useShopName } from 'utils/hooks';

export default function useDocumentTitle(title: string | null) {
  const shouldMakeTitle = title != null;
  const shopName = useShopName();
  const { texts: [separatorText], loaded } = useSimpleTexts([shouldMakeTitle && 'BrowserTitleSeparator']);

  return useMemo(() => {
    if (!shouldMakeTitle || !loaded)
      return null;

    if (!title)
      return { value: shopName };

    if (shopName)
      return { value: `${title} ${separatorText} ${shopName}` };

    return { value: title };
  }, [title, separatorText, shopName]);
}
