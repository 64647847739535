import { EVENT_ACTION_PREFIX, isWrappedAddonAction } from './actions';
import type { Action, Dispatch, Store } from 'redux';

export default function createMiddleware() {
  return (_store: Store) => (next: Dispatch) => (action: Action) => {
    if (isWrappedAddonAction(action)) {
      const eventAction = action.payload.action;
      if (eventAction.type && eventAction.type.startsWith(EVENT_ACTION_PREFIX)) {
        return next(eventAction);
      }
    }

    return next(action);
  };
}
