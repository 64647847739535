import { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'rxjs/operators';
import { useLoadEffect } from '../hooks';
import AddonContext from './AddonContext';
import { requestAddon } from './actions';
import { normalizeAddonId } from './helpers';
import type { StateWithAddons, AddonExports } from './types';
import { useOnChange } from 'utils/hooks';

export function useAddon(id: string) {
  const addonId = useMemo(() => normalizeAddonId(id), [id]);
  const { registry } = useContext(AddonContext);
  const dispatch = useDispatch();
  const [, setState] = useState<any>();

  const metadata = useSelector((state: StateWithAddons) => {
    return state.addons.metadata ? state.addons.metadata[addonId] : undefined;
  });
  const hasBundle = !!(metadata && metadata.bundle);
  const hash = hasBundle ? (metadata!.bundle.hash || null) : undefined;
  const bundleRef = useRef<AddonExports | null | undefined>();

  useOnChange(() => {
    bundleRef.current = hasBundle ? registry.get(addonId, hash) : (metadata ? null : undefined);
  }, [addonId]);

  useLoadEffect(() => {
    if (hasBundle && bundleRef.current === undefined)
      dispatch(requestAddon(addonId, hash!));
  }, [addonId, hash, hasBundle]);

  useEffect(() => {
    if (!hasBundle)
      return;

    const latestBundle = registry.get(addonId, hash);
    if (latestBundle !== bundleRef.current) {
      bundleRef.current = latestBundle;
      setState({});
    }

    const subscription = registry.updates.pipe(
      filter(i => i.id === addonId),
    ).subscribe(e => {
      bundleRef.current = e.addon;
      setState({});
    });

    return () => subscription.unsubscribe();
  }, [addonId, registry, hasBundle]);

  return {
    exports: bundleRef.current,
    metadata,
  };
}
