import styles from './Basket.module.scss';
import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SimpleText, RichText } from 'components/sanaText';
import { WarningAlert } from 'components/primitives/alerts';
import { selectWarehouse } from 'behavior/user';
import { Radio } from 'components/primitives/form';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { reloadBasketPage } from 'behavior/basket';

const WarehouseSelector = () => {
  const dispatch = useDispatch();
  const { warehouses, isAuthenticated } = useSelector(state => state.user);

  const handleChange = e => {
    dispatch(selectWarehouse(e.target.value));
    setTimeout(() => dispatch(reloadBasketPage()), 1000);
  };
  
  if(!warehouses || ! isAuthenticated)
    return null;

  const primaryWarehouseId = warehouses?.find(warehouse => warehouse.isPrimary)?.id;
  const selectedWarehouseId = warehouses?.find(warehouse => warehouse.isSelected)?.id;
  const isPrimaryWarehouse = primaryWarehouseId && selectedWarehouseId && primaryWarehouseId === selectedWarehouseId;
  warehouses.sort((a, b) => {
    return (a.isPrimary === b.isPrimary) ? 0 : a.isPrimary ? -1 : 1;
  });

  const { texts: [primaryWarehouseNote, primaryWarehouseName], loaded } = useSanaTexts(['ShoppingBasket_PrimaryWarehouse_Note', `Warehouse_Name_${primaryWarehouseId}`]);

  const warehousesList = warehouses.map((warehouse, index) => {
    return (
      <div key={index}>
        <Radio value={warehouse.id}
          name="warehouse"
          checked={selectedWarehouseId === warehouse.id}
          onChange={handleChange}
          className={styles.radio}
        >
          <RichText textKey={`Warehouse_Name_${warehouse.id}`} />
        </Radio>
      </div>
    );
  });

  return (
    <div>
      <h2 className={styles.title}>
        <SimpleText textKey="ShoppingBasket_WarehouseSelector_Header" />
      </h2>
        <div>{warehousesList}</div>
        {loaded && makeRichText(primaryWarehouseNote.replace('[PRIMARYWAREHOUSE]', primaryWarehouseName))}
        {!isPrimaryWarehouse && (
          <>
            <RichText textKey="ShoppingBasket_SecondaryWarehouse_Warning" />
            <WarningAlert><RichText textKey="ShoppingBasket_BackorderNotAllowed_Warning" /></WarningAlert>
          </>
        )}
    </div>
  );
};

WarehouseSelector.propTypes = {
};

export default memo(WarehouseSelector);
