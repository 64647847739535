exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FullScreenPopup_popup{background:var(--theme-popup_BackgroundColor,#fff);border:1px solid var(--theme-popup_BorderColor,#e6e6e6);bottom:0;height:auto;outline:0;padding:0}.FullScreenPopup_close{background:none;border:0;cursor:pointer;padding:3px 4px;position:absolute;right:0;top:0}.FullScreenPopup_close img{max-height:30px;max-width:30px;vertical-align:middle}.FullScreenPopup_close svg{fill:var(--theme-lightestText_Color,#b2b2b2);height:30px;vertical-align:middle;width:30px}html.pointer-mouse .FullScreenPopup_close svg:hover{fill:var(--theme-text_Color,grey)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .FullScreenPopup_close svg:active{fill:var(--theme-text_Color,grey)}}}@media not all and (min-resolution:.001dpcm){@media (pointer:coarse){.FullScreenPopup_close svg:hover{-webkit-tap-highlight-color:var(--theme-text_Color,#808080)}}}", ""]);

// exports
exports.locals = {
	"popup": "FullScreenPopup_popup",
	"close": "FullScreenPopup_close"
};