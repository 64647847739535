import styles from './Summary.module.scss';
import { useEffect, useState, useRef, useMemo } from 'react';
import { joinClasses } from 'utils/helpers';
import { AbilityState } from 'behavior/user/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHeaderContext } from 'components/sections/headerContext';
import { requestBasketSummary } from 'behavior/basket';
import LoadingIndicator from './LoadingIndicator';
import Popup from './Popup';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import PreloadTexts from './PreloadTexts';
import withAbilitiesAndRouteChecker from './withAbilitiesAndRouteChecker';
import { throttle } from 'lodash';
import { getThemeFontSizeClassName } from 'components/theme';

const Summary = ({
  basket,
  updatingBy,
  updatedLinesAmount,
  requestBasketSummary,
  abilities,
  id,
  isDesignerMode,
  options,
  style,
  headerStyle,
}) => {
  const headerContext = useHeaderContext();
  const [dropdownOptions, setDropdownOptions] = useState({ show: false });
  const { canViewPrices, popupAbilities } = useMemo(() => {
    const [viewPriceAbility, ...popupAbilities] = abilities;
    return { canViewPrices: viewPriceAbility === AbilityState.Available, popupAbilities };
  }, [abilities]);
  const [linesAmount, setLinesAmount] = useState(null);
  const timeoutId = useRef();
  const showTotals = options.showSubtotal && canViewPrices;
  const [basketSummary, setBasketSummary] = useState(basket);
  const throttledSetBasketSummary = useThrottle(setBasketSummary, 300);
  const throttledRequestBasketSummary = useThrottle(requestBasketSummary, 750);
  const popupHovered = useRef();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current)
      return;

    throttledSetBasketSummary(basket);
  }, [basket]);

  useEffect(() => {
    const basketNotExpired = basketSummary && !basketSummary.expired;

    if (basketNotExpired && (basketSummary?.calculated || !basketSummary?.calculated && !showTotals))
      return;

    if (basketSummary && basketSummary.expired && basketSummary.loaded && !basketSummary.loading)
      throttledRequestBasketSummary(!!showTotals);
    else
      requestBasketSummary(!!showTotals);
  }, [basketSummary, showTotals]);

  useEffect(() => {
    if (isFirstRender.current && (headerContext.available && !headerContext.isSummaryOpened))
      return;

    if (!basketSummary || (basketSummary && basketSummary.expired))
      return;

    const { productLines } = basketSummary;
    if (!updatedLinesAmount || !productLines || productLines.list.length === 0) {
      clearTimeout(timeoutId.current);
      setDropdownOptions({ show: false });
      return;
    }

    setLinesAmount(basketSummary.loaded && !popupHovered.current ? updatedLinesAmount : null);
    setDropdownOptions({ show: true });

    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      basketSummary.loaded && setDropdownOptions({ show: false });
      setLinesAmount(null);
    }, 2000);
  }, [basketSummary && basketSummary.loaded, updatedLinesAmount]);

  useEffect(() => {
    if (isFirstRender.current && !headerContext.isSummaryOpened)
      return;

    if (basketSummary && basketSummary.loading)
      setDropdownOptions({ show: true });
  }, [basketSummary && basketSummary.loading, updatingBy]);

  useEffect(() => {
    if (isFirstRender.current)
      return;

    if (basketSummary && basketSummary.totalCount)
      return;

    setDropdownOptions({ show: false });
  }, [basketSummary && !!basketSummary.totalCount]);

  useEffect(() => {
    headerContext.available && (headerContext.isSummaryOpened = dropdownOptions.show);
  }, [dropdownOptions]);

  useEffect(() => {
    isFirstRender.current = false;

    return () => {
      throttledSetBasketSummary.cancel();
      clearTimeout(timeoutId.current);
    };
  }, []);

  if (!basketSummary)
    return null;

  const { totalCount, loading, isAvailable } = basketSummary;

  let onHover = null;
  let onDropDownHide = null;

  if (!options.compact) {
    onHover = () => {
      clearTimeout(timeoutId.current);
      setLinesAmount(null);
      popupHovered.current = true;
    };
    onDropDownHide = () => {
      clearTimeout(timeoutId.current);
      setLinesAmount(null);
      popupHovered.current = false;
    };
  }

  return (
    <div
      id={id}
      className={joinClasses(
        styles.summary,
        !totalCount && styles.isEmpty,
        options.compact && styles.compact,
      )}
      onMouseOver={onHover}
      onFocus={onHover}
      role="presentation"
    >
      {options.compact
        ? (
          <>
            <Header
              totalCount={totalCount}
              showFixed={headerContext.available && dropdownOptions.show && !loading}
              options={options}
              style={headerStyle}
            />
            {loading && <LoadingIndicator />}
          </>
        )
        : (
          <Dropdown
            bodyAlign="right"
            options={dropdownOptions}
            className={joinClasses(
              styles.dropdown,
              !totalCount && styles.isEmpty,
              getThemeFontSizeClassName(options.contentThemeFontSize),
            )}
            header={<Header totalCount={totalCount} options={options} />}
            onDropDownHide={onDropDownHide}
            hideCaretDown={!options.showExpandIcon || !totalCount}
            disableAnimation
            style={style}
          >
            {!isDesignerMode && (
              <>
                {loading && <LoadingIndicator />}
                {!loading && isAvailable && totalCount > 0 && (
                  <Popup
                    linesAmount={linesAmount}
                    basket={basketSummary}
                    showTotals={showTotals}
                    abilities={popupAbilities}
                    options={options}
                  />
                )}
              </>
            )}
          </Dropdown>
        )
      }
      <PreloadTexts />
    </div>
  );
};

Summary.propTypes = {
  basket: PropTypes.shape({
    totalCount: PropTypes.number,
    expired: PropTypes.bool,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    isAvailable: PropTypes.bool,
  }),
  updatingBy: PropTypes.string,
  updatedLinesAmount: PropTypes.number,
  requestBasketSummary: PropTypes.func.isRequired,
  abilities: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  isDesignerMode: PropTypes.bool,
  options: PropTypes.shape({
    compact: PropTypes.bool.isRequired,
    showExpandIcon: PropTypes.bool,
    contentThemeFontSize: PropTypes.string,
    showSubtotal: PropTypes.bool,
  }).isRequired,
  style: PropTypes.object,
  headerStyle: PropTypes.object,
};

export default connect(({ basket: { summary, updatingBy, updated } }) => ({
  basket: summary,
  updatingBy,
  updatedLinesAmount: updated.linesAmount,
}), { requestBasketSummary })(withAbilitiesAndRouteChecker(Summary));

function useThrottle(func, wait) {
  const throttleRef = useRef();

  if (throttleRef.current == null)
    throttleRef.current = throttle(func, wait);

  return throttleRef.current;
}
