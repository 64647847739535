import { FormName } from './formNames';

export const UNLOCK_FORM_SUBMIT = 'UNLOCK_FORM_SUBMIT' as const;
export const unlockForm = (formKey: FormName) => ({
  type: UNLOCK_FORM_SUBMIT,
  payload: formKey,
});

type HttpPostValue = {
  key: string;
  value: string | null;
};

type PostAction = {
  url: string;
  values: HttpPostValue[];
};

export const POST_FORM = 'POST_FORM' as const;
export const postForm = (options: PostAction) => ({
  type: POST_FORM,
  payload: options,
});

export type PagesAction = ReturnType<
  | typeof unlockForm
  | typeof postForm
>;
