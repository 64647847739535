import type { Epic } from 'behavior/types';
import type { FailureOrderPaymentReason } from './types';
import { mergeMap, startWith, catchError, pluck, exhaustMap } from 'rxjs/operators';
import { of, EMPTY, merge } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { postForm } from 'behavior/pages';
import { toasts } from 'behavior/toasts';
import { renderHTML } from 'utils/render';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import {
  PAY_ORDER,
  PAYMENT_METHOD_EXTRA_DATA_REQUESTED,
  receivePayOrderResult,
  receivePaymentMethodExtraData,
  OrderPaymentAction,
} from './actions';
import { submitOrderMutation, paymentMethodExtraDataQuery } from './queries';
import { skipIfPreview, skipIfPreviewWithToast } from 'behavior/preview';
import { PaymentInput } from './types';
import { AdditionalCustomerData, ExtraPaymentData } from '../checkout';

const epic: Epic<OrderPaymentAction> = (action$, state$, deps) => {
  const payOrder$ = action$.pipe(
    ofType(PAY_ORDER),
    skipIfPreviewWithToast(state$, deps),
    pluck('payload'),
    exhaustMap(({ transactionId, paymentInput, additionalCustomerData, extraPaymentData }) => {
      const { api, logger } = deps;

      return api.graphApi<SubmitOrderResponse>(submitOrderMutation,
        getSubmitParams(transactionId, paymentInput, additionalCustomerData, extraPaymentData), { retries: 0 })
        .pipe(
          mergeMap(({ orderPayment }) => {
            if (!orderPayment)
              return of(receivePayOrderResult({ error: true }), unsetLoadingIndicator());

            return handleOrderPaymentResult(orderPayment.submit);
          }),
          catchError(error => {
            logger.error(error);

            return of(receivePayOrderResult({ error: true }), unsetLoadingIndicator());
          }),
          startWith(setLoadingIndicator()),
        );
    },
    ),
  );

  const paymentMethodExtraData$ = action$.pipe(
    ofType(PAYMENT_METHOD_EXTRA_DATA_REQUESTED),
    skipIfPreview(state$),
    pluck('payload'),
    mergeMap(({ transactionId, paymentMethodId }) => deps.api.graphApi(paymentMethodExtraDataQuery, { transactionId, paymentMethodId }).pipe(
      pluck('orderPayment', 'paymentMethods', 'byId'),
      mergeMap(({ additionalCustomerDataStep, extraPaymentCheckoutStep }) => of(receivePaymentMethodExtraData(paymentMethodId, additionalCustomerDataStep, extraPaymentCheckoutStep), unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );

  return merge(payOrder$, paymentMethodExtraData$);
};

export default epic;

type SubmitParams = {
  transactionId: string;
  additionalCustomerData?: { values: AdditionalCustomerData };
  extraPaymentData?: { values: ExtraPaymentData };
} & PaymentInput;

function getSubmitParams(transactionId: string, paymentInput: PaymentInput, additionalCustomerData?: AdditionalCustomerData, extraPaymentData?: ExtraPaymentData) {
  const input: SubmitParams = { transactionId, ...paymentInput };

  if (additionalCustomerData)
    input.additionalCustomerData = { values: additionalCustomerData };

  if (extraPaymentData)
    input.extraPaymentData = { values: extraPaymentData };

  return { input };
}

function handleOrderPaymentResult(result: OrderPaymentSubmit) {
  if (result.type === 'SuccessOrderPaymentResult')
    return handleSuccessOrderPaymentResult(result);

  return handleFailureOrderPaymentActionResult(result);
}

function handleSuccessOrderPaymentResult(result: SuccessOrderPaymentResult) {
  const { transaction, nextAction } = result;

  if (nextAction)
    return handleNextAction(nextAction);

  if (transaction.cancelled)
    return of(navigateTo(routesBuilder.forPaymentCancelled(transaction.id)));

  if (transaction.failed)
    return of(navigateTo(routesBuilder.forPaymentFailed(transaction.id)));

  if (transaction.isPaymentError)
    return of(navigateTo(routesBuilder.forPaymentError(transaction.id)));

  return of(navigateTo(routesBuilder.forPaymentSubmit(transaction.id)));
}

function handleFailureOrderPaymentActionResult(result: FailureOrderPaymentActionResult) {
  const { reason, orderId } = result;

  return of(receivePayOrderResult({ error: { reason, orderId } }), unsetLoadingIndicator());
}

function handleNextAction(nextAction: NextAction) {
  if (nextAction.type === 'RedirectAction')
    return handleRedirectAction(nextAction);

  return handlePostAction(nextAction);
}

function handlePostAction({ type: _type, ...postAction }: PostAction) {
  return of(postForm(postAction));
}

function handleRedirectAction({ message, isHtmlMessage, url }: RedirectAction) {
  message && toasts.info(isHtmlMessage ? renderHTML(message) : message, { autoDismiss: false });
  window.location.href = url;

  return EMPTY;
}

type RedirectAction = {
  type: 'RedirectAction';
  isHtmlMessage: boolean;
  message: string | null;
  url: string;
};

type PostAction = {
  type: 'PostAction';
  url: string;
  values: {
    key: string;
    value: string | null;
  }[];
};

type NextAction = RedirectAction | PostAction;

type SuccessOrderPaymentResult = {
  type: 'SuccessOrderPaymentResult';
  transaction: {
    id: string;
    cancelled: boolean;
    failed: boolean;
    isPaymentError: boolean;
  };
  nextAction: NextAction;
};

type FailureOrderPaymentResult = {
  type: 'FailureOrderPaymentActionResult';
  reason: FailureOrderPaymentReason;
  orderId: string;

};

type OrderPaymentSubmit = SuccessOrderPaymentResult | FailureOrderPaymentResult;

type FailureOrderPaymentActionResult = {
  reason: FailureOrderPaymentReason;
  orderId: string;
};

type SubmitOrderResponse = {
  orderPayment: {
    submit: OrderPaymentSubmit;
  };
};
