import { PageComponentNames } from '../componentNames';
import { BlogItemPageAction, BLOG_COMMENTS_RECEIVED, BLOG_COMMENT_PROCESSED } from './actions';
import type { BlogItem } from './types';

type State = {
  component: PageComponentNames.BlogItemPage;
  blogItem: BlogItem;
};

export default (state = null as unknown as State, action: BlogItemPageAction) => {
  switch (action.type) {
    case BLOG_COMMENTS_RECEIVED:
      return {
        ...state,
        blogItem: {
          ...state.blogItem,
          comments: {
            ...state.blogItem.comments,
            items: state.blogItem.comments.items.concat(action.payload),
          },
        },
      };
    case BLOG_COMMENT_PROCESSED:
      return {
        ...state,
        blogItem: {
          ...state.blogItem,
          comments: {
            ...state.blogItem.comments,
            saved: Date.now(),
          },
        },
      };
    default:
      return state;
  }
};
