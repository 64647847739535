import styles from './WarehouseSelector.module.scss';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'components/primitives/dropdown';
import { useSelector } from 'react-redux';
import IconWithText from './iconWithText';
import WarehouseSelectorItems from './warehouseSelectorItems';
import { useOnChange } from 'utils/hooks';
import { getThemeFontSizeClassName } from 'components/theme';
import { RouteName } from 'routes';
import { useRoutesChecker } from 'components/primitives/checkers';

const DesktopWarehouseSelectorBlock = ({ model, isDesignerMode }) => {
  const [dropdownOptions, setDropdownOptions] = useState({ show: false });
  const { warehouses, isAuthenticated } = useSelector(state => state.user);
  const selectedWarehouseId = warehouses?.find(warehouse => warehouse.isSelected)?.id;

  useOnChange(() => setDropdownOptions({ show: false }), [selectedWarehouseId], false);
  const isIgnoredRoute = useRoutesChecker([RouteName.BasketPage, RouteName.Checkout, RouteName.QuotePromotion]);  

  if(isIgnoredRoute || !isAuthenticated || !warehouses && !isDesignerMode)
    return null;

  const style = {
    '--toggle-iconColor': model.toggle_IconColor,
    '--toggle-iconHoverColor': model.toggle_IconHoverColor,
    '--toggle-fontFamily': model.toggle_FontFamily && (model.toggle_FontFamily + ', sans-serif'),
    '--toggle-allCaps': model.toggle_AllCaps ? 'uppercase' : null,
    '--toggle-fontColor': model.toggle_FontColor,
    '--content-fontColor': model.content_FontColor,
    '--content-linkFontColor': model.content_LinkFontColor,
    '--content-linkHoverFontColor': model.content_LinkHoverFontColor,
    '--content-linkHoverBackgroundColor': model.content_LinkHoverBackgroundColor,
    '--content-backgroundColor': model.content_BackgroundColor,
    '--content-borderColor': model.content_BorderColor,
    '--content-separatorColor': model.content_SeparatorsColor,
  };

  return (
    <Dropdown
      bodyAlign="right"
      className={styles.dropdown}
      options={dropdownOptions}
      hideCaretDown={!model.content_ShowExpandIcon}
      header={(
        <div className={`${styles.toggleContent} ${getThemeFontSizeClassName(model.toggle_ThemeFontSize)}`}>
          <IconWithText
            presentation={model.toggle_Presentation}
            warehouseId={selectedWarehouseId}
          />
        </div>
      )}
      style={style}
    >
      {!isDesignerMode && (
        <div className={`${styles.body} ${getThemeFontSizeClassName(model.content_ThemeFontSize)}`}>
          <WarehouseSelectorItems
            warehousesInfo={warehouses}
          />
        </div>
      )}
    </Dropdown>
  );
};

DesktopWarehouseSelectorBlock.propTypes = {
  model: PropTypes.shape({
    toggle_Presentation: PropTypes.number.isRequired,
    toggle_IconColor: PropTypes.string,
    toggle_IconHoverColor: PropTypes.string,
    toggle_FontFamily: PropTypes.string,
    toggle_ThemeFontSize: PropTypes.string.isRequired,
    toggle_AllCaps: PropTypes.bool.isRequired,
    toggle_FontColor: PropTypes.string,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_ThemeFontSize: PropTypes.string.isRequired,
    content_FontColor: PropTypes.string,
    content_LinkFontColor: PropTypes.string,
    content_LinkHoverFontColor: PropTypes.string,
    content_LinkHoverBackgroundColor: PropTypes.string,
    content_BackgroundColor: PropTypes.string,
    content_BorderColor: PropTypes.string,
    content_SeparatorsColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default DesktopWarehouseSelectorBlock;
