import type { Action } from 'redux';
import type { Epic } from 'behavior/types';
import { combineEpics } from 'redux-observable';
import { interval, merge, of } from 'rxjs';
import {
  first,
  pluck,
  map,
  mergeMap,
  mapTo,
} from 'rxjs/operators';
import { isVisualDesignerLocation } from 'behavior/visualDesigner';
import { initializeAnalyticsTrackers, notifyDataLayerReady, disableTracking } from './actions';
import ecommerceTracking from './epic.ecommerce';
import userTracking from './epic.user';
import pageViewTracking from './epic.pageView';
import { areSettingsLoaded } from 'behavior/settings';

const epic: Epic<Action> = (action$, state$, deps) => {
  const init$ = state$.pipe(
    pluck('settings'),
    first(areSettingsLoaded),
    map(settings => initializeAnalyticsTrackers(settings.analytics)),
  );

  if (deps.scope === 'SERVER')
    return init$;

  if (isVisualDesignerLocation(window.location))
    return of(disableTracking());

  const dataLayerReady$ = state$.pipe(
    first(state => !!state.analytics?.isTrackingEnabled),
    mergeMap(state => {
      const dataLayerName = state.analytics!.trackers![0].dataLayerName;
      return interval(500).pipe(
        first(_ => (<any>window)[dataLayerName]),
        mapTo(notifyDataLayerReady()),
      );
    }),
  );

  return merge(
    init$,
    dataLayerReady$,
    combineEpics(
      ecommerceTracking,
      userTracking,
      pageViewTracking,
    )(action$ as any, state$, deps),
  );
};

export default epic;
