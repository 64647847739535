import type { Handler } from '../types';
import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { map } from 'rxjs/operators';
import { paymentCancelledQuery } from './queries';
import { loadSystemPageQuery, initSystemPageContent, SystemPageData, SystemPage } from 'behavior/pages/system';
import { RouteName } from 'routes';

const handler: Handler<PaymentCancelledRouteData, PaymentCancelledPage> = (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<PaymentSystemPageResponse>(loadSystemPageQuery('paymentCancelled')).pipe(
      map(({ pages: { paymentCancelled } }) => ({
        page: {
          ...paymentCancelled,
          component: PageComponentNames.PaymentCancelled as const,
          transaction: { id: '' },
        },
      })),
      initSystemPageContent(),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi<PaymentCancelledPageResponse>(paymentCancelledQuery, { id: transactionId }).pipe(
    map(({
      pages: { paymentCancelled },
      paymentTransaction: transaction,
    }) => {
      if (transaction == null)
        return null;

      return ({
        page: {
          ...paymentCancelled,
          component: PageComponentNames.PaymentCancelled as const,
          transaction,
        },
      });
    }),
    initSystemPageContent(),
  );
};

export default handler;

type PaymentCancelledRouteData = {
  routeName: RouteName.OrderPayment;
  params: {
    transactionId?: string;
    previewToken?: string;
  };
};

type PaymentCancelledPage = SystemPage & {
  component: PageComponentNames.PaymentCancelled;
  transaction: {
    id: string;
  };
};

type PaymentSystemPageResponse = {
  pages: {
    paymentCancelled: SystemPageData;
  };
};

type PaymentCancelledPageResponse = PaymentSystemPageResponse & {
  paymentTransaction: {
    id: string;
  };
};
