import PropTypes from 'prop-types';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { whenOrderBoxVisible, hideInMatrixMode } from 'components/objects/product/wrappers';
import { connectToContext } from 'utils/react';
import ProductContext from 'components/objects/product/ProductContext';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import WarehousesStockBlock from './WarehousesStockBlock';
import { requestProductWarehousesStock } from 'behavior/pages/product';

const WarehousesProductStockBlock = ({ product, uomId }) => {
  const dispatch = useDispatch();
  const warehousesStock = product?.warehousesStock;
  const stockLevels = product?.stockLevels;

  const onLoadWarehousesStock = (productId, uomId) => {
      dispatch(requestProductWarehousesStock(productId, uomId));
  };

  const canViewStock = useHasAbilities(AbilityTo.ViewStock)[0];
  
  if (!canViewStock || product?.isProductGroup)
    return null;

  return (
    <WarehousesStockBlock onLoadWarehousesStock={onLoadWarehousesStock} warehousesStock={warehousesStock} productId={product.id} uomId={uomId} stockLevels={stockLevels} />
  );
};

WarehousesProductStockBlock.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    stockLevels: PropTypes.shape({
      outOfStock: PropTypes.number.isRequired,
      lowStock: PropTypes.number.isRequired,
      maxStockNumber: PropTypes.number,
    }),
  }).isRequired,
  uomId: PropTypes.string,
};

export default hideInMatrixMode(whenOrderBoxVisible(
  connectToContext([ProductContext],
    ({ product, uomId }) => ({ product, uomId }),
  )(memo(WarehousesProductStockBlock)),
));