import type { PageTemplatesState, PageTemplate, PageTemplateState } from './types';
import type { PageTemplateAction } from './actions';
import type { UserAuthenticatedAction } from 'behavior/user';
import { USER_AUTHENTICATED } from 'behavior/user';
import { PRODUCT_PAGE_TEMPLATE_LOADED, BLOG_ITEM_PAGE_TEMPLATE_LOADED, PRODUCT_GROUP_PAGE_TEMPLATE_LOADED } from './actions';
import { parseContent } from 'behavior/content';

const initialState = { product: null, blogItem: null, productGroup: null };

export default (state: PageTemplatesState = initialState, action: PageTemplateAction | UserAuthenticatedAction): PageTemplatesState => {
  switch (action.type) {
    case PRODUCT_PAGE_TEMPLATE_LOADED:
      return {
        ...state,
        product: {
          ...createPageTemplateState(action.payload.page, action.payload.languageId),
          preset: action.payload.page.preset,
        },
      };
    case BLOG_ITEM_PAGE_TEMPLATE_LOADED:
      return {
        ...state,
        blogItem: createPageTemplateState(action.payload.page, action.payload.languageId),
      };
    case PRODUCT_GROUP_PAGE_TEMPLATE_LOADED:
      return {
        ...state,
        productGroup: createPageTemplateState(action.payload.page, action.payload.languageId),
      };
    case USER_AUTHENTICATED:
      if (!state.product)
        return state;

      return {
        ...state,
        product: {
          ...state.product,
          expired: true,
        },
      };
    default:
      return state;
  }
};

function createPageTemplateState(page: PageTemplate, languageId: number | null): PageTemplateState {
  return {
    desktop: page.desktop ? parseContent(page.desktop) : null,
    mobile: page.mobile ? parseContent(page.mobile) : null,
    languageId,
  };
}