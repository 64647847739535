import { SystemRouteData, urlsBuilder } from 'routes';
import getLanguageSegment from './getLanguageSegment';
import { of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import type { AppState } from 'behavior';
import type { StateObservable } from 'redux-observable';

export default function requestRoute(route: SystemRouteData, state$: StateObservable<AppState>) {
  const state = state$.value;
  const languageSegment = getLanguageSegment(state);

  if (languageSegment !== undefined && state.settings.loaded) {
    const builder = urlsBuilder({ languageSegment, idEncodings: state.settings.url.idEncodings });
    return of(builder.getUrlForRoute(route));
  }

  return state$.pipe(
    map(s => ({
      languageSegment: getLanguageSegment(s),
      idEncodings: s.settings.loaded ? s.settings.url.idEncodings : undefined,
    })),
    first(<UrlsBuilderOptionsPredicate>(options => options.languageSegment !== undefined && !!options.idEncodings)),
    map(options => urlsBuilder(options).getUrlForRoute(route)),
  );
}

type UrlsBuilderOptions = Parameters<typeof urlsBuilder>[0];
type UrlsBuilderOptionsPredicate = (arg: Partial<UrlsBuilderOptions>) => arg is UrlsBuilderOptions;
