export const enum ProductSpecificationFilter {
  ForList = 'FOR_LIST',
  ForDetails = 'FOR_DETAILS',
  ForComparison = 'FOR_COMPARISON',
  ForGroup = 'FOR_GROUP',
  ForChildItems = 'FOR_CHILD_ITEMS',
}

export const enum CatalogSearchMode {
  ChildAndRegular = 'CHILD_AND_REGULAR',
  GroupsAndRegular = 'GROUPS_AND_REGULAR',
  AllItems = 'ALL_ITEMS',
}