import type { DocumentCollection, Filter } from './types';
import { DOCUMENTS_RECEIVED, DocumentsAction, DocumentsReceivedAction, SortingChangedAction, SORTING_CHANGED } from './actions';
import { createReducer } from 'utils/redux';

type State = {
  docs: DocumentCollection | null;
  filter: Filter;
};

export default createReducer<State, DocumentsAction>(null as any, {
  [DOCUMENTS_RECEIVED]: onDocumentsReceived,
  [SORTING_CHANGED]: onSortingChanged,
});

function onDocumentsReceived(state: State, action: DocumentsReceivedAction) {
  const {
    documents,
    page,
  } = action.payload;

  if (!state.docs || page === 0 || !documents)
    return {
      ...state,
      docs: documents,
    };

  const { items, totalCount } = documents;
  return {
    ...state,
    docs: {
      items: state.docs.items.concat(items),
      totalCount,
    },
  };
}

function onSortingChanged(state: State, action: SortingChangedAction) {
  const {
    sorting,
  } = action.payload;

  return {
    ...state,
    filter: {
      ...state.filter,
      sorting,
    },
  };
}
