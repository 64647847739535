import { Observable, Observer, ReplaySubject } from 'rxjs';

export function createRequestTracker() {
  const activeRequests = new Set<Observable<unknown>>();
  const statusSubject = new ReplaySubject<boolean>(1);

  function forgetRequest(source: Observable<unknown>) {
    setTimeout(() => {
      activeRequests.delete(source);
      !activeRequests.size && statusSubject.next(true);
    }, 0);
  }

  return {
    trackObservable<T>(source: Observable<T>) {
      return new Observable<T>((observer: Observer<T>) => {
        !activeRequests.size && statusSubject.next(false);
        activeRequests.add(source);

        const subscription = source.subscribe(observer);

        return () => {
          forgetRequest(source);
          subscription.unsubscribe();
        };
      });
    },
    hasInProgress() {
      return !!activeRequests.size;
    },
    isReady$: statusSubject.asObservable(),
  };
}
