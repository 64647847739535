import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSimpleTexts } from 'components/sanaText';

const AriaStatus = ({ status }) => {
  const [statusText, setStatusText] = useState(null);
  const [text] = useSimpleTexts([status.key]).texts;

  const textContent = status.text || text;
  useEffect(() => {
    if (!textContent)
      return;

    /* Aria-live block text content should be cleared first and new content should be added after delay of 100ms to fix issues with screen reader
    engines (VoiceOver + Safari, NVDA + Firefox) which sometimes skip announcing if block text content is the same as the previous one.
    Additionally no-break space character should be added for repetitive content for VoiceOver + Safari, otherwise announcing will be randomly skipped,
    solution was taken from here -  https://core.trac.wordpress.org/ticket/36853. */
    const noBreakSpaceCode = '\u00A0';
    const newStatusText = statusText !== textContent || statusText.endsWith(noBreakSpaceCode)
      ? textContent
      : textContent + noBreakSpaceCode;
    setStatusText(null);
    const timeoutId = setTimeout(() => setStatusText(newStatusText), 100);
    return () => clearTimeout(timeoutId);
  }, [status, text]);

  return (
    <div className="visually-hidden" role="status" aria-live={status.assertive ? 'assertive' : 'polite'} aria-atomic>
      {statusText}
    </div>
  );
};

AriaStatus.propTypes = {
  status: PropTypes.shape({
    textKey: PropTypes.string,
    text: PropTypes.string,
    assertive: PropTypes.bool,
  }).isRequired,
};

export default connect(state => ({
  status: state.ariaStatus,
}))(AriaStatus);
