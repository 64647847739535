import type { ErrorResponse } from './types';
import type { Action } from 'redux';
import { catchError } from 'rxjs/operators';
import { of, concat, Observable, OperatorFunction } from 'rxjs';
import { showErrorToast } from './actions';

/**
 * Handle server error with a toast message.
 * @param {array} errorsToHandle - list of error codes to handle.
 * @param {object} observable - observable to return after processing.
 * @returns {function} - Operator object.
 */
export type CatchApiErrorWithToast = (errorsToHandle?: Array<string>, observable?: Observable<unknown>) => OperatorFunction<unknown, Action>;

const catchApiErrorWithToast: CatchApiErrorWithToast = (errorsToHandle = ['INVALID_INPUT'], observable?: Observable<unknown>) =>
  catchError((e: ErrorResponse) => {
    if (e.response && e.response.errors && e.response.errors.length) {
      for (let i = 0, l = e.response.errors.length; i < l; i++) {
        const error = e.response.errors[i];

        if (error.extensions &&
          errorsToHandle.includes(error.extensions.code) &&
          error.message) {
          const showToast$ = of(showErrorToast({ type: 'message', data: error.message }));

          if (observable)
            return concat(showToast$, observable);

          return showToast$;
        }
      }
    }

    throw e;
  }) as OperatorFunction<unknown, Action>;

export default catchApiErrorWithToast;
