import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';
import { loadEditMyGarageQuery } from './queries';

export default (routeData, state$, { api }) => {
  return api.graphApi(loadEditMyGarageQuery).pipe(
    map(({ pages, myGarageVehicles }) => {
      const page = pages.editMyGarage;
      page.component = 'EditMyGarage';
      if (!page)
        return null;

      page.component = PageComponentNames.EditMyGarage;
      page.myGarageVehicles = myGarageVehicles;
      page.backTo = getBackTo(state$, [
        RouteName.EditMyGarage,
      ], routeData.params && routeData.params.language);

      return { page };
    }),
    initSystemPageContent(),
  );
};