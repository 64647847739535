import type { RouteName } from 'routes';
import { ComponentType, useMemo } from 'react';
import { useAbilities } from 'components/objects/user';
import type { AbilityTo } from 'behavior/user';
import { AbilityState } from 'behavior/user/constants';
import { useRoutesChecker } from './hooks';

const defaultAbilityKeys: AbilityTo[] = [];

export default function withAbilitiesAndRouteChecker<Props>(
  Component: ComponentType<Props & { abilities: AbilityState[] }>,
  ignoredRoutes: RouteName[],
  abilityKeysToCheck = defaultAbilityKeys,
  abilityKeys = defaultAbilityKeys,
) {
  function Wrapper(props: Props) {
    const abilitiesToLoad = [...abilityKeysToCheck, ...abilityKeys];
    const {
      abilities: abilitiesStatuses,
      isLoading,
    } = useAbilities(abilitiesToLoad);

    const { abilitiesToCheck, abilities } = useMemo(() => ({
      abilitiesToCheck: abilitiesStatuses.slice(0, abilityKeysToCheck.length),
      abilities: abilitiesStatuses.slice(abilityKeysToCheck.length),
    }), [abilitiesStatuses]);

    const isIgnoredRoute = useRoutesChecker(ignoredRoutes, isLoading);

    if (isIgnoredRoute)
      return null;

    if (abilityKeysToCheck.length) {
      if (!abilitiesToCheck.length || abilitiesToCheck.some(ability => ability !== AbilityState.Available))
        return null;
    }

    return <Component {...props} abilities={abilities} />;
  }

  return Wrapper;
}
