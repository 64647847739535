export const enum ThemeVersion {
  Default = 'default',
  Error = 'error',
}

export const enum ThemeFontSize {
  Large = 'Large',
  Regular = 'Regular',
  Small = 'Small',
  Micro = 'Micro',
  Heading1 = 'Heading1',
  Heading2 = 'Heading2',
  Heading3 = 'Heading3',
  Heading4 = 'Heading4',
  Heading5 = 'Heading5',
  DisplayHeading1 = 'DisplayHeading1',
  DisplayHeading2 = 'DisplayHeading2',
  DisplayHeading3 = 'DisplayHeading3',
}

export const enum BorderStyle {
  None = 0,
  Solid = 1,
  Dashed = 2,
  Dotted = 3,
  Double = 4,
}
