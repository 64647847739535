import { useSelector } from 'react-redux';
import { DomElement } from 'components/primitives/dom';
import { useIsVisualDesigner } from 'components/visualDesigner';

const Injection = () => {
  const isVisualDesigner = useIsVisualDesigner();
  const content = useSelector(s => s.settings.loaded && s.settings.htmlInjections.bodyStart);

  return !isVisualDesigner && content
    ? <DomElement content={content} />
    : null;
};

export default Injection;
