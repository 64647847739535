import { useProductContext, useProductViewContext } from 'components/objects/product/hooks';
import { useMemo } from 'react';

type StockLevel = 'inStock' | 'lowStock' | 'outOfStock';

export default () => {
  const {
    product,
    calculatedInfo,
    variantId,
  } = useProductContext();
  const { isMatrixMode } = useProductViewContext();

  return useMemo(() => {
    if (!product || !product.loaded)
      return null;

    const inventory = (!isMatrixMode && variantId && product.variants?.length)
      ? product.variants.find(v => v.id === variantId)?.inventory
      : product.inventory;

    if (inventory == null)
      return null;

    const stockLevel = getStockLevel(product.stockLevels, inventory);

    return { inventory, stockLevel };
  }, [product, calculatedInfo, variantId, isMatrixMode]);
};

type StockLevels = ReturnType<typeof useProductContext>['product']['stockLevels'];

function getStockLevel(stockLevels: StockLevels, inventory: number): StockLevel | null {
  if (stockLevels == null)
    return null;
  if (inventory <= stockLevels.outOfStock)
    return 'outOfStock';
  if (inventory >= stockLevels.lowStock)
    return 'inStock';
  return 'lowStock';
}
