import type { Epic } from 'behavior/types';
import type { ProductSetAction } from './actions';
import type { CalculatedProductData, ProductData } from './types';
import { areSettingsLoaded } from 'behavior/settings';
import { groupBy, map, mergeMap, switchMap, takeUntil, pluck, first, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PRODUCT_SET_REQUESTED, productSetReceived, PRODUCT_SET_WAREHOUSES_STOCK_REQUESTED, productSetWarehousesStockReceived } from './actions';
import { LOCATION_CHANGED } from 'behavior/events';
import { RELOAD_LOCATION } from 'behavior/routing';
import { productsQuery, loadCalculatedFieldsQuery, warehousesStockQuery } from './queries';
import { CatalogSearchMode } from 'behavior/products/product';
import { merge, of } from 'rxjs';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import type { WarehouseStock } from 'behavior/pages/product';

type ProductSet = {
  products: {
    products: Array<ProductData | CalculatedProductData>;
  };
};

type ProductSetResponse = {
  catalog: {
    productSet: ProductSet | null;
  };
};

const productSetsEpic: Epic<ProductSetAction> = (action$, state$, { api, logger }) => {
  const reset$ = action$.pipe(ofType(LOCATION_CHANGED, RELOAD_LOCATION));

  const productSetRequested$ = action$.pipe(
    ofType(PRODUCT_SET_REQUESTED),
    groupBy(action => action.payload.contentBlockId, action => action.payload),
    mergeMap(group => group.pipe(
      switchMap(({ contentBlockId, productSetId, size, sorting, calculate }) => state$.pipe(
        pluck('settings'),
        first(areSettingsLoaded),
        switchMap(settings => {
          const productOptions = {
            isProductGroupingEnabled: settings.product.productGrouping.isEnabled,
          };
          const variables = {
            id: productSetId,
            options: {
              page: { index: 0, size },
              sorting,
              loadProductsBihaviour: CatalogSearchMode.GroupsAndRegular,
            },
            loadCategories: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
          };
          const query = calculate ? loadCalculatedFieldsQuery : productsQuery(productOptions);

          return api.graphApi<ProductSetResponse>(query, variables).pipe(
            pluck('catalog', 'productSet'),
            map(productSet => productSetReceived(contentBlockId, getProductSetProducts(productSet), calculate)),
            takeUntil(reset$),
          );
        }),
      )),
    )),
  );

  const onRequestProductSetItemWarehousesStock$ = action$.pipe(
    ofType(PRODUCT_SET_WAREHOUSES_STOCK_REQUESTED),
    mergeMap(({ payload: { contentBlockId, id, uomId } }) =>
      api.graphApi<WarehousesStockResponse>(warehousesStockQuery, { id, uomId }).pipe(
        mergeMap(r=>{
          const warehousesStock = r.catalog.warehousesStock;
          return of(productSetWarehousesStockReceived(contentBlockId, id, warehousesStock), unsetLoadingIndicator(),);
        }),
        retryWithToast(action$, logger),
        takeUntil(reset$),
        startWith(setLoadingIndicator()),
      )),
  );

  return merge(productSetRequested$, onRequestProductSetItemWarehousesStock$);
};

export default productSetsEpic;

const getProductSetProducts = (productSet: ProductSet | null): Array<ProductData | CalculatedProductData> => {
  if (productSet)
    return productSet.products.products;

  return [];
};

type WarehousesStockResponse = {
  catalog: {
    warehousesStock: WarehouseStock[];
  };
};
