import styles from './CallUsMessage.module.scss';
import { useSelector } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { renderHTML } from 'utils/render';
import { PhoneIcon } from 'components/primitives/icons';

const transformText = (text, shopPhoneNumber) => text.replace('[PHONE]', shopPhoneNumber);

const CallUsMessageBlock = () => {
  const shopPhoneNumber = useSelector(state => state.settings.shopPhoneNumber);
  const [overriddenTxt, callUsTxt] = useSanaTexts(['HeaderInfoMessage', 'CallUsIfHaveQuestions']).texts;
  
  if (!overriddenTxt && !callUsTxt)
    return null;

  return (
    <span className={styles.block}>
      {overriddenTxt != null
        ? renderHTML(transformText(overriddenTxt, shopPhoneNumber))
        : (
          <>
            <PhoneIcon className={styles.icon} aria-hidden />
            {renderHTML(transformText(callUsTxt, shopPhoneNumber))}
          </>
        )}
    </span>
  );
};

export default CallUsMessageBlock;
