import styles from '../AccountMenu.module.scss';
import { LoginLink, WishlistLink } from 'components/primitives/links';
import { useSelector } from 'react-redux';
import { StoreType } from 'behavior/settings';

const AnonymousMenuItems = () => {
  const publicStore = useSelector(state => state.settings.storeType === StoreType.Public);

  return (
    <>
      <LoginLink className={styles.item} />
      {publicStore && <WishlistLink className={styles.item} />}
    </>
  );
};

export default AnonymousMenuItems;
