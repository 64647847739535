import type { ClientRoute } from './types';

type Params = {
  productIds?: string[];
};

const productComparisonRoute = (): ClientRoute => {
  const path = '/productcompare';

  return {
    getVirtualPath(params, context) {
      if (!params)
        return path;

      const { productIds } = params as Params;
      if (!productIds?.length)
        return path;

      return path + '/' + productIds.map(id => context.encodeId(id)).join('/');
    },
  };
};

export default productComparisonRoute;
