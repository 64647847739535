import {
  NEWSLETTER_UPDATED, NEWSLETTER_SUBSCRIBE, NEWSLETTER_UNSUBSCRIBE,
  updateNewsletterSubscription, NewsletterAction,
} from './actions';
import { createReducer } from 'utils/redux';

type NewsletterState = {
  lastUpdated: Date | null;
  updatedById: string | null;
};

const initialState: NewsletterState = {
  lastUpdated: null,
  updatedById: null,
};

export default createReducer<NewsletterState, NewsletterAction>(initialState, {
  [NEWSLETTER_SUBSCRIBE]: onNewsletterChange,
  [NEWSLETTER_UNSUBSCRIBE]: onNewsletterChange,
  [NEWSLETTER_UPDATED]: onNewsletterUpdated,
});

function onNewsletterChange(state: NewsletterState, _action: any) {
  return {
    ...state,
    updatedById: null,
  };
}

function onNewsletterUpdated(_state: NewsletterState, action: ReturnType<typeof updateNewsletterSubscription>) {
  return {
    updatedById: action.payload.updatedById,
    lastUpdated: new Date(),
  };
}
