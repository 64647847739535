import type {
  SalesAgreementsAction,
  AgreementsListRequestAction,
  AgreementItemRequestAction,
} from './actions';
import type { Epic } from 'behavior/types';
import type { AgreementLine, AgreementLineAvailability } from './types';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  switchMap,
  mergeMap,
  map,
  startWith,
  pluck,
  filter,
} from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { retryWithToast } from 'behavior/errorHandling';
import {
  AGREEMENTS_REQUESTED,
  notifyAgreementsReceived,
  AGREEMENTS_SEARCH_PRODUCT_IDS,
  productIdsReceived,
  AGREEMENT_LINES_AVAILABILITY_REQUESTED,
  receiveAgreementLinesAvailability,
} from './actions';
import {
  agreementsListQuery,
  agreementItemQuery,
  searchForProductIdsQuery,
  linesAvailabilityQuery,
} from './queries';
import { isProductOrderable } from './util';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { skipIfPreview } from 'behavior/preview';
import { SalesAgreementStatus } from 'behavior/salesAgreements';

const isAgreementItemRequest = (action: AgreementsListRequestAction | AgreementItemRequestAction): action is AgreementItemRequestAction =>
  (action as AgreementItemRequestAction).payload.id !== undefined;

const isAgreementListRequest = (action: AgreementsListRequestAction | AgreementItemRequestAction): action is AgreementsListRequestAction => {
  const payload = (action as AgreementsListRequestAction).payload;
  return payload.index !== undefined && payload.activeOnly !== undefined && payload.size !== undefined;
};

const salesAgreementsPageEpic: Epic<SalesAgreementsAction> = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const onAgreementItemRequested$ = action$.pipe(
    ofType(AGREEMENTS_REQUESTED),
    filter(isAgreementItemRequest),
    switchMap(({ payload }) => {
      const params = {
        id: payload.id,
      };

      return api.graphApi<AgreementItemResponse>(agreementItemQuery, params).pipe(
        mergeMap(({ salesAgreements }) =>
          [unsetLoading, notifyAgreementsReceived(salesAgreements && salesAgreements.item && [salesAgreements.item])],
        ),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      );
    }),
  );

  const onAgreementsListRequested$ = action$.pipe(
    ofType(AGREEMENTS_REQUESTED),
    filter(isAgreementListRequest),
    switchMap(({ payload }) => {
      const params = {
        input: {
          activeOnly: payload.activeOnly,
          page: { index: payload.index, size: payload.size },
        },
      };

      return api.graphApi<AgreementsListResponse>(agreementsListQuery, params).pipe(
        mergeMap(({ salesAgreements }) =>
          [unsetLoading, notifyAgreementsReceived(salesAgreements && salesAgreements.list, payload.index ? true : false)],
        ),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      );
    }),
  );

  const onSearch$ = action$.pipe(
    ofType(AGREEMENTS_SEARCH_PRODUCT_IDS),
    skipIfPreview(state$),
    switchMap(({ payload }) => {
      if (!payload.keywords)
        return of(productIdsReceived(payload.keywords, null));

      return api.graphApi<SearchForProductIdsResponse>(searchForProductIdsQuery, payload).pipe(
        map(({ catalog }) => {
          if (!catalog.products || !catalog.products.products.length)
            return productIdsReceived(payload.keywords, null);

          return productIdsReceived(payload.keywords, catalog.products.products.map(p => p.id));
        }),
        retryWithToast(action$, logger),
      );
    }),
  );

  const onAgreementLinesAvailabilityRequested$ = action$.pipe(
    ofType(AGREEMENT_LINES_AVAILABILITY_REQUESTED),
    pluck('payload'),
    switchMap(({ lines, status, id: agreementId }) => requestAbility(AbilityTo.OrderProducts, state$, dependencies).pipe(
      switchMap(canOrderAbility => {
        if (canOrderAbility !== AbilityState.Available)
          return of(receiveAgreementLinesAvailability([]), unsetLoading);

        return requestAbility(AbilityTo.ViewUnitOfMeasure, state$, dependencies).pipe(
          map(canViewUomsAbility => calculateProductsOrderability(lines, status, canViewUomsAbility === AbilityState.Available)),
          switchMap(({ assumedOrderableLineIds, productIdsToCheckOnServer }) => {
            if (productIdsToCheckOnServer.length === 0)
              return of(receiveAgreementLinesAvailability([]), unsetLoading);

            return api.graphApi<LinesAvailabilityResponse>(linesAvailabilityQuery, { agreementId, productIds: productIdsToCheckOnServer }).pipe(
              map(({ salesAgreements }) => {
                if (salesAgreements && salesAgreements.linesAvailability)
                  return salesAgreements.linesAvailability;

                return [];
              }),
              map(linesAvailability => matchReceivedLines(assumedOrderableLineIds, linesAvailability)),
              mergeMap(lines => [receiveAgreementLinesAvailability(lines), unsetLoading]),
              retryWithToast(action$, logger, _ => of(unsetLoading)),
            );
          }),
        );
      }),
      startWith(setLoading),
    )),
  );

  return merge(
    onAgreementItemRequested$,
    onAgreementsListRequested$,
    onSearch$,
    onAgreementLinesAvailabilityRequested$,
  );
};

export default salesAgreementsPageEpic;

function calculateProductsOrderability(agreementLines: AgreementLine[] | undefined, agreementStatus: SalesAgreementStatus, userCanViewUoms: boolean) {
  const assumedOrderableLineIds: string[] = [];
  const productIds = new Set<string>();

  agreementLines && agreementLines.forEach(line => {
    const isOrderable = isProductOrderable(line, agreementStatus, userCanViewUoms);
    if (!isOrderable)
      return;

    assumedOrderableLineIds.push(line.id);
    line.product?.id && productIds.add(line.product.id);
  });

  return {
    assumedOrderableLineIds,
    productIdsToCheckOnServer: [...productIds],
  };
}

function matchReceivedLines(assumedOrderableLineIds: string[], linesAvailability: AgreementLineAvailability[]) {
  return linesAvailability.filter(({ lineId }) => assumedOrderableLineIds.some(assumedLineId => assumedLineId === lineId));
}

type AgreementItemResponse = {
  salesAgreements: {
    item: {
      id: string;
      url: string;
      title: string | null;
      effectiveDate: string | null;
      expirationDate: string | null;
      status: SalesAgreementStatus;
    } | null;
  } | null;
};

type AgreementsListResponse = {
  salesAgreements: {
    list: {
      id: string;
      url: string;
      title: string | null;
      effectiveDate: string | null;
      expirationDate: string | null;
      status: SalesAgreementStatus;
    }[] | null;
  } | null;
};

type SearchForProductIdsResponse = {
  catalog: {
    products: {
      products: {
        id: string;
      }[];
    } | null;
  };
};

type LinesAvailabilityResponse = {
  salesAgreements: {
    linesAvailability: {
      lineId: string;
      variantId: string | null;
    }[] | null;
  } | null;
};