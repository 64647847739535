import Template from './ProfileTemplate';

function createProfileRenderer(titleTextKey, contentRenderer, options) {
  return page => (
    <Template
      titleTextKey={titleTextKey}
      sidebarLinkKey={options.linkKey}
      backTo={options.backTo}
      header={page.content?.header}
      footer={page.content?.footer}
      pageBackTo={page.backTo}
    >
      {contentRenderer(page)}
    </Template>
  );
}

export default createProfileRenderer;
