export default Object.freeze([
  {
    border: {
      color: '#E6E6E6',
      style: 'solid',
      width: '0 0 1px 0',
    },
    columns: [
      {
        contentBlocks: [
          {
            id: '2z8m94ivc',
            model: {
              subLevel_AllCaps: false,
              subLevel_ShowArrowInFrontOfSubItem: false,
              topLevel_AllCaps: false,
              topLevel_ShowActiveItemUnderline: true,
            },
            name: 'MobileMainMenu',
          },
          {
            horizontalAlignment: 'CENTER',
            id: 'ldzh25tcw',
            name: 'Logo',
            stretchWidth: true,
          },
          {
            id: 'vtuopctu0',
            model: {
              box_BorderStyle: 1,
              box_BorderWidth: '1px',
              box_CornerRadius: '2px',
              box_ThemeFontSize: 'Regular',
              button_CornerRadius: '0px 2px 2px 0px',
              button_Layout: 0,
              button_ThemeFontSize: 'Regular',
              suggestions_ShowThumbnails: true,
              suggestions_ThemeFontSize: 'Regular',
            },
            name: 'SearchBar',
            spacing: {
              margin: '0 16px',
            },
            stretchWidth: true,
          },
          {
            id: 'sjvakgtzn',
            name: 'ProductSuggestions',
            spacing: {
              margin: '0 16px 0 0',
            },
          },
          {
            id: 'w17los794',
            model: {
              content_AllCaps: false,
              content_ItemFontSize: 'Regular',
              content_ShowExpandIcon: true,
              content_ShowFlags: true,
              toggle_AllCaps: false,
              toggle_FontSize: 'Regular',
              toggle_Presentation: 0,
            },
            name: 'DesktopLanguageSelector',
          },
          {
            id: 'fkdvr89im',
            model: {
              content_ShowExpandIcon: true,
              content_ThemeFontSize: 'Regular',
              toggle_AllCaps: false,
              toggle_Presentation: 0,
              toggle_ThemeFontSize: 'Regular',
            },
            name: 'DesktopAccountMenu',
          },
          {
            id: 'd5ftls6sa',
            model: {
              basketLink_AllCaps: true,
              basketLink_Layout: 3,
              basketLink_ThemeFontSize: 'Regular',
              content_ShowExpandIcon: false,
              content_ShowThumbnails: true,
              content_ThemeFontSize: 'Regular',
              footer_BasketLinkStyle: 0,
              footer_CheckoutLinkStyle: 2,
              footer_SwapLinksPosition: false,
              heading_AllCaps: true,
              heading_ThemeFontSize: 'Heading4',
              showMiniatureOnBasket: false,
              showSubtotal: false,
            },
            name: 'DesktopBasketSummary',
          },
        ],
        contentOrientation: 'HORIZONTAL',
        id: 'u6e37eunt',
      },
    ],
    fullWidth: true,
    id: 'ittw743g6',
    spacing: {
      hideSpaces: true,
      margin: null,
      padding: null,
    },
    verticalAlignment: 'MIDDLE',
  },
]);
