import { useProductViewContext } from '../hooks';

const hideInMatrixMode = Component => {
  return props => {
    const { isMatrixMode } = useProductViewContext();
    
    return isMatrixMode ? null : <Component {...props} />;
  };
};

export default hideInMatrixMode;
