import { ForgotPasswordAction, FORGOT_PASSWORD_REQUESTED, received } from './actions';
import { forgotMutation } from './queries';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, mergeMap, pluck } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { Epic } from 'behavior/types';
import { toasts } from 'behavior/toasts';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';

const epic: Epic<ForgotPasswordAction> = (action$, _state$, { api, logger }) => action$.pipe(
  ofType(FORGOT_PASSWORD_REQUESTED),
  pluck('payload'),
  switchMap(({ data, updatedById }) => api.graphApi(forgotMutation, { input: data }).pipe(
    mergeMap(_ => {
      toasts.success('', { textKey: 'SendPassword_Text', className: 'ForgotPassword_toast' });

      return [navigateTo(routesBuilder.forLogin()), resetCaptcha(updatedById), received()];
    }),
    catchApiErrorWithToast(undefined, of(resetCaptcha(updatedById))),
    retryWithToast(action$, logger, () => of(resetCaptcha(updatedById))),
  )),
);

export default epic;
