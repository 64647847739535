import type { ReactElement, ReactText } from 'react';
import { memo } from 'react';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

type Props = {
  textKey: string;
  titleTextKey?: string;
  defaultText?: string;
  dontWait?: boolean;
  children: (text: string | null, title: string | null) => ReactElement;
  placeholder?: ReactElement;
};

const UseSanaButton = ({
  textKey,
  titleTextKey,
  defaultText,
  placeholder,
  dontWait,
  children,
}: Props) => {
  const { text, title, loaded } = useSanaButton(textKey, defaultText, undefined, titleTextKey);
  if (!dontWait && !loaded)
    return placeholder || null;

  return children(text, title);
};

export default memo(UseSanaButton);

export function useSanaButton(textKey: string, defaultText?: string, formatWith?: ReactText[], titleTextKey?: string) {
  const buttonTexts = [
    {
      key: `ButtonText_${textKey}`,
      fallback: defaultText,
    },
    titleTextKey && `ButtonAltText_${titleTextKey}`,
  ];
  const { texts: [text, title], loaded } = !!formatWith
    ? useSanaTexts(buttonTexts)
    : useSanaTexts<string | null>(buttonTexts, makeSimpleText);

  if (!formatWith || !loaded)
    return { text, title, loaded };

  return {
    text: makeSimpleText(text, formatWith),
    title: makeSimpleText(title),
    loaded,
  };
}
