import { AppAction, AppState, APP_STATE_UPDATED } from './actions';

const initialState = {
  offlineMode: false as const,
  loaded: false as const,
};

type InitialState = typeof initialState;
export type LoadedState = AppState & { error: boolean; loaded: true };
type IntialErrorState = Omit<InitialState, 'loaded'> & { error: true; loaded: true };

type State = InitialState | LoadedState | IntialErrorState;

export default function reducer(state: State = initialState, action: AppAction): State {
  if (action.type === APP_STATE_UPDATED)
    return { ...state, error: false, ...action.payload, loaded: true };

  return state;
}
