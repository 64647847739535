import type { Handler } from '../types';
import type { FailureOrderPaymentReason } from './types';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { orderPaymentPageQuery } from './queries';
import { loadSystemPageQuery, initSystemPageContent, SystemPageData, SystemPage } from 'behavior/pages/system';
import { RouteName } from 'routes';

const handler: Handler<OrderPaymentRouteData, OrderPaymentPage> = (routeData, _, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<OrderPaymentSystemPageResponse>(loadSystemPageQuery('orderPayment')).pipe(
      map(({ pages: { orderPayment } }) => ({
        page: {
          ...orderPayment,
          component: PageComponentNames.OrderPayment,
          transactionId: '',
          order: {
            id: '',
            currency: { id: '', symbol: '', decimalDigits: 0 },
            orderDate: '-',
            referenceNo: '-',
          },
          paymentMethods: Array.from(Array(3)).map((_, index) => ({
            id: index.toString(),
            name: '',
            isDefault: false,
          })),
        },
      })),
      initSystemPageContent(),
    );
  }

  return api.graphApi<OrderPaymentPageResponse>(orderPaymentPageQuery, { transactionId }).pipe(
    map(({
      pages: { orderPayment: orderPaymentPage },
      orderPayment,
    }) => {
      if (!orderPayment)
        return null;

      return {
        page: {
          transactionId,
          component: PageComponentNames.OrderPayment,
          ...handleOrderPaymentInfoResult(orderPayment),
          ...orderPaymentPage,
        },
      };
    }),
    initSystemPageContent(),
  );
};

export default handler;

function handleOrderPaymentInfoResult({ type, ...result }: OrderPaymentInfo) {
  if (type === 'OrderPaymentInfo') {
    const { paymentMethods: { list: paymentMethods }, ...rest } = result;

    return {
      ...rest,
      paymentMethods: paymentMethods.map(({ info }) => info),
    };
  }

  const { failureReason, orderId } = result;
  return { failureFetching: { reason: failureReason, orderId } };
}

type OrderPaymentPage = SystemPage & {
  component: PageComponentNames;
  transactionId?: string;
  order?: PaymentOrder;
  paymentMethods?: {
    id: string;
    name: string;
    isDefault: boolean;
  }[];
};

type PaymentOrder = {
  id: string;
  currency: {
    id: string;
    symbol: string;
    decimalDigits: number;
  };
  orderDate: string;
  referenceNo: string;
  totalAmount?: number | null;
};

type OrderPaymentRouteData = {
  routeName: RouteName.OrderPayment;
  params: {
    transactionId?: string;
    previewToken?: string;
  };
};

type OrderPaymentSystemPageResponse = {
  pages: {
    orderPayment: SystemPageData;
  };
};
type OrderPaymentPageResponse = OrderPaymentSystemPageResponse & {
  orderPayment: OrderPaymentInfo;
};

type OrderPaymentInfo = {
  order: PaymentOrder;
  type: 'OrderPaymentInfo' | 'UnavailableOrderPaymentInfo';
  paymentMethods: {
    list: {
      info: PaymentMethodInfo;
    }[];
  };
  failureReason?: FailureOrderPaymentReason;
  orderId?: string;
};

type PaymentMethodInfo = {
  description?: string | null;
  id: string;
  imageUrl?: string | null;
  name: string;
  isDefault: boolean | null;
};

