import type { RowContentElement } from 'behavior/content';
import { PageComponentNames } from 'behavior/pages';
import loadable from '@loadable/component';

const Page = loadable(() => import(/*webpackChunkName:"notFound"*/'./Page'));

type Props = {
  content: {
    desktop: RowContentElement[] | null;
    mobile: RowContentElement[] | null;
  };
};

export default {
  [PageComponentNames.NotFound]: (page: Props) => <Page desktop={page.content?.desktop} mobile={page.content?.mobile} />,
};
