import { mergeReducers } from 'utils/redux';
import reducer from './reducer';
import quickOrderReducer from './reducer.quickOrder';
import errorModeReducer from './reducer.errorMode';

export default mergeReducers(
  reducer,
  quickOrderReducer,
  errorModeReducer,
);
