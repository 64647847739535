import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { RouteName } from 'routes';
import { useAbilities } from 'components/objects/user';
import { useRoutesChecker } from 'components/primitives/checkers';

const abilityKeys = [
  AbilityTo.OrderProducts,
  AbilityTo.ViewPrices,
  AbilityTo.CreateOrder,
  AbilityTo.CreateQuote,
  AbilityTo.ViewUnitOfMeasure,
];

const wrapperPropTypes = {
  options: PropTypes.shape({
    showMiniatureOnBasket: PropTypes.bool.isRequired,
  }).isRequired,
};

export default function withAbilitiesAndRouteChecker(Component) {
  function Wrapper({ options, ...props }) {
    const { abilities: allAbilities, isLoading } = useAbilities(abilityKeys);
    const { canOrderProducts, abilities } = useMemo(() => {
      const [orderProductsAbility, ...abilities] = allAbilities;
      return { canOrderProducts: orderProductsAbility === AbilityState.Available, abilities };
    }, [allAbilities]);

    const isIgnoredRoute = useRoutesChecker(
      options.showMiniatureOnBasket ? [RouteName.QuotePromotion] : [RouteName.BasketPage, RouteName.QuotePromotion],
      isLoading,
    );

    if (isIgnoredRoute || !canOrderProducts)
      return null;

    return <Component {...props} options={options} abilities={abilities} />;
  }

  Wrapper.prototype.propTypes = wrapperPropTypes;

  return Wrapper;
}
