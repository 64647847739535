import loadable from '@loadable/component';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import createSystemRenderer from 'components/objects/systemPages/renderer';
import { Steps } from 'behavior/pages/checkout';
import { getPageTitleTextKey } from './helpers';

const Checkout = loadable(() => import(/*webpackChunkName:"checkout"*/'./Checkout'));
const OrderSubmit = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderResult/orderSubmit'));
const OrderFailed = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderResult/orderFailed'));
const OrderCancelled = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderResult/orderCancelled'));
const PaymentError = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderResult/paymentError'));

/* eslint-disable react/no-multi-comp */
function checkoutContentRenderer(page) {
  return <Checkout info={page.info} />;
}

export function checkoutRenderer(page) {
  const backTo = page.info.quote
    ? {
      texts: ['BackToQuote'],
      url: page.info.quote.url,
      route: routesBuilder.forDocument(page.info.quote.id, DocumentType.Quote),
    }
    : null;

  const headerTextKey = getPageTitleTextKey(!!page.info.quote, page.info.isQuote);
  const asDocumentTitle = page.overrideDocumentTitle || page.info.currentStep === Steps.None;
  return createSystemRenderer({ textKey: headerTextKey, asDocumentTitle }, checkoutContentRenderer, backTo)(page);
}

export function orderSubmitRenderer(page) {
  return <OrderSubmit page={page} />;
}

export function orderFailedRenderer(page) {
  return <OrderFailed page={page} />;
}

export function orderCancelledRenderer(page) {
  return <OrderCancelled page={page} />;
}

export function paymentErrorRenderer(page) {
  return <PaymentError page={page} />;
}
