import type { Handler } from '../types';
import type { SalesAgreement, SalesAgreementsState } from './types';
import type { ContentSystemPage, ContentSystemPageData } from '../system';
import type { RouteData } from 'routes';
import { map, switchMap, first, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { agreementDetailPageQuery } from './queries';
import { requestAgreementLinesAvailability } from './actions';
import { initContentSystemPage, loadContentSystemPageQuery } from '../system';
import { SalesAgreementStatus } from 'behavior/salesAgreements';
import { RouteName } from 'routes';
import { areAnalyticsSettingsLoaded } from 'behavior/analytics';
import { agreement } from './stubData';

const salesAgreementHandler: Handler<SalesAgreementPageRouteData, SalesAgreementPage | NotFoundPage> = ({
  params: {
    id: agreementId,
    agreementLine,
    previewToken,
  },
}, state$, { api }) => {
  if (previewToken) {
    return api.graphApi<SystemPageResponse>(loadContentSystemPageQuery('salesAgreement')).pipe(
      map(({ pages: { salesAgreement: page } }) => !page ? null : ({
        page: {
          ...initContentSystemPage(page),
          component: PageComponentNames.SalesAgreement,
          agreement,
        },
      })),
    );
  }

  return state$.pipe(
    first(areAnalyticsSettingsLoaded),
    switchMap(({ analytics }) =>
      api.graphApi<AgreementDetailPageResponse>(agreementDetailPageQuery, { agreementId, loadCategories: analytics.isTrackingEnabled }).pipe(
        mergeMap(({ pages: { salesAgreement: page } }) => {
          if (!page || !page.agreement)
            return EMPTY;

          for (const line of page.agreement.lines) {
            if (line.category) {
              if (line.category.type === 'NotExistingProductCategory') {
                delete line.category.type;
                line.category.exists = false;
              } else {
                line.category.exists = true;
              }
            }
          }
        
          page.agreement.expandedAgreementLineId = agreementLine;
        
          const currentPageState = state$.value.page as SalesAgreementsState;
          if (currentPageState && currentPageState.agreement && currentPageState.agreement.id === page.agreement.id)
            page.search = currentPageState.search;
          
          return of({
            page: {
              ...initContentSystemPage(page),
              component: PageComponentNames.SalesAgreement,
            },
            action$: of(requestAgreementLinesAvailability(page.agreement)),
          });
        }),
      ),
    ),
  );
};

export default salesAgreementHandler;

type SalesAgreementPage = ContentSystemPage & {
  component: PageComponentNames;
  agreement: SalesAgreement | null;
};

type NotFoundPage = {
  component: PageComponentNames.NotFound;
};

type SalesAgreementPageRouteData = RouteData & {
  routeName: RouteName;
  params: {
    id: string;
    agreementLine?: string;
    previewToken?: string;
  };
};

type SystemPageResponse = {
  pages: {
    salesAgreement: ContentSystemPageData | null;
  };
};

type AgreementDetailPageResponse = SystemPageResponse & {
  pages: {
    salesAgreement: {
      agreement: {
        id: string;
        effectiveDate: string | null;
        expirationDate: string | null;
        currency: {
          id: string;
          cultureName: string;
          symbol: string;
          decimalDigits: number;
        };
        address: string | null;
        lines: {
          id: string;
          category: {
            id: string;
            name: string;
            type?: string;
            exists?: boolean;
          } | null;
          discountPercent: number | null;
          price: number | null;
          effectiveDate: string | null;
          expirationDate: string | null;
          isMaxEnforced: boolean;
          product: {
            id: string;
            title: string | null;
            url: string | null;
            exists: boolean;
            isOrderable: boolean | null;
            inventory: number | null;
            stockLevels: {
              outOfStock: number;
              lowStock: number;
              maxStockNumber: number | null;
            } | null;
            uom: {
              id: string;
              description: string | null;
            } | null;
            uoms: {
              id: string;
              description: string | null;
              quantityStep: number;
              maximumQuantity: number | null;
              minimumQuantity: number | null;
            }[] | null;
            categoriesPaths: {
              categories: {
                id: string;
                name: string;
              }[];
            };
            productConfiguratorInfo: {
              isProductConfigurable: boolean | null;
              configuratorId: string | null;
              modelId: string | null;
            } | null;
            variantComponentGroups: {
              id: string;
              name: string | null;
              components: {
                id: string | null;
                name: string | null;
                variants: string[];
              }[];
            }[];
            variants: {
              id: string;
              isOrderable: boolean | null;
            }[] | null;
          } | null;
          uom: {
            id: string;
            description: string | null;
          } | null;
          quantities: {
            value: number | null;
            invoiced: number | null;
            released: number | null;
            remaining: number | null;
            shipped: number | null;
          };
          amounts: {
            value: number | null;
            invoiced: number | null;
            max: number | null;
            min: number | null;
            released: number | null;
            remaining: number | null;
            shipped: number | null;
          };
          location: {
            code: string | null;
            title: string | null;
          } | null;
        }[];
        status: SalesAgreementStatus;
        title: string | null;
        url: string;
        expandedAgreementLineId?: string;
      } | null;
      search?: {
        keywords: string;
        ids: string[] | null;
      };
    } | null;
  };
};