import styles from './header/Header.module.scss';
import { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MobileHeaderTemplate from './header/MobileHeaderTemplate';
import TabletHeaderTemplate from './header/TabletHeaderTemplate';
import DesktopHeaderTemplate from './header/DesktopHeaderTemplate';
import withHeaderContext from './headerContext/withHeaderContext';
import { useResponsiveBreakpoints } from 'utils/layout';
import { joinClasses } from 'utils/helpers';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import { Media } from 'components/responsive';
import { scroll$ } from 'utils/rxjs';
import { merge, of } from 'rxjs';
import { useLayoutShifter } from 'utils/layout';

const Header = ({ isDesktopNavHovered, setDesktopNavHoverStatus }) => {
  const ref = useRef();
  const { xs, md, lg, xl } = useResponsiveBreakpoints();
  const isDesktop = md || lg || xl;
  const [isSticky, setSticky] = useState(false);
  const { topFixedElementsHeight } = useLayoutShifter();
  const headerHeight = useRef('auto');

  useEffect(() => { 
    toggleOverflowAnchorState();
  }, []);

  useEffect(() => {
    !isDesktop && setDesktopNavHoverStatus(false);
  }, [isDesktop]);

  useEffect(() => {
    if (!isDesktop)
      return;

    if (isDesktopNavHovered) {
      const className = 'allow-root-overflow';
      document.documentElement.classList.add(className);

      return () => {
        document.documentElement.classList.remove(className);
      };
    }
  }, [isDesktop, isDesktopNavHovered]);

  useEffect(() => {
    const eventSubscription = merge(of(), scroll$).subscribe(() => {
      const isStaticHeaderInView = ref.current.offsetHeight < window.scrollY;
      setSticky(isStickyPrev => {
        if (isStaticHeaderInView !== isStickyPrev) {
          const { height } = ref.current.getBoundingClientRect();
          headerHeight.current = isStickyPrev ? 'auto' : height + 'px';
        }
        return isStaticHeaderInView;
      });
    });

    return () => eventSubscription.unsubscribe();
  }, [isDesktopNavHovered, isSticky]);

  const desktopNavHovered = isDesktop && isDesktopNavHovered;
  const showSticky = isSticky && !desktopNavHovered;

  const headerClassName = joinClasses(
    isDesktop && styles.desktop,
    xs && styles.mobile,
    desktopNavHovered && styles.navIsHovered,
  );

  const headerStyles = {
    '--sticky-header-margin-top': `${topFixedElementsHeight}px`,
    minHeight: headerHeight.current,
  };

  return (
    <header id="header" className={headerClassName} ref={ref} style={headerStyles}>
      <Media at="xs">
        <MobileHeaderTemplate isSticky={showSticky} />
      </Media>
      <Media at="sm">
        <TabletHeaderTemplate isSticky={showSticky} />
      </Media>
      <Media greaterThanOrEqual="md">
        <DesktopHeaderTemplate isSticky={showSticky} />
      </Media>
    </header>
  );
};

Header.propTypes = {
  isDesktopNavHovered: PropTypes.bool.isRequired,
  setDesktopNavHoverStatus: PropTypes.func.isRequired,
};

const MemoizedHeader = memo(Header);

export default withHeaderContext(MemoizedHeader);
