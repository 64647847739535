import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CloseIcon } from 'components/primitives/icons';
import { productFiltersBlockSelectedValueChanged } from 'behavior/productFilterBlock';
import { useDispatch } from 'react-redux';
import styles from './ProductFilterBlock.module.scss';
const ProductFilterLabel = ({ productFilterBlock }) => {
  const dispatch = useDispatch();

  const getSelectedFilterValue = () => {

    const values = productFilterBlock.selectedProductFilters &&
      productFilterBlock.selectedProductFilters.map(filter => filter?.values[0]?.value).join(' ');
    return values;
  };

  const selectedFilterValue = getSelectedFilterValue();

  const clearSelectedFilters = () => {
    setSelectedFilterValue([]);
  };

  const setSelectedFilterValue = selectedDataFilters => {
    dispatch(
      productFiltersBlockSelectedValueChanged(selectedDataFilters),
    );
  };

  return (
    selectedFilterValue.length > 0 &&
    <div className={styles.productFilterLabel}>
      {selectedFilterValue}
      <button type="button" onClick={clearSelectedFilters} className={styles.clearBtn}>
        <CloseIcon />
      </button>
    </div>
  );
};

ProductFilterLabel.propTypes = {
  productFilterBlock: PropTypes.object,
};

export default connect(({ productFilterBlock }) => ({
  productFilterBlock,
}))(ProductFilterLabel);
