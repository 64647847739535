export const AGREEMENT_APPLY = 'AGREEMENT_APPLY' as const;
export const applyAgreement = (salesAgreementId: string) => ({
  type: AGREEMENT_APPLY,
  payload: { salesAgreementId },
});

export const AGREEMENT_CANCEL = 'AGREEMENT_CANCEL' as const;
export const cancelAgreement = () => ({
  type: AGREEMENT_CANCEL,
});

export const AGREEMENT_APPLIED = 'AGREEMENT_APPLIED' as const;
export const agreementApplied = (salesAgreementId: string) => ({
  type: AGREEMENT_APPLIED,
  payload: { salesAgreementId },
});

export const AGREEMENT_CANCELED = 'AGREEMENT_CANCELED' as const;
export const agreementCanceled = () => ({
  type: AGREEMENT_CANCELED,
});

export const AGREEMENT_TERMS_APPLY = 'AGREEMENT_TERMS_APPLY' as const;
export const applyAgreementTerms = () => ({
  type: AGREEMENT_TERMS_APPLY,
});

export type AgreementAppliedAction = ReturnType<typeof agreementApplied>;
export type SalesAgreementAction =
  | AgreementAppliedAction
  | ReturnType<
    | typeof applyAgreement
    | typeof cancelAgreement
    | typeof agreementCanceled
    | typeof applyAgreementTerms
  >;
