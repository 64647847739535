import Autowhatever from 'react-autosuggest/dist/Autowhatever';
import SectionTitle from 'react-autosuggest/dist/SectionTitle';
import ExtendedItemList from './ExtendedItemList';

export default class ExtendedAutowhatever extends Autowhatever {
  renderItems() {
    const { theme } = this;
    const {
      items,
      id,
      renderItem,
      renderItemData,
      highlightedSectionIndex,
      highlightedItemIndex,
      itemProps,
      listId,
      listLabel,
    } = this.props;

    return (
      <ExtendedItemList
        id={listId}
        items={items}
        itemProps={itemProps}
        renderItem={renderItem}
        renderItemData={renderItemData}
        highlightedItemIndex={
          highlightedSectionIndex === null ? highlightedItemIndex : null
        }
        onHighlightedItemChange={this.onHighlightedItemChange}
        getItemId={this.getItemId}
        theme={theme}
        keyPrefix={`react-autowhatever-${id}-`}
        listLabel={listLabel}
      />
    );
  }
  renderSections() {
    if (this.allSectionsAreEmpty) {
      return null;
    }

    const { theme } = this;
    const {
      id,
      items,
      renderItem,
      renderItemData,
      renderSectionTitle,
      highlightedSectionIndex,
      highlightedItemIndex,
      itemProps,
      listId,
      listLabel,
    } = this.props;

    return items.map((section, sectionIndex) => {
      const keyPrefix = `react-autowhatever-${id}-`;
      const sectionKeyPrefix = `${keyPrefix}section-${sectionIndex}-`;
      const isFirstSection = sectionIndex === 0;

      // `key` is provided by theme()
      /* eslint-disable react/jsx-key */
      return (
        <div
          {...theme(
            `${sectionKeyPrefix}container`,
            'sectionContainer',
            isFirstSection && 'sectionContainerFirst',
          )}
        >
          <SectionTitle
            section={section}
            renderSectionTitle={renderSectionTitle}
            theme={theme}
            sectionKeyPrefix={sectionKeyPrefix}
          />
          <ExtendedItemList
            id={listId}
            items={this.sectionsItems[sectionIndex]}
            itemProps={itemProps}
            renderItem={renderItem}
            renderItemData={renderItemData}
            sectionIndex={sectionIndex}
            highlightedItemIndex={
              highlightedSectionIndex === sectionIndex
                ? highlightedItemIndex
                : null
            }
            onHighlightedItemChange={this.onHighlightedItemChange}
            getItemId={this.getItemId}
            theme={theme}
            keyPrefix={keyPrefix}
            ref={this.storeItemsListReference}
            listLabel={listLabel}
          />
        </div>
      );
      /* eslint-enable react/jsx-key */
    });
  }

  render() {
    const { theme } = this;
    const {
      items,
      id,
      listId,
      multiSection,
      renderInputComponent,
      renderItemsContainer,
      highlightedSectionIndex,
      highlightedItemIndex,
    } = this.props;
    const { isInputFocused } = this.state;
    const renderedItems = multiSection
      ? this.renderSections()
      : this.renderItems();
    const isOpen = renderedItems !== null && !!items.length;
    const ariaActivedescendant = this.getItemId(
      highlightedSectionIndex,
      highlightedItemIndex,
    );
    const itemsContainerId = `react-autowhatever-${id}`;
    const containerProps = {
      ...theme(
        `react-autowhatever-${id}-container`,
        'container',
        isOpen && 'containerOpen',
      ),
      ...this.props.containerProps,
    };
    const inputComponent = renderInputComponent({
      type: 'text',
      value: '',
      autoComplete: 'off',
      role: 'combobox',
      'aria-controls': listId,
      'aria-owns': listId,
      'aria-expanded': !!items.length,
      'aria-autocomplete': 'list',
      'aria-activedescendant': ariaActivedescendant,
      ...theme(
        `react-autowhatever-${id}-input`,
        'input',
        isOpen && 'inputOpen',
        isInputFocused && 'inputFocused',
      ),
      ...this.props.inputProps,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyDown: this.props.inputProps.onKeyDown && this.onKeyDown,
      ref: this.storeInputReference,
    });
    const itemsContainer = renderItemsContainer({
      containerProps: {
        id: itemsContainerId,
        ...theme(
          `react-autowhatever-${id}-items-container`,
          'itemsContainer',
          isOpen && 'itemsContainerOpen',
        ),
        ref: this.storeItemsContainerReference,
      },
      children: renderedItems,
    });

    return (
      <div {...containerProps}>
        {inputComponent}
        {itemsContainer}
      </div>
    );
  }
}