import { memo, ReactNode } from 'react';
import styles from './Placeholder.module.scss';

type Props = {
  className?: string | null;
  lineCount?: number;
};

const Placeholder = ({ className, lineCount = 1, ...attributes }: Props & Omit<JSX.IntrinsicElements['span'], 'className'>) => {
  const placeholders: ReactNode[] = [];

  for (let i = 0; i < lineCount; i++) {
    placeholders.push((
      <span
        key={i}
        className={`${className || ''} ${styles.placeholderItem}`}
        {...attributes}
      />
    ));
  }

  return <>{placeholders}</>;
};

export default memo(Placeholder);
