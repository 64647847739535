import type { Product, Suggestion } from './types';
import type {
  ProductSelectorAction,
  ReceiveSearchSuggestionsAction,
  ReceiveProductAction,
} from './actions';

import { createReducer } from 'utils/redux';
import {
  PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  PRODUCT_SELECTOR_PRODUCT_RECEIVED,
  PRODUCT_SELECTOR_PRODUCT_CLEARED,
} from './actions';
import { NAVIGATED, RoutingAction } from 'behavior/routing';

type ProductSelectorState = {
  suggestions: Suggestion[];
  selectedProduct: Product | null;
};

const initialState: ProductSelectorState = {
  suggestions: [],
  selectedProduct: null,
};

export default createReducer<ProductSelectorState, ProductSelectorAction | RoutingAction>(initialState, {
  [PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED]: onSearchSuggestionsReceived,
  [PRODUCT_SELECTOR_PRODUCT_RECEIVED]: onProductReceived,
  [PRODUCT_SELECTOR_PRODUCT_CLEARED]: onProductCleared,
  [NAVIGATED]: onNavigated,
});

function onSearchSuggestionsReceived(state: ProductSelectorState, action: ReceiveSearchSuggestionsAction) {
  return { ...state, suggestions: action.payload.suggestions };
}

function onProductReceived(state: ProductSelectorState, action: ReceiveProductAction) {
  return { ...state, selectedProduct: action.payload.product };
}

function onProductCleared(state: ProductSelectorState) {
  return { ...state, selectedProduct: null };
}

function onNavigated() {
  return initialState;
}
