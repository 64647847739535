import PropTypes from 'prop-types';
import SummaryBase from './SummaryBase';

const DesktopBasketSummaryBlock = ({ id, model, isDesignerMode }) => {
  const options = {
    compact: false,
    showBasketLinkLabel: true,
    showSubtotal: model.showSubtotal,
    showMiniatureOnBasket: model.showMiniatureOnBasket,
    showExpandIcon: model.content_ShowExpandIcon,
    showThumbnails: model.content_ShowThumbnails,
    basketLinkLayout: model.basketLink_Layout,
    basketLinkThemeFontSize: model.basketLink_ThemeFontSize,
    contentThemeFontSize: model.content_ThemeFontSize,
    headingThemeFontSize: model.heading_ThemeFontSize,
    footerBasketLinkStyle: model.footer_BasketLinkStyle,
    footerCheckoutLinkStyle: model.footer_CheckoutLinkStyle,
    swapFooterLinksPosition: model.footer_SwapLinksPosition,
  };
  const summaryId = `Summary_${id}`;

  const style = {
    '--basketLink-layout': model.basketLink_Layout,
    '--basketLink-fontFamily': model.basketLink_FontFamily && (model.basketLink_FontFamily + ', sans-serif'),
    '--basketLink-allCaps': model.basketLink_AllCaps ? 'uppercase' : null,
    '--basketLink-iconColor': model.basketLink_IconColor,
    '--basketLink-iconHoverColor': model.basketLink_IconHoverColor,
    '--basketLink-fontColor': model.basketLink_FontColor,
    '--basketLink-emptyCartFontColor': model.basketLink_EmptyCartFontColor,
    '--basketLink-badgeBackgroundColor': model.basketLink_BadgeBackgroundColor,
    '--content-fontColor': model.content_FontColor,
    '--content-backgroundColor': model.content_BackgroundColor,
    '--content-borderColor': model.content_BorderColor,
    '--content-separatorsColor': model.content_SeparatorsColor,
    '--heading-fontFamily': model.heading_FontFamily,
    '--heading-allCaps': model.heading_AllCaps ? 'uppercase' : null,
    '--heading-fontColor': model.heading_FontColor,
    '--footer-linkFontColor': model.footer_LinkFontColor,
    '--footer-linkHoverFontColor': model.footer_LinkHoverFontColor,
  };

  return (
    <SummaryBase
      style={style}
      id={summaryId}
      isDesignerMode={isDesignerMode}
      options={options}
    />
  );
};

DesktopBasketSummaryBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showMiniatureOnBasket: PropTypes.bool.isRequired,
    basketLink_Layout: PropTypes.number.isRequired,
    basketLink_FontFamily: PropTypes.string,
    basketLink_ThemeFontSize: PropTypes.string.isRequired,
    basketLink_AllCaps: PropTypes.bool.isRequired,
    basketLink_IconColor: PropTypes.string,
    basketLink_FontColor: PropTypes.string,
    basketLink_EmptyCartFontColor: PropTypes.string,
    basketLink_BadgeBackgroundColor: PropTypes.string,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_ThemeFontSize: PropTypes.string.isRequired,
    content_FontColor: PropTypes.string,
    content_BackgroundColor: PropTypes.string,
    content_BorderColor: PropTypes.string,
    content_SeparatorsColor: PropTypes.string,
    content_ShowThumbnails: PropTypes.bool.isRequired,
    heading_FontFamily: PropTypes.string,
    heading_ThemeFontSize: PropTypes.string.isRequired,
    heading_AllCaps: PropTypes.bool.isRequired,
    heading_FontColor: PropTypes.string,
    footer_BasketLinkStyle: PropTypes.number.isRequired,
    footer_CheckoutLinkStyle: PropTypes.number.isRequired,
    footer_SwapLinksPosition: PropTypes.bool.isRequired,
    footer_LinkFontColor: PropTypes.string,
    footer_LinkHoverFontColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default DesktopBasketSummaryBlock;
