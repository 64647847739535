import { ofType } from 'redux-observable';
import { combineEpics } from 'utils/rxjs';
import {
  map,
  switchMap,
  takeUntil,
  filter,
  pluck,
  tap,
  first,
  mergeMap,
} from 'rxjs/operators';
import {
  ALTERNATE_URLS_REQUESTED,
  URL_LANGUAGE_CHANGED,
  languagesLoaded,
  alternateUrlsLoaded,
  urlLanguageChanged,
  LanguageAction,
} from './actions';
import { languagesQuery, alternateUrlsQuery } from './queries';
import { mapRouteToInput } from './helpers';
import { NAVIGATED, NAVIGATING, RoutingAction } from 'behavior/routing';
import { languageChanged } from 'behavior/events';
import { APP_INIT, InitAppAction } from 'behavior/app';
import { RouteName } from 'routes';
import type { Epic } from 'behavior/types';
import type { AppState } from 'behavior';
import type { Api } from 'utils/api';
import type { Observable } from 'rxjs';
import { AlternateUrl, LanguageType } from './types';

type LanguagesQueryResponse = {
  languages: Array<LanguageType>;
};

type AlternateUrlsQueryResponse = {
  routing: {
    alternateUrls: Array<AlternateUrl>;
  };
};

const languagesEpic: Epic<InitAppAction> = (action$, _state$, { api }) => action$.pipe(
  ofType(APP_INIT),
  switchMap(_ => api.graphApi<LanguagesQueryResponse>(languagesQuery).pipe(
    map(data => languagesLoaded(data.languages)),
  )),
);

const alternateUrlsEpic: Epic<LanguageAction | RoutingAction> = (action$, state$, { api, scope }) => action$.pipe(
  ofType(ALTERNATE_URLS_REQUESTED, scope === 'SERVER' ? NAVIGATED : ''),
  switchMap(_ => {
    const state = state$.value;
    if (state.routing.routeData)
      return loadAlternateUrls(action$, state, api);

    return state$.pipe(
      first(s => !!s.routing.routeData),
      mergeMap(state => loadAlternateUrls(action$, state, api)),
    );
  }),
);

const navigateEpic: Epic<RoutingAction> = (action$, state$, { api }) => action$.pipe(
  ofType(NAVIGATED, NAVIGATING),
  pluck('payload', 'routeData', 'params'),
  map(params => params && params.language as number),
  filter(language => (!!language || !state$.value.localization.currentLanguage.id) && language !== state$.value.localization.currentLanguage.id),
  tap(language => api.setLanguage(language!.toString())),
  map(language => urlLanguageChanged(language!)),
);

const languageChangedEventEpic: Epic<LanguageAction> = action$ => action$.pipe(
  ofType(URL_LANGUAGE_CHANGED),
  map(languageChanged),
);

export default combineEpics(
  languagesEpic,
  alternateUrlsEpic,
  navigateEpic,
  languageChangedEventEpic,
);

function loadAlternateUrls(action$: Observable<LanguageAction | RoutingAction>, state: AppState, api: Api) {
  const route = mapRouteToInput(state.routing.routeData!);
  if (!route.routeName)
    route.routeName = RouteName.NotFound;

  route.params.push({ key: 'currentPath', value: state.routing.location!.pathname });

  return api.graphApi<AlternateUrlsQueryResponse>(alternateUrlsQuery, { route }).pipe(
    map(({ routing }) => alternateUrlsLoaded(routing.alternateUrls)),
    takeUntil(action$.pipe(ofType(NAVIGATED))),
  );
}
