import type { Product as GeneralProduct, CalculatedProduct } from '../types';
import type { ViewerChangedAction } from 'behavior/events';
import type { PageComponentNames } from 'behavior/pages/componentNames';
import { PageReceivedAction, LASTVIEWED_PRODUCTS_PAGE_RECEIVED } from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { deleteProductCalculatedInfo } from 'behavior/products/product';
import { createReducer } from 'utils/redux';

type Product = GeneralProduct | GeneralProduct & CalculatedProduct;

type State = {
  component: PageComponentNames.LastViewedProducts;
  lastViewed: Product[] | null;
};

export default createReducer<State, ViewerChangedAction | PageReceivedAction>(null as unknown as State, {
  [LASTVIEWED_PRODUCTS_PAGE_RECEIVED]: onProductsReceived,
  [VIEWER_CHANGED]: onViewerChanged,
});

function onProductsReceived(state: State, action: PageReceivedAction): State {
  const products = action.payload;
  const lastViewed = state.lastViewed;

  if (lastViewed && lastViewed.length)
    return {
      ...state,
      lastViewed: lastViewed.map(product => {
        const calculated = products.find(({ id }) => id === product.id);
        return { ...product, ...calculated };
      }),
    };

  return { ...state, lastViewed: products as Product[] };
}

function onViewerChanged(state: State): State {
  if (!state.lastViewed)
    return state;

  const lastViewed = state.lastViewed.map(p => deleteProductCalculatedInfo(p as GeneralProduct & CalculatedProduct));
  return { ...state, lastViewed };
}
