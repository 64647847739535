import type { Middleware } from '../types';
import { createUrl } from '../helpers';
import { redirectTo } from '../actions';
import { redirect } from './helpers';

const languageMiddleware: Middleware = (route, location, dependencies) => {
  if (!route.canonicalUrl)
    return;

  const { params, canonicalUrl, language } = route;

  if (!language)
    return redirect(canonicalUrl, location, undefined, dependencies);

  const languageSegment = `/${language.name.toLowerCase()}`;
  const routeData = { routeName: route.routeName, params };

  if (canonicalUrl.startsWith(languageSegment)) {
    if (!location.pathname.startsWith(languageSegment)) {
      return redirectTo(createUrl({ ...location, pathname: languageSegment + location.pathname }), 302, routeData);
    }
  } else if (location.pathname.startsWith(languageSegment)) {
    const pathname = location.pathname.substring(languageSegment.length);
    return redirectTo(createUrl({ ...location, pathname }), 302, routeData);
  }

  return;
};

export default languageMiddleware;
