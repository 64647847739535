import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';
import { selectPropsFromPage } from '../helpers';

const SearchPage = loadable(() => import(/*webpackChunkName:"plp"*/'./Page'));

export default {
  [PageComponentNames.Search]: page => (
    <SearchPage
      facets={page.facets}
      preset={page.preset}
      header={page.content?.header}
      footer={page.content?.footer}
      {...selectPropsFromPage(page)}
    />
  ),
};
