import { RouteName, RouteData } from 'routes';

export const mapRouteToInput = ({ routeName, params }: RouteData) => ({
  key: '',
  routeName: params?.previewToken ? RouteName.PagePreview : routeName,
  params: params ? Object.keys(params).map(k => ({ key: mapParamKey(k), value: stringifyParamValue(params[k]) })) : [],
});

function mapParamKey(key: string): string {
  if (key === 'previewToken')
    return 'token';

  return key;
}

function stringifyParamValue(value: unknown): string {
  if (typeof value === 'string')
    return value;

  return JSON.stringify(value);
}
