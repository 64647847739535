import type { CheckoutOffer } from './types';
import type { Epic } from 'behavior/types';
import type { CheckoutOffersAction } from './actions';
import { of, timer } from 'rxjs';
import {
  switchMap,
  catchError,
  map,
  onErrorResumeNext,
  mapTo,
  delayWhen,
  first,
  withLatestFrom,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CHECKOUT_OFFERS_REQUESTED, offersReceived, offersExpired } from './actions';
import { checkoutOffersQuery } from './queries';
import { NAVIGATING, RoutingAction } from 'behavior/routing';

type CheckoutOffersResponse = {
  catalog: {
    checkoutOffers: CheckoutOffer[] | null;
  };
};

export const checkoutOffersEpic: Epic<CheckoutOffersAction | RoutingAction> = (action$, state$, { api, logger }) => {
  const navigating$ = action$.pipe(
    ofType(NAVIGATING),
    first(),
  );

  // Emit offers expiration action on next navigation after 5 minutes timeout.
  const offersExpirationTimeout$ = timer(300000).pipe(
    delayWhen(_ => navigating$),
    mapTo(offersExpired()),
  );

  return action$.pipe(
    ofType(CHECKOUT_OFFERS_REQUESTED),
    withLatestFrom(state$),
    map(([_, { analytics }]) => ({
      loadCategories: analytics && analytics.isTrackingEnabled,
    })),
    switchMap(params => api.graphApi<CheckoutOffersResponse>(checkoutOffersQuery, params).pipe(
      map(data => {
        const offers = data.catalog.checkoutOffers ?? [];
        return offersReceived(offers);
      }),
      first(),
      catchError(err => {
        logger.error('The following error occurred while requesting checkout offers:', err);
        return of(offersReceived([]));
      }),
      onErrorResumeNext(offersExpirationTimeout$),
    )),
  );
};

export default checkoutOffersEpic;