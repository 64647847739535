import type { NewsItem } from './types';

export const NEWS_LOAD = 'NEWS/LOAD' as const;
export const loadNews = (index: number, size: number) => ({
  type: NEWS_LOAD,
  payload: {
    page: { index, size },
  },
});

export const NEWS_LOADED = 'NEWS/LOADED' as const;
export const newsLoaded = (news: NewsItem[]) => ({
  type: NEWS_LOADED,
  payload: news,
});

export type NewsAction = ReturnType<
  | typeof loadNews
  | typeof newsLoaded
>;
