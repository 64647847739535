import { RouteName } from 'routes';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { ContentSystemPage, ContentSystemPageData, initContentSystemPage, loadContentSystemPageQuery } from '../system';
import type { Handler } from '../types';

const handler: Handler<NewsletterSubscribeRouteData, NewsletterSubscribePage> = (_routeData, _state$, { api }) =>
  api.graphApi<NewsletterSubscribePageResponse>(loadContentSystemPageQuery('subscribe')).pipe(
    map(({ pages: { subscribe } }) => ({
      page: {
        ...initContentSystemPage(subscribe),
        component: PageComponentNames.Subscribe,
      },
    }),
  ),
);

export default handler;

type NewsletterSubscribeRouteData = {
  routeName: RouteName.NewsletterSubscribe;
};

type NewsletterSubscribePage = ContentSystemPage & {
  component: PageComponentNames.Subscribe;
};

type NewsletterSubscribePageResponse = {
  pages: {
    subscribe: ContentSystemPageData;
  };
};
