import styles from './LoadingIndicator.module.scss';
import { useRef, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setStatusTextKey } from 'behavior/ariaStatus';

const LoadingIndicator = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isLoading = useSelector(s => s.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading == null)
      return;

    if (isLoading) {
      ref.current!.style.opacity = '1';
      ref.current!.classList.add(styles.animate);

      let announceTimeoutId: number | undefined;
      const announce = () => {
        dispatch(setStatusTextKey('Aria_LoadingIndicator_Active', true));
        /* Timeout value for repeated announcement is based on time between two 'tick' sounds which JAWS screen reader engine plays for progress bars. */
        announceTimeoutId = window.setTimeout(announce, 5000);
      };

      /* First announce timeout value choice is based on first input delay (FID - measures the time from when a user first interacts with application/site to the time when
      the browser is actually able to respond to that interaction) value of 500ms plus extra 500ms. Value of 1000ms proved to be optimal during practical testing. */
      announceTimeoutId = window.setTimeout(announce, 1000);
      return () => window.clearTimeout(announceTimeoutId);
    } else {
      const animationTimeoutId = setTimeout(() => {
        ref.current!.style.opacity = '0';
        ref.current!.classList.remove(styles.animate);
      }, 500);
      return () => window.clearTimeout(animationTimeoutId);
    }
  }, [isLoading]);

  return (
    <div style={{ opacity: 0 }} ref={ref} className={styles.loadingIndicator}>
      <div className={styles.indicatorLine} />
    </div>
  );
};

export default memo(LoadingIndicator);
