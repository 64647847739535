import type { ProductComparisonPageState } from './types';
import { createReducer } from 'utils/redux';
import { removeProductFromSpecificationsModel } from './util';
import { PageComponentNames } from 'behavior/pages';
import {
  PRODUCT_COMPARISON_REMOVE_PRODUCT,
  PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS,
  ProductComparisonAction,
} from 'behavior/productComparison/actions';

export default createReducer<ProductComparisonPageState | null, ProductComparisonAction>(null, {
  [PRODUCT_COMPARISON_REMOVE_PRODUCT]: onProductRemoved,
  [PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS]: onAllProductsRemoved,
});

function onProductRemoved(state: ProductComparisonPageState | null, action: { payload: { id: string } }) {
  if (state?.component !== PageComponentNames.ProductComparison)
    return state;

  const { products, specifications } = state;
  const { id } = action.payload;
  const productToRemove = products.find(item => item.id === id);

  return {
    ...state,
    products: products.filter(item => item.id !== id),
    specifications: productToRemove
      ? removeProductFromSpecificationsModel(specifications, products.indexOf(productToRemove))
      : specifications,
  };
}

function onAllProductsRemoved(state: ProductComparisonPageState | null) {
  if (state?.component !== PageComponentNames.ProductComparison)
    return state;

  return {
    ...state,
    products: [],
    specifications: [],
  };
}
