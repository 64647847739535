import { forwardRef, memo } from 'react';
import MobileFooterTemplate from './footer/MobileFooterTemplate';
import DesktopFooterTemplate from './footer/DesktopFooterTemplate';
import { Media } from 'components/responsive';

const Footer = forwardRef((_, ref) => (
  <footer id="footer" ref={ref}>
    <Media at="xs">
      <MobileFooterTemplate />
    </Media>
    <Media greaterThanOrEqual="sm">
      <DesktopFooterTemplate />
    </Media>
  </footer>
));

export default memo(Footer);
