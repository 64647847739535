export const SANATEXTS_REQUESTED = 'SANATEXTS_REQUESTED' as const;
export const loadSanaTexts = (keys: string[]) => ({
  type: SANATEXTS_REQUESTED,
  payload: keys,
});

export const SANATEXTS_LOADED = 'SANATEXTS_LOADED' as const;
export const sanaTextsLoaded = (sanaTexts: Record<string, string | null>) => ({
  type: SANATEXTS_LOADED,
  payload: sanaTexts,
});

export type SanaTextAction = ReturnType<
  | typeof loadSanaTexts
  | typeof sanaTextsLoaded
>;
