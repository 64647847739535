import type { CreateReturnOrderPage } from './types';
import {
  CreateDocBasedReturnOrderAction,
  DOC_BASED_RETURN_ORDER_SUBMITTED,
  DOC_BASED_RETURN_ORDER_FAILED,
} from './actions';

export default (state: CreateReturnOrderPage, action: CreateDocBasedReturnOrderAction): CreateReturnOrderPage => {
  switch (action.type) {
    case DOC_BASED_RETURN_ORDER_SUBMITTED:
      return { ...state, isFailed: undefined, updatedById: undefined };
    case DOC_BASED_RETURN_ORDER_FAILED:
      return { ...state, isFailed: true, updatedById: action.payload.updatedById };
    default:
      return state;
  }
};
