import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { renderRows, extendDefaultSettings } from 'components/containers';
import UseStickyHeader from './UseStickyHeader';
import { tablet as defaultContent } from './defaultContent';

const TabletHeaderTemplate = ({ isSticky }) => {
  const { tablet, showTabletSticky, tabletSticky } = useSelector(state => state.header);
  const showSticky = showTabletSticky && isSticky;

  if (!showTabletSticky)
    return renderRows(tablet ?? extendDefaultSettings(defaultContent));

  return (
    <UseStickyHeader showSticky={showSticky}>
      {showSticky
        ? renderRows(tabletSticky ?? extendDefaultSettings(defaultContent))
        : renderRows(tablet ?? extendDefaultSettings(defaultContent))
      }
    </UseStickyHeader>
  );
};

TabletHeaderTemplate.propTypes = {
  isSticky: PropTypes.bool,
};

export default memo(TabletHeaderTemplate);
