import { memo, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import HeaderContext from './HeaderContext';
import { useOnPageChanged } from 'utils/hooks';

export default function withHeaderContext(Component) {
  function WrapWithHeaderContext({ isPrintMode, ...props }) {
    const searchInputValueRef = useRef('');
    const isSummaryOpenedRef = useRef(false);
    const [isDesktopNavHovered, setDesktopNavHoverStatus] = useState(false);

    useOnPageChanged(() => void (isSummaryOpenedRef.current = false));

    if (isPrintMode)
      isSummaryOpenedRef.current = false;

    const contextValue = useMemo(() => ({
      get searchInputValue() {
        return searchInputValueRef.current;
      },
      set searchInputValue(value) {
        searchInputValueRef.current = value;
      },
      get isSummaryOpened() {
        return isSummaryOpenedRef.current;
      },
      set isSummaryOpened(value) {
        isSummaryOpenedRef.current = value;
      },
      get available() {
        return true;
      },
      setDesktopNavHoverStatus,
    }), []);

    return (
      <HeaderContext.Provider value={contextValue}>
        {isPrintMode ? null : <Component {...props} isDesktopNavHovered={isDesktopNavHovered} setDesktopNavHoverStatus={setDesktopNavHoverStatus} />}
      </HeaderContext.Provider>
    );
  }

  WrapWithHeaderContext.prototype.propTypes = {
    isPrintMode: PropTypes.bool,
  };

  return memo(WrapWithHeaderContext);
}
