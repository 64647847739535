import styles from './Faq.module.scss';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import { renderHTML } from 'utils/render';

const FaqList = loadable(() => import(/*webpackChunkName:"faq"*/'./List'));

const FaqPage = ({ heading, headerText, footerText, faqItems }) => {
  const htmlBlockStyles = `fr-view ${styles.htmlBlock}`;

  return (
    <Container>
      <h1>{heading}</h1>
      {headerText && <div className={htmlBlockStyles}>{renderHTML(headerText)}</div>}
      <FaqList faqItems={faqItems} />
      {footerText && <div className={htmlBlockStyles}>{renderHTML(footerText)}</div>}
    </Container>
  );
};

FaqPage.propTypes = {
  heading: PropTypes.string,
  headerText: PropTypes.string,
  footerText: PropTypes.string,
  faqItems: PropTypes.array,
};

export default FaqPage;
