import type { BlogPage } from './types';
import type { ViewMode } from './constants';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { RouteName } from 'routes';
import { Handler } from 'behavior/pages/types';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { blogPageQuery } from './queries';
import { ContentSystemPageData, initContentSystemPage } from '../system';

const handler: Handler<BlogRouteData, BlogPage> = ({ params, options }, state$, { api }) => {
  if (options?.itemsOnly) {
    const page = state$.value.page as BlogPage;

    return of({
      page: {
        ...page,
        options: {
          ...page.options,
          pageSize: options.size || params.count,
          pageIndex: options.page || (params.page ? params.page - 1 : 0),
        },
      },
    });
  }

  return api.graphApi<BlogPageResponse>(blogPageQuery).pipe(
    map(({ pages: { blog } }) => ({
      page: {
        ...initContentSystemPage(blog),
        options: {
          keywords: params.q,
          pageSize: params.count,
          pageIndex: params.page ? params.page - 1 : undefined,
          viewMode: params.viewMode,
          facets: params.facets,
        },
        component: PageComponentNames.Blog as const,
      },
    })),
  );
};

export default handler;

type BlogRouteData = {
  routeName: RouteName.BlogItemPage;
  params: {
    q?: string;
    page?: number;
    count?: number;
    viewMode?: ViewMode;
    facets?: Record<string, string[]>;
  };
  options?: {
    itemsOnly: boolean;
    size?: number;
    page?: number;
  };
};

type BlogPageResponse = {
  pages: {
    blog: ContentSystemPageData;
  };
};
