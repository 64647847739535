import type { Handler } from '../types';
import type { EntityTemplateField } from 'behavior/entityTemplates';
import type { BackTo } from 'routes/types';
import { loadEditProfilePageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent, SystemPageData, SystemPage } from '../system';

const editProfileHandler: Handler<EditProfileRouteData, EditProfilePage> = (routeData, state$, { api }) => {
  return api.graphApi<EditProfilePageResponse>(loadEditProfilePageQuery).pipe(
    map(({ pages, profile }) => {
      const page = pages.editProfile;
      if (!page)
        return null;

      return {
        page: {
          ...page,
          templateFields: profile.profileFields.filter(shouldIncludeField),
          component: PageComponentNames.EditProfile as const,
          backTo: getBackTo(state$, [RouteName.EditProfile], routeData.params?.language),
        },
      };
    }),
    initSystemPageContent(),
  );
};

export default editProfileHandler;

const shouldIncludeField = (field: EntityTemplateField) => {
  return field.type !== 'ReadOnlyTemplateField' || field.displayValue;
};

type EditProfileRouteData = {
  routeName: RouteName.EditProfile;
  params?: {
    language: number;
  };
};

type EditProfilePage = SystemPage & {
  component: PageComponentNames.EditProfile;
  templateFields: EntityTemplateField[];
  backTo?: BackTo;
};

type EditProfilePageResponse = {
  pages: {
    editProfile: SystemPageData;
  };
  profile: {
    profileFields: EntityTemplateField[];
  };
};
