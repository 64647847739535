import styles from './LanguageSelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { getFlagImage } from './flags';

const MobileHeader = ({ selectedLanguage }) => {
  const { name, displayName } = selectedLanguage;
  const imagePath = getFlagImage(selectedLanguage);

  return (
    <div className={styles.header}>
      {imagePath
        ? <img src={imagePath} alt={name} title={displayName} className={styles.flag} />
        : <span className={styles.fakeFlag}>{name}</span>
      }
    </div>
  );
};

MobileHeader.propTypes = {
  selectedLanguage: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    imagePath: PropTypes.string,
  }).isRequired,
};

export default memo(MobileHeader);
