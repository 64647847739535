import type { Handler } from '../types';
import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { paymentFailedQuery } from './queries';
import { RouteName, routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { loadSystemPageQuery, initSystemPageContent, SystemPageData, SystemPage, initPageContent } from 'behavior/pages/system';

const handler: Handler<OrderPaymentFailedRouteData, OrderPaymentFailedPage> = (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<OrderPaymentFailedSystemPageResponse>(loadSystemPageQuery('paymentFailed')).pipe(
      map(({ pages: { paymentFailed } }) => ({
        page: {
          ...paymentFailed,
          component: PageComponentNames.PaymentFailed as const,
          transaction: { id: '' },
        },
      })),
      initSystemPageContent(),
    );
  }
  if (!transactionId)
    return of(null);

  return api.graphApi<OrderPaymentFailedPageResponse>(paymentFailedQuery, { id: transactionId }).pipe(
    mergeMap(({
      pages: { paymentFailed },
      paymentTransaction: transaction,
    }) => {
      if (transaction == null)
        return of(null);

      if (transaction.isPaymentError)
        return of({
          action$: of(navigateTo(routesBuilder.forPaymentError(transactionId))),
        });

      return of({
        page: {
          ...initPageContent(paymentFailed),
          component: PageComponentNames.PaymentFailed as const,
          transaction,
        },
      });
    }),
  );
};

export default handler;

type OrderPaymentFailedRouteData = {
  routeName: RouteName.OrderPayment;
  params: {
    transactionId?: string;
    previewToken?: string;
  };
};

type OrderPaymentFailedPage = SystemPage & {
  component: PageComponentNames.PaymentFailed;
  transaction: {
    id: string;
  };
};

type OrderPaymentFailedSystemPageResponse = {
  pages: {
    paymentFailed: SystemPageData;
  };
};

type OrderPaymentFailedPageResponse = OrderPaymentFailedSystemPageResponse & {
  paymentTransaction: {
    id: string;
    document: {
      id: string;
    };
    isPaymentError: boolean;
  };
};
