import { CONTACT_US_FORM_SUBMITTED, CONTACT_US_RESPONSE_RECEIVED, ContactUsAction } from './actions';
import type { EntityTemplateField } from 'behavior/entityTemplates';

type State = {
  templateFields?: EntityTemplateField[];
  successStatus: boolean | null;
  updatedById: string | null;
};

const initialState: State = {
  successStatus: null,
  updatedById: null,
};

export default function reducer(state: State = initialState, action: ContactUsAction) {
  switch (action.type) {
    case CONTACT_US_FORM_SUBMITTED:
      return { ...state, successStatus: null, updatedById: null };
    case CONTACT_US_RESPONSE_RECEIVED:
      return { ...state, successStatus: action.payload.success, updatedById: action.payload.updatedById };
    default:
      return state;
  }
}
