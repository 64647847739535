import styles from './LanguageSelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { getFlagImage } from './flags';
import { TogglePresentationOption } from './constants';

const Header = ({ selectedLanguage, presentation, className = '' }) => {
  const { name, displayName } = selectedLanguage;
  const imagePath = getFlagImage(selectedLanguage);

  const flagWithText = () => {
    // eslint-disable-next-line react/no-multi-comp
    const Flag = () => <img src={imagePath} alt={name} title={displayName} className={styles.flag} />;

    switch (presentation) {
      case TogglePresentationOption.TextOnly:
        return <span>{displayName}</span>;
      case TogglePresentationOption.TextRightOfFlag:
        return <><Flag /><span>{displayName}</span></>;
      case TogglePresentationOption.TextLeftOfFlag:
        return <><span>{displayName}</span><Flag /></>;
      case TogglePresentationOption.TextBelowFlag:
        return <div><Flag /><div>{displayName}</div></div>;
      default:
        return <Flag />;
    }
  };

  return (
    <div className={`${styles.header} ${className}`}>
      {imagePath ? flagWithText() : <span className={styles.fakeFlag}>{name}</span>}
    </div>
  );
};

Header.propTypes = {
  selectedLanguage: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    imagePath: PropTypes.string,
  }).isRequired,
  presentation: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default memo(Header);
