import type { ErrorResponse } from './types';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export function catchBasketCalculationError<O extends unknown, T extends (e: ErrorResponse) => any>(errorsHandler: T) {
  return catchError<O, ReturnType<T>>((e: ErrorResponse) => {
    if (e.response && e.response.errors && e.response.errors.length) {
      for (let i = 0, l = e.response.errors.length; i < l; i++) {
        const error = e.response.errors[i];
        const errorCode = error.extensions?.code;

        if (errorCode === 'BASKET_CALCULATION')
          return errorsHandler(e);
      }
    }

    return throwError(e);
  });
}
