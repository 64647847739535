import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { defaultSearchParams } from './constants';
import { createSearchStructure, useTrackingOptions } from 'utils/structureData';
import renderUntilPageChanged from 'components/objects/renderUntilPageChanged';

const Schema = (props: { routePath: string }) => {
  const trackingOptions = useTrackingOptions();

  const target = props.routePath.replace(defaultSearchParams, '{q}');
  const data = createSearchStructure(target, trackingOptions);

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>
    </Helmet>
  );
};

const Memoized = memo(Schema, _ => true);

export default renderUntilPageChanged(Memoized);
