import { SimpleText } from 'components/sanaText';
import { groupBy } from 'lodash';
import { Fragment } from 'react';
import { Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';

const renderMake = (results, _styles, menuProps) => {
  const makeConstants = {
    TopMakes: 'TopMakes',
    AllMakes: 'AllMakes',
  };
  let index = 0;
  const groupsMakes = groupBy(results, 'isTop');
  const items = Object.keys(groupsMakes).map(isTop => (
    <Fragment key={isTop === 'true' ? makeConstants.TopMakes : makeConstants.AllMakes}>
      {index !== 0 && <Menu.Divider />}
      <Menu.Header>
        {isTop === 'true' ? (
          <SimpleText textKey={makeConstants.TopMakes} />
        ) : (
          <SimpleText textKey={makeConstants.AllMakes} />
        )}
      </Menu.Header>
      {groupsMakes[isTop].map(i => {
        const item = (
          <MenuItem key={index} option={i} position={index}>
            <Highlighter search={menuProps.text}>
              {i[menuProps.labelKey]}
            </Highlighter>
          </MenuItem>
        );

        index += 1;
        return item;
      })}
    </Fragment>
  ));
  return <Menu id="Makes">{items}</Menu>;
};

export default renderMake;
