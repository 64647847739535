import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { isWidgetAllowed } from 'behavior/productComparison';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

const Widget = loadable(() => import(/*webpackChunkName:"comp-widget"*/'./Widget'));

const WidgetLoader = () => {
  const isAllowed = useSelector(state => {
    const routeData = state.routing.routeData;
    return routeData ? isWidgetAllowed(routeData.routeName, routeData.params) : false;
  });

  const [canCompareProducts] = useHasAbilities(AbilityTo.CompareProducts);

  return isAllowed && canCompareProducts && <Widget />;
};

export default WidgetLoader;
