import styles from './Alert.module.scss';
import { ErrorIcon } from 'components/primitives/icons';
import Alert, { Props as AlertProps } from './Alert';

type Props = Omit<AlertProps, 'icon'>;

const DangerAlert = ({ children, className = '', ...props }: Props) => (
  <Alert className={`${styles.danger} ${className}`} icon={ErrorIcon} {...props}>
    {children}
  </Alert>
);

export default DangerAlert;
