import styles from './Link.module.scss';
import type { LoadedSettings } from 'behavior/settings';
import { memo } from 'react';
import { useSimpleTexts } from 'components/sanaText';
import { useSelector } from 'react-redux';
import { Placeholder } from 'components/primitives/placeholders';

const ContactStoreLink = ({ linkTextKey }: { linkTextKey: string }) => {
  const { texts: [contactLink], loaded } = useSimpleTexts([linkTextKey]);
  const shopEmailAddress = useSelector(s => (s.settings as LoadedSettings).shopEmailAddress);

  return loaded
    ? <a href={`mailto:${shopEmailAddress}`}>{contactLink}</a>
    : <Placeholder className={styles.contactPlaceholder} />;
};

export default memo(ContactStoreLink);
