import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';

const Text = ({ isAuthenticated }) => (
  <SimpleText textKey={isAuthenticated ? 'AccountMenu_Account' : 'AccountMenu_Login'} />
);

Text.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default Text;
