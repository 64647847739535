import type { Handler } from '../types';
import { ContentSystemPage, ContentSystemPageData, initContentSystemPage, loadContentSystemPageQuery } from '../system';
import { PageComponentNames } from 'behavior/pages';
import { RouteData, RouteName } from 'routes';
import { decapitalize } from 'utils/helpers';
import { getBackTo } from '../helpers';
import { tap, switchMap, pluck, first, map } from 'rxjs/operators';
import { StoreType, areSettingsLoaded } from 'behavior/settings';

type PageRouteData = RouteData & {
  routeName: RouteName.ForgotPassword;
};

type ForgotPasswordPage = ContentSystemPage & { component: PageComponentNames.ForgotPassword };
type ClosedForgotPasswordPage = ContentSystemPage & { emptyLayout: true; component: PageComponentNames.ClosedStoreForgotPassword };

type Page = (ForgotPasswordPage | ClosedForgotPasswordPage)
  & Pick<RouteData, 'options'>
  & { backTo: ReturnType<typeof getBackTo> };

type PageResponse = {
  pages: {
    forgotPassword: ContentSystemPageData;
  };
};

type ClosedStorePageResponse = {
  pages: {
    closedStoreForgotPassword: ContentSystemPageData;
  };
};

type PageHandler = Handler<PageRouteData, Page>;
type State = Parameters<PageHandler>[1];
type Api = Parameters<PageHandler>[2]['api'];

const forgotPasswordPageName = decapitalize(RouteName.ForgotPassword);

const handler: PageHandler = (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(areSettingsLoaded),
    switchMap(
      settings => {
        const shouldLoadClosedStorePage = routeData.params?.previewToken
          ? routeData.params?.closedStore
          : settings.storeType === StoreType.Closed;

        return shouldLoadClosedStorePage
          ? loadClosedStoreForgotPasswordPage(routeData, state$, api)
          : loadSystemForgotPasswordPage(routeData, state$, api);
      },
    ),
    tap<{ page: Page }>(({ page }) => {
      if (routeData.options)
        page.options = routeData.options;
    }),
  );
};

export default handler;

function loadClosedStoreForgotPasswordPage(routeData: PageRouteData, state$: State, api: Api) {
  return api.graphApi<ClosedStorePageResponse>(loadContentSystemPageQuery('closedStoreForgotPassword')).pipe(
    pluck('pages', 'closedStoreForgotPassword'),
    map(page => ({
      page: {
        ...initContentSystemPage(page),
        component: PageComponentNames.ClosedStoreForgotPassword as const,
        emptyLayout: true as const,
        backTo: _getBackTo(state$, routeData),
      },
    })),
  );
}

function loadSystemForgotPasswordPage(routeData: PageRouteData, state$: State, api: Api) {
  return api.graphApi<PageResponse>(loadContentSystemPageQuery(forgotPasswordPageName)).pipe(
    pluck('pages', 'forgotPassword'),
    map(page => ({
      page: {
        ...initContentSystemPage(page),
        component: PageComponentNames.ForgotPassword as const,
        backTo: _getBackTo(state$, routeData),
      },
    })),
  );
}

function _getBackTo(state$: State, routeData: PageRouteData) {
  return getBackTo(state$, [
    RouteName.Login,
    RouteName.ForgotPassword,
  ], routeData.params && routeData.params.language);
}
