import type { Epic } from 'behavior/types';
import { LatestBlogItemsAction, latestBlogItemsRequestCompleted, REGISTER_LATEST_BLOG_ITEMS } from './actions';
import { ofType } from 'redux-observable';
import { groupBy, map, mergeMap, pluck, exhaustMap, switchMap } from 'rxjs/operators';
import { latestBlogItemsQuery } from './queries';
import { from, merge } from 'rxjs';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { USER_PROFILE_UPDATED } from 'behavior/user';

const epic: Epic<LatestBlogItemsAction> = (action$, state$, { api }) => {
  const register$ = action$.pipe(
    ofType(REGISTER_LATEST_BLOG_ITEMS),
    pluck('payload'),
    groupBy(({ key }) => key),
    mergeMap(group => group.pipe(
      exhaustMap(({ key, settings: { amount } }) => api.graphApi<LatestBlogItemsResponse>(latestBlogItemsQuery, { amount }).pipe(
        pluck('blog', 'list', 'items'),
        map(items => latestBlogItemsRequestCompleted(key, items)),
      )),
    )),
  );

  const reload$ = action$.pipe(
    ofType(LANGUAGE_CHANGED, VIEWER_CHANGED, USER_PROFILE_UPDATED),
    switchMap(() => from(Object.entries(state$.value.blog.latestItems)).pipe(
      mergeMap(([key, { settings: { amount } }]) => api.graphApi<LatestBlogItemsResponse>(latestBlogItemsQuery, { amount }).pipe(
        pluck('blog', 'list', 'items'),
        map(items => latestBlogItemsRequestCompleted(key, items)),
      )),
    )),
  );

  return merge(register$, reload$);
};

export default epic;

type LatestBlogItemsResponse = {
  blog: {
    list: {
      items: {
        id: string;
        title: string;
        url: string;
        image: string | null;
        publishDate: string;
      }[];
    };
  };
};