import type {
  BlogItemsLoadOptions,
  BlogFacetsLoadOptions,
  BlogItem,
  BlogFacet,
} from './types';

export const BLOG_ITEMS_REQUESTED = 'BLOG/ITEMS/REQUESTED' as const;
export const requestItems = (options: BlogItemsLoadOptions) => ({
  type: BLOG_ITEMS_REQUESTED,
  payload: { options },
});

export const BLOG_ITEMS_RECEIVED = 'BLOG/ITEMS/RECEIVED' as const;
export const itemsReceived = (items: BlogItem[], totalCount: number, appendItems: boolean) => ({
  type: BLOG_ITEMS_RECEIVED,
  payload: { items, totalCount, appendItems },
});

export const BLOG_ITEMS_CLEARED = 'BLOG/ITEMS/CLEARED' as const;
export const clearItems = (currentPageSize: number | undefined) => ({
  type: BLOG_ITEMS_CLEARED,
  payload: { currentPageSize },
});

export const BLOG_FACETS_REQUESTED = 'BLOG/FACETS/REQUESTED' as const;
export const requestFacets = (options: BlogFacetsLoadOptions) => ({
  type: BLOG_FACETS_REQUESTED,
  payload: { options },
});

export const BLOG_FACETS_RECEIVED = 'BLOG/FACETS/RECEIVED' as const;
export const facetsReceived = (facets: BlogFacet[]) => ({
  type: BLOG_FACETS_RECEIVED,
  payload: { facets },
});

export type BlogItemsAction = ReturnType<
  | typeof requestItems
  | typeof itemsReceived
  | typeof clearItems
  | typeof requestFacets
  | typeof facetsReceived
>;
