import { shallowEqual } from 'react-redux';

export function memoize<T extends (...args: any) => any>(func: T): T {
  let memo: any = null;
  let memoArgs: any | null = null;
  const result = (...args: any): ReturnType<T> => {
    if (shallowEqual(memoArgs, args))
      return memo;

    const result = func.apply(null, args);
    if (!shallowEqual(memo, result))
      memo = result;

    memoArgs = args;
    return memo;
  };
  return result as T;
}
