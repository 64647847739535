import type { TrackingOptions } from '../types';
import type { BlogItem } from './types';
import { stripHtmlTagsAndFormatting } from '../../helpers';
import { createUrl } from '../../url';
import { getToLocaleDate } from 'utils/format';
import createComment from './comment';

export default function createBlogItem(item: BlogItem, culture: string, options: TrackingOptions) {
  const { title, url, shortDescription, author, publishDate } = item;
  const formatDate = getToLocaleDate(culture);

  const schema: Record<string, unknown> = {
    '@context': 'https://schema.org/',
    '@type': 'BlogPosting',
    headline: title,
    url: createUrl(url, options.origin),
    inLanguage: culture,
    author: {
      '@type': 'Person',
      name: author,
    },
    creator: {
      '@type': 'Person',
      name: author,
    },
    accountablePerson: {
      '@type': 'Person',
      name: author,
    },
    dateCreated: formatDate(publishDate),
    datePublished: formatDate(publishDate),
    ...createComment(item, culture),
  };

  if (shortDescription)
    schema.articleBody = stripHtmlTagsAndFormatting(shortDescription);

  return schema;
}
