import { NavigationGroupCode, ComponentGroup } from 'behavior/navigation';

export function findNavigationPath(url, navigation) {
  const queueOfPaths = navigation.map(item => [item]);

  while (queueOfPaths.length) {
    const path = queueOfPaths.shift();
    const lastItem = path[path.length - 1];

    if (lastItem.link?.url === url)
      return path;

    if (lastItem.children)
      for (const child of lastItem.children)
        queueOfPaths.push([...path, child]);
  }
}

export function getMainNavigation(state) {
  const group = state.navigation[ComponentGroup.CascadingMainMenu]
    ?? state.navigation[ComponentGroup.MultiColumnMainMenu];

  return group?.[NavigationGroupCode.Main];
}
