import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link, { SystemLinkProps } from './base/Link';
import { useSelector } from 'react-redux';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { withAbilities } from 'components/objects/user';

type Props = {
  abilities: [AbilityState];
} & Omit<SystemLinkProps, 'to'>;

const WishlistLink = ({ abilities: [wishlistAbility], ...attributes }: Props) => {
  const isAuthenticated = useSelector(s => s.user.isAuthenticated);

  if (wishlistAbility == null)
    return null;

  let isAvailable = wishlistAbility === AbilityState.Available;

  if (!isAvailable && !isAuthenticated)
    isAvailable = wishlistAbility === AbilityState.Unauthorized;

  if (!isAvailable)
    return null;

  return (
    <Link {...attributes} to={routesBuilder.forWishList}>
      <SimpleText textKey="WishList" />
    </Link>
  );
};

export default withAbilities(WishlistLink, [AbilityTo.UseWishlist]);
