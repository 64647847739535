import { Steps } from './constants';
import { trackCheckoutOption } from 'behavior/analytics/actions';

const stepsNames = Object.values(Steps);

export const getStepNumber = step => stepsNames.indexOf(step) + 1;

export const trackStepOption = (step, info) => {
  switch (step) {
    case Steps.Address:
      return trackCheckoutOption({
        step: getStepNumber(step),
        option: info.shippingAddress?.shippingOption,
      });
    case Steps.Shipping:
      const shippingMethod = getMethod(info.shippingMethods, info.shippingMethodId);
      return shippingMethod && trackCheckoutOption({
        step: getStepNumber(step),
        option: shippingMethod,
      });
    case Steps.Payment:
      const paymentMethod = getMethod(info.paymentMethods, info.paymentMethodId);
      return paymentMethod && trackCheckoutOption({
        step: getStepNumber(step),
        option: paymentMethod,
      });
    default:
      return null;
  }
};

export const getMethod = (methods, methodId) => {
  const method = methods &&
  methods.find(method => method.id === methodId);
  return method && method.name;
};