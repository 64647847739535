import { tap, catchError } from 'rxjs/operators';
import { BehaviorSubject, pipe, UnaryFunction, Observable } from 'rxjs';
import { throttle } from 'lodash';
import type { Connection } from './types';

type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

export function createConnectionTracker(fetch: Fetch) {
  const navigator = typeof window !== 'undefined' ? window.navigator : { onLine: true };

  const stateSubject = new BehaviorSubject<Connection>({ onLine: true, accessible: true });
  const trackConnection: UnaryFunction<Observable<any>, Observable<any>> = pipe(
    catchError(e => {
      onError(e);
      throw e;
    }),
    tap(_ => {
      const state = stateSubject.value;
      if (!state.accessible) {
        onError.cancel();
        stateSubject.next({ onLine: true, accessible: true });
      }
    }),
  );

  const onError = throttle(function newFunction(e) {
    if (!(e instanceof TypeError))
      return;

    const state = stateSubject.value;
    // already correct status
    if (!state.accessible)
      return;

    if (!navigator.onLine) {
      // no connection
      stateSubject.next({ onLine: false, accessible: false });
      return;
    }
    // try to reach another site
    fetch('https://example.com', { method: 'HEAD', mode: 'no-cors' })
      .then(_ => stateSubject.next({ onLine: true, accessible: false }),
        reason => {
          if (reason instanceof TypeError)
            stateSubject.next({ onLine: false, accessible: false });
          else
            stateSubject.next({ onLine: true, accessible: false });
        },
      );
  }, 300);

  return { connection$: stateSubject.asObservable(), trackConnection };
}
