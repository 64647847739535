import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, exhaustMap, pluck } from 'rxjs/operators';
import { CONTACT_US_FORM_SUBMITTED, receiveContactUsResponse, ContactUsAction } from './actions';
import { contactUsMutation } from './queries';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormName } from 'behavior/pages';
import { toasts } from 'behavior/toasts';

type ContactUsMutationResponse = {
  contactUs: boolean;
};

const contactUsEpic: Epic<ContactUsAction> = (action$, _, { api, logger }) => action$.pipe(
  ofType(CONTACT_US_FORM_SUBMITTED),
  pluck('payload'),
  exhaustMap(({ input, files, updatedById }) => api.graphApi<ContactUsMutationResponse>(contactUsMutation, { input }, { files, retries: 0 }).pipe(
    pluck('contactUs'),
    mergeMap(success => {
      success && toasts.success('', { textKey: 'ContactForm_MessageSendSuccessfully', className: 'ContactUs_toast' });

      return [receiveContactUsResponse(success, updatedById), resetCaptcha(updatedById), unlockForm(FormName.ContactUs)];
    }),
    catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(updatedById), unlockForm(FormName.ContactUs))),
    retryWithToast(action$, logger, _ => of(resetCaptcha(updatedById), unlockForm(FormName.ContactUs))),
  )),
);

export default contactUsEpic;
