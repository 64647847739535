import { of } from 'rxjs';
import { mergeMap, first, pluck, tap } from 'rxjs/operators';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { createOfflinePage } from './helpers';
import { StoreType, areSettingsLoaded } from 'behavior/settings';
import type { Handler, Page } from 'behavior/pages/types';
import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import type { RouteData } from 'routes';
import type { StateObservable } from 'redux-observable';

type Next<TRouteData extends RouteData, TPage extends Page | null> = (routeData: TRouteData, state$: StateObservable<AppState>, dependencies: StoreDependencies) => ReturnType<Handler<TRouteData, TPage>>;

type Middleware<TRouteData extends RouteData, TPage extends Page | null> = (next: Next<TRouteData, TPage>, routeData: TRouteData, state$: StateObservable<AppState>, dependencies: StoreDependencies) => ReturnType<Handler<TRouteData, TPage>>;

export function closedStoreMiddleware<TRouteData extends RouteData, TPage extends Page | null>(next: Next<TRouteData, TPage>, routeData: TRouteData, state$: StateObservable<AppState>, dependencies: StoreDependencies) {
  return state$.pipe(
    pluck('settings'),
    first(areSettingsLoaded),
    mergeMap(settings => {
      if (settings.storeType === StoreType.Closed && !isPreview(routeData))
        return authorizeMiddleware(next, routeData, state$, dependencies);

      return next(routeData, state$, dependencies);
    }),
  );
}

export function authorizeMiddleware<TRouteData extends RouteData, TPage extends Page | null>(next: Next<TRouteData, TPage>, routeData: TRouteData, state$: StateObservable<AppState>, dependencies: StoreDependencies) {
  const filter = (user: AppState['user']) => {
    if (user.isAuthenticated)
      return next(routeData, state$, dependencies).pipe(
        tap(result => {
          if (!result || !result.page)
            return;

          result.page.authentication = {
            ...result.page.authentication,
            required: true,
          };
        }),
      );

    return of({ statusCode: 401 });
  };

  if (state$.value.user.initialized)
    return filter(state$.value.user);

  return state$.pipe(
    pluck('user'),
    first(user => user.initialized),
    mergeMap(filter),
  );
}

export function createAbilityMiddleware<TRouteData extends RouteData, TPage extends Page | null>(ability: AbilityTo) {
  return function (next: Next<TRouteData, TPage>, routeData: TRouteData, state$: StateObservable<AppState>, dependencies: StoreDependencies) {
    if (isPreview(routeData))
      return next(routeData, state$, dependencies);

    return requestAbility(ability, state$, dependencies).pipe(
      mergeMap(abilityState => {
        switch (abilityState) {
          case AbilityState.NotAvailable:
            return of(null);
          case AbilityState.Unauthorized:
            return of({ statusCode: 401 });
          case AbilityState.TemporaryNotAvailable:
            return of({ statusCode: 503, page: createOfflinePage() });
          default:
            return next(routeData, state$, dependencies).pipe(
              tap(result => {
                if (!result || !result.page)
                  return;

                const authentication = result.page.authentication;
                if (!authentication) {
                  result.page.authentication = {
                    abilities: [ability],
                  };
                  return;
                }

                result.page.authentication = {
                  ...authentication,
                  abilities: authentication.abilities
                    ? [...authentication.abilities, ability]
                    : [ability],
                };
              }),
            );
        }
      }),
    );
  };
}

export const applyMiddleware = <TRouteData extends RouteData, TPage extends Page>(handler: Handler<TRouteData, TPage>, ...middleware: Middleware<TRouteData, TPage>[]) =>
  middleware.reverse().reduce((next, current) => current.bind(null, next), handler);

function isPreview<TRouteData extends RouteData>(routeData: TRouteData) {
  return routeData.params?.previewToken != null;
}
