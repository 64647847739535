import styles from '../MainNav.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ComponentGroup } from 'behavior/navigation/constants';
import DesktopNavBase from './DesktopNavBase';
import NavMenuContext from '../NavMenuContext';
import { joinClasses } from 'utils/helpers';

const CascadingMainMenuBlock = ({ model, id, isDesignerMode }) => {
  const context = useMemo(() => ({ componentGroup: ComponentGroup.CascadingMainMenu }), []);
  const navId = `MainNav_${id}`;

  const style = useMemo(() => ({
    '--topLevel-fontFamily': model.topLevel_FontFamily && (model.topLevel_FontFamily + ', sans-serif'),
    '--topLevel-fontSize': model.topLevel_FontSize,
    '--topLevel-allCaps': model.topLevel_AllCaps ? 'uppercase' : null,
    '--topLevel-fontColor': model.topLevel_FontColor,
    '--topLevel-hoverFontColor': model.topLevel_HoverFontColor,
    '--topLevel-nonHoveredItemFontColor': model.topLevel_NonHoveredItemFontColor,
    '--topLevel-activeItemUnderlineColor': model.topLevel_ActiveItemUnderlineColor,
    '--topLevel-expandedMenuBackgroundColor': model.topLevel_ExpandedMenuBackgroundColor,
    '--topLevel-expandedMenuBorderColor': model.topLevel_ExpandedMenuBorderColor,
    '--topLevel-itemHeight': model.topLevel_ItemHeight,
    '--subLevel-fontFamily': model.subLevel_FontFamily && (model.subLevel_FontFamily + ', sans-serif'),
    '--subLevel-fontSize': model.subLevel_FontSize,
    '--subLevel-allCaps': model.subLevel_AllCaps ? 'uppercase' : null,
    '--subLevel-fontColor': model.subLevel_FontColor,
    '--subLevel-hoverFontColor': model.subLevel_HoverFontColor,
    '--subLevel-hoverBackgroundColor': model.subLevel_HoverBackgroundColor,
  }), [model]);

  const className = joinClasses(
    model.topLevel_ShowActiveItemUnderline && styles.showActiveItemUnderline,
    model.subLevel_ShowArrowInFrontOfSubItem && styles.showSubItemArrow,
  );

  return (
    <NavMenuContext.Provider value={context}>
      <DesktopNavBase
        isDesignerMode={isDesignerMode}
        id={navId}
        style={style}
        className={className}
      />
    </NavMenuContext.Provider>
  );
};

CascadingMainMenuBlock.propTypes = {
  model: PropTypes.shape({
    topLevel_FontFamily: PropTypes.string,
    topLevel_FontSize: PropTypes.string,
    topLevel_AllCaps: PropTypes.bool.isRequired,
    topLevel_FontColor: PropTypes.string,
    topLevel_HoverFontColor: PropTypes.string,
    topLevel_NonHoveredItemFontColor: PropTypes.string,
    topLevel_ActiveItemUnderlineColor: PropTypes.string,
    topLevel_ShowActiveItemUnderline: PropTypes.bool.isRequired,
    topLevel_ExpandedMenuBackgroundColor: PropTypes.string,
    topLevel_ExpandedMenuBorderColor: PropTypes.string,
    topLevel_ItemHeight: PropTypes.string,
    subLevel_FontFamily: PropTypes.string,
    subLevel_FontSize: PropTypes.string,
    subLevel_AllCaps: PropTypes.bool.isRequired,
    subLevel_FontColor: PropTypes.string,
    subLevel_HoverFontColor: PropTypes.string,
    subLevel_HoverBackgroundColor: PropTypes.string,
    subLevel_ShowArrowInFrontOfSubItem: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default CascadingMainMenuBlock;
