import { memo } from 'react';
import ReactDOM from 'react-dom';
import DomElement from './DomElement';
import { useExtras } from 'utils/layout';

const DomBody = (props: { content: string }) => {
  const extras = useExtras();
  if (!extras)
    return null;

  return ReactDOM.createPortal(<DomElement content={props.content} />, extras);
};

export default memo(DomBody);
