import { Link } from 'components/primitives/links';
import { BaseNavigationItemPropTypes } from 'components/objects/navigation/PropTypes';
import { renderHTML } from 'utils/render';

const NavItem = ({ item: { link, title, cssClass } }) => (
  <li className={cssClass}>
    <Link {...link}>{title && renderHTML(title)}</Link>
  </li>
);

NavItem.propTypes = {
  item: BaseNavigationItemPropTypes.isRequired,
};

export default NavItem;
