import styles from '../../Details.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomerType } from 'behavior/user/constants';
import { Placeholder } from 'components/primitives/placeholders';
import { makeRichText, makeSimpleText } from 'utils/render';
import { useSanaTexts, UseSanaTexts } from 'components/sanaText';

const WarehouseStockItem = ({ warehouseStock, stockLevels, customerType, forVariant }) => {
  const { outOfStock, lowStock, maxStockNumber } = stockLevels;
  let className = '';
  let sanaTextsToLoad = [];
 
  const stockValuePlaceholder = <Placeholder className={styles.stockValuePlaceholder} />;
  const { id, inventory, eta } = warehouseStock;
  const { texts: [etaText] } = useSanaTexts(['Eta_Template']);
  const showEta = inventory <= outOfStock && !!eta;
 
  if (typeof inventory !== 'undefined') {
    let textKey = 'StockText_';
 
    if (inventory <= outOfStock) {
      textKey += 'OutOfStock';
      className = styles.outStock;
    }
    else if (inventory > lowStock) {
      textKey += 'InStock';
      className = styles.inStock;
    }
    else {
      textKey += 'LowStock';
      className = styles.lowStock;
    }
 
    if (!customerType || customerType === CustomerType.B2C)
      textKey += '_B2C';
    else
      textKey += '_B2B';
 
    if (forVariant)
      textKey += '_Variant';
 
    sanaTextsToLoad = [textKey];
 
    if (maxStockNumber && maxStockNumber < inventory)
      sanaTextsToLoad.push('StockText_MaxStockNumber');
    else
      sanaTextsToLoad.push('');
  } else {
    return stockValuePlaceholder;
  }

  sanaTextsToLoad.push(`Warehouse_ShortName_${id}`);
 
  return (
    <UseSanaTexts options={sanaTextsToLoad} placeholder={stockValuePlaceholder}>
      {([stockText, maxStockNumberText, selectedWarehouseShortName]) => {
        let stockTextToShow = stockText.replace('[STOCKAMOUNT]', maxStockNumberText ? maxStockNumberText : inventory);
        stockTextToShow = stockTextToShow.replace('[STATE]', selectedWarehouseShortName ?? id);
        return (stockText && (
          <span className={`${styles.stockValue} ${className}`}>
            {makeRichText(stockTextToShow, [maxStockNumber])} {showEta && makeSimpleText(etaText, [eta])}
          </span>
        ));
      }}
    </UseSanaTexts>
  );
};

WarehouseStockItem.propTypes = {
  warehouseStock: PropTypes.shape({
    id: PropTypes.string,
    inventory: PropTypes.number,
    eta: PropTypes.string,
  }),
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
  additionalClass: PropTypes.string,
  customerType: PropTypes.string,
  forVariant: PropTypes.bool,  
};

export default connect(({ user: { customerType } }) => ({ customerType }))(WarehouseStockItem);
