import { PageComponentNames } from 'behavior/pages';
import createRenderer from './renderer';
import profile from 'components/objects/profile';
import representRenderer from 'components/objects/representation';
import lastViewedRenderer from 'components/objects/productList/lastViewed';
import {
  checkoutRenderer,
  orderSubmitRenderer,
  orderFailedRenderer,
  orderCancelledRenderer,
  paymentErrorRenderer,
} from 'components/objects/checkout';
import {
  orderPaymentRenderer,
  paymentSuccessfulRenderer,
  paymentCancelledRenderer,
  paymentFailedRenderer,
} from 'components/objects/orderPayment';

export default {
  ...profile,
  [PageComponentNames.Represent]: createRenderer({ textKey: 'RepresentCustomer_Title' }, representRenderer),
  [PageComponentNames.LastViewedProducts]: createRenderer({ textKey: 'LastViewedProducts_Header' }, lastViewedRenderer),
  [PageComponentNames.Checkout]: checkoutRenderer,
  [PageComponentNames.OrderSubmit]: orderSubmitRenderer,
  [PageComponentNames.OrderFailed]: orderFailedRenderer,
  [PageComponentNames.OrderCancelled]: orderCancelledRenderer,
  [PageComponentNames.PaymentError]: paymentErrorRenderer,
  [PageComponentNames.OrderPayment]: createRenderer({ textKey: 'Payment_Header' }, orderPaymentRenderer),
  [PageComponentNames.PaymentSuccessful]: createRenderer({ textKey: 'Payment_SuccessHeader' }, paymentSuccessfulRenderer),
  [PageComponentNames.PaymentCancelled]: createRenderer({ textKey: 'Payment_CancelledHeader' }, paymentCancelledRenderer),
  [PageComponentNames.PaymentFailed]: createRenderer({ textKey: 'Payment_PaymentFailureHeader' }, paymentFailedRenderer),
};
