import type { BlogComment, BlogCommentInput } from './types';

export const BLOG_COMMENTS_REQUESTED = 'BLOG_ITEM/COMMENTS/REQUESTED' as const;
export const requestComments = (blogItemId: string, pageIndex: number, pageSize?: number) => ({
  type: BLOG_COMMENTS_REQUESTED,
  payload: { blogItemId, options: { page: { index: pageIndex, size: pageSize } } },
});

export const BLOG_COMMENTS_RECEIVED = 'BLOG_ITEM/COMMENTS/RECEIVED' as const;
export const commentsReceived = (comments: BlogComment[]) => ({
  type: BLOG_COMMENTS_RECEIVED,
  payload: comments,
});

export const BLOG_COMMENT_SUBMITTED = 'BLOG_ITEM/COMMENT/SUBMITTED' as const;
export const sendComment = (input: BlogCommentInput) => ({
  type: BLOG_COMMENT_SUBMITTED,
  payload: input,
});

export const BLOG_COMMENT_PROCESSED = 'BLOG_ITEM/COMMENT/PROCESSED' as const;
export const commentProcessed = () => ({
  type: BLOG_COMMENT_PROCESSED,
});

export type BlogItemPageAction = ReturnType<
  | typeof requestComments
  | typeof commentsReceived
  | typeof sendComment
  | typeof commentProcessed
>;
