import { isValidElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { encodeIndexIntoText } from './utils';
import { useInsiteEditorResourceTexts } from 'components/visualDesigner/texts';
import { sanaTextsRequested, setTextOnlyMode } from 'behavior/insiteEditor/actions';

export default useSanaTexts => (options, process) => {
  const result = useSanaTexts(options, process);

  if (!useSelector(state => state.insiteEditor.initialized))
    return result;

  return useProcessSanaTexts(options, result);
};

function useProcessSanaTexts(options, result) {
  const enabledProcessing = !options.disableInsiteEditor;

  const sanaTextsMapping = useSelector(state => state.insiteEditor.mapping);
  const requestedTexts = useSelector(state => state.insiteEditor.requested);
  const page = useSelector(state => enabledProcessing && state.page);
  const T = useInsiteEditorResourceTexts();
  const dispatch = useDispatch();

  const optionsTextKeys = options.map(option => option && (typeof option === 'string' ? option : option.key));
  useEffect(() => {
    if (!options.textOnly)
      return;

    const nonEmptyTexts = optionsTextKeys.filter(Boolean);
    if (nonEmptyTexts.length > 0)
      dispatch(setTextOnlyMode(nonEmptyTexts));
  }, [optionsTextKeys.length]);

  useEffect(() => {
    const nonEmptyTexts = optionsTextKeys.filter(Boolean);
    if (nonEmptyTexts.every(t => requestedTexts.includes(t)))
      return;

    dispatch(sanaTextsRequested(nonEmptyTexts));
  }, [page, optionsTextKeys.length]);

  if (!enabledProcessing || !result.loaded)
    return result;

  const processedResult = options.insiteEditorPostLoad ? options.insiteEditorPostLoad(result) : result;
  const processedTexts = processedResult.texts.map((value, index) => {
    if (isValidElement(value))
      return value;

    const sanaTextKey = optionsTextKeys[index];
    if (sanaTextKey && !options.skipReplacingEmptyText && !value)
      value = T.InsiteEditor_EmptySanaText || '';

    const mappingId = getKeyByValue(sanaTextsMapping, sanaTextKey);
    if (mappingId === undefined)
      return value;

    const sanaTextIndex = parseInt(mappingId);
    return encodeIndexIntoText(value, sanaTextIndex);
  });

  return { ...processedResult, texts: processedTexts };
}

function getKeyByValue(object, value) {
  return object && Object.keys(object).find(key => object[key].textKey === value);
}
