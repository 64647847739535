import type { LocalStorage } from 'utils/localStorage';
import { storageKey } from './constants';

type Variables = {
  options: {
    ids: string[];
    page: {
      index: number;
      size: number;
    };
    ignoreGrouping?: boolean;
  };
  loadCategories?: boolean;
};

export function createApiVariables(localStorage: LocalStorage, count?: number, skipFirst = false): Variables | undefined {
  const products = localStorage.getItem<string[]>(storageKey);
  if (!products?.length)
    return undefined;

  let filteredProducts = products, start = 0;

  if (skipFirst)
    start = 1;

  if (start || count)
    filteredProducts = filteredProducts.slice(start, count ? count + start : count);

  if (!filteredProducts.length)
    return undefined;

  return {
    options: {
      ids: filteredProducts,
      page: {
        index: 0,
        size: filteredProducts.length,
      },
    },
  };
}

export function sortProducts<T extends { id: string }>(products: T[] | null, variables: Variables) {
  if (!products || !products.length)
    return products;

  const ids = variables.options.ids;
  return products
    .sort((first, second) => ids.indexOf(first.id.toLowerCase()) - ids.indexOf(second.id.toLowerCase()));
}
