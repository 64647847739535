import type { StyleModule } from './types';
import { createContext } from 'react';

type StyleContext = {
  insertCss: (styles: StyleModule[]) => RemoveCss | void;
  isReactDOMServer?: boolean;
};

type RemoveCss = () => void;

export default createContext({} as StyleContext);
