import type { Epic } from 'behavior/types';
import { switchMap, map, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { FooterAction, footerLoaded } from './actions';
import { loadFooterQuery } from './queries';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { USER_PROFILE_UPDATED } from 'behavior/user';
import { ignoreIfVisualDesigner } from 'behavior/visualDesigner';

const footerEpic: Epic<FooterAction> = (action$, state$, { api }) => action$.pipe(
  ofType(LANGUAGE_CHANGED, VIEWER_CHANGED, USER_PROFILE_UPDATED),
  ignoreIfVisualDesigner(state$),
  switchMap(() => api.graphApi(loadFooterQuery).pipe(
    pluck('footer'),
    map(footerLoaded),
  )),
);

export default footerEpic;
