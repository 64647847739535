import Column from './ContentBlockColumn';
import { VdColumnContainer } from 'components/visualDesigner';

export default function createColumn(column, index, fullWidthRow, rowVerticalAlignment) {
  const verticalAlignment = column.verticalAlignment === 'INHERITED' ? rowVerticalAlignment : column.verticalAlignment;
  return (
    <VdColumnContainer key={column.id} index={index} column={column}>
      <Column {...column} fullWidthRow={fullWidthRow} verticalAlignment={verticalAlignment} />
    </VdColumnContainer>
  );
}