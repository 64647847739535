import { useContext, createElement, createContext, useMemo } from 'react';
import { createMedia } from '@artsy/fresnel';
import { Helmet } from 'react-helmet';
import type { MediaBreakpointProps } from '@artsy/fresnel/dist/Media';
import type { ReactNode, ComponentType } from 'react';

type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type MediaProps = MediaBreakpointProps<BreakpointKey> & {
  children: ReactNode;
  className?: string;
};

type ProviderProps = {
  children: ReactNode;
  breakpoints: Record<Exclude<BreakpointKey, 'xs'>, number>;
};

const Context = createContext({} as ComponentType<MediaProps>);

const Media = (props: MediaProps) => {
  const Instance = useContext(Context);
  return createElement(Instance, props);
};

export default Media;

const MediaProvider = ({ children, breakpoints }: ProviderProps) => {
  const { Media, MediaContextProvider, mediaStyles } = useMemo(() => {
    const options = { breakpoints: { xs: 0, ...breakpoints } };
    const { Media, MediaContextProvider, createMediaStyle } = createMedia(options);
    const mediaStyles = createMediaStyle();

    return { Media, MediaContextProvider, mediaStyles };
  }, [breakpoints]);

  return (
    <Context.Provider value={Media}>
      <Helmet>
        <style>{mediaStyles}</style>
      </Helmet>
      <MediaContextProvider>{children}</MediaContextProvider>
    </Context.Provider>
  );
};

export { MediaProvider };
