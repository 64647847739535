import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createPriceModel } from './priceUtils';

const emptyPriceModel = {};
export const usePriceModel = (salesPrice: number | null | undefined, basePrice: number) => {
  const actionPricesMode = useSelector(s => s.settings.loaded ? s.settings.product.actionPricesPresentationType : undefined);

  return useMemo(() => {
    if (salesPrice == null || !actionPricesMode)
      return emptyPriceModel;

    return createPriceModel(salesPrice, basePrice, actionPricesMode);
  }, [salesPrice, basePrice, actionPricesMode]);
};
