import type { Handler } from '../types';
import type { EntityTemplateField } from 'behavior/entityTemplates';
import { createProspectPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { ContentSystemPageData, initContentSystemPageContent } from '../system';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import type { CreateProspectPage } from './types';

const handler: Handler<CreateProspectRouteData, CreateProspectPage> = (routeData, state$, { api }) =>
  api.graphApi<CreateProspectPageResponse>(createProspectPageQuery).pipe(
    map(({ pages: { createProspect: page }, profile }) => {
      if (page == null)
        return null;

      return {
        page: {
          ...page,
          component: PageComponentNames.CreateProspect as const,
          templateFields: profile.prospectFields,
          backTo: getBackTo(state$, [RouteName.CreateProspect], routeData.params?.language),
        },
      };
    }),
    initContentSystemPageContent(),
  );

export default handler;

type CreateProspectRouteData = {
  routeName: RouteName.CreateProspect;
  params?: {
    language: number;
  };
};

type CreateProspectPageResponse = {
  pages: {
    createProspect: ContentSystemPageData | null;
  };
  profile: {
    prospectFields: EntityTemplateField[];
  };
};
