import type { CustomerCollection } from './types';

export const CUSTOMERS_REQUESTED = 'REPRESENTATION/CUSTOMERS/REQUESTED' as const;
export const requestCustomers = (keywords = '', index = 0, size = 10) => ({
  type: CUSTOMERS_REQUESTED,
  payload: { keywords, page: { index, size } },
});

export const CUSTOMERS_RECEIVED = 'REPRESENTATION/CUSTOMERS/RECEIVED' as const;
export const customersLoaded = (customers: CustomerCollection, keywords: string) => ({
  type: CUSTOMERS_RECEIVED,
  payload: { customers, keywords },
});

export type RepresentationAction = ReturnType<
  | typeof requestCustomers
  | typeof customersLoaded
>;
