import PropTypes from 'prop-types';
import LogoBase from './LogoBase';

const Logo = ({ small, className }) => <LogoBase small={small} className={className} />;

Logo.propTypes = {
  small: PropTypes.bool,
  className: PropTypes.string,
};

export default Logo;
