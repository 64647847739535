import { BASKET_PAGE_REQUESTED, BASKET_RECEIVED, RequestBasketPageAction, ReloadBasketPageAction, BasketReceivedAction } from 'behavior/basket';
import { BASKET_ORDER_TEMPLATE_SAVED, BasketPageAction as BasketAction } from './actions';
import { PageComponentNames } from '../componentNames';
import { SaveOrderTemplateResult } from './types';

type State = {
  linesUpdating?: boolean;
  component: PageComponentNames.Basket;
  templateSaveResult?: SaveOrderTemplateResult;
};

type BasketPageAction = BasketAction | RequestBasketPageAction | ReloadBasketPageAction | BasketReceivedAction;

export default function (state: State, action: BasketPageAction) {
  switch (action.type) {
    case BASKET_PAGE_REQUESTED:
      return { ...state, linesUpdating: true };
    case BASKET_RECEIVED:
      return { ...state, linesUpdating: false };
    case BASKET_ORDER_TEMPLATE_SAVED:
      return { ...state, templateSaveResult: action.payload.savingResult };
    default:
      return state;
  }
}
