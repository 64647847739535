import { ChangePasswordAction, PASSWORD_CHANGE, notifyPasswordChanged } from './actions';
import { switchMap, mergeMap } from 'rxjs/operators';
import { changePassword } from './queries';
import { of } from 'rxjs';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormName } from 'behavior/pages';
import { ofType } from 'redux-observable';
import { Epic } from 'behavior/types';
import { toasts } from 'behavior/toasts';
import { navigateToPrevious } from 'behavior/routing';
import { RouteName, routesBuilder } from 'routes';

const epic: Epic<ChangePasswordAction> = (action$, _state$, { api, logger }) => action$.pipe(
  ofType(PASSWORD_CHANGE),
  switchMap(action => api.graphApi(changePassword, { input: action.payload }, { retries: 0 }).pipe(
    mergeMap(_ => {
      toasts.success('', { textKey: 'PasswordSuccessfullyUpdated', className: 'ChangePassword_toast' });

      return [
        navigateToPrevious([RouteName.ChangePassword, RouteName.Login, RouteName.EditProfile], routesBuilder.forMyAccount()),
        notifyPasswordChanged(),
      ];
    }),
    catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormName.ChangePassword))),
    retryWithToast(action$, logger, _ => of(unlockForm(FormName.ChangePassword))),
  )),
);

export default epic;
