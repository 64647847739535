import styles from './Breadcrumb.module.scss';
import { ReactNode, memo } from 'react';
import { Link } from 'components/primitives/links';
import { useSimpleTexts } from 'components/sanaText';
import { useRoutes } from 'components/primitives/route';
import { RouteData, routesBuilder } from 'routes';
import Schema from './Schema';
import { isHomePage } from './helpers';
import { joinClasses } from 'utils/helpers';
import { SmallArrowRightIcon } from 'components/primitives/icons';

const homeRoute = routesBuilder.forHome();

type Props = {
  items: {
    title: string | null;
    link: {
      url: string;
      to?: RouteData;
    };
  }[];
  noTopMargin?: boolean;
  backLink?: ReactNode;
};

const Breadcrumb = ({ items, noTopMargin, backLink = null }: Props) => {
  const [homePath] = useRoutes([homeRoute]);
  const addHomeLink = !isHomePage(items[0], homePath);
  const [homeText, ariaLabel] = useSimpleTexts([
    addHomeLink && 'BreadCrumb_StartingNode',
    'Aria_Breadcrumb_Label',
  ]).texts;

  if (items.length === 0) {
    if (!backLink)
      return null;

    return (
      <div className={joinClasses(styles.back, 'd-print-none', !noTopMargin && styles.topMargin)}>
        {backLink}
      </div>
    );
  }

  const shouldRender = !addHomeLink || homeText;
  const links = shouldRender && items.map((item, index) => (
    <li key={index}>
      <SmallArrowRightIcon className={styles.icon} aria-hidden />
      <Link {...item.link}>
        {item.title}
      </Link>
    </li>
  ));

  return (
    <div
      className={joinClasses(
        styles.container,
        !backLink && styles.noBack,
        !noTopMargin && styles.topMargin,
        'd-print-none',
      )}
    >
      {backLink &&
        <div className={styles.back}>
          {backLink}
          <ins role="presentation" />
        </div>
      }
      <nav className={styles.nav} aria-label={ariaLabel}>
        <ul>
          {homeText && <li><Link url={homePath} to={homeRoute}>{homeText}</Link></li>}
          {links}
        </ul>
        {shouldRender && <Schema nodes={items} homeText={homeText} homePath={homePath} />}
      </nav>
    </div>
  );
};

export default memo(Breadcrumb);
