import type { State } from './reducer';

export function mergeSettings(oldValue: Record<string, unknown>, newValue: Record<string, unknown>) {
  for (const [key, value] of Object.entries(newValue)) {
    const shouldMerge = !!value && typeof value === 'object' && !Array.isArray(value);

    oldValue[key] = shouldMerge
      ? mergeSettings({ ...oldValue[key] as Record<string, unknown> }, value as Record<string, unknown>)
      : value;
  }

  return oldValue;
}

export function areSettingsLoaded(state: State): state is LoadedSettings {
  return state.loaded;
}

export type LoadedSettings = Extract<State, { loaded: true }>;
