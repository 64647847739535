import { of } from 'rxjs';
import { RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { orderFailedQuery } from './queries';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import type { Handler } from 'behavior/pages/types';
import type { ContentSystemPageData } from '../system/content';
import type { OrderFailedPage } from './types';
import { initContentSystemPage, loadContentSystemPageQuery } from 'behavior/pages/system';
import { CheckoutProcessNames } from './constants';

const handler: Handler<OrderFailedRouteData, OrderFailedPage> = (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<OrderFailedPreviewResponse>(loadContentSystemPageQuery('orderFailed')).pipe(
      map(({ pages: { orderFailed } }) => ({
        page: {
          ...initContentSystemPage(orderFailed),
          component: PageComponentNames.OrderFailed as const,
          transaction: {
            id: '',
            failed: true,
            cancelled: false,
            isPaymentError: false,
            checkoutProcessName: CheckoutProcessNames.Order,
          },
        },
      })),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi<OrderFailedPageResponse>(orderFailedQuery, { id: transactionId }).pipe(
    mergeMap(({ pages: { orderFailed }, paymentTransaction: transaction }) => {
      if (transaction == null)
        return of(null);

      if (transaction.isPaymentError)
        return of({
          action$: of(navigateTo(routesBuilder.forPaymentError(transactionId))),
        });

      return of({
        page: {
          ...initContentSystemPage(orderFailed),
          component: PageComponentNames.OrderFailed as const,
          transaction: {
            id: transactionId,
            ...transaction,
            failed: true as const,
            cancelled: false as const,
          },
        },
      });
    }),
  );
};

export default handler;

type OrderFailedRouteData = {
  routeName: RouteName.OrderFailed;
  params: {
    previewToken?: string;
    transactionId?: string;
  };
};

type OrderFailedPreviewResponse = {
  pages: {
    orderFailed: ContentSystemPageData;
  };
};

type OrderFailedPageResponse = {
  pages: {
    orderFailed: ContentSystemPageData;
  };
  paymentTransaction: {
    isPaymentError: boolean;
    checkoutProcessName: CheckoutProcessNames;
  };
};
