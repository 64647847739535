import type { LatestBlogItem } from './types';

export const REGISTER_LATEST_BLOG_ITEMS = 'BLOG/LATEST_ITEMS/REGISTERED' as const;
export const registerLatestBlogItems = (key: string, settings: { amount: number }) => ({
  type: REGISTER_LATEST_BLOG_ITEMS,
  payload: { key, settings },
});

export const UNREGISTER_LATEST_BLOG_ITEMS = 'BLOG/LATEST_ITEMS/UNREGISTERED' as const;
export const unregisterLatestBlogItems = (key: string) => ({
  type: UNREGISTER_LATEST_BLOG_ITEMS,
  payload: { key },
});

export const LATEST_BLOG_ITEMS_REQUEST_COMPLETED = 'BLOG/LATEST_ITEMS/REQUEST/COMPLETED' as const;
export const latestBlogItemsRequestCompleted = (key: string, items: LatestBlogItem[]) => ({
  type: LATEST_BLOG_ITEMS_REQUEST_COMPLETED,
  payload: { key, items },
});

export type LatestBlogItemsAction = ReturnType<
  | typeof registerLatestBlogItems
  | typeof unregisterLatestBlogItems
  | typeof latestBlogItemsRequestCompleted
>;
