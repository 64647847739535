import type { RetryWithToast } from '../retryWithToast';
import { Action } from 'redux';
import { catchError } from 'rxjs/operators';
import { EMPTY, OperatorFunction } from 'rxjs';

const retryWithToast: RetryWithToast = (_action$, _logger, getContinueWith) =>
  catchError(error => {
    if (!getContinueWith)
      return EMPTY;

    return getContinueWith(error);
  }) as OperatorFunction<unknown, Action<unknown>>;

export default retryWithToast;