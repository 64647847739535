import { Preset } from 'behavior/pages/product';
import { useProductViewContext } from '../hooks';

const hideInMatrixPreset = Component => {
  return props => {
    const { preset } = useProductViewContext();

    if (preset === Preset.DetailsWithMatrix)
      return null;

    return <Component {...props} />;
  };
};

export default hideInMatrixPreset;
