import { useMemo, useEffect } from 'react';
import { useComputedValue } from 'utils/hooks';
import { sanaTextsLoaded } from 'behavior/sanaText';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import insiteEditorUseSanaTextsWrapper from './insiteEditorUseSanaTextsWrapper';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { addConstructedTexts, updateConstructedText } from 'behavior/insiteEditor/actions';

const insiteEditorUseRegisteredSanaTextsCore = options => {
  const dispatch = useDispatch();
  const constructedTexts = useSelector(state => state.insiteEditor.constructed);
  const { textOnly, isConstructedTexts } = options;

  useEffect(() => {
    const textsToLoad = options.reduce((acc, { key, value }) => {
      acc[key] = textOnly ? encodeSimpleText(value) : value;
      return acc;
    }, {});

    dispatch(sanaTextsLoaded(textsToLoad));
  }, [options, textOnly]);

  useEffect(() => {
    if (!isConstructedTexts)
      return;

    const textsToLoad = options.map(option => ({ key: option.key, deleted: false }));
    dispatch(addConstructedTexts(textsToLoad));
  }, [options.length, isConstructedTexts]);

  useEffect(() => {
    if (!constructedTexts || constructedTexts.length === 0)
      return;

    const deletedText = constructedTexts.find(text => !!text.deleted);
    if (!deletedText)
      return;

    const textsToLoad = options.reduce((acc, { key, value }) => {
      if (key === deletedText.key)
        acc[key] = textOnly ? encodeSimpleText(value) : value;

      return acc;
    }, {});

    dispatch(sanaTextsLoaded(textsToLoad));
    return () => dispatch(updateConstructedText({ key: deletedText.key, deleted: false }));
  }, [constructedTexts, options, textOnly]);

  const selectSanaTexts = useComputedValue(() => makeSanaTextsSelector(options), options.length);
  const sanaTexts = useSelector(selectSanaTexts);

  return useMemo(() => {
    const texts = sanaTexts.map(({ value }) => value !== undefined ? value : null);
    const preparedTexts = !textOnly ? texts : texts.map(decodeSimpleText);

    return { texts: preparedTexts, loaded: preparedTexts.length > 0 };
  }, [sanaTexts, textOnly]);
};

function selectSanaTexts(sanaTexts, options) {
  return options.map(option => ({ key: option.key, value: sanaTexts[option.key] }));
}

function makeSanaTextsSelector(options) {
  return createSelector(
    state => state.sanaTexts,
    sanaTexts => selectSanaTexts(sanaTexts, options),
  );
}

const encodeRules = [
  { from: /&/g, to: '&amp;' },
  { from: /</g, to: '&lt;' },
  { from: />/g, to: '&gt;' },
];

const decodeRules = [
  { from: /&nbsp;/g, to: '' },
  { from: /&lt;/g, to: '<' },
  { from: /&gt;/g, to: '>' },
  { from: /&amp;/g, to: '&' },
];

function encodeSimpleText(text) {
  return replace(text, encodeRules);
}

function decodeSimpleText(text) {
  return replace(stripHtmlTagsAndFormatting(text), decodeRules);
}

function replace(text, rules) {
  if (!text)
    return text;

  return rules.reduce((acc, rule) => acc.replace(rule.from, rule.to), text);
}

export const insiteEditorUseRegisteredSanaTexts = insiteEditorUseSanaTextsWrapper(insiteEditorUseRegisteredSanaTextsCore);