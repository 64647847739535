import styles from './Toast.module.scss';
import { joinClasses } from 'utils/helpers';

type Placement = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

const placements: Readonly<Record<Placement, string>> = Object.freeze({
  'top-left': styles.topLeft,
  'top-center': styles.topCenter,
  'top-right': styles.topRight,
  'bottom-left': styles.bottomLeft,
  'bottom-center': styles.bottomCenter,
  'bottom-right': styles.bottomRight,
});

type Props = {
  hasToasts: boolean;
  placement: Placement;
};

const ToastContainer = ({ hasToasts, placement, ...props }: Props) => (
  <div
    className={joinClasses(
      styles.container,
      placements[placement],
      !hasToasts && styles.noInteraction,
    )}
    {...props}
  />
);

export default ToastContainer;
