export const PASSWORD_CHANGE = 'PASSWORD_CHANGE' as const;
export const changePassword = (oldPassword: string, newPassword: string) => ({
  type: PASSWORD_CHANGE,
  payload: { old: oldPassword, new: newPassword },
});

export const PASSWORD_CHANGED = 'PASSWORD_CHANGED' as const;
export const notifyPasswordChanged = () => ({ type: PASSWORD_CHANGED });

export type ChangePasswordAction = ReturnType<
  | typeof changePassword
  | typeof notifyPasswordChanged
>;
