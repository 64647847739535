import styles from 'components/primitives/grid/Grid.module.scss';
import type { HtmlProps, HtmlTagName } from 'utils/react';
import { forwardRef, memo, ReactElement, ReactNode, Ref } from 'react';
import { joinClasses } from 'utils/helpers';
import { Optional } from 'utils/types';

type Props<T1 extends HtmlTagName | undefined, T2 extends HtmlTagName = T1 extends HtmlTagName ? T1 : 'div'> = {
  as: T1;
  className?: string | null;
  noGutters?: boolean;
  noWrap?: boolean;
  mainAxisAlign?: keyof typeof justificationClassNames;
  crossAxisAlign?: keyof typeof alignmentClassNames;
  children: ReactNode;
} & Omit<HtmlProps<T2>, 'className' | 'ref'>;

declare function Row<T extends HtmlTagName>(props: Props<T> & { ref?: Ref<T> }): ReactElement;
declare function Row<T extends undefined>(props: Optional<Props<T>, 'as'> & { ref?: Ref<HTMLDivElement> }): ReactElement;

const ForwardRefRow = forwardRef<any, Props<any>>((
  {
    as: Tag = 'div',
    className,
    noGutters,
    noWrap,
    mainAxisAlign,
    crossAxisAlign,
    children,
    ...attributes
  },
  ref,
) => {
  const rowClasses = joinClasses(
    styles.row,
    noGutters && 'row-no-gutters',
    noWrap && styles.flexNowrap,
    mainAxisAlign && justificationClassNames[mainAxisAlign],
    crossAxisAlign && alignmentClassNames[crossAxisAlign],
    className,
  );
  return (
    <Tag className={rowClasses} {...attributes} ref={ref}>
      {children}
    </Tag>
  );
});

ForwardRefRow.displayName = 'Row';

export default memo(ForwardRefRow) as typeof Row;

const justificationClassNames = {
  start: styles.justifyContentStart,
  center: styles.justifyContentCenter,
  end: styles.justifyContentEnd,
  between: styles.justifyContentBetween,
  around: styles.justifyContentAround,
} as const;

const alignmentClassNames = {
  start: styles.alignItemsStart,
  center: styles.alignItemsCenter,
  end: styles.alignItemsEnd,
  baseline: styles.alignItemsBaseline,
  stretch: styles.alignItemsStretch,
} as const;
