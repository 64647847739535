import { PageComponentNames } from 'behavior/pages/componentNames';
import { NEWS_LOADED, NewsAction } from './actions';
import { NewsItem } from './types';

type State = {
  news: NewsItem[];
  component: PageComponentNames.News;
};

export default function (state: State, action: NewsAction) {
  switch (action.type) {
    case NEWS_LOADED:
      return { ...state, news: action.payload };
    default:
      return state;
  }
}
