import type { Handler } from 'behavior/pages/types';
import type { LoadedSettings } from 'behavior/settings';
import type { AppState } from 'behavior';
import type { ProductSegment } from '../queries.types';
import type {
  SortField,
  ProductListOptions,
  ProductListParams,
  ProductListPageBase,
} from '../types';
import { map, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { createLoadOptions as createLoadOptionsBase } from '../helpers';
import { createSearchQuery } from './queries';
import { productsGeneralInfoLoaded } from '../actions';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { RouteName } from 'routes';

const handler: Handler<ProductsWithCategoryRouteData, ProductsWithCategoryPage> = ({ params, options }, state$, { api }) => {
  const handle = ({ settings }: LoadedAppState) => {
    if (!params.id)
      return of(null);

    const viewMode = params.viewMode || settings.productList.defaultViewMode;
    const loadOptions = createLoadOptions(params, { ...options, viewMode }, settings);

    const productsOnly = options && options.productsOnly;
    const state = state$.value;

    return api.graphApi<LoadProductsWithCategoryResponse>(createSearchQuery({
      productsOnly,
      isInsiteEditor: state.insiteEditor.initialized,
      isProductGroupingEnabled: settings.product.productGrouping.isEnabled,
    }), {
      id: params.id,
      options: loadOptions,
      loadLargeImages: false,
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    }).pipe(
      map(({ catalog: { products: foundProducts, categories: foundCategories } }) => {
        if (!foundProducts)
          return null;

        if (productsOnly) {
          const products = foundProducts.products;
          const appendProducts = options?.appendProducts || false;
          const size = loadOptions.page.size || products.length;
          const totalCount = foundProducts.totalCount;
          const productGroupsCount = foundProducts.productGroupsCount;
          const productsCount = foundProducts.productsCount;
          const sorting = params.sort;

          return {
            page: state.page as ProductsWithCategoryPage,
            action$: of(productsGeneralInfoLoaded(products, appendProducts, size, totalCount, productGroupsCount, productsCount, sorting)),
          };
        }

        const category = foundCategories[0];
        if (!category)
          return null;

        const { sort, viewMode, agreementLine: salesAgreementLineId } = params;
        const {
          showThumbnails,
          defaultSorting,
          defaultViewMode,
          viewModeSwitchEnabled,
          pagingType,
          backgroundColor,
          backgroundImage,
        } = settings.productList;
        const { products, totalCount, facets, productGroupsCount, productsCount } = foundProducts;

        if (facets)
          facets.facets = facets.facets.filter(f => f.name !== 'Product category');

        const page: ProductsWithCategoryPage = {
          id: params.id,
          products,
          totalCount,
          productGroupsCount,
          productsCount,
          backgroundColor,
          backgroundImage,
          preset: null,
          facets,
          viewModeSwitchEnabled,
          showThumbnails,
          pagingType,
          selectedSorting: sort || defaultSorting,
          defaultSorting,
          defaultViewMode,
          selectedViewMode: viewMode || defaultViewMode,
          lastViewedEnabled: true,
          component: PageComponentNames.ProductsWithCategory,
          categoryName: category.name,
        };

        if (!!salesAgreementLineId)
          page.salesAgreement = { preSelectedLine: { id: salesAgreementLineId } };

        return { page };
      }),
    );
  };

  return state$.pipe(
    first(isStateLoaded),
    switchMap(handle),
  );
};

export default handler;

function isStateLoaded(state: AppState): state is LoadedAppState {
  return state.settings.loaded && !!state.analytics;
}

type LoadedAppState = AppState & {
  settings: LoadedSettings;
  analytics: NonNullable<AppState['analytics']>;
};

function createLoadOptions(params: ProductListParams, options: ProductListOptions, settings: LoadedSettings) {
  const loadOptions = createLoadOptionsBase(params, options, settings.productList);

  if (!loadOptions.sorting && settings.productList.defaultSorting)
    loadOptions.sorting = [settings.productList.defaultSorting];

  loadOptions.categoryIds = [params.id];

  return loadOptions;
}

type ProductsWithCategoryRouteData = {
  routeName: RouteName.ProductsWithCategory;
  params: ProductListParams;
  options?: ProductListOptions;
};

type ProductsWithCategoryPage = ProductListPageBase & {
  component: PageComponentNames.ProductsWithCategory;
  backgroundColor: string | null;
  backgroundImage: string | null;
  defaultSorting: SortField;
  categoryName: string;
  salesAgreement?: {
    preSelectedLine?: {
      id: string;
    };
  };
};

type LoadProductsWithCategoryResponse = {
  catalog: {
    categories: Array<{
      name: string;
    }>;
    products: {
      products: Array<ProductSegment & {
        variantComponentGroups: Array<{
          id: string;
        }>;
      }>;
      facets: {
        facets: Array<{
          name: string;
          title: string;
          sortDescending: boolean;
          crawlable: boolean;
          values: Array<{
            title: string;
            textTitle: string | null;
            count: number;
            value: string | null;
            selected: boolean;
          }>;
        }>;
        multiSelect: boolean;
      } | null;
      totalCount: number;
      productGroupsCount: number;
      productsCount: number;
    } | null;
  };
};
