import type { ActionPricesPresentationType } from 'behavior/settings';
import { getDiscountAmount, getDiscountPercentage } from './discountUtils';

export const createPriceModel = (salesPrice: number, basePrice: number, actionPricesMode: ActionPricesPresentationType) => {
  switch (actionPricesMode) {
    case 'SALES_PRICE':
      return {
        price: salesPrice,
      };

    case 'BASE_AND_SALES_PRICE':
      return {
        price: salesPrice,
        actionPrice: basePrice,
      };

    case 'BASE_AND_SALES_PRICE_DISCOUNT_PERCENTAGE':
      return {
        price: salesPrice,
        actionPrice: basePrice,
        discountPercentage: getDiscountPercentage(salesPrice, basePrice),
      };

    case 'BASE_AND_SALES_PRICE_DISCOUNT_AMOUNT':
      return {
        price: salesPrice,
        actionPrice: basePrice,
        discountAmount: getDiscountAmount(salesPrice, basePrice),
      };

    case 'SALES_PRICE_DISCOUNT_AMOUNT':
      return {
        price: salesPrice,
        discountAmount: getDiscountAmount(salesPrice, basePrice),
      };

    case 'SALES_PRICE_DISCOUNT_PERCENTAGE':
      return {
        price: salesPrice,
        discountPercentage: getDiscountPercentage(salesPrice, basePrice),
      };

    default:
      throw new Error(`Action prices presentation mode ${actionPricesMode} is not supported.`);
  }
};
