exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "[data-hero-colored=true] .CallUsMessage_block,[data-hero-colored=true] .CallUsMessage_icon{color:inherit}.CallUsMessage_block{font-size:var(--theme-text_SmallerSize,12px);line-height:1.6}.CallUsMessage_block .CallUsMessage_icon{margin-right:.18em;max-height:.33em;max-width:.33em;vertical-align:middle}", ""]);

// exports
exports.locals = {
	"block": "CallUsMessage_block",
	"icon": "CallUsMessage_icon"
};