import styles from '../MainNav.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ComponentGroup } from 'behavior/navigation/constants';
import DesktopNavBase from './DesktopNavBase';
import NavMenuContext from '../NavMenuContext';
import { joinClasses } from 'utils/helpers';

const MultiColumnMainMenuBlock = ({ model, id, isDesignerMode }) => {
  const context = useMemo(() => ({
    componentGroup: ComponentGroup.MultiColumnMainMenu,
    showViewMoreLink: model.secondLevel_ShowViewMoreLink,
  }), []);
  const navId = `MainNav_${id}`;

  const style = useMemo(() => ({
    '--topLevel-fontFamily': model.topLevel_FontFamily && (model.topLevel_FontFamily + ', sans-serif'),
    '--topLevel-fontSize': model.topLevel_FontSize,
    '--topLevel-allCaps': model.topLevel_AllCaps ? 'uppercase' : null,
    '--topLevel-fontColor': model.topLevel_FontColor,
    '--topLevel-hoverFontColor': model.topLevel_HoverFontColor,
    '--topLevel-nonHoveredItemFontColor': model.topLevel_NonHoveredItemFontColor,
    '--topLevel-activeItemUnderlineColor': model.topLevel_ActiveItemUnderlineColor,
    '--topLevel-expandedMenuBackgroundColor': model.topLevel_ExpandedMenuBackgroundColor,
    '--topLevel-expandedMenuBorderColor': model.topLevel_ExpandedMenuBorderColor,
    '--topLevel-itemHeight': model.topLevel_ItemHeight,
    '--secondLevel-fontFamily': model.secondLevel_FontFamily && (model.secondLevel_FontFamily + ', sans-serif'),
    '--secondLevel-fontSize': model.secondLevel_FontSize,
    '--secondLevel-allCaps': model.secondLevel_AllCaps ? 'uppercase' : null,
    '--secondLevel-fontColor': model.secondLevel_FontColor,
    '--secondLevel-hoverFontColor': model.secondLevel_HoverFontColor,
    '--secondLevel-separatorLineColor': model.secondLevel_SeparatorLineColor,
    '--secondLevel-viewMoreLinkFontColor': model.secondLevel_ViewMoreLinkFontColor,
    '--secondLevel-viewMoreLinkHoverFontColor': model.secondLevel_ViewMoreLinkHoverFontColor,
    '--thirdLevel-fontFamily': model.thirdLevel_FontFamily && (model.thirdLevel_FontFamily + ', sans-serif'),
    '--thirdLevel-fontSize': model.thirdLevel_FontSize,
    '--thirdLevel-allCaps': model.thirdLevel_AllCaps ? 'uppercase' : null,
    '--thirdLevel-fontColor': model.thirdLevel_FontColor,
    '--thirdLevel-hoverFontColor': model.thirdLevel_HoverFontColor,
  }), [model]);

  const className = joinClasses(
    model.topLevel_ShowActiveItemUnderline && styles.showActiveItemUnderline,
    model.thirdLevel_ShowArrowInFrontOfSubItem && styles.showSubItemArrow,
  );

  return (
    <NavMenuContext.Provider value={context}>
      <DesktopNavBase
        isDesignerMode={isDesignerMode}
        id={navId}
        style={style}
        className={className}
      />
    </NavMenuContext.Provider>
  );
};

MultiColumnMainMenuBlock.propTypes = {
  model: PropTypes.shape({
    topLevel_FontFamily: PropTypes.string,
    topLevel_FontSize: PropTypes.string,
    topLevel_AllCaps: PropTypes.bool.isRequired,
    topLevel_FontColor: PropTypes.string,
    topLevel_HoverFontColor: PropTypes.string,
    topLevel_NonHoveredItemFontColor: PropTypes.string,
    topLevel_ActiveItemUnderlineColor: PropTypes.string,
    topLevel_ShowActiveItemUnderline: PropTypes.bool.isRequired,
    topLevel_ExpandedMenuBackgroundColor: PropTypes.string,
    topLevel_ExpandedMenuBorderColor: PropTypes.string,
    topLevel_ItemHeight: PropTypes.string,
    secondLevel_FontFamily: PropTypes.string,
    secondLevel_FontSize: PropTypes.string,
    secondLevel_AllCaps: PropTypes.bool.isRequired,
    secondLevel_FontColor: PropTypes.string,
    secondLevel_HoverFontColor: PropTypes.string,
    secondLevel_SeparatorLineColor: PropTypes.string,
    secondLevel_ShowViewMoreLink: PropTypes.bool.isRequired,
    secondLevel_ViewMoreLinkFontColor: PropTypes.string,
    secondLevel_ViewMoreLinkHoverFontColor: PropTypes.string,
    thirdLevel_FontFamily: PropTypes.string,
    thirdLevel_FontSize: PropTypes.string,
    thirdLevel_AllCaps: PropTypes.bool.isRequired,
    thirdLevel_FontColor: PropTypes.string,
    thirdLevel_HoverFontColor: PropTypes.string,
    thirdLevel_ShowArrowInFrontOfSubItem: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default MultiColumnMainMenuBlock;
