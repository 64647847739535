import styles from './Containers.module.scss';
import 'css/Animations.module.scss';
import { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import createContentBlock from './createContentBlock';
import { Col } from 'components/primitives/grid';
import MediaBackground from 'components/primitives/grid/MediaBackground';
import { usePrintMode } from 'utils/hooks';
import { VerticalAlignmentsMap, HorizontalAlignmentsMap, AnimationsMap, NoBorderStyleValue } from './constants';
import { useCssAnimation } from './hooks';
import VdColumnContentWrapper from 'components/visualDesigner/column/VdColumnContentWrapper';
import { useResponsiveBreakpoints } from 'utils/layout';
import { joinClasses } from 'utils/helpers';

const ContentBlockColumn = ({
  id: columnId,
  colspan: { sm, md, lg, xl },
  contentBlocks,
  columnAnimation,
  contentOrientation,
  verticalAlignment,
  horizontalAlignment,
  attributes,
  background,
  border,
  padding,
  minHeight,
  fullWidthRow,
}) => {
  const { id, className } = attributes;
  const colRef = useRef(null);
  const animationClassName = useCssAnimation(colRef, columnAnimation);
  const showBorder = border.style !== NoBorderStyleValue && !!border.width;
  const isSplitContent = contentOrientation === 'HORIZONTAL_SPLIT';
  const isHorizontalOrientation = contentOrientation === 'HORIZONTAL' || isSplitContent;

  const { xs: viewXs } = useResponsiveBreakpoints();

  const wrapperStyles = {
    borderWidth: showBorder && border.width || null,
    padding: padding || null,
    '--minHeight_Desktop': minHeight.desktop,
    '--minHeight_Tablet': minHeight.tablet,
    '--minHeight_Mobile': minHeight.mobile,
  };

  const showBackground = showBorder
    || background.color
    || background.mobileImage
    || background.desktopImage
    || background.video;

  const containerClassName = joinClasses(
    styles.contentBox,
    styles[VerticalAlignmentsMap[verticalAlignment]],
    styles[HorizontalAlignmentsMap[horizontalAlignment]],
    isHorizontalOrientation ? styles.horizontalOrientation : styles.verticalOrientation,
    isSplitContent && styles.splitContent,
  );

  const isPrintMode = usePrintMode();
  const bounds = useMemo(() => ({ sm, md, lg, xl }), [sm, md, lg, xl]);
  const memoizedContentBlocks = useMemo(() => renderContentBlocks(contentBlocks, bounds, fullWidthRow, contentOrientation, isPrintMode),
    [contentBlocks, bounds, fullWidthRow, contentOrientation, isPrintMode]);

  return (
    <Col
      ref={colRef}
      xs={12} sm={sm} md={md} lg={lg} xl={xl}
      id={id || null}
      className={`${styles.col} ${animationClassName} ${className || ''}`}
    >
      {showBackground &&
        <MediaBackground
          parentType="column"
          {...background}
          borderWidth={border.width}
          borderStyle={border.style}
          borderColor={border.color}
          cornerRadius={border.radius}
          imageLoadVisibleByDefault={!!AnimationsMap[columnAnimation]}
          hideImageOnMobile={background.hideImageOnMobile}
          isMobile={viewXs}
          mutedSound
        />
      }
      <div className={containerClassName} style={wrapperStyles}>
        <VdColumnContentWrapper columnId={columnId} contentOrientation={contentOrientation}>
          {memoizedContentBlocks}
        </VdColumnContentWrapper>
      </div>
    </Col>
  );
};

ContentBlockColumn.propTypes = {
  id: PropTypes.string.isRequired,
  colspan: PropTypes.object.isRequired,
  attributes: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  contentBlocks: PropTypes.array,
  columnAnimation: PropTypes.string,
  contentOrientation: PropTypes.string,
  verticalAlignment: PropTypes.string,
  horizontalAlignment: PropTypes.string,
  background: PropTypes.shape({
    fullWidth: PropTypes.bool,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
    hideImageOnMobile: PropTypes.bool,
    imageAltText: PropTypes.string,
    video: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  border: PropTypes.shape({
    width: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.string,
  }).isRequired,
  padding: PropTypes.string,
  minHeight: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  fullWidthRow: PropTypes.bool,
};

export default ContentBlockColumn;

function renderContentBlocks(contentBlocks, bounds, fullWidthRow, contentOrientation, isPrintMode) {
  if (!contentBlocks || !contentBlocks.length)
    return null;

  return contentBlocks.map((contentBlock, index) => createContentBlock({
    ...contentBlock,
    bounds,
    fullWidthRow,
    contentOrientation,
    isPrintMode,
  }, index));
}
