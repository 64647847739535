import type { Transform } from './types';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';
import { stylesToJSON } from 'utils/helpers';

const transformImage: Transform = node => {
  if (node.type !== 'tag' || node.name !== 'img' || !node.attribs?.src)
    return;

  const { alt = '', style, class: className, usemap, ...attributes } = node.attribs;
  return (
    <ResponsiveLazyImage
      alt={alt}
      wrapperClassName={className}
      style={style ? stylesToJSON(style) : undefined}
      useMap={usemap}
      {...attributes}
    />
  );
};

export default transformImage;
