import { subAccountsPageQuery, subAccountsPreviewPageQuery } from './queries';
import { ContentSystemPageData, initContentSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import type { Handler } from '../types';
import type { SubAccountsPage, SubAccountsOptions, SubAccount } from './types';
import { RouteName } from 'routes';
import { subAccounts } from './stubData';

const handler: Handler<SubAccountsRouteData, SubAccountsPage> = ({ params }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi<SubAccountsPreviewPageResponse>(subAccountsPreviewPageQuery).pipe(
      map(({ pages: { subAccounts: page }, profile }) => ({
        page: {
          ...page,
          component: PageComponentNames.SubAccounts as const,
          subAccounts,
          options: profile.subAccounts.options,
        },
      })),
      initContentSystemPageContent(),
    );
  }

  return api.graphApi<SubAccountsPageResponse>(subAccountsPageQuery).pipe(
    map(({ pages: { subAccounts: page }, profile }) => ({
      page: {
        ...page,
        component: PageComponentNames.SubAccounts as const,
        subAccounts: profile.subAccounts.search,
        options: profile.subAccounts.options,
      },
    })),
    initContentSystemPageContent(),
  );
};

export default handler;

type SubAccountsRouteData = {
  routeName: RouteName.SubAccounts;
  params?: {
    previewToken?: string;
  };
};

type SubAccountsPageResponseBase = {
  pages: {
    subAccounts: ContentSystemPageData;
  };
};

type SubAccountsPreviewPageResponse = SubAccountsPageResponseBase & {
  profile: {
    subAccounts: {
      options: SubAccountsOptions;
    };
  };
};

type SubAccountsPageResponse = SubAccountsPageResponseBase & {
  profile: {
    subAccounts: {
      options: SubAccountsOptions;
      search: {
        list: SubAccount[];
      };
    };
  };
};
