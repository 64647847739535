import type { BlogPage } from './types';
import {
  BlogItemsAction,
  BLOG_ITEMS_RECEIVED,
  BLOG_FACETS_RECEIVED,
  BLOG_ITEMS_CLEARED,
} from './actions';
import { createFacetsModel } from './helpers';

export default (state: BlogPage, action: BlogItemsAction) => {
  switch (action.type) {
    case BLOG_ITEMS_RECEIVED:
      const { items, totalCount, appendItems } = action.payload;

      return {
        ...state,
        items: appendItems && state.items ? [...state.items, ...items] : items,
        totalCount,
      };
    case BLOG_FACETS_RECEIVED:
      const { facets } = action.payload;
      const { selectedFacets, availableFacets } = createFacetsModel(facets);

      return {
        ...state,
        hasFacets: facets?.length > 0,
        availableFacets,
        selectedFacets,
      };
    case BLOG_ITEMS_CLEARED:
      return {
        ...state,
        items: undefined,
        options: {
          ...state.options,
          pageSize: action.payload.currentPageSize,
          pageIndex: undefined,
        },
      };
    default:
      return state;
  }
};
