import { memo } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  model: {
    title_FontSize: string | null;
  };
};

const PageTitle = ({ model }: Props) => {
  const title = useSelector(state => state.page.title);
  const style = model.title_FontSize ? { fontSize: model.title_FontSize } : null;

  return title ? <h1 style={style}>{title}</h1> : null;
};

export default memo(PageTitle);
