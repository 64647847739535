import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, mergeMapTo } from 'rxjs/operators';
import { subscribeMutation, unsubscribeMutation } from './queries';
import { NEWSLETTER_SUBSCRIBE, NEWSLETTER_UNSUBSCRIBE, updateNewsletterSubscription, NewsletterAction } from './actions';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { FormName, unlockForm } from 'behavior/pages';

const epic: Epic<NewsletterAction> = function newsletterEpic(action$, _, { api, logger }) {
  const subscribe$ = action$.pipe(
    ofType(NEWSLETTER_SUBSCRIBE),
    switchMap(({ payload: { input, updatedById } }) => api.graphApi(subscribeMutation, { input }).pipe(
      mergeMapTo([
        resetCaptcha(updatedById),
        updateNewsletterSubscription(updatedById, input.email),
        unlockForm(FormName.NewsletterSubscribe),
      ]),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(updatedById), unlockForm(FormName.NewsletterSubscribe))),
      retryWithToast(action$, logger, () => of(resetCaptcha(updatedById), unlockForm(FormName.NewsletterSubscribe))),
    )),
  );

  const unsubscribe$ = action$.pipe(
    ofType(NEWSLETTER_UNSUBSCRIBE),
    switchMap(({ payload: { input, updatedById } }) => api.graphApi(unsubscribeMutation, { input }).pipe(
      mergeMapTo([
        updateNewsletterSubscription(updatedById, input.email),
        unlockForm(FormName.NewsletterUnsubscribe),
      ]),
      catchApiErrorWithToast(),
      retryWithToast(action$, logger),
    )),
  );

  return merge(subscribe$, unsubscribe$);
};

export default epic;
