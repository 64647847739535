import { pad } from '../helpers';

const customFormats = new Map<string, (date: Date) => string>([
  ['it-IT', d => `${pad(d.getDate(), 2)}/${pad(d.getMonth() + 1, 2)}/${d.getFullYear()}`],
  ['nl-BE', d => `${d.getDate()}/${pad(d.getMonth() + 1, 2)}/${d.getFullYear()}`],
  ['be-BE', d => `${pad(d.getDate(), 2)}.${pad(d.getMonth() + 1, 2)}.${d.getFullYear()}`],
  ['ja-JP', d => `${d.getFullYear()}/${pad(d.getMonth() + 1, 2)}/${pad(d.getDate(), 2)}`],
]);

export function getCustomDateFormatForCulture(culture: string) {
  return customFormats.get(culture);
}
