import createRenderer from './createProfileRenderer';
import { PageComponentNames } from 'behavior/pages';
import editProfileRenderer from './editProfile';
import orderTemplatesRenderer from './orderTemplates';
import editMyGarageRenderer from './myGarage';

export default {
  [PageComponentNames.EditProfile]: createRenderer('YourDetails', editProfileRenderer, { linkKey: 'edit' }),
  [PageComponentNames.OrderTemplates]: createRenderer('OrderTemplates_Header', orderTemplatesRenderer, { linkKey: 'templates' }),
  [PageComponentNames.EditMyGarage]: createRenderer('EditMyGarage', editMyGarageRenderer, { linkKey: 'myGarage' }),
};
