import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import type { OrderResult, PaymentInput } from './types';

export const PAY_ORDER = 'PAY_ORDER/SUBMIT' as const;
export const payOrder = (transactionId: string, paymentInput: PaymentInput, additionalCustomerData?: AdditionalCustomerData, extraPaymentData?: ExtraPaymentData) => ({
  type: PAY_ORDER,
  payload: { transactionId, paymentInput, additionalCustomerData, extraPaymentData },
});

export const PAY_ORDER_RESULT_RECEIVED = 'PAY_ORDER/RESULT_RECEIVED' as const;
export const receivePayOrderResult = (result: OrderResult) => ({
  type: PAY_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const PAYMENT_METHOD_EXTRA_DATA_REQUESTED = 'ORDER_PAYMENT/PAYMENT_METHOD_EXTRA_DATA/REQUESTED' as const;
export const requestPaymentMethodExtraData = (transactionId: string, paymentMethodId: string) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_REQUESTED,
  payload: { transactionId, paymentMethodId },
});

export const PAYMENT_METHOD_EXTRA_DATA_RECEIVED = 'ORDER_PAYMENT/ADDITIONAL_CUSTOMER_DATA/RECEIVED' as const;
export const receivePaymentMethodExtraData = (paymentMethodId: string, additionalCustomerData?: AdditionalCustomerData, extraPaymentData?: ExtraPaymentData) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
  payload: { paymentMethodId, additionalCustomerData, extraPaymentData },
});

export type ReceivePayOrderResultAction = ReturnType<typeof receivePayOrderResult>;
export type ReceivePaymentMethodExtraData = ReturnType<typeof receivePaymentMethodExtraData>;
export type PayOrder = ReturnType<typeof payOrder>;

export type OrderPaymentAction = ReturnType<
  | typeof receivePayOrderResult
  | typeof receivePaymentMethodExtraData
  | typeof payOrder
  | typeof requestPaymentMethodExtraData
>;
