import { RouteName } from 'routes';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { ContentSystemPage, ContentSystemPageData, initContentSystemPage, loadContentSystemPageQuery } from '../system';
import type { Handler } from '../types';

const handler: Handler<NewsletterUnubscribeRouteData, NewsletterUnsubscribePage> = (_routeData, _state$, { api }) =>
  api.graphApi<NewsletterUnsubscribePageResponse>(loadContentSystemPageQuery('unsubscribe')).pipe(
    map(({ pages: { unsubscribe } }) => ({
      page: {
        ...initContentSystemPage(unsubscribe),
        component: PageComponentNames.Unsubscribe,
      },
    }),
  ),
);

export default handler;

type NewsletterUnubscribeRouteData = {
  routeName: RouteName.NewsletterUnSubscribe;
};

type NewsletterUnsubscribePage = ContentSystemPage & {
  component: PageComponentNames.Unsubscribe;
};

type NewsletterUnsubscribePageResponse = {
  pages: {
    unsubscribe: ContentSystemPageData;
  };
};
