import type { Epic } from 'behavior/types';
import type { Action } from 'redux';
import type { ModifiedLines } from 'behavior/basket/types';
import { merge, from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, startWith, exhaustMap, pluck } from 'rxjs/operators';
import { DocumentAction, DOCUMENT_REORDER_REQUESTED, documentReorderResultReceived } from '../actions';
import { reorderMutation } from '../queries';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { basketChangeCompleted, navigateTo } from 'behavior/events';
import {
  trackAddToBasket,
  trackRemoveFromBasket,
  getProductsTrackingDataFromLines,
} from 'behavior/analytics';
import { reloadLocation } from 'behavior/routing';
import { ReorderResult } from '../constants';

const epic: Epic<DocumentAction> = (action$, state$, dependencies) =>
  action$.pipe(
    ofType(DOCUMENT_REORDER_REQUESTED),
    exhaustMap(({ payload }) =>
      dependencies.api.graphApi<ReorderResponse>(reorderMutation,
        {
          input: payload,
          requestModifiedLines: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
        },
        { retries: 0 },
      ).pipe(
        pluck('documents', 'reorder'),
        mergeMap(reorder => {
          if (reorder == null)
            return of(reloadLocation());

          const { result, basket, deletedLines } = reorder;
          const linesUpdated = result === ReorderResult.Success
            ? basket.productLines.totalCount
            : 0;
          const resultActions: Action[] = [
            unsetLoadingIndicator(),
            documentReorderResultReceived(result),
            basketChangeCompleted(linesUpdated),
          ];

          if (result !== ReorderResult.Success)
            return from(resultActions);

          const removedProducts = deletedLines ? getProductsTrackingDataFromLines(deletedLines.list) : [];
          if (removedProducts && removedProducts.length)
            resultActions.push(trackRemoveFromBasket({ products: removedProducts }));

          const addedProducts = basket.addedLines ? getProductsTrackingDataFromLines(basket.addedLines.list) : [];
          if (addedProducts && addedProducts.length) {
            resultActions.push(trackAddToBasket({ products: addedProducts }));
          }

          return merge(
            from(resultActions),
            of(navigateTo(routesBuilder.forBasket())),
          );
        }),
        retryWithToast(action$, dependencies.logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

export default epic;

type ReorderResponse = {
  documents: {
    reorder: {
      result: ReorderResult | null;
      basket: {
        productLines: {
          totalCount: number;
        };
        addedLines: ModifiedLines;
      };
      deletedLines: ModifiedLines;
    };
  };
};