import styles from './Header.module.scss';
import { memo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { renderRows, extendDefaultSettings } from 'components/containers';
import UseStickyHeader from './UseStickyHeader';
import { useLayoutShifter } from 'utils/layout';
import { mobile as defaultContent } from './defaultContent';
import { MobileStickyDisplayMode } from 'behavior/header';

const ELEMENT_NAME = 'BottomMobileSticky';
const SHIFT_TYPE = 'BOTTOM'; /*:ShiftType*/

const MobileHeaderTemplate = ({ isSticky }) => {
  const { mobile, mobileSticky, mobileStickyDisplayMode } = useSelector(state => state.header);
  const showSticky = isSticky;
  const { bottomFixedElementsHeight, updateElementsAffectingShiftData } = useLayoutShifter();
  const stickyMobileBottomRef = useRef(null);

  useEffect(() => {
    if (!stickyMobileBottomRef.current)
      return;

    updateElementsAffectingShiftData(
      SHIFT_TYPE,
      ELEMENT_NAME,
      stickyMobileBottomRef.current.offsetHeight,
      true,
      true,
    );
    return () => updateElementsAffectingShiftData(SHIFT_TYPE, ELEMENT_NAME);
  }, []);

  const headerStyles = {
    marginBottom: `${stickyMobileBottomRef.current ? bottomFixedElementsHeight - stickyMobileBottomRef.current.offsetHeight : 0}px`,
  };

  if (mobileStickyDisplayMode === MobileStickyDisplayMode.None) 
    return renderRows(mobile ?? extendDefaultSettings(defaultContent));

  if (mobileStickyDisplayMode === MobileStickyDisplayMode.Bottom)
    return (
      <>
        {renderRows(mobile ?? extendDefaultSettings(defaultContent))}
        <div ref={stickyMobileBottomRef} className={styles.bottom} style={headerStyles}>
          {renderRows(mobileSticky ?? extendDefaultSettings(defaultContent))}
        </div>
      </>
    );

  return (
    <UseStickyHeader showSticky={showSticky}>
      {showSticky
        ? renderRows(mobileSticky ?? extendDefaultSettings(defaultContent))
        : renderRows(mobile ?? extendDefaultSettings(defaultContent))
      }
    </UseStickyHeader>
  );
};

MobileHeaderTemplate.propTypes = {
  isSticky: PropTypes.bool,
};

export default memo(MobileHeaderTemplate);
