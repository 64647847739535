import type { LatestBlogItem } from './types';
import {
  LatestBlogItemsAction,
  LATEST_BLOG_ITEMS_REQUEST_COMPLETED,
  REGISTER_LATEST_BLOG_ITEMS,
  UNREGISTER_LATEST_BLOG_ITEMS,
} from './actions';

type State = Record<string, {
  items?: LatestBlogItem[];
  settings: { amount: number };
}>;

export const initialState: State = {};

export default (state = initialState, action: LatestBlogItemsAction): State => {
  switch (action.type) {
    case REGISTER_LATEST_BLOG_ITEMS:
        return {
          ...state,
          [action.payload.key]: { settings: action.payload.settings },
        };
    case UNREGISTER_LATEST_BLOG_ITEMS:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.payload.key]: _, ...stateWithoutKey } = state;

      return stateWithoutKey;
    case LATEST_BLOG_ITEMS_REQUEST_COMPLETED:
      if (!(action.payload.key in state))
        return state;

      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          items: action.payload.items,
        },
      };
    default:
      return state;
  }
};
