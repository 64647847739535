import type { TrackerInfo } from './types';
import type { AppState } from 'behavior';

const push = <TPayload>(payload: TPayload) => (dataLayerName: string) => {
  const dataLayer = typeof window !== 'undefined' && (<any>window)[dataLayerName];
  if (!dataLayer)
    return;

  dataLayer.push(payload);
};

export const pushToDataLayer = <TInput, TOutput>(
  state: AppState,
  inputData: TInput,
  createPayload: ((input: TInput, state: AppState) => TOutput),
) => {
  const dataLayersNames = getDataLayersNames(state.analytics!.trackers);
  if (!dataLayersNames.length)
    return;

  const payload = createPayload(inputData, state);
  dataLayersNames.forEach(push(payload));
};

const getDataLayersNames = (trackers?: Array<TrackerInfo>) => {
  const dataLayersNames: string[] = [];

  if (trackers) {
    trackers.forEach(t => {
      if (!dataLayersNames.includes(t.dataLayerName))
        dataLayersNames.push(t.dataLayerName);
    });
  }
  return dataLayersNames;
};