import type { Product, CalculatedProduct } from '../types';

export const LASTVIEWED_PRODUCTS_PAGE_REQUESTED = 'LASTVIEWED_PRODUCTS_PAGE_REQUESTED' as const;
export const requestPage = (calculatedOnly = false) => ({
  type: LASTVIEWED_PRODUCTS_PAGE_REQUESTED,
  payload: { calculatedOnly },
});

export const LASTVIEWED_PRODUCTS_PAGE_RECEIVED = 'LASTVIEWED_PRODUCTS_PAGE_RECEIVED' as const;
export const pageReceived = (products: (Product | CalculatedProduct)[]) => ({
  type: LASTVIEWED_PRODUCTS_PAGE_RECEIVED,
  payload: products,
});
export type PageReceivedAction = ReturnType<typeof pageReceived>;

export type LastViewedProductsPageAction = ReturnType<
  | typeof requestPage
  | typeof pageReceived
>;
