export const ARIA_STATUS_SET = 'ARIA_STATUS_SET' as const;
export const setStatusTextKey = (key: string, assertive = false) => ({
  type: ARIA_STATUS_SET,
  payload: { key, assertive },
});
export const setStatusText = (text: string, assertive = false) => ({
  type: ARIA_STATUS_SET,
  payload: { text, assertive },
});

export type AriaStatusAction = ReturnType<
  | typeof setStatusTextKey
  | typeof setStatusText
>;
