import { createReducer } from 'utils/redux';
import { PRODUCT_FILTERS_BLOCK_SELECTED_VALUES_CHANGED, PRODUCT_FILTER_MAKE_LOAD_COLLECTION_RECEIVED, PRODUCT_FILTER_YEAR_LOAD_COLLECTION_RECEIVED, PRODUCT_FILTER_MODEL_LOAD_COLLECTION_RECEIVED, ProductFilterAction, ProductFiltersBlockSelectedValueChangedAction, ProductFieldYearCollectionReceivedAction, ProductFieldMakeCollectionReceivedAction, ProductFieldModelCollectionReceivedAction } from './actions';
import { FilterData, FilterValue, FilterValueMake } from './types';

type Action = ProductFilterAction;

const initialState = {
  selectedProductFilters: [] as FilterData[],
  productFilterYears: [] as FilterValue[],
  productFilterMakes: [] as FilterValueMake[],
  productFilterModels: [] as FilterValue[],
};

export type State = typeof initialState | ({
  selectedProductFilters: FilterData[];
  productFilterYears: FilterValue[];
  productFilterMakes: FilterValueMake[];
  productFilterModels: FilterValue[];
});

export default createReducer<State, Action>(initialState, {
  [PRODUCT_FILTERS_BLOCK_SELECTED_VALUES_CHANGED]: onProductFiltersBlockSelectedValueChanged,
  [PRODUCT_FILTER_YEAR_LOAD_COLLECTION_RECEIVED]: onProductFieldYearCollectionReceived,
  [PRODUCT_FILTER_MAKE_LOAD_COLLECTION_RECEIVED]: onProductFieldMakeCollectionReceived,
  [PRODUCT_FILTER_MODEL_LOAD_COLLECTION_RECEIVED]: onProductFieldModelCollectionReceived,
});

function onProductFieldYearCollectionReceived(state: State, action: ProductFieldYearCollectionReceivedAction): State {
  const { options } = action.payload;
  return {
    ...state,
    productFilterYears: options?.productFilterYear.values || [],
  };
}

function onProductFieldMakeCollectionReceived(state = initialState, action: ProductFieldMakeCollectionReceivedAction) {
  const { options } = action.payload;
  return {
    ...state,
    productFilterMakes: options?.productFilterMake?.values || [],
  };
}

function onProductFieldModelCollectionReceived(state = initialState, action: ProductFieldModelCollectionReceivedAction) {
  const { options } = action.payload;
  return {
    ...state,
    productFilterModels: options?.productFilterModel?.values || [],
  };
}

function onProductFiltersBlockSelectedValueChanged(state = initialState, action: ProductFiltersBlockSelectedValueChangedAction) {
  const { data } = action.payload;
  return {
    ...state,
    selectedProductFilters: data,
  };
}
