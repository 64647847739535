//only with relative paths and named import here, the IntelliSense for component props in add-ons will work correctly
import { navigateTo as navigateToCore } from '../../webstore/behavior/events';

export {
  LANGUAGE_CHANGED,
  LOCATION_CHANGED,
  VIEWER_CHANGED,
  BASKET_CHANGE_STARTED,
  BASKET_CHANGE_COMPLETED,
  basketChangeStarted,
  basketChangeCompleted,
} from '../../webstore/behavior/events';

export {
  WAREHOUSE_SELECTED,
} from '../../webstore/behavior/user';

export const navigateTo = (to, url, omitScroll) => navigateToCore(to, url, { omitScroll });

export { retryWithToast } from '../../webstore/behavior/errorHandling';