import styles from './Error.module.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSanaTexts } from 'components/sanaText';
import { Container } from 'components/primitives/grid';
import { Logo } from 'components/objects/logo';
import { Placeholder } from 'components/primitives/placeholders';
import { DangerAlert, alertStyles } from 'components/primitives/alerts';
import { useVerticalOverflowFix } from 'utils/hooks';
import { makeSimpleText } from 'utils/render';
import { DocumentTitle, useDocumentTitle } from '../documentTitle';

const ErrorBoundaryPage = () => {
  const options = [
    'ErrorPage_PageErrorTitle',
    'ErrorBoundaryPage_Title',
    'ErrorBoundaryPage_ReloadMessageText',
    'ErrorPage_PageErrorReload_Text',
    'ErrorPage_PageErrorReload_Reload',
    'ErrorPages_HomepageLinkTemplate',
    'ErrorPages_HomepageLink',
    'ContactUs',
  ];
  options.disableInsiteEditor = true;
  const { texts: [
    pageTitleTxt,
    title,
    reloadPageMsg,
    reloadTemplate,
    reload,
    homeTemplate,
    returnTxt,
    contactUs,
  ], loaded } = useSanaTexts(options);

  const shopEmailAddress = useSelector(s => s.settings.shopEmailAddress);
  const placeholder = loaded ? '' : <Placeholder className={styles.loading} />;

  useVerticalOverflowFix();

  useEffect(() => {
    if (!loaded)
      return;

    /* DangerAlert block should be focused for NVDA screen reader in Mozilla Firefox to announce its content, otherwise announcement will not occur.
    Also this gives quick access to focusable elements inside actions list for screen reader/keyboard users thus bypassing logo link. */
    document.querySelector(`.${styles.block}`).focus();
  }, [loaded]);

  const documentTitle = useDocumentTitle(loaded ? makeSimpleText(pageTitleTxt) || 'Requested page cannot be shown' : null);

  return (
    <div id="errorBoundaryPage" className={styles.errBoundary}>
      {documentTitle && <DocumentTitle title={documentTitle} skipAnnounce />}
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <Logo className={styles.logo} />
        <h1>{placeholder || title || 'Sorry, the requested page cannot be shown'}</h1>
        <DangerAlert className={styles.block} role="alert" tabIndex="-1">
          <div>
            {placeholder || makeSimpleText(reloadPageMsg) || 'Unfortunately there was an issue with retrieving the information for this page. Please, try the following:'}
          </div>
          <ul className={styles.actionList}>
            <li>
              {placeholder || makeSimpleText(
                reloadTemplate || '{0} the page',
                [<button className={alertStyles.link} onClick={_ => window.location.reload()}>{makeSimpleText(reload || 'Reload')}</button>],
                true,
              )}
            </li>
            <li>
              {placeholder || makeSimpleText(
                homeTemplate || '{0} to homepage.',
                [<a href="/">{makeSimpleText(returnTxt) || 'Return'}</a>],
                true,
              )}
            </li>
            <li>
              {placeholder || <a href={`mailto:${shopEmailAddress}`}>{makeSimpleText(contactUs || 'Contact us')}</a>}
            </li>
          </ul>
        </DangerAlert>
      </Container>
    </div>
  );
};

export default ErrorBoundaryPage;
