import {
  DOCUMENT_LINES_RECEIVED,
  DOCUMENT_REORDER_REQUESTED,
  DOCUMENT_REORDER_RESULT_RECEIVED,
  QUOTE_STOCK_RECEIVED,
  QUOTE_PROMOTION_REQUESTED,
  QUOTE_PROMOTION_RESULT_RECEIVED,
  SET_QUOTE_PROMOTION_ERROR,
  DOCUMENT_EDIT_REQUESTED,
  DOCUMENT_EDIT_RESULT_RECEIVED,
  ORDER_AUTHORIZATION_ACTION_RESULT_RECEIVED,
  DOCUMENT_DOWNLOAD_REPORT_RESULT_RECEIVED,
  DocumentAction,
} from './actions';
import { isQuoteInStock } from './helpers';
import { DocumentType } from 'behavior/documents';
import type { Document, DocumentPage } from './types';

export default (state: DocumentPage, action: DocumentAction): DocumentPage => {
  switch (action.type) {
    case DOCUMENT_LINES_RECEIVED:
      const document = {
        ...state.document,
        ...action.payload.documentLines,
      } as Document;

      if (document && document.documentType === DocumentType.Quote)
        return {
          ...state,
          document: { ...document, hasStock: isQuoteInStock(document) },
        };

      return { ...state, document };
    case DOCUMENT_REORDER_REQUESTED:
      return { ...state, reorderResult: null, editResult: state.editResult ? null : state.editResult  };
    case DOCUMENT_REORDER_RESULT_RECEIVED:
       return { ...state, reorderResult: action.payload.result };
    case QUOTE_STOCK_RECEIVED:
      const { quote } = action.payload;

      if (!quote || state.document.documentType !== DocumentType.Quote)
        return state;

      return {
        ...state,
        document: { ...state.document, hasStock: isQuoteInStock(quote, true) },
      };
    case QUOTE_PROMOTION_REQUESTED:
      return { ...state, promotionResult: null };
    case QUOTE_PROMOTION_RESULT_RECEIVED:
    case SET_QUOTE_PROMOTION_ERROR:
      return { ...state, promotionResult: action.payload };
    case DOCUMENT_EDIT_REQUESTED:
      return { ...state, editResult: null, reorderResult: state.reorderResult ? null : state.reorderResult };
    case DOCUMENT_EDIT_RESULT_RECEIVED:
      return { ...state, editResult: action.payload.result };
    case ORDER_AUTHORIZATION_ACTION_RESULT_RECEIVED:
      if (state.document.documentType !== DocumentType.Order)
        return state;

      const { authorizationActionResultStatus, authorizationInfo } = action.payload;

      return {
        ...state,
        document: {
          ...state.document,
          authorizationInfo,
          authorizationActionResultStatus,
        },
      };
    case DOCUMENT_DOWNLOAD_REPORT_RESULT_RECEIVED:
      return { ...state, downloadReportResult: action.payload.result };
    default:
      return state;
  }
};
