import type { ReactElement, ReactNode } from 'react';
import { ArrowRightIcon } from './Icons';

type Props = {
  icon?: ReactElement | false;
  noWrapper?: boolean;
  children: ReactNode;
};

const ButtonContent = ({ icon, noWrapper, children }: Props) => (
  <>
    {icon === undefined ? <ArrowRightIcon /> : icon}
    {noWrapper ? children : <span className="btn-cnt">{children}</span>}
  </>
);

export default ButtonContent;
