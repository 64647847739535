import type { ProductConfiguratorState } from './types';
import {
  PRODUCT_CONFIGURATOR_CONFIGURATION_FAILED,
  PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED,
  PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_RECEIVED,
  PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED,
  PRODUCT_CONFIGURATOR_FINISHED,
  ProductConfiguratorAction,
} from './actions';

export default (state: ProductConfiguratorState = {}, action: ProductConfiguratorAction): ProductConfiguratorState => {
  switch (action.type) {
    case PRODUCT_CONFIGURATOR_CONFIGURATION_FAILED:
      return configurationFailed(state, action);
    case PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED:
      return newConfigurationReceived(state, action);
    case PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_RECEIVED:
      return editConfigurationReceived(state, action);
    case PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED:
      return messageReceived(state, action);
    case PRODUCT_CONFIGURATOR_FINISHED:
      return configuratorFinished(state);
    default:
      return state;
  }
};

function configurationFailed(state: ProductConfiguratorState, { payload }: { payload: string }): ProductConfiguratorState {
  return {
    ...state,
    productConfigurator: {
      updatedById: payload,
    },
  };
}

function newConfigurationReceived(
  state: ProductConfiguratorState,
  { payload }: {
    payload: { configuratorUrl: string; configurationId: string };
  }): ProductConfiguratorState {
  return {
    ...state,
    productConfigurator: {
      configuratorUrl: payload.configuratorUrl,
      configurationId: payload.configurationId,
    },
  };
}

function editConfigurationReceived(
  state: ProductConfiguratorState,
  { payload }: {
    payload: { configuratorUrl: string; configurationId: string; basketLineId: string };
  }): ProductConfiguratorState {
  return {
    ...state,
    productConfigurator: {
      configuratorUrl: payload.configuratorUrl,
      configurationId: payload.configurationId,
      editedBasketLineId: payload.basketLineId,
    },
  };
}

function messageReceived(state: ProductConfiguratorState, { payload }: { payload: { message: MessageEvent } }): ProductConfiguratorState {
  if (!payload.message.data.length)
    return state;

  return {
    ...state,
    productConfigurator: {
      ...state.productConfigurator,
      message: payload.message,
    },
  };
}

function configuratorFinished(state: ProductConfiguratorState): ProductConfiguratorState {
  if (!state.productConfigurator) {
    return state;
  }

  const newState = {
    ...state,
  };

  delete newState.productConfigurator;
  return newState;
}
