import styles from './ImageBlock.module.scss';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ImageScalings, ImageBehaviors } from './constants';
import Image from './Image';
import ImageWithLink from './ImageWithLink';
import ImageWithPreview from './ImageWithPreview';
import { joinClasses } from 'utils/helpers';
import { getBorderStyleCssValue } from 'components/theme';

const ScalingOptionMap = {
  [ImageScalings.ByWidth]: styles.scaleByWidth,
  [ImageScalings.ByHeight]: styles.scaleByHeight,
  [ImageScalings.DoNotScale]: styles.doNotScale,
};

const AlignmentOptionMap = {
  LEFT: styles.left,
  RIGHT: styles.right,
  CENTER: styles.center,
  JUSTIFY: styles.justify,
};

const ImageBlock = ({ model, wrapperRef, sharedStyleProps: { horizontalAlignment }, isDesignerMode }) => {
  if (!model)
    return null;

  const {
    url,
    altText,
    style,
    scaling = ImageScalings.ByWidth,
    behavior,
    link,
    borderStyle,
    borderColor,
    borderWidth,
    cornerRadius,
  } = model;

  if (!url || url === '')
    return null;

  useEffect(() => {
    Object.assign(wrapperRef.current.style, {
      borderStyle: getBorderStyleCssValue(borderStyle),
      borderColor,
      borderWidth: borderStyle !== 0 ? (borderWidth || 0) : null,
      borderRadius: cornerRadius,
      overflow: cornerRadius ? 'hidden' : null,
    });
  }, [borderStyle, borderColor, borderWidth, cornerRadius]);

  const imageProps = {
    src: url,
    alt: altText || null,
    title: altText || null,
    className: styles[style.toLowerCase()],
    wrapperClassName: joinClasses(
      ScalingOptionMap[scaling],
      AlignmentOptionMap[horizontalAlignment],
      isDesignerMode && styles.defaultWidth,
      isDesignerMode && scaling === ImageScalings.ByHeight && styles.defaultHeight,
    ),
  };

  if (behavior === ImageBehaviors.UseLink)
    return <ImageWithLink link={link} {...imageProps} />;

  if (behavior === ImageBehaviors.OpenInOverlay)
    return <ImageWithPreview {...imageProps} />;

  return <Image {...imageProps} />;
};

ImageBlock.propTypes = {
  wrapperRef: PropTypes.object.isRequired,
  isDesignerMode: PropTypes.bool,
  model: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
    style: PropTypes.string,
    scaling: PropTypes.oneOf(Object.values(ImageScalings)),
    behavior: PropTypes.oneOf(Object.values(ImageBehaviors)),
    link: PropTypes.shape({
      url: PropTypes.string,
      target: PropTypes.string,
    }),
  }),
  sharedStyleProps: PropTypes.shape({
    horizontalAlignment: PropTypes.string,
  }),
};

export default ImageBlock;