import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { trackProductClick } from 'behavior/analytics';
import ProductTrackingContext from './ProductTrackingContext';

const WithProductClickTracking = ({ children, product: trackingProduct, trackingSource }) => {
  const dispatch = useDispatch();
  const context = (!trackingProduct || !trackingSource) ? useContext(ProductTrackingContext) : null;

  const product = trackingProduct ?? context?.product;
  const source = trackingSource ?? context?.trackingSource;

  const onClickHandler = useCallback(() => {
    product && dispatch(trackProductClick({ product, source }));
  }, [product, source]);

  return children(onClickHandler);
};

WithProductClickTracking.propTypes = {
  children: PropTypes.func.isRequired,
  product: PropTypes.object,
  trackingSource: PropTypes.string,
};

export default WithProductClickTracking;