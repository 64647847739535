import type { Epic } from 'behavior/types';
import type { Action } from 'redux';
import type { AppState } from 'behavior';
import type { SummaryModel } from '../types';
import { combineEpics } from 'utils/rxjs';
import { EMPTY, of, identity, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  BASKET_RECEIVED, BASKET_SUMMARY_RECEIVED,
  broadcastBasket, BASKET_BROADCAST, basketArrived,
  basketUpdated, basketSummaryReceived,
  BasketAction, BroadcastBasketAction,
} from '../actions';
import { BASKET_CHANGE_STARTED, BASKET_CHANGE_COMPLETED, EventAction } from 'behavior/events';
import {
  ignoreElements, first, filter, takeUntil,
  tap, mergeMap, switchMap, delayWhen, switchMapTo,
} from 'rxjs/operators';
import { Updaters } from '../constants';
import { visibility$ } from 'utils/rxjs/eventsObservables';
import { basketChangeCompleted } from 'behavior/events';
import { skipIfPreview } from 'behavior/preview';
import { isBasketModel } from '../helpers';

const syncBasketChangesEpic: Epic<Action> = (_action$, state$, { broadcast }) => {
  const visible$ = visibility$.pipe(first(identity));
  let lastModified: number | null;

  const dataToActions = (data: BroadcastBasketAction['payload']) => {
    const state = state$.value;
    const modifiedDate = data.modifiedDate;

    if (state.basket.modifiedDate && state.basket.modifiedDate >= modifiedDate)
      return EMPTY;

    const updatedAction = basketUpdated(Updaters.Sync, modifiedDate);
    const completedAction = basketChangeCompleted(0);

    // eslint-disable-next-line eqeqeq
    if (data.language != getLanguage(state))
      return of(updatedAction, completedAction);

    let action: Action | undefined;
    if ('summary' in data) {
      if (data.summary)
        action = basketSummaryReceived(data.summary, data.salesAgreementInfo, modifiedDate);
      else
        action = basketSummaryReceived(data.summary);
    } else {
      const basket = data.basket;
      const stateBasket = state.basket.model;
      if (!stateBasket || (isBasketModel(basket) && isBasketModel(stateBasket) && stateBasket.page.index === basket.page.index))
        action = basketArrived(basket, data.salesAgreementInfo, modifiedDate);
    }

    return action
      ? of(action, updatedAction, completedAction)
      : of(updatedAction, completedAction);
  };

  return (broadcast.action$ as Observable<BroadcastBasketAction>).pipe(
    ofType(BASKET_BROADCAST),
    skipIfPreview(state$),
    switchMap(action => of(action.payload).pipe(
      delayWhen(_ => visible$),
      filter(data => {
        const value = data.modifiedDate;
        const datesAreNotEqual = lastModified !== value;
        if (datesAreNotEqual)
          lastModified = value;

        return datesAreNotEqual;
      }),
      mergeMap(dataToActions),
    )),
  );
};

const notifyBasketChangesEpic: Epic<EventAction | BasketAction> = (action$, state$, { broadcast }) => {
  const reset$ = action$.pipe(ofType(BASKET_CHANGE_STARTED));
  let lastModified: number | null;

  return action$.pipe(
    ofType(BASKET_CHANGE_COMPLETED),
    filter(action => {
      if (!action.payload.linesAmount) {
        const updaterId = state$.value.basket.updated.updaterId;
        return updaterId === Updaters.Basket || updaterId === Updaters.SalesAgreement;
      }

      return true;
    }),
    switchMapTo(action$.pipe(
      ofType(BASKET_RECEIVED, BASKET_SUMMARY_RECEIVED),
      first(),
      filter(_ => {
        const value = state$.value.basket.modifiedDate!;
        const datesAreNotEqual = lastModified !== value;
        if (datesAreNotEqual)
          lastModified = value;

        return datesAreNotEqual;
      }),
      tap(action => {
        const { model, summary, modifiedDate, salesAgreementInfo } = state$.value.basket;
        const data = {
          language: getLanguage(state$.value),
          modifiedDate: modifiedDate!,
          ...(action.type === BASKET_RECEIVED
            ? { basket: model!, salesAgreementInfo: salesAgreementInfo! }
            : { summary: summary as SummaryModel | null, salesAgreementInfo: salesAgreementInfo! }
          ),
        };
        broadcast.dispatch(broadcastBasket(data));
      }),
      ignoreElements(),
      takeUntil(reset$),
    )),
  );
};

export default combineEpics(notifyBasketChangesEpic, syncBasketChangesEpic);

function getLanguage(state: AppState) {
  return state.localization.currentLanguage.id;
}
