export const ContentHorizontalAlignment = Object.freeze({
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
});

export const VerticalSelfAlignment = Object.freeze({
  INHERITED: 'auto',
  TOP: 'flex-start',
  MIDDLE: 'center',
  BOTTOM: 'flex-end',
  JUSTIFY: 'stretch',
});
