import { filterExistingImages, iEquals } from 'utils/helpers';
import { toUrlHash } from 'utils/url';
import { Steps, ShippingAddressOption } from './constants';
import { selectAddress, checkoutInfoUpdated } from './actions';
import { getRefreshCheckoutQuery } from './queries';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { DocumentType } from 'behavior/documents';
import { CheckoutPresets } from 'behavior/settings/constants';

export function filterImages(checkoutInfo) {
  if (checkoutInfo && checkoutInfo.productLines && checkoutInfo.productLines.list)
    for (const line of checkoutInfo.productLines.list)
      if (line.product && line.product.images)
        line.product.images = filterExistingImages(line.product.images);

  return checkoutInfo;
}

const stepsNames = Object.values(Steps);
export const getStepByUrlHash = hash => {
  if (hash && (hash = hash.substr(1)))
    return stepsNames.find(stepName => iEquals(stepName, hash));
};

export function updateShippingAddressIfNeeded(checkoutInfo, state$, dependencies) {
  if (checkoutInfo.shippingAddress.shippingOption && checkoutInfo.shippingAddress.address)
    return of(undefined);

  if (!checkoutInfo.shippingAddress.isDefault)
    checkoutInfo.shippingAddress.recheck = true;

  if (checkoutInfo.isGuest) {
    checkoutInfo.shippingAddress.shippingOption = ShippingAddressOption.Billing;
    checkoutInfo.shippingAddress.address = checkoutInfo.billingAddress;

    if (checkoutInfo.billingAddress && state$.value.settings.checkout.pagePreset === CheckoutPresets.OneStep)
      return of(selectAddress(null, checkoutInfo.shippingAddress.recheck));

    return of(undefined);
  }

  const defaultShippingOption = state$.value.settings.checkout.defaultShippingAddressOption;
  const shippingOptions = [ShippingAddressOption.Billing, ShippingAddressOption.Existing, ShippingAddressOption.Custom, ShippingAddressOption.NotPreselect];

  return updateShippingAddress(defaultShippingOption, shippingOptions, checkoutInfo, state$, dependencies);
}

function updateShippingAddress(shippingOption, shippingOptions, checkoutInfo, state$, dependencies) {
  if (shippingOptions.length === 0) {
    checkoutInfo.shippingAddress.address = null;
    checkoutInfo.shippingAddress.shippingOption = null;

    return of(undefined);
  }

  const index = shippingOptions.indexOf(shippingOption);
  if (index === -1) {
    shippingOption = shippingOptions[0];

    return updateShippingAddress(shippingOption, shippingOptions, checkoutInfo, state$, dependencies);
  }

  shippingOptions.splice(index, 1);

  switch (shippingOption) {
    case ShippingAddressOption.Billing:
      return shipToBilling(shippingOptions, checkoutInfo, state$, dependencies);
    case ShippingAddressOption.Existing:
      return shipToExisting(shippingOptions, checkoutInfo, state$, dependencies);
    case ShippingAddressOption.NotPreselect:
      return notPreselect(shippingOptions, checkoutInfo, state$, dependencies);
    default:
      return shipToCustom(shippingOptions, checkoutInfo, state$, dependencies);
  }
}

function shipToBilling(shippingOptions, checkoutInfo, state$, dependencies) {
  if (!checkoutInfo.billingAddress)
    return updateShippingAddress(ShippingAddressOption.Existing, shippingOptions, checkoutInfo, state$, dependencies);

  return requestAbility(AbilityTo.ShipToBillingAddress, state$, dependencies).pipe(
    mergeMap(shipToBillingAbility => {
      if (shipToBillingAbility === AbilityState.Available) {
        checkoutInfo.shippingAddress.shippingOption = ShippingAddressOption.Billing;
        checkoutInfo.shippingAddress.address = checkoutInfo.billingAddress;

        if (state$.value.settings.checkout.pagePreset === CheckoutPresets.OneStep)
          return of(selectAddress(null, checkoutInfo.shippingAddress.recheck));

        return of(undefined);
      }

      return updateShippingAddress(ShippingAddressOption.Existing, shippingOptions, checkoutInfo, state$, dependencies);
    }),
  );
}

function shipToExisting(shippingOptions, checkoutInfo, state$, dependencies) {
  if (!checkoutInfo.shippingAddresses?.length)
    return updateShippingAddress(ShippingAddressOption.Custom, shippingOptions, checkoutInfo, state$, dependencies);

  const shippingAddress = checkoutInfo.shippingAddress;
  shippingAddress.shippingOption = ShippingAddressOption.Existing;

  if (!shippingAddress.isDefault || checkoutInfo.shippingAddresses.length === 1) {
    shippingAddress.address = checkoutInfo.shippingAddresses[0];

    if (state$.value.settings.checkout.pagePreset === CheckoutPresets.OneStep)
      return of(selectAddress(shippingAddress.address.id, shippingAddress.recheck));
  }

  return of(undefined);
}

function shipToCustom(shippingOptions, checkoutInfo, state$, dependencies) {
  if (!checkoutInfo.shippingAddress.templateFields?.length)
    return updateShippingAddress(ShippingAddressOption.Billing, shippingOptions, checkoutInfo, state$, dependencies);

  checkoutInfo.shippingAddress.shippingOption = ShippingAddressOption.Custom;
  checkoutInfo.shippingAddress.address = null;

  return of(undefined);
}

function notPreselect(shippingOptions, checkoutInfo, state$, dependencies) {
  if (shippingOptions.length === 1)
    return updateShippingAddress(shippingOptions[0], shippingOptions, checkoutInfo, state$, dependencies);

  return requestAbility(AbilityTo.ShipToBillingAddress, state$, dependencies).pipe(
    mergeMap(shipToBillingAbility => {
      const isBillingAddressAvailable = shipToBillingAbility === AbilityState.Available && !!checkoutInfo.billingAddress;
      const isShippingAddressesAvailable = !!checkoutInfo.shippingAddresses?.length;
      const isCustomAddressAvailable = !!checkoutInfo.shippingAddress.templateFields?.length;

      const options = [isBillingAddressAvailable, isShippingAddressesAvailable, isCustomAddressAvailable];

      if (options.filter(x => x).length !== 1) {
        checkoutInfo.shippingAddress.shippingOption = ShippingAddressOption.NotPreselect;
        checkoutInfo.shippingAddress.address = null;

        return of(undefined);
      }
      let selectedOption;

      if(isBillingAddressAvailable)
        selectedOption = ShippingAddressOption.Billing;
      else if(isShippingAddressesAvailable)
        selectedOption = ShippingAddressOption.Existing;
      else 
        selectedOption = ShippingAddressOption.Custom;

      return updateShippingAddress(selectedOption, shippingOptions, checkoutInfo, state$, dependencies);
    }),
  );
}

export function refreshCheckoutData(state$, deps) {
  const state = state$.value,
    isGuest = state.page.info.isGuest;

  return deps.api.graphApi(getRefreshCheckoutQuery(isGuest, !!state.page.info.quote), {
    asQuote: state.page.info?.isQuote || false,
    maxLines: state.settings.checkout.maxOverviewLines + 1,
  }).pipe(
    mergeMap(({ checkout, viewer }) => {
      if (!checkout)
        return EMPTY;

      if (!checkout.valid)
        return of(navigateOnIncorrect(state.page.info));

      adjustPaymentMethodData(checkout);
      adjustShippingMethodData(checkout);
      adjustGuestProfileData(checkout);

      if (!isGuest)
        adjustCheckoutAddresses(checkout, viewer);

      return updateShippingAddressIfNeeded(checkout, state$, deps).pipe(
        mergeMap(updateAddress => {
          if (updateAddress)
            return of(checkoutInfoUpdated(checkout), updateAddress);

          return of(checkoutInfoUpdated(checkout));
        }),
      );
    }),
  );
}

export function adjustShippingMethodData(info) {
  info.shippingMethodId = info.shippingMethod?.info?.id;
  info.recheckShippingMethod = info.shippingMethod?.unavailable;

  delete info.shippingMethod;
}

export function adjustPaymentMethodData(info) {
  if (info.paymentMethod?.info) {
    info.paymentMethodId = info.paymentMethod.info.id;
    info.isOnAccount = info.paymentMethod.info.isOnAccount;
  } else {
    info.paymentMethodId = info.isOnAccount = undefined;
  }
  info.recheckPaymentMethod = !info.paymentMethodId && !!info.paymentMethod;

  info.extraPaymentStep = info.paymentMethod?.extraPaymentCheckoutStep;
  info.customerDataStep = info.paymentMethod?.additionalCustomerDataStep;

  delete info.paymentMethod;
}

export function adjustGuestProfileData(info) {
  if (info.guestProfile) {
    info.billingAddress = info.guestProfile.billingAddress;
    info.email = info.guestProfile.email;
  }
  delete info.guestProfile;
}

export function adjustCheckoutAddresses(checkoutInfo, viewer) {
  const { customer: { billingAddress, shippingAddresses } } = viewer;
  checkoutInfo.billingAddress = billingAddress;
  checkoutInfo.shippingAddresses = shippingAddresses;
}

export function navigateOnIncorrect(checkoutInfo) {
  if (checkoutInfo && checkoutInfo.quote)
    return navigateTo(routesBuilder.forDocument(checkoutInfo.quote.id, DocumentType.Quote), checkoutInfo.quote.url);

  return navigateTo(routesBuilder.forBasket());
}

export function getStepNavigationInfo(step, location, isPromotion, asQuote, isGuest) {
  const url = getStepUrl(step.id, location);
  const to = getStepRouteData(step, isPromotion, asQuote, isGuest);
  return { url, to };
}

export function getFirstIncompleteStepBeforeCurrent(steps, currentStep) {
  for (const step of steps) {
    if (step.id === currentStep)
      return;

    if (!step.isCompleted)
      return step;
  }

  return;
}

function getStepUrl(stepId, location) {
  const hash = stepId !== Steps.Address ? toUrlHash(stepId) : '';
  return location.pathname + location.search + hash;
}

function getStepRouteData(step, isPromotion, asQuote, isGuest) {
  const routeData = isPromotion
    ? routesBuilder.forQuotePromotion(step.id)
    : routesBuilder.forCheckout(asQuote, step.id, isGuest);

  if (!step.isCompleted)
    routeData.options.stepInvalid = true;

  return routeData;
}
