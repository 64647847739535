import type { ClientRoute } from './types';
import { toDashedUrl } from 'utils/url';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { omit } from 'lodash';

const blogRoute = (): ClientRoute => {
  const path = '/blog/';
  const queryVariables = ['q', 'view-mode', 'page', 'count'];

  return {
    getVirtualPath(params) {
      if (!params)
        return path;

      let query = '';
      for (const variable of queryVariables) {
        const value = params[variable];
        if (value == null || value === false)
          continue;

        query += `${variable}=${value}&`;
      }

      const facets = omit(params, ['facets', ...queryVariables]);
      for (const [variable, value] of Object.entries(facets)) {
        if (value == null)
          continue;

        query += `${encode(variable)}=${encode(value as string)}&`;
      }

      return `${path}?${query.slice(0, -1)}`;
    },
  };

  function encode(str: string) {
    return toDashedUrl(stripHtmlTagsAndFormatting(str), 50).toLowerCase();
  }
};

export default blogRoute;
