import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { exhaustMap, pluck, map, mergeMap, startWith } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormName } from 'behavior/pages';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { toasts } from 'behavior/toasts';
import { requestAbility } from 'behavior/user/epic';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { CreateDocBasedReturnOrderAction, DOC_BASED_RETURN_ORDER_SUBMITTED, setReturnOrderFailed } from './actions';
import { createReturnOrderMutation } from './queries';

const epic: Epic<CreateDocBasedReturnOrderAction> = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  const createReturnOrder$ = action$.pipe(
    ofType(DOC_BASED_RETURN_ORDER_SUBMITTED),
    pluck('payload'),
    exhaustMap(({ input, files, updatedById }) => api.graphApi<CreateReturnOrderResponse>(
      createReturnOrderMutation, { input }, { files, retries: 0 },
    ).pipe(
      pluck('documents', 'returnOrders', 'createDocBased', 'orderId'),
      mergeMap(orderId => {
        if (!orderId)
          return of(setReturnOrderFailed(updatedById), unsetLoadingIndicator());

        toasts.success('', { textKey: 'ReturnRequest_OrderCreated' });

        return requestAbility(AbilityTo.ViewReturnOrders, state$, dependencies).pipe(
          map(abilityState => navigateTo(abilityState === AbilityState.Available
            ? routesBuilder.forDocument(orderId, DocumentType.ReturnOrder)
            : routesBuilder.forInvoices())),
        );
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormName.CreateDocBasedReturnOrder), unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unlockForm(FormName.CreateDocBasedReturnOrder), unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );

  return createReturnOrder$;
};

export default epic;

type CreateReturnOrderResponse = {
  documents: {
    returnOrders: {
      createDocBased: {
        orderId: string | null;
      };
    };
  };
};
