import styles from './ProductTiles.module.scss';
import { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import OrderBox from 'components/objects/productList/b2c/OrderBox';
import { ProductPrice, ProductThumbnail } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import ViewProductButton from 'components/objects/productList/ViewProductButton';
import Stock from './Stock';
import WarehousesProductListStockBlock from './WarehousesProductListStockBlock';

const ProductTile = ({ product, noImage, tilesPerView, contentBlockId }) => {
  const ref = useRef(null);
  const { id, url, title, image, isProductGroup, childItemsCount } = product;
  const getRouteData = routesBuilder.forProduct.bind(null, id);

  useEffect(() => {
    if (!tilesPerView)
      return;

    ref.current.style.width = 100 / tilesPerView + '%';

    return () => ref.current.style.width = '';
  }, [tilesPerView]);

  const { trackingSource } = useContext(ProductTrackingContext);

  const canOrder = useHasAbilities(AbilityTo.OrderProducts)[0];

  const namePlaceholder = <Placeholder className={styles.namePlaceholder} />;

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource }}>
      <article className={styles.productTile} ref={ref} aria-label={`${title} (${id})`}>
        <div className={styles.tileTop}>
          <div className={styles.productImg}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} draggable="false" url={url} to={getRouteData}>
                  <ProductThumbnail draggable="false" src={image && image.small} noImageSrc={noImage} title={title} />
                </Link>
              )}
            </WithProductClickTracking>
          </div>
          <div className={styles.productDescription}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} url={url} to={getRouteData} draggable="false" className={styles.productTitle}>
                  {title}
                </Link>
              )}
            </WithProductClickTracking>
            {!isProductGroup && <><div className={styles.productId}>
              <span className={styles.name}>
                <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
              </span>
              <span className={styles.value}>{id}</span>
            </div>
            {contentBlockId && <><div><Stock inventory={product.inventory} stockLevels={product.stockLevels} eta={product.eta} /></div>
              <WarehousesProductListStockBlock id={product.id} stockLevels={product.stockLevels} uom={product.uom} warehousesStock={product.warehousesStock} contentBlockId={contentBlockId} />
              </>
            }
            </>
            }
            {isProductGroup && childItemsCount > 0 &&
              <div className={styles.productId}>
                <span className={styles.name}>
                  {childItemsCount}
                </span>
                <span className={styles.value}>
                  <SimpleText
                    textKey="ChildProductsCount"
                    placeholder={namePlaceholder}
                  />
                </span>
              </div>}
          </div>
        </div>
        <div className={styles.tileBottom}>
          {!isProductGroup && <div className={styles.productAction}>
            <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
          </div>}
          {canOrder && <OrderBox product={product} className={styles.orderBox} withoutBackTo />}
          {product && product.isProductGroup &&
            <ViewProductButton
              textKey="ViewProductGroup"
              className={'btn-action'}
              product={product}
            />
          }
        </div>
      </article>
    </ProductTrackingContext.Provider>
  );
};

ProductTile.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      small: PropTypes.string,
    }),
  }).isRequired,
  noImage: PropTypes.string,
  tilesPerView: PropTypes.number,
  contentBlockId: PropTypes.string,
};

export default ProductTile;
