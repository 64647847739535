import loadable from '@loadable/component';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { PageContent } from 'components/objects/pages';
import { useIsBasketErrorMode } from 'utils/hooks';

const MainContent = loadable(() => import(/*webpackChunkName:"basket"*/'./MainContent'));
const BasketErrorMessage = loadable(() => import(/*webpackChunkName:"basket"*/'./BasketErrorMessage'));

const BasketPageContent = ({ preset, header, footer }) => {
  const isErrorMode = useIsBasketErrorMode();

  return (
    <>
      {!isErrorMode && <PageContent content={header} position="header" />}
      {isErrorMode
        ? <BasketErrorMessage />
        : <MainContent preset={preset} />
      }
      {!isErrorMode && <PageContent content={footer} position="footer" />}
    </>
  );
};

BasketPageContent.propTypes = {
  preset: PropTypes.string,
  header: PropTypes.array,
  footer: PropTypes.array,
};

export default memo(BasketPageContent);
