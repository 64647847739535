import type { Epic } from 'behavior/types';
import { BasketPageAction, BASKET_ORDER_TEMPLATE_SAVE, orderTemplateSavingResultReceived } from './actions';
import type { SaveOrderTemplateResult } from './types';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormName } from 'behavior/pages';
import { saveTemplateMutation } from './queries';

const epic: Epic<BasketPageAction> = (action$, _state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(BASKET_ORDER_TEMPLATE_SAVE),
    mergeMap(action => api.graphApi<SaveOrderTemplateResponse>(saveTemplateMutation, action.payload).pipe(
      mergeMap(({ orderTemplates }) => of(
        orderTemplateSavingResultReceived(orderTemplates && orderTemplates.save),
        unlockForm(FormName.SaveOrderTemplate)),
      ),
      retryWithToast(action$, logger, _ => of(unlockForm(FormName.SaveOrderTemplate))),
      takeUntil(locationChanged$),
    )),
  );
};

export default epic;

type SaveOrderTemplateResponse = {
  orderTemplates: {
    save: SaveOrderTemplateResult;
  } | null;
};
