import styles from './MainNav.module.scss';
import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavigationGroupCode } from 'behavior/navigation/constants';
import { NavDimensionsContext } from './NavDimensionsProvider';
import { useNavMenuContext } from './hooks';

const sublistWidth = +styles.subListWidth;

export const Directions = {
  FORWARDS: 'FORWARDS',
  BACKWARDS: 'BACKWARDS',
};

export const Positions = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const SublistPositionContext = createContext(null);

export const SublistPositionProvider = ({ children }) => {
  const navDimensions = useContext(NavDimensionsContext);
  const { componentGroup } = useNavMenuContext();
  const menuTopLevelHash = useSelector(state => (state.navigation[componentGroup]?.[NavigationGroupCode.Main] || []).map(i => i.title).join('_'));

  const contextValue = useMemo(() => {
    if (!navDimensions)
      return null;

    const sublistPositions = new Map();

    return {
      menuTopLevelHash,
      addPositionsList: (key, startOffset, startDirection = Directions.FORWARDS) => {
        const isStartDirectionForwards = isForwardsDirection(startDirection);
        const startPosition = startOffset
          ? isStartDirectionForwards
            ? startOffset
            : navDimensions.navWidth - startOffset
          : isStartDirectionForwards
            ? 0
            : navDimensions.navWidth;
        const leftPositioningAvailable = startPosition + sublistWidth <= navDimensions.navWidth;
        const rightPositioningAvailable = startPosition - sublistWidth >= 0;

        let defaultPositioning = false, correctedPosition = null;
        if (!leftPositioningAvailable && !rightPositioningAvailable) {
          defaultPositioning = true;
        } else {
          if (isStartDirectionForwards) {
            if (!leftPositioningAvailable && rightPositioningAvailable)
              correctedPosition = Positions.RIGHT;
          } else {
            if (!rightPositioningAvailable && leftPositioningAvailable)
              correctedPosition = Positions.LEFT;
          }
        }

        sublistPositions.set(key, {
          defaultPositioning,
          direction: startDirection,
          position: startPosition,
          correctedPosition,
          list: [],
        });
      },
      getSublistPosition: (key, index) => {
        const currPosListData = sublistPositions.get(key);

        if (!currPosListData || currPosListData.defaultPositioning)
          return Positions.LEFT;

        let { correctedPosition, direction, position, list } = currPosListData;
        let currPos = list[index];
        if (currPos)
          return currPos;

        if (correctedPosition) {
          currPos = correctedPosition;
          currPosListData.correctedPosition = null;
        } else {
          currPos = isForwardsDirection(direction) ? Positions.LEFT : Positions.RIGHT;
        }

        if (direction === Directions.FORWARDS) {
          const currentPosition = position + sublistWidth;
          if (currentPosition <= navDimensions.navWidth) {
            position = currentPosition;
            currPos = Positions.LEFT;
          } else {
            position = position - sublistWidth * 2;
            direction = Directions.BACKWARDS;
            currPos = Positions.RIGHT;
          }
        } else {
          const currentPosition = position - sublistWidth;
          if (currentPosition >= 0) {
            position = currentPosition;
            currPos = Positions.RIGHT;
          } else {
            position = position + sublistWidth * 2;
            direction = Directions.FORWARDS;
            currPos = Positions.LEFT;
          }
        }

        currPosListData.position = position;
        currPosListData.direction = direction;
        list[index] = currPos;

        return currPos;
      },
    };
  }, [navDimensions?.navWidth, menuTopLevelHash]);

  return (
    <SublistPositionContext.Provider value={contextValue}>
      {children}
    </SublistPositionContext.Provider>
  );
};

export default SublistPositionProvider;

SublistPositionProvider.propTypes = {
  children: PropTypes.node,
};

function isForwardsDirection(direction) {
  return direction === Directions.FORWARDS;
}