import { forwardRef } from 'react';
import { makeSimpleText } from 'utils/render';

type Props = {
  message: string;
  labelId: string | null;
  descriptionId: string | null;
};

// Forwarded ref is being passed in Toast component for all error dialog toasts, although it is not used here,
// it should be added to avoid 'Function components cannot be given refs' React error.
const ErrorToastWithMessage = forwardRef<undefined, Props>(({ message, labelId, descriptionId }, _ref) => (
  <>
    <div id={labelId}>{makeSimpleText(message)}</div>
    {/* Element with id equal to descriptionId should be present in layout to avoid W3C validation error "The aria-describedby attribute
        must point to an element in the same document" on toast wrapper. */}
    <i id={descriptionId} />
  </>
));

export default ErrorToastWithMessage;
