import styles from './../HeaderMenu.module.scss';
import PropTypes from 'prop-types';
import NavItem from './NavItem';
import { useLoadNavigation, useAriaLabel } from 'components/objects/navigation/hooks';
import { ComponentGroup, NavigationGroupCode } from 'behavior/navigation/constants';
import { joinClasses } from 'utils/helpers';

const DesktopHeaderMenuBlock = ({ model }) => {
  const [items, ready] = useLoadNavigation(ComponentGroup.HeaderMenu, NavigationGroupCode.HeaderMenu);
  const ariaLabel = useAriaLabel(NavigationGroupCode.HeaderMenu);

  if (!ready)
    return null;

  const hasSubLevel = items.some(item => item.children?.length);
  const topLevelClassName = joinClasses(styles.topLevel, hasSubLevel && styles.columns, model.links_FontSize);
    
  const style = {
    '--link-fontFamily': model.links_FontFamily && (model.links_FontFamily + ', sans-serif'),
    '--link-fontSize': model.links_FontSize,
    '--link-fontColor': model.links_FontColor,
    '--link-backgroundColor': model.links_BackgroundColor,
    '--link-allCaps': model.links_AllCaps ? 'uppercase' : null,
    '--link-hoverFontColor': model.links_HoverFontColor,
    '--link-hoverBackgroundColor': model.links_HoverBackgroundColor,
  };
    
  return (
    <nav
      aria-label={ariaLabel}
      className={`${styles.container} ${model.links_ShowArrowInFront ? styles.showArrowInFront : ''}`}
      style={style}
    >
      <ul className={topLevelClassName}>
        {items.map((item, index) => <NavItem key={index} item={item} />)}
      </ul>
    </nav>
  );
};

DesktopHeaderMenuBlock.propTypes = {
  model: PropTypes.shape({
    links_FontFamily: PropTypes.string,
    links_FontSize: PropTypes.string,
    links_FontColor: PropTypes.string,
    links_BackgroundColor: PropTypes.string,
    links_AllCaps: PropTypes.bool,
    links_HoverFontColor: PropTypes.string,
    links_HoverBackgroundColor: PropTypes.string,
  }).isRequired,
};

export default DesktopHeaderMenuBlock;
