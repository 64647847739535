import { memo } from 'react';
import PropTypes from 'prop-types';

const Tag = ({ tracker, children }) => {
  if (!tracker)
    return null;

  const script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','${tracker.dataLayerName}','${tracker.containerId}');`;

  return children(<script>{script}</script>);
};

Tag.propTypes = {
  tracker: PropTypes.shape({
    containerId: PropTypes.string.isRequired,
    dataLayerName: PropTypes.string.isRequired,
  }),
  children: PropTypes.func.isRequired,
};

export default memo(Tag);