import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const ListPage = loadable(() => import(/*webpackChunkName:"sub-acc"*/'./ListPage'));
const DetailsPage = loadable(() => import(/*webpackChunkName:"sub-acc"*/'./Details'));

/* eslint-disable react/no-multi-comp */
export default {
  [PageComponentNames.SubAccounts]: page => (
    <ListPage desktop={page.content?.desktop} mobile={page.content?.mobile} />
  ),
  [PageComponentNames.SubAccount]: page => (
    <DetailsPage desktop={page.content?.desktop} mobile={page.content?.mobile} isCreatePage={!page.subAccount} />
  ),
};
