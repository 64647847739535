/* eslint-disable react/no-multi-comp */
import { createElement } from 'react';
import {
  SimpleText as SimpleTextCore,
  RichText as RichTextCore,
} from 'components/sanaText';
import {
  UseSanaTexts as UseSanaTextsCore,
} from 'components/sanaText/addons';
import type { GetProps } from 'react-redux';

export default function (addonId: string) {
  const makeTextKey = (textKey: string) => `${addonId}__${textKey}`;
  const SimpleText = ({ textKey, children, ...props }: GetProps<typeof SimpleTextCore>) => (
    createElement(SimpleTextCore, { textKey: makeTextKey(textKey), ...props }, children)
  );

  const RichText = ({ textKey, children, ...props }: GetProps<typeof RichTextCore>) => (
    createElement(RichTextCore, { textKey: makeTextKey(textKey), ...props }, children)
  );

  const UseSanaTexts = ({ textKeys, children, ...props }: GetProps<typeof UseSanaTextsCore>) => (
    createElement(UseSanaTextsCore, { textKeys: textKeys.map(makeTextKey), ...props }, children)
  );

  return {
    SimpleText,
    RichText,
    UseSanaTexts,
  };
}

