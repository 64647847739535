import type { Handler } from '../types';
import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { RouteName } from 'routes';

const handler: Handler<InvoiceUpdateFailedRouteData, InvoiceUpdateFailedPage> = () => of({
  page: {
    component: PageComponentNames.InvoiceUpdateFailed,
  },
});

type InvoiceUpdateFailedRouteData = {
  routeName: RouteName.InvoiceUpdateFailed;
};

type InvoiceUpdateFailedPage = {
  component: PageComponentNames.InvoiceUpdateFailed;
};

export default handler;
