import { createContext } from 'react';

type HeaderContext = {
  searchInputValue: string;
  isSummaryOpened: boolean;
  available: boolean;
  setDesktopNavHoverStatus(isHovered: boolean): void;
};

export default createContext<HeaderContext>({
  searchInputValue: '',
  isSummaryOpened: false,
  available: false,
  setDesktopNavHoverStatus: () => {},
});
