import type { User, Viewer, Abilities, Token } from './types';
import type { StateObservable } from 'redux-observable';
import type { Action } from 'redux';
import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import type { BackTo } from 'routes/types';
import { authenticated, loginFailed } from './actions';
import { viewerChanged, navigateTo } from 'behavior/events';
import { tap } from 'rxjs/operators';
import { routesBuilder } from 'routes';
import { Observer, Observable, from, of } from 'rxjs';
import { getBackToFromUrl } from 'behavior/pages/helpers';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { arrayToObject } from 'utils/helpers';

type ExpirationsObserver = Observer<Date | null>;

export function createMapLoginResult(state$: StateObservable<AppState>, { api, scope }: StoreDependencies, expirationsObserver?: ExpirationsObserver) {
  return function (email: string, loginResult: { token: Token | null }, viewerResult: Viewer): Observable<Action> {
    if (loginResult.token) {
      expirationsObserver && expirationsObserver.next(loginResult.token.expiration);
      api.setAuthToken(loginResult.token.value);

      const data = createUserData(viewerResult, true);
      data.email = email;

      const redirectTo = createRedirectTo(getBackToFromUrl(scope) || state$.value.page.backTo);

      if (redirectTo) {
          return from([
            authenticated(data),
            viewerChanged(),
            redirectTo(),
          ]);
      }

      return from([authenticated(data), viewerChanged(), navigateTo(routesBuilder.forHome())]);
    }

    return of(unsetLoadingIndicator(), loginFailed());
  };
}

function createRedirectTo(backTo: BackTo | { url: string; routeData?: undefined } | undefined) {
  if (backTo && (backTo.routeData || backTo.url)) {
    if (backTo.routeData)
      return () => navigateTo(backTo.routeData, backTo.url);

    if (backTo.url)
      return () => navigateTo(undefined, backTo.url!);
  }

  return;
}

export function createUserData(viewer: Viewer, isAuthenticated: boolean): User {
  const userData = { ...viewer, isAuthenticated } as User;

  if (viewer.abilities)
    userData.abilities = convertAbilities(viewer.abilities);

  return userData;
}

export function handleToken(api: StoreDependencies['api'], expirationsObserver?: ExpirationsObserver, broadcast = true) {
  return tap(<T extends { viewer?: Viewer }>(res: T) => {
    const token = res.viewer?.token;
    if (token !== undefined) {
      expirationsObserver && expirationsObserver.next(token.expiration);
      api.setAuthToken(token.value, broadcast);
      delete res.viewer!.token;
    }
  });
}

export function convertAbilities(abilities: Required<Viewer>['abilities']): Abilities {
  return arrayToObject(abilities, ability => ability.key, ability => ability.state);
}
