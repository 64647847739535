import type { Handler } from '../types';
import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { paymentSubmitQuery } from './queries';
import { loadSystemPageQuery, initSystemPageContent, SystemPageData, SystemPage, initPageContent } from 'behavior/pages/system';
import { RouteName, routesBuilder, SystemRouteData } from 'routes';
import { navigateTo } from 'behavior/events';

const handler: Handler<OrderPaymentSubmitRouteData, OrderPaymentSubmitPage> = (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<OrderPaymentSubmitSystemPageResponse>(loadSystemPageQuery('paymentSubmit')).pipe(
      map(({ pages: { paymentSubmit } }) => ({
        page: {
          ...paymentSubmit,
          component: PageComponentNames.PaymentSuccessful as const,
          transaction: { id: '', document: { id: '' }, isPaymentError: false, failed: false, cancelled: false },
        },
      })),
      initSystemPageContent(),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi<OrderPaymentSubmitPageResponse>(paymentSubmitQuery, { id: transactionId }).pipe(
    mergeMap(({
      pages: { paymentSubmit },
      paymentTransaction: transaction,
    }) => {
      if (transaction == null)
        return of(null);

      const createRedirectResult = (buildRoute: ((id: string) => SystemRouteData)) => of({ action$: of(navigateTo(buildRoute(transaction.id))) });

      if (transaction.isPaymentError)
        return createRedirectResult(routesBuilder.forPaymentError);
      if (transaction.failed)
        return createRedirectResult(routesBuilder.forOrderFailed);
      if (transaction.cancelled)
        return createRedirectResult(routesBuilder.forOrderCancelled);

      return of({
        page: {
          ...initPageContent(paymentSubmit),
          component: PageComponentNames.PaymentSuccessful as const,
          transaction,
        },
      });
    }),
  );
};

export default handler;

type OrderPaymentSubmitRouteData = {
  routeName: RouteName.OrderPayment;
  params: {
    transactionId?: string;
    previewToken?: string;
  };
};

type OrderPaymentSubmitPage = SystemPage & {
  component: PageComponentNames.PaymentSuccessful;
  transaction: {
    id: string;
    document: {
      id: string;
    };
  };
};

type OrderPaymentSubmitSystemPageResponse = {
  pages: {
    paymentSubmit: SystemPageData;
  };
};

type OrderPaymentSubmitPageResponse = OrderPaymentSubmitSystemPageResponse & {
  paymentTransaction: {
    id: string;
    document: {
      id: string;
    };
    isPaymentError: boolean;
    failed: boolean;
    cancelled: boolean;
  };
};

