import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';

const Text = ({ warehouseId, useFullName=false }) => (
  <RichText textKey={useFullName ? `Warehouse_Name_${warehouseId}` : `Warehouse_ShortName_${warehouseId}`} />
);

Text.propTypes = {
  warehouseId: PropTypes.string,
  useFullName: PropTypes.bool,
};

export default Text;
