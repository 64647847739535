import PropTypes from 'prop-types';
import Banner from '../ErrorBanner';

const ErrorPage = ({ data }) => <Banner timeId={data?.timeId} />;

ErrorPage.propTypes = {
  data: PropTypes.shape({
    timeId: PropTypes.string,
  }),
};

export default ErrorPage;
