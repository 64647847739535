import type { Epic } from 'behavior/types';
import { CREATE_INVOICE_ORDER, createInvoiceOrderResultReceived, InvoicePaymentAction } from '../actions';
import { createInvoiceOrderMutation } from '../queries';
import { mergeMap, startWith, catchError, pluck, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';

type CreateInvoiceOrderResponse = {
  invoicePayment: {
    create: {
      orderId: string;
    };
  };
};

const epic: Epic<InvoicePaymentAction> = (action$, _, { api, logger }) => action$.pipe(
  ofType(CREATE_INVOICE_ORDER),
  pluck('payload', 'invoices'),
  exhaustMap(invoices => api.graphApi<CreateInvoiceOrderResponse>(createInvoiceOrderMutation, { input: { invoices } }, { retries: 0 }).pipe(
    mergeMap(({ invoicePayment }) => {
      if (!invoicePayment)
        return of(createInvoiceOrderResultReceived({ error: true }), unsetLoadingIndicator());

      return of(navigateTo(routesBuilder.forInvoicePayment(invoicePayment.create.orderId)));
    }),
    catchError(error => {
      logger.error(error);

      return of(createInvoiceOrderResultReceived({ error: true }), unsetLoadingIndicator());
    }),
    startWith(setLoadingIndicator()),
  )),
);

export default epic;
