import type { SanaButtonProps } from './SanaButton';
import { memo, useState, useEffect } from 'react';
import SanaButton from './SanaButton';
import { LoadingIcon, ArrowDownIcon } from './Icons';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';

type Props = {
  textKey: string;
  className?: string;
  totalCount: number;
  loadedCount: number;
  batchSize: number;
  loadNext: (index: number) => void;
} & SanaButtonProps;

const LoadMoreButton = ({ textKey, className = '', totalCount, loadedCount, batchSize, loadNext, ...props }: Props) => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    return () => void setTimeout(toggleOverflowAnchorState, 50);
  }, [loadedCount]);

  const nextIndex = loadedCount < totalCount &&
    Math.floor(loadedCount / batchSize);

  if (!nextIndex)
    return null;

  return (
    <SanaButton
      textKey={textKey}
      className={className}
      icon={isLoading ? <LoadingIcon /> : <ArrowDownIcon />}
      formatWith={[batchSize]}
      onClick={() => {
        setLoading(true);
        toggleOverflowAnchorState(true);
        loadNext(nextIndex);
      }}
      {...props}
    />
  );
};

export default memo(LoadMoreButton);
