import type { BlogSuggestion } from './types';
import type { Epic } from 'behavior/types';
import { BlogSuggestionsAction, REQUEST_BLOG_SUGGESTIONS, blogSuggestionsRequestCompleted } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { blogSuggestionsQuery } from './queries';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';

const epic: Epic<BlogSuggestionsAction> = (action$, _, { api, logger }) => action$.pipe(
  ofType(REQUEST_BLOG_SUGGESTIONS),
  pluck('payload'),
  switchMap(options => api.graphApi<BlogSuggestionsResponse>(blogSuggestionsQuery, { options }).pipe(
    pluck('blog', 'list', 'items'),
    map(items => {
      const blogItems: BlogSuggestion[] = [];
    
      for (const item of items) {
        const blogItem: BlogSuggestion = {
          ...item,
          routeData: routesBuilder.forBlogItem(item.id),
        };
    
        blogItems.push(blogItem);
      }
    
      return blogSuggestionsRequestCompleted(blogItems);
    }),
    retryWithToast(action$, logger),
  )),
);

export default epic;

type BlogSuggestionsResponse = {
  blog: {
    list: {
      items: {
        id: string;
        title: string;
        url: string;
      }[];
    };
  };
};
