export enum VisualDesignerMode {
  InsiteEditor = 'insiteEditor',
  Header = 'header',
  Footer = 'footer',
  Page = 'page',
  ProductDetails = 'productDetails',
  ProductGroupDetails = 'productGroupDetails',
  Orders = 'orders',
  Quotes = 'quotes',
  Invoices = 'invoices',
  ReturnOrders = 'returnOrders',
  CreditNotes = 'creditNotes',
  ReturnReceipts = 'returnReceipts',
  Shipments = 'shipments',
  SalesAgreements = 'salesAgreements',
  SubAccount = 'subAccount',
  OrderAuthorizations = 'orderAuthorizations',
  CreateDocFreeReturnOrder = 'createDocFreeReturnOrder',
  CreateDocBasedReturnOrder = 'createDocBasedReturnOrder',
  BlogItem = 'blogItem',
  SubAccounts = 'subAccounts',
  OrderConfirmation = 'orderConfirmation',
  OrderFailed = 'orderFailed',
  OrderCancelled = 'orderCancelled',
  PaymentError = 'paymentError',
  SalesAgreementDetails = 'salesAgreementDetails',
  InvoicePayment = 'invoicePayment',
  OrderDetails = 'orderDetails',
  QuoteDetails = 'quoteDetails',
  InvoiceDetails = 'invoiceDetails',
  ReturnOrderDetails = 'returnOrderDetails',
  CreditNoteDetails = 'creditNoteDetails',
  ReturnReceiptDetails = 'returnReceiptDetails',
  ShipmentDetails = 'shipmentDetails',
  Content = 'content',
}
