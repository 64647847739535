type FacetValue = {
  textTitle?: string | null;
  title?: string;
};

/**
 * Makes facet values alphanumeric sorting.
 * @param {Array} facetValues facet values.
 * @param {boolean} [sortDescending] sorting order.
 * @returns {Array} sorted facet values.
 */
export const alphanumSort = <T extends FacetValue>(facetValues: T[], sortDescending?: boolean): T[] =>
  sortDescending
    ? facetValues.sort((a, b) => compare(b, a))
    : facetValues.sort(compare);

function compare(a: FacetValue, b: FacetValue) {
  const aKey = selectKey(a);
  if (aKey == null)
    // Return 0 to preserve default order (e.g. img instead of title).
    return 0;

  const bKey = selectKey(b);
  if (bKey == null)
    // Return 0 to preserve default order (e.g. img instead of title).
    return 0;

  if (!cmp)
    cmp = Intl.Collator('en-u-kn').compare;

  return cmp(aKey, bKey);
}

let cmp: (a: string, b: string) => number;

function selectKey(facetValue: FacetValue) {
  const title = facetValue.textTitle || facetValue.title;
  return title ? title.toLowerCase() : null;
}
