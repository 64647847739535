import type { OperatorFunction } from 'rxjs';
import type { SystemPageData } from './types';
import { tap } from 'rxjs/operators';
import { parseContent, RowContentElement } from 'behavior/content';

type SystemPage<TData extends SystemPageData> = Omit<TData, 'content'> & {
  content: {
    header: RowContentElement[] | null;
    footer: RowContentElement[] | null;
  } | null;
};

export function initSystemPageContent<TData extends { page: SystemPageData }>(): OperatorFunction<TData | null, Omit<TData, 'page'> & { page: SystemPage<TData['page']> }> {
  return tap<TData | null>(data => {
    if (!data || !data.page)
      return;

    data.page = initPageContent(data.page) as any;
  }) as any;
}

export function initPageContent<TData extends SystemPageData>(page: TData): SystemPage<TData> {
  const result = page as SystemPage<TData>;
  if (!result.content)
    return result;

  const { header, footer } = page.content!;

  if (header)
    result.content.header = parseContent(header);

  if (footer)
    result.content.footer = parseContent(footer);

  return result;
}
