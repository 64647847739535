import styles from './LanguageSelector.module.scss';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Placeholder } from 'components/primitives/placeholders';
import { ModalDrawer } from 'components/primitives/modalDrawer';
import { useOnChange } from 'utils/hooks';
import { getThemeFontSizeClassName } from 'components/theme';
import useLanguageSelectorBehavior from './useLanguageSelectorBehavior';
import MobileHeader from './MobileHeader';
import Body from './Body';
import { isBrowser } from 'utils/detections';

const MobileLanguageSelectorBlock = ({ model, isDesignerMode, id }) => {
  const {
    languagesReady,
    selectedLanguage,
    sortedLanguages,
    alternateUrls,
    loadAlternateUrls,
  } = useLanguageSelectorBehavior();
  const [opened, setOpened] = useState(false);
  const isLoading = useSelector(state => state.isLoading);

  const handleOpen = useCallback(e => {
    if (e.type === 'keydown' && e.key !== 'Enter' && e.key !== ' ')
      return;

    loadAlternateUrls();
    setOpened(!opened);
    e.preventDefault();  // Prevents possible 'keyup' event on wrong target after modal will be opened and scrolling on 'Space' key being pressed.
  }, [opened, loadAlternateUrls]);
  const handleClose = useCallback(() => setOpened(false), []);

  useOnChange(() => isLoading && handleClose(), [isLoading], false);

  if (!languagesReady || (sortedLanguages.length <= 1 && !isDesignerMode))
    return null;

  const languageSelectorId = `MobileLanguageSelector_${id}`;

  const style = {
    '--content-backgroundColor': model.content_BackgroundColor,
    '--content-itemFontColor': model.content_ItemFontColor,
    '--content-allCaps': model.content_AllCaps ? 'uppercase' : null,
  };

  return (
    <>
      <div
        tabIndex="0"
        role="button"
        disabled={isLoading || null}
        className={styles.mobileOpener}
        onKeyDown={handleOpen}
        onClick={handleOpen}
        aria-controls={isBrowser && !isDesignerMode ? languageSelectorId : null}
        suppressHydrationWarning
      >
        <MobileHeader selectedLanguage={selectedLanguage} />
        {model.content_ShowExpandIcon && (
          <span className={styles.caret}>
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        )}
      </div>
      {isBrowser && !isDesignerMode && (
        <ModalDrawer
          expanded={opened}
          onClose={handleClose}
          id={languageSelectorId}
          className={`${styles.drawer} ${getThemeFontSizeClassName(model.content_ItemFontSize)}`}
          containerClassName={styles.drawerContainer}
          style={style}
        >
          {alternateUrls && alternateUrls.length
            ? (
              <Body
                selectedLanguage={selectedLanguage}
                languages={sortedLanguages}
                alternateUrls={alternateUrls}
                showFlags={model.content_ShowFlags}
                className={styles.mobileItem}
              />
            )
            : (
              <div className={`${styles.placeholder} ${styles.mobile}`}>
                <Placeholder lineCount={sortedLanguages.length} className={styles.placeholderItem} />
              </div>
            )
          }
        </ModalDrawer>
      )}
    </>
  );
};

MobileLanguageSelectorBlock.propTypes = {
  model: PropTypes.shape({
    content_ShowFlags: PropTypes.bool.isRequired,
    content_AllCaps: PropTypes.bool.isRequired,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_BackgroundColor: PropTypes.string,
    content_ItemFontSize: PropTypes.string.isRequired,
    content_ItemFontColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default MobileLanguageSelectorBlock;
