import { ARIA_STATUS_SET } from './actions';
import type { AriaStatusAction } from './actions';

const initialState = {};

export type AriaStatusState = typeof initialState | { text: string; assertive: boolean } | { key: string; assertive: boolean };

export default function ariaReducer(state = initialState, action: AriaStatusAction): AriaStatusState {
  switch (action.type) {
    case ARIA_STATUS_SET:
      return action.payload;
    default:
      return state;
  }
}
