import {
  INSITE_EDITOR_LOADED,
  INSITE_EDITOR_RESOURCE_TEXTS_RECEIVED,
  INSITE_EDITOR_SET_SETTINGS,
  INSITE_EDITOR_SET_SANA_TEXTS_INFO,
  INSITE_EDITOR_SANA_TEXTS_REQUESTED,
  INSITE_EDITOR_SET_TEXT_ONLY_MODE,
  INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS,
  INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT,
} from './actions';
import { NAVIGATING } from 'behavior/routing';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { USER_PROFILE_UPDATED } from 'behavior/user';
import { SANATEXTS_LOADED } from 'behavior/sanaText/actions';
import { createReducer } from 'utils/redux';

const initialState = {
  initialized: false,
  resourceTexts: {},
  settings: {},
};

export default createReducer(initialState, {
  [INSITE_EDITOR_LOADED]: onInsiteEditorLoaded,
  [INSITE_EDITOR_RESOURCE_TEXTS_RECEIVED]: onReceiveResourceTexts,
  [INSITE_EDITOR_SET_SETTINGS]: onSetSettings,
  [SANATEXTS_LOADED]: onSanaTextsLoaded,
  [INSITE_EDITOR_SET_SANA_TEXTS_INFO]: onSetSanaTextsInfo,
  [INSITE_EDITOR_SANA_TEXTS_REQUESTED]: onSanaTextsRequested,
  [INSITE_EDITOR_SET_TEXT_ONLY_MODE]: onSetTextOnlyMode,
  [INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS]: onAddConstructedTexts,
  [INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT]: onUpdateConstructedText,
  [NAVIGATING]: onNavigating,
  [VIEWER_CHANGED]: onSanaTextsExpired,
  [LANGUAGE_CHANGED]: onSanaTextsExpired,
  [USER_PROFILE_UPDATED]: onSanaTextsExpired,
});

function onInsiteEditorLoaded(state) {
  return {
    ...state,
    initialized: true,
  };
}

function onSanaTextsLoaded(state, action) {
  if (!state.initialized)
    return state;

  let counter = state.counter || 0;
  const existingKeys = state.mapping
    ? new Set(Object.values(state.mapping).map(m => m.textKey))
    : null;
  const textsObj = Object.keys(action.payload).reduce(
    (acc, value) => {
      if (existingKeys?.has(value))
        return acc;
      acc[counter++] = { textKey: value };
      return acc;
    },
    {},
  );

  return {
    ...state,
    counter,
    mapping: { ...state.mapping, ...textsObj },
  };
}

function onSetSanaTextsInfo(state, action) {
  const { sanaTexts: sanaTextsInfo } = action.payload;

  return {
    ...state,
    sanaTextsInfo,
  };
}

function onSanaTextsRequested(state, action) {
  const { textKeys } = action.payload;
  const texts = new Set(state.requested);
  textKeys.forEach(text => texts.add(text));

  return {
    ...state,
    requested: Array.from(texts),
  };
}

function onSetTextOnlyMode(state, action) {
  const { textKeys } = action.payload;
  const texts = new Set(state.textOnlyModeKeys);
  textKeys.forEach(text => texts.add(text));

  return {
    ...state,
    textOnlyModeKeys: Array.from(texts),
  };
}

function onNavigating(state) {
  return {
    ...state,
    requested: [],
  };
}

function onAddConstructedTexts(state, action) {
  const { texts } = action.payload;
  const constructedTexts = state.constructed ? Array.from(state.constructed) : [];
  texts.forEach(text => constructedTexts.every(constructedText =>
    constructedText.key !== text.key) && constructedTexts.push(text));

  return {
    ...state,
    constructed: constructedTexts,
  };
}

function onUpdateConstructedText(state, action) {
  const { text } = action.payload;

  const updatedConstructed = state.constructed?.map(constructedText =>
    constructedText.key === text.key ? text : constructedText);

  return {
    ...state,
    constructed: updatedConstructed,
  };
}

function onReceiveResourceTexts(state, action) {
  const { texts } = action.payload;
  return {
    ...state,
    resourceTexts: {
      ...state.resourceTexts,
      ...texts,
    },
  };
}

function onSetSettings(state, action) {
  const { settings } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      ...settings,
    },
  };
}

function onSanaTextsExpired(state) {
  if (!state.insiteEditor)
    return state;

  return {
    ...state,
    mapping: {},
  };
}
