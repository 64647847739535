import type { RoutingAction } from 'behavior/routing';
import { LoadingIndicatorAction, LOADING_INDICATOR_SET, LOADING_INDICATOR_UNSET } from './actions';
import { NAVIGATION_REQUESTED, NAVIGATING, NAVIGATED } from 'behavior/routing';

type LoadingIndicatorState = boolean;

export default function reducer(state: LoadingIndicatorState = false, action: LoadingIndicatorAction | RoutingAction) {
  switch (action.type) {
    case LOADING_INDICATOR_SET:
    case NAVIGATION_REQUESTED:
    case NAVIGATING:
      return true;
    case LOADING_INDICATOR_UNSET:
    case NAVIGATED:
      return false;
    default:
      return state;
  }
}