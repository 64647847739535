import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import BasketTitle from './BasketTitle';
import BasketBreadcrumb from './Breadcrumb';
import BasketPageContent from './BasketPageContent';
import WarehouseSelector from './WarehouseSelector';

const BasketPage = ({ preset, header, footer }) => (
  <>
    <Container>
      <BasketBreadcrumb />
      <BasketTitle />
      <WarehouseSelector />
    </Container>
    <BasketPageContent preset={preset} header={header} footer={footer} />
  </>
);

BasketPage.propTypes = {
  preset: PropTypes.string,
  header: PropTypes.array,
  footer: PropTypes.array,
};

export default BasketPage;
