exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HtmlBlock_html{word-wrap:break-word;margin:0;max-width:100%}", ""]);

// exports
exports.locals = {
	"html": "HtmlBlock_html"
};