import { SalesAgreementStatus } from 'behavior/salesAgreements';
import type { AgreementLine } from './types';

export const agreements = {
  items: Array.from(Array(5)).map((_, index) => ({
    id: index.toString(),
    status: SalesAgreementStatus.Active,
    title: null,
    url: '',
    effectiveDate: '-',
    expirationDate: '-',
  })),
  loadedLength: 5,
};

export const agreement = {
  id: '',
  title: '',
  status: SalesAgreementStatus.Active,
  currency: { id: '' },
  effectiveDate: '-',
  expirationDate: '-',
  address: '-',
  lines: Array.from(Array(3)).map((_, index) => ({
    id: index.toString(),
    quantities: {},
    amounts: {},
    uom: { id: '' },
  } as AgreementLine)),
};