import PropTypes from 'prop-types';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import WarehousesStockBlock from 'components/objects/product/blocks/warehousesStock/WarehousesStockBlock';
import { requestProductSetItemWarehousesStock } from 'behavior/content/productSets';

const WarehousesProductListStockBlock = ({ id, stockLevels, uom, warehousesStock, contentBlockId }) => {
  const dispatch = useDispatch();
  const uomId = uom?.id;

  const onLoadWarehousesStock = (productId, uomId) => {
    dispatch(requestProductSetItemWarehousesStock(contentBlockId, productId, uomId));
  };

  const canViewStock = useHasAbilities(AbilityTo.ViewStock)[0];
  
  if (!canViewStock)
    return null;

  return (
    <WarehousesStockBlock onLoadWarehousesStock={onLoadWarehousesStock} warehousesStock={warehousesStock} productId={id} uomId={uomId} stockLevels={stockLevels} />
  );
};

WarehousesProductListStockBlock.propTypes = {
  id: PropTypes.string.isRequired,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
  uom: PropTypes.object,
  warehousesStock: PropTypes.array,
  contentBlockId: PropTypes.string,
};

export default memo(WarehousesProductListStockBlock);