import type { Handler } from '../types';
import type { RouteName } from 'routes';
import type { OrderAuthorizationCollection, OrderAuthorizationsFilter, OrderAuthorizationsPage } from './types';
import { ContentSystemPageData, initContentSystemPage, loadContentSystemPageQuery } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { requestAuthorizations } from './actions';
import { orderAuthorizationsPageQuery } from './queries';
import { createOptions, normalizeFilter } from '../documents';

type OrderAuthorizationsRouteData = {
  routeName: RouteName.OrderAuthorizations;
  params?: {
    filter: OrderAuthorizationsFilter;
    index?: number;
    previewToken?: string;
  };
  options?: {
    onlyItems?: boolean;
  };
};

type OrderAuthorizationsPreviewPageResponse = {
  pages: {
    orderAuthorizations: ContentSystemPageData;
  };
};

type OrderAuthorizationsPageResponse = {
  pages: {
    orderAuthorizations: ContentSystemPageData;
  };
  profile: {
    orderAuthorizations: OrderAuthorizationCollection;
  };
};

export const size = 10;

const handler: Handler<OrderAuthorizationsRouteData, OrderAuthorizationsPage> = ({ params, options }, state$, { api }) => {
  const filter = normalizeFilter(params?.filter);

  if (!filter.authorizationStatus)
    filter.authorizationStatus = null;

  if (params?.previewToken) {
    return api.graphApi<OrderAuthorizationsPreviewPageResponse>(loadContentSystemPageQuery('orderAuthorizations')).pipe(
      map(({ pages: { orderAuthorizations: page } }) => ({
        page: {
          component: PageComponentNames.OrderAuthorizations,
          orderAuthorizations: { totalCount: 0, items: [] },
          size,
          filter,
          ...initContentSystemPage(page),
        },
      })),
    );
  }

  const onlyItems = options?.onlyItems;
  const input = createOptions(params, filter, onlyItems);

  if (onlyItems)
    return of({
      action$: of(requestAuthorizations(input)),
      page: {
        ...state$.value.page as OrderAuthorizationsPage,
        filter,
      },
    });

  return api.graphApi<OrderAuthorizationsPageResponse>(orderAuthorizationsPageQuery, { input }).pipe(
    map(({ pages: { orderAuthorizations: page }, profile: { orderAuthorizations } }) => ({
      page: {
        component: PageComponentNames.OrderAuthorizations,
        orderAuthorizations,
        size,
        filter,
        ...initContentSystemPage(page),
      },
    })),
  );
};

export default handler;
