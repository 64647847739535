import { useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { loadUserAbilities } from 'behavior/user';
import { useLoadEffect } from 'utils/hooks';
import { getAbilitiesSelector, getExpAbilitiesSelector } from './selectors';

/**
 * @typedef {Object} AbilitiesInfo
 * @property {string[]} abilities Array of abilities statuses.
 * @property {boolean} isLoading Flag, indicating if abilities are being loaded.
 */

/**
 * Provides statuses for passed ability keys and flag indicating if them are being loaded.
 * @param {string[]} abilityKeys Ability keys.
 * @returns {AbilitiesInfo} Abilities information.
 */
export default function useAbilities(abilityKeys) {
  let shouldUpdate = false;

  const [getAbilities, getExpired] = useMemo(
    () => [getAbilitiesSelector(abilityKeys), getExpAbilitiesSelector(abilityKeys)],
    abilityKeys,
  );

  const { abilities, expired } = useSelector(state => ({
    abilities: getAbilities(state),
    expired: getExpired(state),
  }), shallowEqual);

  const dispatch = useDispatch();

  useLoadEffect(() => {
    const abilitiesToLoad = [];

    for (let length = abilityKeys.length, i = 0; i < length; i++) {
      if (abilities[i] == null || expired[i])
        abilitiesToLoad.push(abilityKeys[i]);
    }

    if (abilitiesToLoad.length) {
      dispatch(loadUserAbilities(abilitiesToLoad));
      shouldUpdate = true;
    }
  }, [abilities, expired]);

  return { abilities, isLoading: shouldUpdate };
}