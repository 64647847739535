exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DisabledSearchBox_fake-input{align-items:center;background-color:var(--box-backgroundColor,var(--theme-searchBox_BackgroundColor,#fff));color:var(--box-fontColor,var(--theme-input_Color,#333));display:flex;font-family:var(--box-fontFamily);height:100%;line-height:1.2;padding:0 4.2em 0 1.2em}", ""]);

// exports
exports.locals = {
	"fake-input": "DisabledSearchBox_fake-input",
	"fakeInput": "DisabledSearchBox_fake-input"
};