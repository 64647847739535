import type { CreateProspectPage } from './types';
import { UserAction, USER_CREATE_PROSPECT, USER_CREATE_PROSPECT_PROCESSED } from 'behavior/user';

export default (state: CreateProspectPage, action: UserAction): CreateProspectPage => {
  switch (action.type) {
    case USER_CREATE_PROSPECT:
      return { ...state, updatedById: undefined };
    case USER_CREATE_PROSPECT_PROCESSED:
      return { ...state, updatedById: action.payload.updatedById };
    default:
      return state;
  }
};
