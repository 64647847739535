import { themeVarPrefix } from 'css/exports/_theme_vars.scss';

export function getAdditionalButtonsCSSVars(themeVars) {
  let vars = '';

  if (themeVars.button_BorderWidth) {
    const borderWidthValues = getSplittedCssValues(themeVars.button_BorderWidth);
    if (themeVars.button_BorderStyle !== 'none') {
      vars += `
        ${themeVarPrefix}button_BorderTopWidth: ${borderWidthValues[0]};
        ${themeVarPrefix}button_BorderBottomWidth: ${borderWidthValues[2] || borderWidthValues[0]};
      `;
    }
  }

  if (themeVars.button_Icon)
    vars += `${themeVarPrefix}button_IconContent: url("${themeVars.button_Icon}");`;
  if (themeVars.button_Hover_Icon)
    vars += `${themeVarPrefix}button_Hover_IconContent: url("${themeVars.button_Hover_Icon}");`;

  return vars;
}

export function getSplittedCssValues(value) {
  return value.split(' ').map(v => v === '0' ? '0px' : v);
}