import type {
  AgreementLineAvailability,
  SalesAgreement,
  SalesAgreementsState,
} from './types';
import { SalesAgreementsAction } from './actions';
import { createReducer } from 'utils/redux';
import {
  AGREEMENTS_RECEIVED,
  AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED,
  AGREEMENT_LINES_AVAILABILITY_RECEIVED,
} from './actions';

export default createReducer<SalesAgreementsState, SalesAgreementsAction>({}, {
  [AGREEMENTS_RECEIVED]: onAgreementsReceived,
  [AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED]: onSearchProductIdsReceived,
  [AGREEMENT_LINES_AVAILABILITY_RECEIVED]: onAgreementLinesAvailabilityReceived,
});

function onAgreementsReceived(state: SalesAgreementsState, { payload }: { payload: { agreements: SalesAgreement[] | null; append: boolean } }) {
  const newAgreements = payload.agreements || [];

  const items = payload.append && state.agreements
    ? state.agreements.items.concat(newAgreements)
    : newAgreements;

  const agreements = {
    items,
    loadedLength: newAgreements.length,
  };

  return { ...state, agreements };
}

function onSearchProductIdsReceived(state: SalesAgreementsState, { payload: search }: { payload: { keywords: string; ids: string[] | null } }) {
  return { ...state, search };
}

function onAgreementLinesAvailabilityReceived(state: SalesAgreementsState, { payload: availableLines }: { payload: AgreementLineAvailability[] }) {
  if (availableLines.length === 0 || state.agreement == null)
    return state;

  if (!state.agreement.lines)
    return {
      ...state,
      agreement: {
        ...(state.agreement),
        lines: [],
      },
    };

  const lines = state.agreement.lines.map(line => {
    const availableLine = availableLines.find(({ lineId }) => lineId === line.id);

    if (!(availableLine?.variantId && line.product && line.product.variants)
      && !line.product?.variants?.some(variant => !variant.isOrderable)
    ) {
      return {
        ...line,
        isLineOrderable: !!availableLine,
      };
    }

    const variantComponentGroups: VariantComponentGroup[] = [];
    const agreementLineId = line.id.toUpperCase();

    line.product.variantComponentGroups.forEach(componentGroup => {
      const components: Component[] = [];

      componentGroup.components.forEach(component => {
        const variants = component.variants.filter(v => {
          const componentVariantId = v.toUpperCase();

          const variant = line.product && line.product.variants && line.product.variants.find(variant =>
            variant.id.toUpperCase() === componentVariantId);

          const isVariantOrderable = variant && variant.isOrderable;

          return isVariantOrderable && availableLines.some(
            ({ lineId, variantId }) => lineId.toUpperCase() === agreementLineId
              && (!variantId || variantId.toUpperCase() === componentVariantId),
          );
        });

        if (!variants.length)
          return;

        components.push({
          ...component,
          variants,
        });
      });

      if (!components.length)
        return;

      variantComponentGroups.push({
        ...componentGroup,
        components,
      });
    });

    if (!variantComponentGroups.length) {
      return {
        ...line,
        isLineOrderable: false,
      };
    }

    return {
      ...line,
      isLineOrderable: !!availableLine,
      product: {
        ...line.product,
        variantComponentGroups,
      },
    };
  });

  return {
    ...state,
    agreement: {
      ...(state.agreement),
      lines,
    },
  };
}

type Component = {
  id: string | null;
  name: string | null;
  variants: string[];
};

type VariantComponentGroup = {
  id: string;
  name: string | null;
  components: Component[];
};
