import { memo, ReactElement } from 'react';
import { LinkButton, ButtonProps } from './LinkButton';
import { useSanaButton } from 'components/primitives/buttons';
import type { SystemLinkProps, LinkProps } from './Link';

type ExtraProps = {
  textKey: string;
  titleTextKey?: string;
  defaultText?: string;
  placeholder?: ReactElement | string;
};

type OmitProps = 'ref' | 'title';
type LinkButtonProps = Omit<LinkProps | SystemLinkProps, OmitProps> & ButtonProps & ExtraProps;

function SanaLinkButton(props: Omit<LinkProps, OmitProps> & ButtonProps & ExtraProps): ReactElement;
function SanaLinkButton(props: Omit<SystemLinkProps, OmitProps> & ButtonProps & ExtraProps): ReactElement;
function SanaLinkButton({
  textKey,
  titleTextKey = textKey,
  defaultText,
  placeholder,
  ...props
}: LinkButtonProps) {
  const { text, title, loaded } = useSanaButton(textKey, defaultText, undefined, titleTextKey);
  if (!loaded)
    return placeholder || null;

  return <LinkButton title={title} {...props}>{text}</LinkButton>;
}

export default memo(SanaLinkButton) as typeof SanaLinkButton;
