export const PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED' as const;
export const resetPassword = (token: string, newPassword: string) => ({
    type: PASSWORD_RESET_REQUESTED,
    payload: { token, password: newPassword },
});

export const PASSWORD_RESET_RESULT = 'PASSWORD_RESET_RESULT' as const;
export const notifyResetResult = (success: boolean) => ({ type: PASSWORD_RESET_RESULT, payload: { success } });

export type ResetPasswordAction = ReturnType<
    | typeof resetPassword
    | typeof notifyResetResult
>;
