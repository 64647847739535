import animations from 'css/Animations.module.scss';

export const AnimationsMap = Object.freeze({
  NONE: null,
  FADE_IN: animations.fadeIn,
  ZOOM_IN: animations.zoomIn,
  MOVING_IN_FROM_TOP: animations.fadeInDown,
  MOVING_IN_FROM_BOTTOM: animations.fadeInUp,
  MOVING_IN_FROM_LEFT: animations.fadeInLeft,
  MOVING_IN_FROM_RIGHT: animations.fadeInRight,
});

export const VerticalAlignmentsMap = Object.freeze({
  INHERITED: 'inherited',
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
  JUSTIFY: 'justify-vertical',
});

export const HorizontalAlignmentsMap = Object.freeze({
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify-horizontal',
});

export const NoBorderStyleValue = 'NONE';