import type { OrderResult } from './types';
import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import type {
  OrderPaymentAction,
  ReceivePaymentMethodExtraData,
  ReceivePayOrderResultAction,
} from './actions';
import {
  PAY_ORDER,
  PAY_ORDER_RESULT_RECEIVED,
  PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
} from './actions';
import { createReducer } from 'utils/redux';

type OrderPaymentState = {
  payOrderResult?: OrderResult | null;
  additionalCustomerData?: AdditionalCustomerData;
  extraPaymentData?: { paymentMethodId: string } & ExtraPaymentData;
};

export default createReducer<OrderPaymentState, OrderPaymentAction>(null as unknown as OrderPaymentState, {
  [PAY_ORDER_RESULT_RECEIVED]: onPayOrderResultReceived,
  [PAY_ORDER]: onPayOrder,
  [PAYMENT_METHOD_EXTRA_DATA_RECEIVED]: onPaymentMethodExtraDataReceived,
});

function onPayOrderResultReceived(state: OrderPaymentState, action: ReceivePayOrderResultAction) {
  return { ...state, payOrderResult: action.payload.result };
}

function onPayOrder(state: OrderPaymentState) {
  return { ...state, payOrderResult: null };
}

function onPaymentMethodExtraDataReceived(state: OrderPaymentState, action: ReceivePaymentMethodExtraData) {
  const { paymentMethodId, additionalCustomerData, extraPaymentData } = action.payload;

  return {
    ...state,
    additionalCustomerData,
    extraPaymentData: extraPaymentData && { paymentMethodId, ...extraPaymentData },
  };
}
