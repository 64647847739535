import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useProductRouteBuilder } from './hooks';
import { appendAgreementLineToUrl } from 'behavior/salesAgreements';

const WithProductRoute = ({ children, product: { id, url }, withoutBackTo, salesAgreementLineId }) => {
  const routeBuilder = useProductRouteBuilder(id, withoutBackTo, salesAgreementLineId);
  const productUrl = salesAgreementLineId ? appendAgreementLineToUrl(url, salesAgreementLineId) : url;

  return children(productUrl, routeBuilder);
};

WithProductRoute.propTypes = {
  children: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  withoutBackTo: PropTypes.bool,
  salesAgreementLineId: PropTypes.string,
};

export default connect(mapStateToProps)(WithProductRoute);

function mapStateToProps({ page: { salesAgreement } }) {
  return {
    salesAgreementLineId: salesAgreement?.preSelectedLine?.id,
  };
}
