import ErrorToastWithReload from './ErrorToastWithReload';
import ErrorToastWithRetry from './ErrorToastWithRetry';
import ErrorToastWithMessage from './ErrorToastWithMessage';

/* eslint-disable react/no-multi-comp */
const toastsCreators = {
  /*Error handling type*/
  retry: (id, data) => <ErrorToastWithRetry {...data} id={id} />,
  reload: (_id, data) => <ErrorToastWithReload {...data} />,
  message: (_id, data) => <ErrorToastWithMessage message={data} />,
};

export default function create(id, type, data) {
  const creator = toastsCreators[type];
  if (!creator)
    throw new Error(`Error toast of type ${type} is not registered.`);

  return creator(id, data);
}
