import React from 'react';
import PropTypes from 'prop-types';
import styles from './FlyersBlock.module.scss';
import { isUndefined } from './ImageBlockUtilities';
import { renderHTML } from 'utils/render';

const SubTitle = ({ tileConfigData }) => {
    const { subtitleColor, subtitle } = { ...tileConfigData };
    return (
        <div className={styles.flexiTileSubHeader}
          style={{
              color:
                  (!isUndefined(tileConfigData) && !isUndefined(subtitleColor))
                      ? tileConfigData.subtitleColor : 'inherit',
          }}
        >
            <div className={subtitleColor ? styles.textWrapper : styles.textMargin}>
                {renderHTML(!isUndefined(subtitle) ? subtitle : '')}
            </div>
        </div>
    );
};

SubTitle.propTypes = {
    tileConfigData: PropTypes.shape({
        subtitleColor: PropTypes.string,
        subtitle: PropTypes.string,
    }),
};

export default React.memo(SubTitle);