import React, { useState, useMemo } from 'react';
import styles from './FlyersBlock.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LazyImage } from 'components/primitives/responsiveImages';
import Title from './Title';
import SubTitle from './SubTitle';
import { LinkButton } from 'components/primitives/links';
import { isUndefined } from './ImageBlockUtilities';
import { Button } from 'components/primitives/buttons';
import btnStyles from 'components/primitives/buttons/Button.module.scss';

function ImageFlayerBlock({
  tileData,
  adminClasses,
  textPositionClassNamesString,
  tileBackgroundStyles,
  buttonDisplayStyle,
}) {
  const [loaded, setLoaded] = useState(false);
  const [hover, setHover] = useState(false);
  const { background, text, button, buttonLink, layout } = { ...tileData };
  const placeholderCss = loaded
    ? styles.imageSkeleton
    : styles.imagePlaceholder;

  let linkStyle = {
    color:
      !isUndefined(button) &&
      !isUndefined(button.buttonTextColor) &&
      button.buttonTextColor,
    background:
      !isUndefined(button) &&
      !isUndefined(button.buttonBackgroundColor) &&
      button.buttonBackgroundColor,
    border:
      !isUndefined(button) &&
      !isUndefined(button.buttonBorderColor) &&
      `1px solid ${button.buttonBorderColor}`,
    display: buttonDisplayStyle,
  };

  if (hover)
    linkStyle = {
      ...linkStyle,
      backgroundColor:
        !isUndefined(button) &&
        !isUndefined(button.buttonBackgroundHoverColor) &&
        button.buttonBackgroundHoverColor,
      border:
        !isUndefined(button) &&
        !isUndefined(button.buttonBorderHoverColor) &&
        `1px solid ${button.buttonBorderHoverColor}`,
    };
  else
    linkStyle = {
      ...linkStyle,
      backgroundColor:
        !isUndefined(button) &&
        !isUndefined(button.buttonBackgroundColor) &&
        button.buttonBackgroundColor,
      border:
        !isUndefined(button) &&
        !isUndefined(button.buttonBorderColor) &&
        `1px solid ${button.buttonBorderColor}`,
    };

  const backgroundImageTag = useMemo(() => {
    const { backgroundImagePath } = {
      ...tileData.background,
    };
    return (
      <LazyImage
        afterLoad={() => setLoaded(true)}
        src={backgroundImagePath}
        className={classNames(styles.tileImage, styles['imageBestView'])}
        draggable="false"
      />
    );
  }, [background.backgroundImagePath]);

  const textBlock = (
    <div className={styles.textBlock}>
      {text?.title?.length !== 0 && <Title tileConfigData={text} />}
      {text?.subtitle?.length !== 0 && (
        <SubTitle tileConfigData={text} />
      )}
      {!isUndefined(button) &&
        button.buttonText !== 0 &&
        !isUndefined(buttonLink) &&
        !buttonLink.useFileLink &&
        buttonLink.navigationLink && (
          <div className={styles.btnWrp}>
            <LinkButton
              url={buttonLink.navigationLink?.url}
              to={buttonLink.navigationLink?.to}
              target={buttonLink.navigationLink?.target}
              style={linkStyle}
              onMouseEnter={() => {
                setHover(!hover);
              }}
              onMouseLeave={() => {
                setHover(!hover);
              }}
              className="flayers-button-click"
            >
              {button?.buttonText}
            </LinkButton>
          </div>
        )}
      {!isUndefined(button) &&
        button.buttonText !== 0 &&
        !isUndefined(buttonLink) &&
        buttonLink.useFileLink &&
        buttonLink.buttonFileLink && (
          <div className={styles.btnWrp}>
            <a
              href={
                !isUndefined(buttonLink) &&
                !isUndefined(buttonLink.buttonFileLink) &&
                buttonLink.buttonFileLink
              }
              target="_blank"
              rel="noopener noreferrer"
              className={styles.btnLink}
            >
            <Button
              className={`${btnStyles.btn} flayers-button-click`}
              style={linkStyle}
              onMouseEnter={() => {
                setHover(!hover);
              }}
              onMouseLeave={() => {
                setHover(!hover);
              }}
            >
                {button?.buttonText}
            </Button>
            </a>
          </div>
        )}
    </div>
  );

  return (
    <div
      className={classNames(styles.flexTileBlock, 'flayers-content-block', styles.flayersContentBlock, layout.textAndButtonBelow ? styles.textBelow : '', background.useBackgroundColor ? styles.withBgColor : '', background.verticalSplit && !layout.textAndButtonBelow ? styles.verticalSplit : '')}
      style={{ backgroundColor: background.useBackgroundColor ? background.backgroundColor : '', minHeight: layout.tileHeight ? `${layout.tileHeight}px` : (layout.textAndButtonBelow ? '400px' : '0') }}
    >
      <div className={styles.tileBlockImg} style={{ tileBackgroundStyles }}>
        <div className={styles.imageContainer}>
          {background.useBackgroundImage && (
            <div className={styles.placeholderWrapper}>
              <div
                className={classNames(
                  placeholderCss,
                  styles.inner,
                  adminClasses,
                )}
              />
              {backgroundImageTag}
            </div>
          )}

          {(!layout.textAndButtonBelow && (text?.title?.length !== 0 ||
            text?.subtitle?.length !== 0 ||
            button?.buttonText?.length !== 0)) && (
            <div
              className={classNames(
                styles.textOverlay,
                textPositionClassNamesString,
              )}
            >
              {textBlock}
            </div>
          )}
        </div>
      </div>
      {(layout.textAndButtonBelow && (text?.title?.length !== 0 ||
        text?.subtitle?.length !== 0 ||
        button?.buttonText?.length !== 0)) && (
          <div className={styles.textBelowContent}>
            <div
              className={classNames(
                styles.textOverlay,
                textPositionClassNamesString,
              )}
            >
              {textBlock}
            </div>
        </div>
      )}
    </div>
  );
}

ImageFlayerBlock.propTypes = {
  tileData: PropTypes.shape({
    background: PropTypes.shape({
      useBackgroundImage: PropTypes.bool,
      backgroundImagePath: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
  }),
  adminClasses: PropTypes.string,
  textPositionClassNamesString: PropTypes.string,
  tileBackgroundStyles: PropTypes.object,
  buttonDisplayStyle: PropTypes.string,
};
export default React.memo(ImageFlayerBlock);
