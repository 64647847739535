import { FilterData, MakeFilterData, ModelFilterData, YearFilterData } from './types';

export const PRODUCT_FILTERS_BLOCK_SELECTED_VALUES_CHANGED = 'PRODUCT_FILTERS_BLOCK_SELECTED_VALUES_CHANGED' as const;
export const productFiltersBlockSelectedValueChanged = (selectedValues: FilterData[]) => ({
    type: PRODUCT_FILTERS_BLOCK_SELECTED_VALUES_CHANGED,
    payload: { data: selectedValues },
});
export type ProductFiltersBlockSelectedValueChangedAction = ReturnType<typeof productFiltersBlockSelectedValueChanged>;

export const PRODUCT_FILTER_YEAR_LOAD_COLLECTION_REQUESTED = 'PRODUCT_FILTER_YEAR_LOAD_COLLECTION_REQUESTED' as const;
export const productFieldYearLoadCollection = (options: FilterData) => ({
    type: PRODUCT_FILTER_YEAR_LOAD_COLLECTION_REQUESTED,
    payload: { options },
});
export type ProductFieldYearLoadCollectionAction = ReturnType<typeof productFieldYearLoadCollection>;

export const PRODUCT_FILTER_YEAR_LOAD_COLLECTION_RECEIVED = 'PRODUCT_FILTER_YEAR_LOAD_COLLECTION_RECEIVED' as const;
export const productFieldYearCollectionReceived = (options: YearFilterData) => ({
    type: PRODUCT_FILTER_YEAR_LOAD_COLLECTION_RECEIVED,
    payload: { options },
});
export type ProductFieldYearCollectionReceivedAction = ReturnType<typeof productFieldYearCollectionReceived>;

export const PRODUCT_FILTER_MAKE_LOAD_COLLECTION_REQUESTED = 'PRODUCT_FILTER_MAKE_LOAD_COLLECTION_REQUESTED' as const;
export const productFieldMakeLoadCollection = (options: FilterData) => ({
    type: PRODUCT_FILTER_MAKE_LOAD_COLLECTION_REQUESTED,
    payload: { options },
});
export type ProductFieldMakeLoadCollectionAction = ReturnType<typeof productFieldMakeLoadCollection>;

export const PRODUCT_FILTER_MAKE_LOAD_COLLECTION_RECEIVED = 'PRODUCT_FILTER_MAKE_LOAD_COLLECTION_RECEIVED' as const;
export const productFieldMakeCollectionReceived = (options: MakeFilterData) => ({
    type: PRODUCT_FILTER_MAKE_LOAD_COLLECTION_RECEIVED,
    payload: { options },
});
export type ProductFieldMakeCollectionReceivedAction = ReturnType<typeof productFieldMakeCollectionReceived>;

export const PRODUCT_FILTER_MODEL_LOAD_COLLECTION_REQUESTED = 'PRODUCT_FILTER_MODEL_LOAD_COLLECTION_REQUESTED' as const;
export const productFieldModelLoadCollection = (options: Array<FilterData>) => ({
    type: PRODUCT_FILTER_MODEL_LOAD_COLLECTION_REQUESTED,
    payload: { options },
});
export type ProductFieldModelLoadCollectionAction = ReturnType<typeof productFieldModelLoadCollection>;

export const PRODUCT_FILTER_MODEL_LOAD_COLLECTION_RECEIVED = 'PRODUCT_FILTER_MODEL_LOAD_COLLECTION_RECEIVED' as const;
export const productFieldModelCollectionReceived = (options: ModelFilterData) => ({
    type: PRODUCT_FILTER_MODEL_LOAD_COLLECTION_RECEIVED,
    payload: { options },
});
export type ProductFieldModelCollectionReceivedAction = ReturnType<typeof productFieldModelCollectionReceived>;

export type ProductFilterAction = ReturnType<
    | typeof productFiltersBlockSelectedValueChanged
    | typeof productFieldYearCollectionReceived
    | typeof productFieldMakeCollectionReceived
    | typeof productFieldModelCollectionReceived
>;
