import type { BlogSuggestion } from './types';
import { BlogSuggestionsAction, BLOG_SUGGESTIONS_REQUEST_COMPLETED, BLOG_SUGGESTIONS_CLEARED } from './actions';

export const initialState: BlogSuggestion[] = [];

export default (state = initialState, action: BlogSuggestionsAction) => {
  switch (action.type) {
    case BLOG_SUGGESTIONS_REQUEST_COMPLETED:
      return !action.payload.length
        ? initialState
        : action.payload;
    case BLOG_SUGGESTIONS_CLEARED:
      return initialState;
    default:
      return state;
  }
};
