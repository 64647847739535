import styles from '../Link.module.scss';
import { memo, MouseEvent } from 'react';
import { connectToContext } from 'utils/react';
import { HashRoutingContext, HashRoutingContextValue } from 'components/hash';
import { setFocusWithoutScrolling } from 'utils/dom';

type Props = {
  hash: string;
  title: string;
  navigateTo: HashRoutingContextValue['navigateTo'];
  omitScroll?: boolean;
} & Omit<JSX.IntrinsicElements['a'], 'href' | 'className' | 'title'>;

const SkipLink = ({ hash, title, navigateTo, onClick, omitScroll, ...attributes }: Props) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick && onClick(e);
    !omitScroll && navigateTo(hash);
    e.currentTarget.blur();

    const target = document.getElementById(hash.substring(1));

    if (!target)
      return;
    // Focus should be set on the hash element after small timeout,
    // otherwise it will be set on body element
    setTimeout(setFocusWithoutScrolling.bind(null, target));
  };
  return (
    <a
      href={hash}
      className={`visually-hidden ${styles.skipLink}`}
      onClick={handleClick}
      {...attributes}
    >
      {title}
    </a>
  );
};

export default connectToContext(
  [HashRoutingContext],
  ({ navigateTo }) => ({ navigateTo }),
)(memo(SkipLink));
