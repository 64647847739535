import styles from './LinkButton.module.scss';
import { themeVarPrefix } from 'css/exports/_theme_vars.scss';
import PropTypes from 'prop-types';
import { LinkButton } from 'components/primitives/links';
import { useSimpleTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { getSplittedCssValues } from 'components/styles/helpers';

const LinkButtonBlock = ({ model }) => {
  const borderWidthValues = model.button_BorderWidth && getSplittedCssValues(model.button_BorderWidth);
  const paddingValues = model.button_Padding && getSplittedCssValues(model.button_Padding);
  
  const style = {
    [`${themeVarPrefix}button_Color`]: model.button_Color,
    [`${themeVarPrefix}button_Hover_Color`]: model.button_Hover_Color,
    [`${themeVarPrefix}button_TextShadow`]: model.button_TextShadow,
    [`${themeVarPrefix}button_BackgroundColor`]: model.button_BackgroundColor,
    [`${themeVarPrefix}button_Hover_BackgroundColor`]: model.button_Hover_BackgroundColor,
    [`${themeVarPrefix}button_BorderStyle`]: model.button_BorderStyle,
    [`${themeVarPrefix}button_BorderWidth`]: model.button_BorderWidth,
    [`${themeVarPrefix}button_BorderRadius`]: model.button_BorderRadius,
    [`${themeVarPrefix}button_BorderColor`]: model.button_BorderColor,
    [`${themeVarPrefix}button_Hover_BorderColor`]: model.button_Hover_BorderColor,
    [`${themeVarPrefix}button_ShowIcon`]: model.button_ShowIcon,
    [`${themeVarPrefix}button_IconPosition`]: model.button_IconPosition,
    [`${themeVarPrefix}button_IconContent`]: model.button_Icon ? `url("${model.button_Icon}")` : null,
    [`${themeVarPrefix}button_Hover_IconContent`]: model.button_Hover_Icon ? `url("${model.button_Hover_Icon}")` : null,
    [`${themeVarPrefix}button_SkewIconBackground`]: model.button_SkewIconBackground,
    [`${themeVarPrefix}button_IconBackgroundColor`]: model.button_IconBackgroundColor,
    [`${themeVarPrefix}button_Hover_IconBackgroundColor`]: model.button_Hover_IconBackgroundColor,
    [`${themeVarPrefix}button_BorderTopWidth`]: borderWidthValues ? borderWidthValues[0] : null,
    [`${themeVarPrefix}button_BorderBottomWidth`]: borderWidthValues ? borderWidthValues[2] || borderWidthValues[0] : null,
    [`${themeVarPrefix}button_PaddingTop`]: paddingValues ? paddingValues[0] : null,
    [`${themeVarPrefix}button_PaddingRight`]: paddingValues ? paddingValues[1] || paddingValues[0] : null,
    [`${themeVarPrefix}button_PaddingBottom`]: paddingValues ? paddingValues[2] || paddingValues[0] : null,
    [`${themeVarPrefix}button_PaddingLeft`]: paddingValues ? paddingValues[3] || paddingValues[1] || paddingValues[0] : null,
    width: model.button_FullWidth ? '100%' : null,
  };

  return (
    <LinkButton
      url={model.link?.url}
      to={model.link?.to}
      target={model.link?.target}
      aria-label={model.buttonAltText}
      className={styles.element}
      style={style}
    >
      {model.isSanaText
        ? useSimpleTexts([model.buttonText], makeRichText).texts[0]
        : model.buttonText
      }
    </LinkButton>
  );
};

LinkButtonBlock.propTypes = {
  model: PropTypes.shape({
    buttonText: PropTypes.string,
    buttonAltText: PropTypes.string,
    link: PropTypes.object,
    button_Color: PropTypes.string,
    button_BackgroundColor: PropTypes.string,
    button_Hover_BackgroundColor: PropTypes.string,
    button_BottomBorderColor: PropTypes.string,
    button_Hover_BottomBorderColor: PropTypes.string,
  }),
  isDesignerMode: PropTypes.bool,
};

export default LinkButtonBlock;
