import { memo } from 'react';
import PropTypes from 'prop-types';

const Tag = ({ tracker, children }) => {
  if (!tracker)
    return null;

  const script = `(function(w,d,l,id) {
w[l]=w[l]||[],w[l].push({start:(new Date).getTime(),event:"stg.start"});var scripts=d.getElementsByTagName('script')[0],tags=d.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}d.cookie=a+"="+b+d+"; path=/"}
var isStgDebug=(w.location.href.match("stg_debug")||d.cookie.match("stg_debug"))&&!w.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];l!=="dataLayer"&&qP.push("data_layer_name="+l),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="//sana-commerce.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&w[l].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(w,"ppms",["tm","cm"]);
})(window,document,'${tracker.dataLayerName}','${tracker.containerId}');`;

  return children(<script>{script}</script>);
};

Tag.propTypes = {
  tracker: PropTypes.shape({
    containerId: PropTypes.string.isRequired,
    dataLayerName: PropTypes.string.isRequired,
  }),
  children: PropTypes.func.isRequired,
};

export default memo(Tag);