import BroadcastChannel from 'broadcast-channel';
import { fromEvent, Observable } from 'rxjs';
import { tap, mapTo, share, filter, distinctUntilChanged } from 'rxjs/operators';

type AuthTokenStorageOptions = {
  broadcast: boolean;
  initialToken?: string;
};

type AuthTokenStorage = {
  saveValue(token: string | null, broadcast?: boolean): void;
  getValue(): string | undefined | null;
  new$?: Observable<any>;
};

export function createAuthTokenStorage({ broadcast = false, initialToken = undefined }: AuthTokenStorageOptions) {
  let currentValue: string | null | undefined = initialToken;
  let currentHashCode = getHashCode(initialToken);
  let channel: BroadcastChannel | undefined;

  const result: AuthTokenStorage = {
    saveValue(token: string | null, broadcast?: boolean) {
      if (currentValue !== token) {
        currentValue = token;

        if (broadcast && channel) {
          currentHashCode = getHashCode(token);
          channel.postMessage(currentHashCode);
        }
      }
    },
    getValue() {
      return currentValue;
    },
  };

  if (broadcast) {
    channel = new BroadcastChannel('auth', { webWorkerSupport: false });
    const messages$ = fromEvent(channel, 'message').pipe(
      distinctUntilChanged(),
      filter(hashCode => currentHashCode !== hashCode),
      tap(_ => currentValue = undefined),
      mapTo({}),
      share(),
    );

    Object.defineProperty(result, 'new$', {
      writable: false,
      value: messages$,
    });
  }

  function getHashCode(token: string | null | undefined): number | undefined {
    if (token == null)
      return undefined;

    if (token.length === 0)
      return 0;

    let hash = 0;
    for (let i = 0; i < token.length; i++) {
      hash = ((hash << 5) - hash) + token.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }

    return hash;
  }

  return result;
}
