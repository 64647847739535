import styles from './Heading.module.scss';
import { memo, FunctionComponent } from 'react';
import { HeadingStyle } from './constants';

type Props = {
  headingStyle: HeadingStyle;
  color?: string;
  backgroundColor?: string;
};

const Heading: FunctionComponent<Props> = ({ headingStyle, color, backgroundColor, children }) => {
  const headingStyles = {
    color,
    backgroundColor,
  };

  switch (headingStyle) {
    case HeadingStyle.DisplayHeading1:
      return <div className={`dh1 ${styles.container}`} style={headingStyles}>{children}</div>;
    case HeadingStyle.DisplayHeading2:
      return <div className={`dh2 ${styles.container}`} style={headingStyles}>{children}</div>;
    case HeadingStyle.DisplayHeading3:
      return <div className={`dh3 ${styles.container}`} style={headingStyles}>{children}</div>;
    case HeadingStyle.Heading1:
      return <h1 className={styles.container} style={headingStyles}>{children}</h1>;
    case HeadingStyle.Heading2:
      return <h2 className={styles.container} style={headingStyles}>{children}</h2>;
    case HeadingStyle.Heading3:
      return <h3 className={styles.container} style={headingStyles}>{children}</h3>;
    case HeadingStyle.Heading4:
      return <h4 className={styles.container} style={headingStyles}>{children}</h4>;
    case HeadingStyle.Heading5:
      return <h5 className={styles.container} style={headingStyles}>{children}</h5>;
    default:
      return null;
  }
};

export default memo(Heading);