import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import ErrorButton from './ErrorButton';
import { makeSimpleText } from 'utils/render';
import { isBrowser } from 'utils/detections';

const ErrorId = ({ id, containerId }) => {
  const options = ['ErrorId'];
  options.disableInsiteEditor = true;
  const format = useSanaTexts(options).texts[0] || 'Error ID: {0}';

  return (
    // Aria-label is added to avoid multiple announcing of the same content by NVDA screen reader in Firefox.
    <div id={containerId} aria-label={makeSimpleText(format, [id])}>
      {makeSimpleText(
        format,
        [(ErrorButton.canCopy || !isBrowser) ? <ErrorButton id={id} className={linkStyles.link} disableInsiteEditor /> : id],
        true,
      )}
    </div>
  );
};

ErrorId.propTypes = {
  id: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
};

export default ErrorId;
