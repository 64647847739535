export const LOADING_INDICATOR_SET = 'LOADING_INDICATOR_SET' as const;
export const setLoadingIndicator = () => ({
  type: LOADING_INDICATOR_SET,
});

export const LOADING_INDICATOR_UNSET = 'LOADING_INDICATOR_UNSET' as const;
export const unsetLoadingIndicator = () => ({
  type: LOADING_INDICATOR_UNSET,
});

export type LoadingIndicatorAction = ReturnType<
  | typeof setLoadingIndicator
  | typeof unsetLoadingIndicator
>;
