import DesktopCascadingMainMenu from './mainNav/desktop/CascadingMainMenuBlock';
import DesktopMultiColumnMainMenu from './mainNav/desktop/MultiColumnMainMenuBlock';
import MobileMainMenu from './mainNav/mobile/MainMenuBlock';
import LinkMenu from './linkMenu/LinkMenuBlock';
import DesktopHeaderMenu from './headerMenu/desktop/DesktopHeaderMenuBlock';

export default {
  DesktopCascadingMainMenu,
  DesktopMultiColumnMainMenu,
  MobileMainMenu,
  LinkMenu,
  DesktopHeaderMenu,
};
