export const enum ViewMode {
  List = 'List',
  Grid = 'Grid',
}

export const enum PagingType {
  Links = 'Links',
  LoadMore = 'LoadMore',
  ContinuousScrolling = 'ContinuousScrolling',
}
