import { isEqual } from 'lodash';
import { visualDesignerHeader, insiteEditorHeader } from './constants';
import { isInsiteEditorLocation, isVisualDesignerLocation } from 'behavior/visualDesigner';
import type { Api } from 'utils/api';
import type { AppLocation } from 'behavior/routing/types';
import type { ColumnContentElement, ContentBlock, VisualDesignerRow } from 'behavior/content';

type FindRowResult = {
  row: VisualDesignerRow;
  rowIndex: number;
};

type FindColumnResult = FindRowResult & {
  column: ColumnContentElement<ContentBlock>;
  columnIndex: number;
};

type FindContentBlockResult = FindColumnResult & {
  contentBlock: ContentBlock;
  contentBlockIndex: number;
};

type ModifyRowActionType = (arg: VisualDesignerRow) => VisualDesignerRow;
type ModifyColumnActionType = (arg: ColumnContentElement<ContentBlock>) => ColumnContentElement<ContentBlock>;
type ModifyContentBlockActionType = (arg: ContentBlock) => ContentBlock;
export function modifyContentElementById(
  id: string,
  content: VisualDesignerRow[],
  modifyAction: ModifyRowActionType | ModifyColumnActionType | ModifyContentBlockActionType): VisualDesignerRow[] {

  const result = findContentElementById(id, content);
  if (!result)
    return content;

  const { row: oldRow, column: oldColumn, contentBlock: oldBlock } = result as FindContentBlockResult;
  if (id === oldRow.id) {
    const modifyRowAction = modifyAction as ModifyRowActionType;
    return replaceById(id, content, modifyRowAction(oldRow));
  }
  if (id === oldColumn?.id) {
    const modifyColumnAction = modifyAction as ModifyColumnActionType;
    const newRow = {
      ...oldRow,
      columns: replaceById(id, oldRow.columns, modifyColumnAction(oldColumn)),
    };
    return replaceById(oldRow.id, content, newRow);
  }
  if (id === oldBlock?.id && !!oldColumn) {
    const modifyContentBlockAction = modifyAction as ModifyContentBlockActionType;
    const newColumn = {
      ...oldColumn,
      contentBlocks: replaceById(id, oldColumn.contentBlocks, modifyContentBlockAction(oldBlock)),
    };
    const newRow = {
      ...oldRow,
      columns: replaceById(oldColumn.id, oldRow.columns, newColumn),
    };
    return replaceById(oldRow.id, content, newRow);
  }

  return content;
}

function replaceById<T extends { id: string }>(id: string, array: T[], newItem: T) {
  return array.map(item => item.id === id ? newItem : item);
}

type FindContentElementByIdResult = FindRowResult | FindColumnResult | FindContentBlockResult | null;
export function findContentElementById(id: string, content: VisualDesignerRow[]): FindContentElementByIdResult {
  function find<T>(array: T[], func: (element: T, index: number) => FindContentElementByIdResult): FindContentElementByIdResult {
    for (let i = 0; i < array.length; i++) {
      const result = func(array[i], i);
      if (result)
        return result;
    }
    return null;
  }

  const result = find(content, (row, rowIndex) => {
    if (row.id === id) {
      return { row, rowIndex };
    }

    return find(row.columns, (column, columnIndex) => {
      if (column.id === id) {
        return { row, rowIndex, column, columnIndex };
      }

      return find(column.contentBlocks, (contentBlock, contentBlockIndex) => {
        if (contentBlock.id === id) {
          return { row, rowIndex, column, columnIndex, contentBlock, contentBlockIndex };
        }

        return null;
      });
    });
  });

  return result;
}

export function mergePageContentData(oldData: VisualDesignerRow[] | null, newData: VisualDesignerRow[]) {
  if (oldData) {
    newData = newData.map(rowData => {
      rowData.columns = rowData.columns.map(columnData => {
        columnData.contentBlocks = columnData.contentBlocks.map(contentBlockData => {
          const result = findContentElementById(contentBlockData.id, oldData);
          const oldContentBlockDataModel = (result && 'contentBlock' in result) ? result.contentBlock?.model : null;
          if (isEqual(oldContentBlockDataModel, contentBlockData.model))
            contentBlockData.model = oldContentBlockDataModel;

          return contentBlockData;
        });
        return columnData;
      });
      return rowData;
    });
  }
  return newData;
}

export function initApiForVisualDesigner(location: AppLocation, api: Api) {
  if (!isVisualDesignerLocation(location))
    return;

  api.headers.add(visualDesignerHeader, 'true');
  isInsiteEditorLocation(location) && api.headers.add(insiteEditorHeader, 'true');
}

type Units = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ColumnStructure = Units[];
export function updateStructure(row: VisualDesignerRow, columnsStructure: ColumnStructure): ColumnContentElement<ContentBlock>[] {
  return row.columns.map((col, index) => {
    const colspan = createColspan(columnsStructure[index]);
    return {
      ...col,
      colspan,
      weight: columnsStructure[index],
    };
  });
}

export function getColumnsStructure(columnsLength: number): ColumnStructure {
  const maxUnits = 12;
  const columnsStructure: ColumnStructure = [];
  const unitsPerCol = Math.floor(maxUnits / columnsLength) as Units;

  for (let i = 0; i < columnsLength; i++) {
    columnsStructure.push(unitsPerCol);
  }

  const totalUnits = unitsPerCol * columnsLength;
  if (totalUnits !== maxUnits) {
    let freeUnits = maxUnits - totalUnits;
    do {
      for (let i = 0; i < columnsLength; i++) {
        if (!freeUnits)
          break;

        columnsStructure[i]++;
        freeUnits--;
      }
    }
    while (freeUnits);
  }

  return columnsStructure;
}

function createColspan(units: Units) {
  return {
    sm: units,
    md: units,
    lg: units,
  };
}

export function isRow(findResult: FindContentElementByIdResult): findResult is FindRowResult {
  return (!!findResult && !('column' in findResult));
}

export function isColumn(findResult: FindContentElementByIdResult): findResult is FindColumnResult {
  return (!!findResult && !('contentBlock' in findResult) && 'column' in findResult);
}
