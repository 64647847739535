export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED' as const;
export const submit = (data: { email: string; captcha?: string | null }, updatedById: string) => ({
  type: FORGOT_PASSWORD_REQUESTED,
  payload: { data, updatedById },
});

export const FORGOT_PASSWORD_RECEIVED = 'FORGOT_PASSWORD_RECEIVED' as const;
export const received = () => ({ type: FORGOT_PASSWORD_RECEIVED });

export type ForgotPasswordAction = ReturnType<
  | typeof submit
  | typeof received
>;
