import type {
  ProductListPageAction,
  ProductsUpdatedAction,
  ProductsGeneralInfoLoadedAction,
  RequestProductsGeneralInfoAction,
  ProductListItemWarehousesStockUpdatedAction,
} from './actions';
import type { ViewerChangedAction } from 'behavior/events';
import {
  PRODUCTLIST_PRODUCTS_UPDATED,
  PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED,
  PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED,
  PRODUCTLIST_WAREHOUSES_STOCK_RECEIVED,
} from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import {  } from 'behavior/user';
import { createReducer } from 'utils/redux';
import { PageComponentNames } from '../componentNames';
import { sortOptionsAreEqual } from './helpers';
import { deleteProductCalculatedInfo } from 'behavior/products/product';
import {
  CalculatedProduct,
  CalculatedVariantsProduct,
  Product as GeneralProduct,
  ProductVariants,
  SortField,
} from './types';
import { WarehouseStock } from '../product/types';

export type Product = (GeneralProduct
  | GeneralProduct & CalculatedProduct
  | GeneralProduct & ProductVariants
  | GeneralProduct & ProductVariants & CalculatedVariantsProduct
  | GeneralProduct & CalculatedProduct & ProductVariants
  | GeneralProduct & CalculatedProduct & ProductVariants & CalculatedVariantsProduct)
  & {
    calculated?: boolean;
    calculatedInfoChanged?: boolean;
    appended?: boolean;
  };

type ProductListPageState = {
  component: PageComponentNames.ProductList | PageComponentNames.Search | PageComponentNames.ProductsWithCategory;
  products: Product[] | undefined;
  totalCount?: number;
  productGroupsCount?: number;
  productsCount?: number;
  selectedSorting?: SortField | null;
};

export default createReducer<ProductListPageState, ProductListPageAction | ViewerChangedAction>(null as unknown as ProductListPageState, {
  [PRODUCTLIST_PRODUCTS_UPDATED]: onProductsUpdated,
  [PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED]: onProductsGeneralInfoLoaded,
  [PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED]: onProductsRequested,
  [VIEWER_CHANGED]: onViewerChanged,
  [PRODUCTLIST_WAREHOUSES_STOCK_RECEIVED]: onProductListItemWarehousesStockReceived,
});

function onProductsUpdated(state: ProductListPageState, action: ProductsUpdatedAction) {
  if (!state?.products)
    return state;

  const calculatedProducts: Array<CalculatedProduct | CalculatedVariantsProduct | ProductVariants> = action.payload;
  const updatedProducts = state.products.map(product => {
    const calculatedInfo = calculatedProducts.find(i => i.id === product.id);
    if (!calculatedInfo)
      return { ...product, calculatedInfoChanged: false };

    const calculatedInfoChanged = 'price' in calculatedInfo;
    return { ...product, ...calculatedInfo, calculated: true, calculatedInfoChanged };
  });

  return {
    ...state,
    products: updatedProducts,
  };
}

function onProductsGeneralInfoLoaded(state: ProductListPageState, action: ProductsGeneralInfoLoadedAction) {
  if (!productListComponentNames.has(state.component))
    return state;

  const {
    products,
    appendProducts,
    pageSize,
    totalCount,
    productGroupsCount,
    productsCount,
    sorting,
  } = action.payload;

  const result = updatePageProducts(state, products, appendProducts, pageSize);

  if (totalCount)
    result.totalCount = totalCount;

  if (productGroupsCount)
    result.productGroupsCount = productGroupsCount;

  if (productsCount)
    result.productsCount = productsCount;

  if (sorting && !sortOptionsAreEqual(sorting, result.selectedSorting))
    result.selectedSorting = sorting;

  return result;
}

function updatePageProducts(state: ProductListPageState, products: Array<GeneralProduct>, appendProducts: boolean, pageSize: number): ProductListPageState {
  if (!state.products)
    return state;

  const normalizedProductsCount = pageSize - state.products.length % pageSize;
  const updatedProducts: Array<Product> = appendProducts
    ? [...state.products, ...products.slice(-normalizedProductsCount)]
    : products;

  return {
    ...state,
    products: ResetCalculatedInfoChanged(updatedProducts),
  };
}

function ResetCalculatedInfoChanged(updatedProducts: Array<Product>): Array<Product> {
  return updatedProducts.map(p => ({ ...p, calculatedInfoChanged: false }));
}

function onProductsRequested(state: ProductListPageState, action: RequestProductsGeneralInfoAction) {
  const { options: { sorting } } = action.payload;
  if (!sorting)
    return state;

  const [sort] = sorting;
  if (sortOptionsAreEqual(sort, state.selectedSorting))
    return state;

  return { ...state, selectedSorting: sort };
}

const productListComponentNames = new Set([
  PageComponentNames.ProductList,
  PageComponentNames.Search,
  PageComponentNames.ProductsWithCategory,
]);

function onViewerChanged(state: ProductListPageState, _action: ViewerChangedAction): ProductListPageState {
  if (!state.products || !productListComponentNames.has(state.component))
    return state;

  const products: Array<Product> = state.products.map(product => {
    const updatedProduct = deleteProductCalculatedInfo(product as CalculatedProduct) as Product;

    delete updatedProduct.appended;
    delete updatedProduct.calculatedInfoChanged;

    return updatedProduct;
  });

  return { ...state, products };
}

function onProductListItemWarehousesStockReceived(state: ProductListPageState, action: ProductListItemWarehousesStockUpdatedAction) {
  if (!state?.products)
    return state;

  if(!action.payload.warehousesStock)
    return state;
  
  const warehousesStock: Array<WarehouseStock> = action.payload.warehousesStock;
  const productId: string = action.payload.id;
  const updatedProducts = state.products.map(product => {
    if (product.id != productId)
      return { ...product, calculatedInfoChanged: false };
    
    return { ...product, warehousesStock: warehousesStock};
  });

  return {
    ...state,
    products: updatedProducts,
  };
}
