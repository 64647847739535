import { PASSWORD_RESET_RESULT, ResetPasswordAction } from './actions';

type State = {
  result: {
    success: boolean;
  };
};

export default function resetPasswordReducer(state: State, action: ResetPasswordAction) {
  switch (action.type) {
    case PASSWORD_RESET_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
}
