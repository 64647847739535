export const EDIT_0R_CREATE_MY_GARAGE_VEHICLE = 'EDIT_0R_CREATE_MY_GARAGE_VEHICLE';
export const EDIT_0R_CREATE_MY_GARAGE_VEHICLE_RECEIVED = 'EDIT_0R_CREATE_MY_GARAGE_VEHICLE_RECEIVED';
export const GET_MY_GARAGE_VEHICLES = 'GET_MY_GARAGE_VEHICLES';
export const GET_MY_GARAGE_VEHICLES_RECEIVED = 'GET_MY_GARAGE_VEHICLES_RECEIVED';
export const CHANGE_ORDER_GARAGE_VEHICLES = 'CHANGE_ORDER_GARAGE_VEHICLES';
export const CHANGE_ORDER_GARAGE_VEHICLES_RECEIVED = 'CHANGE_ORDER_GARAGE_VEHICLES_RECEIVED';

export const editOrCreateMyGarageVehicle = (input, files) => ({
  type: EDIT_0R_CREATE_MY_GARAGE_VEHICLE,
  payload: { input, files },
});

export const editOrCreateMyGarageVehicleReceived = options => ({
  type: EDIT_0R_CREATE_MY_GARAGE_VEHICLE_RECEIVED,
  payload: { options },
});

export const getMyGarageVehicles = () => ({
  type: GET_MY_GARAGE_VEHICLES,
});

export const getMyGarageVehiclesReceived = options => ({
  type: GET_MY_GARAGE_VEHICLES_RECEIVED,
  payload: { options },
});

export const changeOrderGarageVehicles = input => ({
  payload: { input },
  type: CHANGE_ORDER_GARAGE_VEHICLES,
});

export const changeOrderGarageVehiclesReceived = options => ({
  type: CHANGE_ORDER_GARAGE_VEHICLES_RECEIVED,
  payload: { options },
});