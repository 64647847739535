import { memo, useMemo, useEffect, useRef, ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import { fixIncorrectScrollAnchoring } from 'utils/helpers';

type Props = {
  expanded?: boolean;
  duration?: number;
  containerClass?: string | null;
  timingFunction?: string;
  onEnter?: (node: HTMLElement) => void;
  onEntering?: (node: HTMLElement) => void;
  onEntered?: (node: HTMLElement) => void;
  onExit?: (node: HTMLElement) => void;
  onExiting?: (node: HTMLElement) => void;
  onExited?: (node: HTMLElement) => void;
  children: ReactNode;
} & Omit<JSX.IntrinsicElements['div'], 'className' | 'style'>;

/**
  * @typedef {Object} ComponentProps
  * @property {boolean} expanded - Property indicating transition state - from zero height to children content height(expanded) or vice versa(collapsed)
  * @property {number} duration - Transition duration in milliseconds.
  * @property {string} containerClass - Custom class passed for additional styling to wrapper element.
  * @property {string} timingFunction - Timing function name for height transition.
  * @property {function} onEnter - Callback which is invoked before react-transition-group Transition component "entering" status is applied.
  * @property {function} onEntering - Callback which is invoked after react-transition-group Transition component "entering" status is applied.
  * @property {function} onEntered - Callback which is invoked after react-transition-group Transition component "entered" status is applied.
  * @property {function} onExit - Callback which is invoked before react-transition-group Transition component "exiting" status is applied.
  * @property {function} onExiting - Callback which is invoked after react-transition-group Transition component "exiting" status is applied.
  * @property {function} onExited - Callback which is invoked after react-transition-group Transition component "exited" status is applied.
  * @property {React.ReactNode} children - Anything that can be rendered by React: numbers, strings, another React elements.
  * @returns {React.ReactNode} - children wrapped up with the component.
  */

/**
  * Component which provides height transition from one component state to another over time
  * @param {ComponentProps}
  */

const VerticalSliding = ({
  expanded,
  duration = 200,
  containerClass,
  timingFunction = 'ease-in-out',
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
  children,
  ...attributes
}: Props) => {
  const timeoutRef = useRef<number>();

  const setContainerDisplayStyle = (node: HTMLElement) => {
    const height = parseInt(node.style.height, 10);
    node.style.display = (!expanded && height === 0) ? 'none' : '';
  };

  const handleEnter = (node: HTMLElement) => {
    toggleOverflowAnchorState(true);
    setContainerDisplayStyle(node);
    onEnter && onEnter(node);
  };

  const handleEntering = (node: HTMLElement) => {
    node.style.height = `${node.scrollHeight}px`;
    onEntering && onEntering(node);
  };

  const handleEntered = (node: HTMLElement) => {
    timeoutRef.current = setTimeout(toggleOverflowAnchorState, 50);
    node.style.height = 'auto';
    node.style.overflow = 'visible';

    onEntered && onEntered(node);
    fixIncorrectScrollAnchoring();
  };

  const handleExit = (node: HTMLElement) => {
    toggleOverflowAnchorState(true);
    node.style.height = `${node.scrollHeight}px`;
    onExit && onExit(node);
  };

  const handleExiting = (node: HTMLElement) => {
    timeoutRef.current = window.setTimeout(() => {
      node.style.height = '0';
      node.style.overflow = 'hidden';

      onExiting && onExiting(node);
    }, 25);
  };

  const handleExited = (node: HTMLElement) => {
    setContainerDisplayStyle(node);
    timeoutRef.current = window.setTimeout(toggleOverflowAnchorState, 50);
    onExited && onExited(node);
  };

  const defaultStyles = useMemo(() => ({
    display: !expanded ? 'none' : null,
    height: expanded ? 'auto' : '0',
    transitionProperty: 'height',
    transitionDuration: `${duration}ms`,
    transitionTimingFunction: timingFunction,
    overflow: expanded ? 'visible' : 'hidden',
  }), [duration, timingFunction]);

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  return (
    <Transition
      in={expanded}
      timeout={duration}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      onExited={handleExited}
    >
      <div
        className={containerClass}
        style={defaultStyles}
        {...attributes}
      >
        {children}
      </div>
    </Transition>
  );
};

export default memo(VerticalSliding);

export function toggleOverflowAnchorState(shouldDisable?: boolean) {
  /* "overflow-anchor" is non-standard property which used for opt out of the browser's scroll anchoring behavior,
    which adjusts scroll position to minimize content shifts. It is implemented and enabled by default in
    Chrome, Firefox and Opera and causes unexpected behaviour when browser tab layout is dynamically changing
    its height, so during this action it should be temporarily disabled. */
  document.body.style.overflowAnchor = shouldDisable ? 'none' : '';
}
