import type { Error, ErrorResponse } from './types';
import { catchError } from 'rxjs/operators';

type FieldError = {
  message: string;
  fieldName: string | undefined;
};

export function catchApiValidationErrors(errorsHandler: (errors: Array<FieldError>) => any) {
  return catchError((e: ErrorResponse) => {
    if (e.response && e.response.errors && e.response.errors.length) {
      const errors: Array<FieldError> = [];

      for (let i = 0, l = e.response.errors.length; i < l; i++) {
        const error = e.response.errors[i];
        const errorCode = error.extensions?.code;

        if (errorCode !== 'INVALID_INPUT')
          continue;

        errors.push({
          message: error.message,
          fieldName: getErrorFieldName(error),
        });
      }

      if (errors.length)
        return errorsHandler(errors);
    }

    throw e;
  });
}

function getErrorFieldName(error: Error) {
  const path = error.path;
  if (!path)
    return;

  return path.join('.');
}
