import { useSelector, shallowEqual, DefaultRootState } from 'react-redux';
import {
  sm, md, lg, xl,
  breakpoints_Small, breakpoints_Medium, breakpoints_Large, breakpoints_ExtraLarge,
} from 'css/exports/_breakpoints.scss';
import { useContext } from 'react';
import MatchMediaContext from './MatchMediaContext';

export const useMatchMediaContext = () => useContext(MatchMediaContext);

export const useBreakpoints = () => useSelector(selectBreakpoints, shallowEqual);

function selectBreakpoints(state: DefaultRootState) {
  const themeValues = state.theme.values || {};
  return {
    sm: +(themeValues[breakpoints_Small] || sm),
    md: +(themeValues[breakpoints_Medium] || md),
    lg: +(themeValues[breakpoints_Large] || lg),
    xl: +(themeValues[breakpoints_ExtraLarge] || xl),
  };
}
