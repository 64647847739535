import { default as createHandler } from './handler';
import {
  ordersPageQuery,
  quotesPageQuery,
  invoicesPageQuery,
  returnOrdersPageQuery,
  creditNotesPageQuery,
  returnReceiptsPageQuery,
  shipmentsPageQuery,
} from './queries';
import { PageComponentNames } from '../componentNames';
import { DocumentType } from 'behavior/documents';

export type { 
  DocumentsPage,
  Document,
  Order,
  Quote,
  Invoice,
  ReturnOrder,
  ReturnReceipt,
  CreditNote,
  Shipment,
  Filter,
  DocumentCollection,
  HeaderSorting,
} from './types';

export { requestDocuments } from './actions';

export { normalizeFilter, createOptions } from './handler';
export const ordersHandler = createHandler(DocumentType.Order, PageComponentNames.Orders, ordersPageQuery);
export const quotesHandler = createHandler(DocumentType.Quote, PageComponentNames.Quotes, quotesPageQuery);
export const invoicesHandler = createHandler(DocumentType.Invoice, PageComponentNames.Invoices, invoicesPageQuery);
export const returnOrdersHandler = createHandler(DocumentType.ReturnOrder, PageComponentNames.ReturnOrders, returnOrdersPageQuery);
export const creditNotesHandler = createHandler(DocumentType.CreditNote, PageComponentNames.CreditNotes, creditNotesPageQuery);
export const returnReceiptsHandler = createHandler(DocumentType.ReturnReceipt, PageComponentNames.ReturnReceipts, returnReceiptsPageQuery);
export const shipmentsHandler = createHandler(DocumentType.Shipment, PageComponentNames.Shipments, shipmentsPageQuery);
