import PropTypes from 'prop-types';
import { ShopAccountType } from 'behavior/user';
import AnonymousMenuItems from './AnonymousMenuItems';
import CustomerMenuItems from './CustomerMenuItems';
import ImpersonatedCustomerMenuItems from './ImpersonatedCustomerMenuItems';
import SalesAgentMenuItems from './SalesAgentMenuItems';

const AccountMenuItems = ({ isAuthenticated, isImpersonating, shopAccountType, logoutDelay }) => {
  if (!isAuthenticated)
    return <AnonymousMenuItems />;

  if (shopAccountType === ShopAccountType.SalesAgent) {
    return isImpersonating
      ? <ImpersonatedCustomerMenuItems logoutDelay={logoutDelay} />
      : <SalesAgentMenuItems logoutDelay={logoutDelay} />;
  }

  return <CustomerMenuItems logoutDelay={logoutDelay} />;
};

AccountMenuItems.propTypes = {
  isAuthenticated: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  shopAccountType: PropTypes.string,
  logoutDelay: PropTypes.number,
};

export default AccountMenuItems;
