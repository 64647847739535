import type { LocalStorage } from 'utils/localStorage';

const productComparisonStorageKey = 'productComparison.products';
const widgetCollapsedStorageKey = 'productComparison.widgetCollapsed';

type ProductComparisonSettings = {
  maxProductsToCompare: number;
};

type ProductSettings = {
  productComparison: ProductComparisonSettings;
};

export const getProductsFromStorage = (localStorage: LocalStorage, settings: ProductSettings) => {
  const items = localStorage.getItem<string>(productComparisonStorageKey);
  if (!items)
    return [];

  const productIds = JSON.parse(items);
  return getProductIdsToCompare(productIds, settings.productComparison);
};

export const getProductIdsToCompare = (productIds: string[], productComparisonSettings: ProductComparisonSettings) => {
  const maxProductsToCompare = productComparisonSettings.maxProductsToCompare;
  const productIdsToCompare = productIds.length < maxProductsToCompare
    ? productIds
    : productIds.slice(productIds.length - maxProductsToCompare);

  return productIdsToCompare;
};

export const addProductToStorage = (localStorage: LocalStorage, settings: ProductSettings, productId: string) => {
  let ids = getProductsFromStorage(localStorage, settings);
  if (ids.some(id => id === productId))
    return;

  ids = [...ids, productId];

  localStorage.setItem(productComparisonStorageKey, JSON.stringify(ids));
};

export const removeProductFromStorage = (localStorage: LocalStorage, settings: ProductSettings, productId: string) => {
  const ids = getProductsFromStorage(localStorage, settings);
  const stringifiedArray = JSON.stringify(ids.filter(id => id !== productId));

  localStorage.setItem(productComparisonStorageKey, stringifiedArray);
};

export const removeAllProductsFromStorage = (localStorage: LocalStorage) => {
  localStorage.removeItem(productComparisonStorageKey);
};

export const setWidgetCollapsedState = (localStorage: LocalStorage, collapsed: boolean) =>
  localStorage.setItem(widgetCollapsedStorageKey, collapsed);

export const getWidgetCollapsedState = (localStorage: LocalStorage) =>
  localStorage.getItem(widgetCollapsedStorageKey);
