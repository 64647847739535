import styles from './Alert.module.scss';
import { SuccessIcon } from 'components/primitives/icons';
import Alert, { Props as AlertProps } from './Alert';

type Props = Omit<AlertProps, 'icon'>;

const SuccessAlert = ({ children, className = '', ...props }: Props) => (
  <Alert className={`${styles.success} ${className}`} icon={SuccessIcon} {...props}>
    {children}
  </Alert>
);

export default SuccessAlert;
