import { SanaForm } from 'components/objects/forms';
import { Button } from 'components/primitives/buttons';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useDispatch, useSelector } from 'react-redux';
import {
  productFiltersBlockSelectedValueChanged,
  productFieldMakeCollectionReceived,
  productFieldModelCollectionReceived,
} from 'behavior/productFilterBlock';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Typeahead as TypeHead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  productFieldMakeLoadCollection,
  productFieldModelLoadCollection,
  productFieldYearLoadCollection,
} from 'behavior/productFilterBlock/actions';
import renderMake from './renderMenuMakes';
import styles from './ProductFilterBlock.module.scss';
import buttonStyles from 'components/primitives/buttons/Button.module.scss';
import Sticky from 'react-sticky-el';
import classNames from 'classnames';
import { editOrCreateMyGarageVehicle } from 'behavior/pages/myGarage';
import { CustomerType } from 'behavior/user';
import { RouteName } from 'routes';
import { navigateTo } from 'behavior/events';
import { useLayoutShifter } from 'utils/layout';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

const ProductFilterBlock = ({ productFilterBlock, routeData, onApply, className }) => {

  const [editMyGarage, viewYMMSearchAbility] = useHasAbilities(AbilityTo.EditMyGarage, AbilityTo.ViewYMMSearchAbility);
  const [appliedClass] = useState(false);
  const [isYMMSelected, setIsYMMSelected] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(s => s.user);
  const [
    year,
    make,
    model,
    apply,
    selectVehicle,
    cannotFindModelCallToSupport,
    clearFilters,
    saveToGarage,
  ] = useSanaTexts(
    [
      'Year',
      'Make',
      'Model',
      'Apply',
      'SelectVehicle',
      'CannotFindModelCallToSupport',
      'ClearFilters',
      'SaveToGarage',
    ],
    makeSimpleText,
  ).texts;

  const { topFixedElementsHeight } = useLayoutShifter();

  const getSelectedFilterValue = name => {
    const values =
      productFilterBlock.selectedProductFilters &&
      productFilterBlock.selectedProductFilters.find(f => f.name === name)
        ?.values;
    return values || [];
  };

  const [selectedYear, setSelectedYearState] = useState(
    getSelectedFilterValue('Year'),
  );
  const [selectedMake, setSelectedMakeState] = useState(
    getSelectedFilterValue('Make'),
  );
  const [selectedModel, setSelectedModelState] = useState(
    getSelectedFilterValue('Model'),
  );

  useEffect(() => {
    if (productFilterBlock.productFilterYears.length === 0) {
      dispatch(
        productFieldYearLoadCollection({
          filters: [{ name: 'Year', values: [] }],
        }),
      );
    }
  },[productFilterBlock.productFilterYears.length]);

  useEffect(() => {
    const year = getSelectedFilterValue('Year');
    const make = getSelectedFilterValue('Make');
    const model = getSelectedFilterValue('Model');
    setSelectedYearState(year);
    setSelectedMakeState(make);
    setSelectedModelState(model);

    if (make?.length === 0) {
      dispatch(productFieldMakeCollectionReceived([]));
    }
    if (model?.length === 0) {
      dispatch(productFieldModelCollectionReceived([]));
    }
    
    if(year.length > 0 || make.length > 0 || model.length > 0)
    {
      setIsYMMSelected(true);
    }
    else
    {
      setIsYMMSelected(false);
    }
  }, [productFilterBlock.selectedProductFilters]);

  const setSelectedFilterValue = selectedDataFilters => {
    dispatch(productFiltersBlockSelectedValueChanged(selectedDataFilters));
  };

  const onChangeYear = selectedYear => {
    clearMakeData();
    clearModelData();
    const selectedYearValues = selectedYear.map(year => year.value);
    setSelectedYearState(selectedYear);
    if (selectedYearValues?.length > 0) {
      dispatch(
        productFieldMakeLoadCollection({
          filters: [{ name: 'Year', values: selectedYearValues }],
        }),
      );
    }
  };

  const onChangeMake = selectedMake => {
    clearModelData();
    const selectedYearValues = selectedYear?.map(year => year.value);
    const selectedMakeValues = selectedMake.map(make => make.value);
    setSelectedMakeState(selectedMake);
    if (selectedYearValues?.length > 0 && selectedMakeValues?.length > 0) {
      dispatch(
        productFieldModelLoadCollection({
          filters: [
            { name: 'Year', values: selectedYearValues },
            { name: 'Make', values: selectedMakeValues },
          ],
        }),
      );
    }
  };

  const onChangeModel = selectedModel => {
    setSelectedModelState(selectedModel);
  };

  const onClickApply = () => {
    if (
      selectedYear.length > 0 &&
      selectedMake.length > 0 &&
      selectedModel.length > 0
    ) {
      setSelectedFilterValue([
        { name: 'Year', values: selectedYear },
        { name: 'Make', values: selectedMake },
        { name: 'Model', values: selectedModel },
      ]);
    }
    if(!(routeData.routeName === RouteName.Search || routeData.routeName === RouteName.ProductList)) {
      dispatch(navigateTo(null, RouteName.Search));
    }
    onApply({ selectedYear, selectedMake, selectedModel });
  };

  const onCLickClearSelectedFilters = () => {
    setSelectedFilterValue([]);
    onApply({ selectedYear, selectedMake, selectedModel });
  };

  const filterBy = (option, state) => {
    if (state.selected.length) {
      return true;
    }
    return option.value.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  };

  const clearMakeData = () => {
    setSelectedMakeState([]);
    dispatch(productFieldMakeCollectionReceived([]));
  };

  const clearModelData = () => {
    setSelectedModelState([]);
    dispatch(productFieldModelCollectionReceived([]));
  };

  const onClickSaveToGarage = () => {
    dispatch(
      editOrCreateMyGarageVehicle({
        year: selectedYear[0].value,
        make: selectedMake[0].value,
        model: selectedModel[0].value,
      }),
    );
  };

  const setActive = event => {
    event.target.closest('div').classList.add('active');
  };

  const removeActive = event => {
    event.target.closest('div').classList.remove('active');
  };

  if(!viewYMMSearchAbility)
    return null;

  return (
    <SanaForm
      name="productFilterBlock"
      className={`${styles.productFilterListContainer} ${className}`}
    >
      <Sticky
        mode="top"
        stickyClassName={classNames(
          !appliedClass ? styles.sticky : styles.stickyApplied,
          topFixedElementsHeight > 0 ? styles.inpersonatedLine : '',
        )}
        topOffset={-64}
        positionRecheckInterval={10}
      >
        <div className={styles.wrapper}>
          <div className={styles.wrapperCnt}>
            <div className={styles.topText}>{selectVehicle}</div>
            <div className={styles.content}>
              <TypeHead
                id={'Year'}
                options={productFilterBlock.productFilterYears}
                labelKey="value"
                filterBy={filterBy}
                onChange={onChangeYear}
                placeholder={year}
                selected={selectedYear}
                className={styles.productFilterList}
                shouldSelect
                disabled={productFilterBlock.productFilterYears.length === 0 || isYMMSelected}
                onFocus={setActive}
                onBlur={removeActive}
              />
              <TypeHead
                id={'Make'}
                options={productFilterBlock.productFilterMakes}
                labelKey="value"
                filterBy={filterBy}
                onChange={onChangeMake}
                placeholder={make}
                selected={selectedMake}
                renderMenu={renderMake}
                className={styles.productFilterList}
                shouldSelect
                disabled={productFilterBlock.productFilterMakes.length === 0 || isYMMSelected}
                onFocus={setActive}
                onBlur={removeActive}
              />
              <TypeHead
                id={'Model'}
                options={productFilterBlock.productFilterModels}
                labelKey="value"
                filterBy={filterBy}
                onChange={onChangeModel}
                placeholder={model}
                selected={selectedModel}
                className={styles.productFilterList}
                shouldSelect
                disabled={productFilterBlock.productFilterModels.length === 0 || isYMMSelected}
                onFocus={setActive}
                onBlur={removeActive}
              />
              {!isYMMSelected ? (
                <Button
                  onClick={onClickApply}
                  size={'sm'}
                  theme={'action'}
                  className={styles.btnApply}
                  disabled={
                    selectedYear.length === 0 ||
                    selectedMake.length === 0 ||
                    selectedModel.length === 0
                  }
                >
                  {apply}
                </Button>
              ) : (
                <Button
                  onClick={onCLickClearSelectedFilters}
                  size={'sm'}
                  theme={'regular'}
                  className={styles.btnClear}
                >
                  {clearFilters}
                </Button>
              )}
              {editMyGarage && user.isAuthenticated && user.shopAccountType !== CustomerType.Sales && (
                <Button
                  onClick={onClickSaveToGarage}
                  className={`${buttonStyles.btn} ${buttonStyles.btnSmall} ${styles.btnSaveToGarage}`}
                  disabled={
                    selectedYear.length === 0 ||
                    selectedMake.length === 0 ||
                    selectedModel.length === 0
                  }
                >
                  {saveToGarage}
                </Button>
              )}
            </div>
            <div className={styles.bottomText}>
              {cannotFindModelCallToSupport}
            </div>
          </div>
        </div>
      </Sticky>
    </SanaForm>
  );
};

ProductFilterBlock.defaultProps = {
  onApply: () => {},
};

ProductFilterBlock.propTypes = {
  productFilterBlock: PropTypes.object,
  onApply: PropTypes.func,
  className: PropTypes.string,
  routeData: PropTypes.object,
};

export default connect(({ productFilterBlock,  routing: { routeData } }) => ({
  productFilterBlock,
  routeData,
}))(ProductFilterBlock);
