import { useSelector } from 'react-redux';
import { DomHead } from 'components/primitives/dom';

const Injection = () => {
  const content = useSelector(s => s.settings.loaded && s.settings.htmlInjections.head);
  return content
    ? <DomHead content={content} />
    : null;
};

export default Injection;
