export const PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_REQUESTED = 'PRODUCT_CONFIGURATOR/NEW/CONFIGURATION_REQUESTED' as const;
export const requestNewProductConfiguration = (input: {
  configuratorId: string;
  modelId: string;
  productId: string;
  quantity: number;
  uomId: null | string;
}, updatedById: string) => ({
  type: PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_REQUESTED,
  payload: { input, updatedById },
});

export const PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED = 'PRODUCT_CONFIGURATOR/NEW/CONFIGURATION_RECEIVED' as const;
export const receiveNewProductConfiguration = ({ configurationId, configuratorUrl }: { configurationId: string; configuratorUrl: string }) => ({
  type: PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED,
  payload: { configurationId, configuratorUrl },
});

export const PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_REQUESTED = 'PRODUCT_CONFIGURATOR/EDIT/CONFIGURATION_REQUESTED' as const;
export const requestEditProductConfiguration = (configurationId: string, basketLineId: string, updatedById: string) => ({
  type: PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_REQUESTED,
  payload: { configurationId, basketLineId, updatedById },
});

export const PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_RECEIVED = 'PRODUCT_CONFIGURATOR/EDIT/CONFIGURATION_RECEIVED' as const;
export const receiveEditProductConfiguration = ({
  configurationId,
  basketLineId,
  configuratorUrl,
}: {
  configurationId: string;
  basketLineId: string;
  configuratorUrl: string;
}) => ({
  type: PRODUCT_CONFIGURATOR_EDIT_CONFIGURATION_RECEIVED,
  payload: { configurationId, basketLineId, configuratorUrl },
});

export const PRODUCT_CONFIGURATOR_CONFIGURATION_FAILED = 'PRODUCT_CONFIGURATOR/CONFIGURATION_FAILED ' as const;
export const productConfigurationFailed = (updatedById: string) => ({
  type: PRODUCT_CONFIGURATOR_CONFIGURATION_FAILED,
  payload: updatedById,
});

export const PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED = 'PRODUCT_CONFIGURATOR/MESSAGE_RECEIVED' as const;
export const receiveProductConfiguratorMessage = (message: MessageEvent) => ({
  type: PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED,
  payload: { message },
});

export const PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED = 'PRODUCT_CONFIGURATOR/ADD_TO_BASKET/TRIGGERED' as const;
export const triggerAddProductConfigurationToBasket = (configurationId: string) => ({
  type: PRODUCT_CONFIGURATOR_ADD_TO_BASKET_TRIGGERED,
  payload: { configurationId },
});

export const PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED = 'PRODUCT_CONFIGURATOR/ADD_TO_BASKET/COMPLETED' as const;
export const completeAddProductConfigurationToBasket = () => ({
  type: PRODUCT_CONFIGURATOR_ADD_TO_BASKET_COMPLETED,
});

export const PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED = 'PRODUCT_CONFIGURATOR/REDIRECT_TO_BASKET/TRIGGERED' as const;
export const triggerRedirectToBasket = () => ({
  type: PRODUCT_CONFIGURATOR_REDIRECT_TO_BASKET_TRIGGERED,
});

export const PRODUCT_CONFIGURATOR_MODIFY_BASKET_TRIGGERED = 'PRODUCT_CONFIGURATOR/MODIFY_BASKET/TRIGGERED' as const;
export const triggerModifyBasket = (configurationId: string, editedBasketLineId: string) => ({
  type: PRODUCT_CONFIGURATOR_MODIFY_BASKET_TRIGGERED,
  payload: { configurationId, editedBasketLineId },
});

export const PRODUCT_CONFIGURATOR_FINISHED = 'PRODUCT_CONFIGURATOR/FINISHED' as const;
export const finishProductConfigurator = () => ({
  type: PRODUCT_CONFIGURATOR_FINISHED,
});

export type ProductConfiguratorAction = ReturnType<
  | typeof requestNewProductConfiguration
  | typeof receiveNewProductConfiguration
  | typeof requestEditProductConfiguration
  | typeof receiveEditProductConfiguration
  | typeof productConfigurationFailed
  | typeof receiveProductConfiguratorMessage
  | typeof triggerAddProductConfigurationToBasket
  | typeof completeAddProductConfigurationToBasket
  | typeof triggerRedirectToBasket
  | typeof triggerModifyBasket
  | typeof finishProductConfigurator
>;