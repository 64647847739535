import type { RouteName } from 'routes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useRoutesChecker = (routes: RouteName[], usePrevRoute: boolean) => {
  const currentRouteName = useSelector(({ routing: { routeData, previous } }) => {
    const prevRouteName = previous?.routeData?.routeName;
    return usePrevRoute && prevRouteName ? prevRouteName : routeData?.routeName;
  });

  return useMemo(() => {
    return currentRouteName && routes.includes(currentRouteName);
  }, [currentRouteName, routes]);
};
