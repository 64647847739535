import type { LoadNewsResponse } from './types';
import { loadNewsQuery } from './queries';
import { NEWS_LOAD, newsLoaded, NewsAction } from './actions';
import { createApiCallEpic } from '../index';

const newsEpic = createApiCallEpic<NewsAction, LoadNewsResponse>(
  NEWS_LOAD,
  loadNewsQuery,
  data => newsLoaded(data.news.list.items),
);

export default newsEpic;
