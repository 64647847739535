import {
  VISUAL_DESIGNER_UPDATE_VIEW_PAGE,
  VISUAL_DESIGNER_PREVIEW_ADD_ROW,
  VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW,
  VISUAL_DESIGNER_PREVIEW_DELETE_ROW,
  VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT,
  VISUAL_DESIGNER_PREVIEW_COPY_CONTENT_BLOCK,
  updatePage,
  previewAddRow,
  previewDuplicateRow,
  previewDeleteRow,
  previewMoveContentElement,
  previewCopyContentBlock,
} from './actions';
import { arrayMove, generateKey } from 'utils/helpers';
import {
  mergePageContentData,
  modifyContentElementById,
  findContentElementById,
  getColumnsStructure,
  updateStructure,
  isColumn,
  isRow,
} from './helpers';
import { rowTemplate } from './constants';
import type { ColumnContentElement, ContentBlock, VisualDesignerRow } from 'behavior/content';
import type { VisualDesignerPageAction } from './actions';

type VisualDesignerPageState = {
  content: VisualDesignerRow[];
  revision?: number;
  defaultTitle?: string;
  translationTitle?: string;
};

const initialState: VisualDesignerPageState = { content: [] };

export default (state = initialState, action: VisualDesignerPageAction): VisualDesignerPageState => {
  switch (action.type) {
    case VISUAL_DESIGNER_UPDATE_VIEW_PAGE:
      return onUpdateViewPage(state, action);
    case VISUAL_DESIGNER_PREVIEW_ADD_ROW:
      return onVisualDesignerPreviewAddRow(state, action);
    case VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW:
      return onVisualDesignerPreviewDuplicateRow(state, action);
    case VISUAL_DESIGNER_PREVIEW_DELETE_ROW:
      return onVisualDesignerPreviewDeleteRow(state, action);
    case VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT:
      return onVisualDesignerPreviewMoveContentElement(state, action);
    case VISUAL_DESIGNER_PREVIEW_COPY_CONTENT_BLOCK:
      return onVisualDesignerPreviewCopyContentBlock(state, action);
      
    default: return state;
  }
};

function onUpdateViewPage(state: VisualDesignerPageState, action: ReturnType<typeof updatePage>) {
  const { page } = action.payload;
  page.content = mergePageContentData(state.content, page.content);

  return {
    ...state,
    ...page,
    revision: !state.revision ? 1 : state.revision + 1,
  };
}

function onVisualDesignerPreviewAddRow(state: VisualDesignerPageState, action: ReturnType<typeof previewAddRow>) {
  const { index, id } = action.payload;
  const newRow = { ...rowTemplate, id: id || generateKey() };
  const content = [
    ...state.content.slice(0, index),
    newRow,
    ...state.content.slice(index),
  ];

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewDuplicateRow(state: VisualDesignerPageState, action: ReturnType<typeof previewDuplicateRow>) {
  const { index, id } = action.payload;
  const newRow = { ...rowTemplate, id: id || generateKey() };
  const content = [
    ...state.content.slice(0, index + 1),
    newRow,
    ...state.content.slice(index + 1),
  ];

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewDeleteRow(state: VisualDesignerPageState, action: ReturnType<typeof previewDeleteRow>) {
  const content = state.content.filter((_, i) => i !== action.payload.index);

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewMoveContentElement(state: VisualDesignerPageState, action: ReturnType<typeof previewMoveContentElement>) {
  const { indexBefore, indexAfter } = action.payload;

  if (!('sourceElementId' in action.payload)) {
    // move row
    return {
      ...state,
      content: arrayMove(state.content, indexBefore, indexAfter),
    };
  }

  const { targetElementId, sourceElementId } = action.payload;
  const sourceResult = findContentElementById(sourceElementId, state.content);
  let newContent: VisualDesignerRow[] | undefined;

  if (!targetElementId || targetElementId === sourceElementId) {
    if (isColumn(sourceResult)) {
      // move content element in the same column
      newContent = modifyContentElementById(sourceElementId, state.content,
        (sourceElement: ColumnContentElement<ContentBlock>) => ({
          ...sourceElement,
          contentBlocks: arrayMove(sourceElement.contentBlocks, indexBefore, indexAfter),
        }));
    }
    else {
      // move column in the same row
      newContent = modifyContentElementById(sourceElementId, state.content, (sourceElement: VisualDesignerRow) => ({
        ...sourceElement,
        columns: arrayMove(sourceElement.columns, indexBefore, indexAfter),
      }));
    }
  } else {
    const targetResult = findContentElementById(targetElementId, state.content);

    if (isColumn(sourceResult) && isColumn(targetResult)) {
      // move content element into the different column
      newContent = moveContentBlock(state.content, sourceResult.row, sourceResult.column, indexBefore, targetResult.row, targetResult.column, indexAfter);
    }
    else if (isRow(sourceResult) && isRow(targetResult)) {
      // move column into the different row
      newContent = moveColumn(state.content, sourceResult.row, indexBefore, targetResult.row, indexAfter);
    }
  }

  if (!newContent)
    return state;

  return {
    ...state,
    content: newContent,
  };
}

function onVisualDesignerPreviewCopyContentBlock(state: VisualDesignerPageState, action: ReturnType<typeof previewCopyContentBlock>) {
  const { indexBefore, indexAfter, sourceElementId, targetElementId } = action.payload;
  const sourceResult = findContentElementById(sourceElementId, state.content);

  if (!sourceResult || !isColumn(sourceResult))
    return state;

  const blockDuplicate = Object.assign({}, sourceResult.column.contentBlocks[indexBefore]);
  blockDuplicate.id = generateKey();

  const newContent = modifyContentElementById(targetElementId, state.content, (targetColumn: ColumnContentElement<ContentBlock>) => ({
    ...targetColumn,
    contentBlocks: insertElement(targetColumn.contentBlocks, indexAfter, blockDuplicate),
  }));

  return {
    ...state,
    content: newContent,
  };
}

function moveContentBlock(
  content: VisualDesignerRow[],
  sourceRow: VisualDesignerRow,
  sourceColumn: ColumnContentElement<ContentBlock>,
  indexBefore: number, targetRow: VisualDesignerRow,
  targetColumn: ColumnContentElement<ContentBlock>,
  indexAfter: number,
) {
  const blockToMove = sourceColumn.contentBlocks[indexBefore];
  const newSourceColumn = {
    ...sourceColumn,
    contentBlocks: deleteElement(sourceColumn.contentBlocks, indexBefore),
  };
  const newTargetColumn = {
    ...targetColumn,
    contentBlocks: insertElement(targetColumn.contentBlocks, indexAfter, blockToMove),
  };

  if (sourceRow === targetRow) {
    const newRow = {
      ...sourceRow,
      columns: sourceRow.columns.map(column => {
        if (column.id === newSourceColumn.id)
          return newSourceColumn;
        if (column.id === newTargetColumn.id)
          return newTargetColumn;
        return column;
      }),
    };

    return content.map(row => row.id === newRow.id ? newRow : row);
  }
  else {
    const newSourceRow = {
      ...sourceRow,
      columns: sourceRow.columns.map(column => column.id === newSourceColumn.id ? newSourceColumn : column),
    };
    const newTargetRow = {
      ...targetRow,
      columns: targetRow.columns.map(column => column.id === newTargetColumn.id ? newTargetColumn : column),
    };

    return content.map(row => {
      if (row.id === sourceRow.id)
        return newSourceRow;
      else if (row.id === targetRow.id)
        return newTargetRow;
      else
        return row;
    });
  }
}

function moveColumn(
  content: VisualDesignerRow[],
  sourceRow: VisualDesignerRow,
  indexBefore: number,
  targetRow: VisualDesignerRow,
  indexAfter: number,
) {
  const columnToMove = sourceRow.columns[indexBefore];
  const newSourceRow = {
    ...sourceRow,
    columns: deleteElement(sourceRow.columns, indexBefore),
  };
  const newTargetRow = {
    ...targetRow,
    columns: insertElement(targetRow.columns, indexAfter, columnToMove),
  };

  return content.map(row => {
    if (row.id === sourceRow.id) {
      const sourceColumnsStructure = getColumnsStructure(newSourceRow.columns.length);
      return {
        ...newSourceRow,
        columns: updateStructure(newSourceRow, sourceColumnsStructure),
      };
    } else if (row.id === targetRow.id) {
      const targetColumnsStructure = getColumnsStructure(newTargetRow.columns.length);
      return {
        ...newTargetRow,
        columns: updateStructure(newTargetRow, targetColumnsStructure),
      };
    }

    return row;
  });
}

function insertElement<T>(array: T[], index: number, element: T) {
  const newArray = [...array];
  newArray.splice(index, 0, element);
  return newArray;
}

function deleteElement<T>(array: T[], index: number) {
  const newArray = [...array];
  newArray.splice(index, 1);
  return newArray;
}
