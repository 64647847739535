import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const NewsListPage = loadable(() => import(/*webpackChunkName:"news"*/'./NewsListPage'));
const NewsDetailsPage = loadable(() => import(/*webpackChunkName:"news"*/'./NewsDetailsPage'));

/* eslint-disable react/no-multi-comp */
export default {
  [PageComponentNames.News]: page => <NewsListPage header={page.content?.header} footer={page.content?.footer} />,
  [PageComponentNames.NewsDetails]: _page => <NewsDetailsPage />,
};
