import styles from './Error.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import ErrorId from './ErrorId';

const ErrorToastWithReload = forwardRef(({ timeId, labelId, descriptionId }, ref) => {
  const onClick = () => window.location.reload();

  const options = ['ReloadErrorMessageText', 'ButtonText_Reload'];
  options.disableInsiteEditor = true;

  const { texts: [msgText, btnText], loaded } = useSimpleTexts(options);

  return (
    <div className={styles.body}>
      <p id={labelId}>
        {loaded
          ? msgText || 'Unfortunately there was an issue with retrieving the information for this page.'
          : <Placeholder className={styles.loading} />
        }
      </p>
      {loaded
        ? (
          <button
            ref={ref}
            className={`${linkStyles.link} ${styles.actionBtn}`}
            onClick={onClick}
          >
            {btnText || 'Reload'}
          </button>
        )
        : <Placeholder className={styles.loading} />
      }
      {/* Element with id equal to descriptionId should be present in layout to avoid W3C validation error "The aria-describedby attribute
          must point to an element in the same document" on toast wrapper. */}
      {timeId ? <ErrorId id={timeId} containerId={descriptionId} /> : <i id={descriptionId} />}
    </div>
  );
});

ErrorToastWithReload.propTypes = {
  timeId: PropTypes.string,
  labelId: PropTypes.string,
  descriptionId: PropTypes.string,
};

export default ErrorToastWithReload;

export const preload = {
  texts: [
    'ReloadErrorMessageText',
    'ButtonText_Reload',
  ],
};
