import modalStyles from 'components/objects/modals/Modal.module.scss';
import styles from './FullScreenPopup.module.scss';
import { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import scrollLock from 'scroll-lock';
import { useLayoutShifter } from 'utils/layout';
import { useFocusLockWhiteList } from 'utils/hooks';
import { joinClasses } from 'utils/helpers';
import { CrossBigIcon } from 'components/primitives/icons';
import { useSimpleTexts } from 'components/sanaText';
import type { ReactNode, HTMLProps, KeyboardEvent } from 'react';

type Props = {
  children: ReactNode;
  hide: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
} & Omit<HTMLProps<HTMLDivElement>, 'onKeyDown'>;

const Popup = ({ children, hide, onKeyDown, className, ...props }: Props) => {
  const { topShiftBlockHeight, bottomShiftBlockHeight } = useLayoutShifter();
  const [closeBtnTitle] = useSimpleTexts(['ButtonAltText_Close']).texts;

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      hide();
      return;
    }

    onKeyDown && onKeyDown(e);
  }, []);

  const modalContainer = document.getElementById('modals');
  if (!modalContainer)
    return null;

  const modalRef = useRef<HTMLDivElement>(null);
  const whiteList = useFocusLockWhiteList();

  useEffect(() => {
    scrollLock.disablePageScroll();
    return scrollLock.enablePageScroll;
  }, []);

  useEffect(() => {
    if (!modalRef.current)
      return;

    modalRef.current.style.top = topShiftBlockHeight + 'px';
    modalRef.current.style.bottom = bottomShiftBlockHeight + 'px';
  }, [!!modalRef.current, topShiftBlockHeight, bottomShiftBlockHeight]);

  const modalBody = (
    <FocusLock
      shards={[
        modalRef.current!,
        document.querySelector('.react-toast-notifications__container') as HTMLElement,
      ]}
      returnFocus
      whiteList={whiteList}
    >
      {/*eslint-disable-next-line jsx-a11y/no-static-element-interactions*/}
      <div
        className={joinClasses(modalStyles.modal, styles.popup, className)}
        onKeyDown={handleKeyDown}
        ref={modalRef}
        tabIndex={-1}
        {...props}
      >
        <div className={modalStyles.body}>
          {children}
        </div>
        <button className={styles.close} onClick={hide} title={closeBtnTitle}>
          <CrossBigIcon aria-hidden />
        </button>
      </div>
    </FocusLock>
  );

  return ReactDOM.createPortal(modalBody, modalContainer);
};

export default Popup;
