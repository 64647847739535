import type { Epic } from 'behavior/types';
import type { LastViewedProductsPageAction } from './actions';
import type { Product, CalculatedProduct } from '../types';
import { loadCalculatedFieldsQuery } from '../queries';
import { createLastViewedProductsQuery } from './queries';
import { map, takeUntil, mergeMap, switchMap, filter, withLatestFrom } from 'rxjs/operators';
import { LOCATION_CHANGED } from 'behavior/events';
import {
  LASTVIEWED_PRODUCTS_PAGE_REQUESTED,
  pageReceived,
  requestPage,
} from './actions';
import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { createApiVariables, sortProducts } from 'behavior/products/lastViewedTracking';

const lastViewedProductsPageEpic: Epic<LastViewedProductsPageAction> = (action$, state$, { api, localStorage }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const empty$ = of(pageReceived([]));

  const all$ = action$.pipe(
    ofType(LASTVIEWED_PRODUCTS_PAGE_REQUESTED),
    filter(({ payload: { calculatedOnly } }) => !calculatedOnly),
    withLatestFrom(state$),
    switchMap(([_, state]) => {
      const variables = createApiVariables(localStorage);
      if (!variables)
        return empty$;

      variables.loadCategories = state.analytics?.isTrackingEnabled;
      variables.options.ignoreGrouping = true;

      return api.graphApi<ProductsResponse>(createLastViewedProductsQuery({
        isInsiteEditor: state.insiteEditor.initialized,
      }), variables, { retries: 0 }).pipe(
        map(d => sortProducts(d.catalog.products?.products || [], variables!)),
        mergeMap(products => of(pageReceived(products!), requestPage(true))),
        takeUntil(locationChanged$),
      );
    }),
  );

  const calculated$ = action$.pipe(
    ofType(LASTVIEWED_PRODUCTS_PAGE_REQUESTED),
    filter(({ payload: { calculatedOnly } }) => calculatedOnly),
    map(_ => {
      const variables = createApiVariables(localStorage);
      if (variables)
        variables.options.ignoreGrouping = true;

      return variables;
    }),
    filter(Boolean),
    switchMap(variables => api.graphApi<CalculatedProductsResponse>(loadCalculatedFieldsQuery, variables).pipe(
      filter(d => !!d.catalog.products),
      map(d => pageReceived(d.catalog.products!.products)),
      takeUntil(locationChanged$),
    )),
  );

  return merge(all$, calculated$);
};

export default lastViewedProductsPageEpic;

type ProductsResponse = {
  catalog: {
    products: {
      products: Product[];
    } | null;
  };
};

type CalculatedProductsResponse = {
  catalog: {
    products: {
      products: CalculatedProduct[];
    } | null;
  };
};
