import type {
  Facets,
  BlogFacet,
  FacetValue,
  SelectedFacet,
  AvailableFacet,
  FacetSettings,
} from './types';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { alphanumSort } from 'behavior/facets';

export function getFacetFilter(facets: Record<string, string[]> | undefined, settings?: FacetSettings): Facets | undefined {
  if (facets) {
    const facetsArr = Object.entries(facets).map(([name, values]) => ({ name, values }));

    if (facetsArr.length)
      return { filter: facetsArr, settings };
  }

  if (settings)
    return { settings };

  return undefined;
}

export function createFacetsModel(facets: BlogFacet[]) {
  const selectedFacets: SelectedFacet[] = [];
  const availableFacets: AvailableFacet[] = [];

  for (const facet of facets) {
    const facetModel = {
      title: facet.title,
      textTitle: stripHtmlTagsAndFormatting(facet.title),
      name: facet.name,
      anySelected: false,
      values: [...facet.values],
    };

    const selectedFacetModel = {
      title: facet.title,
      name: facet.name,
      values: [] as FacetValue[],
    };

    for (const value of facet.values) {
      if (value.selected) {
        facetModel.anySelected = true;
        selectedFacetModel.values.push(value);
      }
    }

    if (selectedFacetModel.values.length) {
      selectedFacetModel.values = alphanumSort(selectedFacetModel.values);
      selectedFacets.push(selectedFacetModel);
    }

    alphanumSort(facetModel.values);
    availableFacets.push(facetModel);
  }

  return {
    selectedFacets,
    availableFacets,
  };
}
