import loadable from '@loadable/component';

export default {
  ProfileDocumentFilter: loadable(() => import(/*webpackChunkName:"documents"*/'./filter')),
  ProfileOrderList: loadable(() => import(/*webpackChunkName:"documents"*/'./orderList')),
  ProfileQuoteList: loadable(() => import(/*webpackChunkName:"documents"*/'./quoteList')),
  ProfileInvoiceList: loadable(() => import(/*webpackChunkName:"documents"*/'./invoiceList')),
  ProfileReturnOrderList: loadable(() => import(/*webpackChunkName:"documents"*/'./returnOrderList')),
  ProfileNewReturnOrderButton: loadable(() => import(/*webpackChunkName:"documents"*/'./newReturnOrderButton')),
  ProfileReturnReceiptList: loadable(() => import(/*webpackChunkName:"documents"*/'./returnReceiptList')),
  ProfileCreditNoteList: loadable(() => import(/*webpackChunkName:"documents"*/'./creditNoteList')),
  ProfileShipmentList: loadable(() => import(/*webpackChunkName:"documents"*/'./shipmentList')),
};
