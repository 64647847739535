import type { TrackerInfo } from './types';
import {
  AnalyticsAction,
  ANALYTICS_TRACKERS_INITIALIZE,
  ANALYTICS_DATALAYER_READY,
  ANALYTICS_DISABLE_TRACKING,
  InitializeAnalyticsTrackersAction,
} from './actions';
import trackerFactory from './trackerFactory';

export type AnalyticsState = {
  isTrackingEnabled: boolean;
  isDataLayerReady?: boolean;
  trackers?: TrackerInfo[];
} | null;

export default (state: AnalyticsState = null, action: AnalyticsAction): AnalyticsState => {
  switch (action.type) {
    case ANALYTICS_TRACKERS_INITIALIZE:
      return onTrackersInitialize(state, action);
    case ANALYTICS_DATALAYER_READY:
      return onDataLayerReady(state);
    case ANALYTICS_DISABLE_TRACKING:
      return { isTrackingEnabled: false };
    default: return state;
  }
};

function onTrackersInitialize(state: AnalyticsState, { payload }: InitializeAnalyticsTrackersAction): AnalyticsState {
  if (!!state)
    return state;

  const trackers = trackerFactory(payload);
  if (!trackers || !trackers.length) {
    return {
      isTrackingEnabled: false,
    };
  }

  return {
    isTrackingEnabled: true,
    trackers,
  };
}

function onDataLayerReady(state: AnalyticsState): AnalyticsState {
  return {
    ...state!,
    isDataLayerReady: true,
  };
}
