import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import type { StateObservable } from 'redux-observable';
import type { ProcessedImage, ProcessedMedia, ProductGroupPage } from './types';
import { parseContent, RowContentElementData } from 'behavior/content';

import { of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { productGroupPageQuery } from './queries';
import { areAnalyticsSettingsLoaded } from 'behavior/analytics';
import { requestCalculatedProductList } from './actions';
import { ProductMediaType } from '../product';
import { parseVideoData } from 'utils/video';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { productGroupPageTemplateLoaded } from 'behavior/pageTemplates';

type Params = {
  params: {
    id: string;
    language: number;
    previewToken?: string;
  };
};

export default ({ params: { id: groupId, language, previewToken } }: Params, state$: StateObservable<AppState>, { api }: StoreDependencies) => state$.pipe(
  first(areAnalyticsSettingsLoaded),
  switchMap(({ settings, analytics, pageTemplates }) => {
    if (!settings.product.productGrouping.isEnabled)
      return of(null);

    const pageTemplate = pageTemplates.productGroup;
    const loadTemplateContent = !pageTemplate || language !== pageTemplate.languageId || !!pageTemplate.expired;
    const options = {
      loadTemplateContent,
    };

    const query = productGroupPageQuery(options);
    const loadCategories = analytics.isTrackingEnabled;
    return api.graphApi<ProductGroupPageResponse>(query, { groupId, loadCategories }).pipe(
      map(({ pages: { productGroupDetails } }) => {
        if (!productGroupDetails)
          return null;

        const component = PageComponentNames.ProductGroup;
        const productGroup = productGroupDetails.productGroup;
        const backTo = previewToken
          ? undefined
          : getBackTo(state$, [RouteName.ProductGroupPage, RouteName.ProductDetails], language);

        const page: ProductGroupPage = {
          ...productGroup,
          metaTitle: productGroup.title,
          media: processMedia(productGroup.title, productGroup.media),
          component,
          backTo,
          products: productGroup.products.list,
          content: {
            desktop: productGroupDetails.content.desktop && parseContent(productGroupDetails.content.desktop),
            mobile: productGroupDetails.content.mobile && parseContent(productGroupDetails.content.mobile),
          },
        };

        if (productGroup.products.list.length === 0)
          return { page };

        const groupedProductIds = productGroup.products.list.map(p => p.id);

        return {
          page,
          action$: productGroupDetails.templateContent
            ? of(
              requestCalculatedProductList(groupedProductIds),
              productGroupPageTemplateLoaded(productGroupDetails.templateContent, language || null),
            )
            : of(requestCalculatedProductList(groupedProductIds)),
        };
      }),
    );
  }),
);

export const processMedia = (pageTitle: string, media?: Media): ProcessedMedia | undefined => {
  if (!media)
    return undefined;

  const results: ProcessedMedia = [];

  media.forEach(item => {
    switch (item.type) {
      case ProductMediaType.Video:
        const videoData = parseVideoData(item.url);

        if (!videoData)
          break;

        results.push({
          type: item.type,
          videoData,
          title: pageTitle,
        });
        break;

      case ProductMediaType.Image:
        if (item.small || item.medium || item.large) {
          if (item.title) {
            results.push(item as ProcessedImage);
          } else {
            results.push({
              ...item,
              title: pageTitle,
            });
          }
        }
        break;

      default:
        return;
    }
  });

  return results;
};

type ProductGroupPageResponse = {
  pages: {
    productGroupDetails: {
      productGroup: {
        id: string;
        title: string;
        description: string | null;
        media?: Array<{
          type: ProductMediaType.Image;
          small: string | null;
          medium: string | null;
          large: string | null;
          title: string | null;
        } | {
          type: ProductMediaType.Video;
          url: string;
        }> | null;
        products: {
          list: Array<{
            id: string;
            url: string | null;
            title: string | null;
            uom: {
              id: string;
            } | null;
            uoms: Array<{
              id: string;
              description: string | null;
              minimumQuantity: number | null;
              maximumQuantity: number | null;
              defaultQuantity: number | null;
              quantityStep: number;
            }> | null;
            stockLevels: {
              outOfStock: number;
              lowStock: number;
              maxStockNumber: number | null;
            } | null;
            categoriesPaths?: {
              categories: Array<{
                name: string;
              }>;
            };
          }>;
        };
      };
      templateContent?: {
        desktop: RowContentElementData[] | null;
        mobile: RowContentElementData[] | null;
      } | null;
      content: {
        desktop: RowContentElementData[] | null;
        mobile: RowContentElementData[] | null;
      };
    };
  };
};

type Media = Array<{
  type: ProductMediaType.Image;
  small: string | null;
  medium: string | null;
  large: string | null;
  title: string | null;
} | {
  type: ProductMediaType.Video;
  url: string;
}> | null;
